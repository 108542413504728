import { PageView } from "../../components"
import { UserContext } from "../../hooks/UserContext"
import { fetchHomeSummary } from "../../services/storeServices"
import { useQuery } from "react-query"
import { useContext } from "react"
import { MetricsContainer } from "./MetricsContainer"
import { OrderStatsContainer } from "./OrderStatsContainer"
import SelfOnboarding from "./SelfOnboarding"
import { useTranslation } from "react-i18next"
import { Title } from "@tremor/react"

export const Home = () => {
    const { user } = useContext(UserContext)
    const { t } = useTranslation()

    const {
        isLoading: isLoading,
        // isError: isError,
        // error,
        data: onboardingData,
        // isFetching,
        // isPreviousData,
        refetch,
    } = useQuery(["home_info", user.current_store?.id], () => fetchHomeSummary(user.current_store?.id), {
        staleTime: 1000 * 60 * 1, // after 1min, refetch if screen is focused
    })

    const onReloadData = () => {
        refetch()
    }

    return (
        <PageView>
            <section>
                <div className="mt-1 mb-5">
                    <Title className="font-semibold text-black !text-xl">{t("home.title", { store_name: user.current_store?.name })}</Title>
                </div>
            </section>

            {onboardingData && onboardingData["onboarding_data"] && onboardingData["onboarding_data"]["requires_onboarding"] && (
                <section>
                    <SelfOnboarding
                        onboardingData={onboardingData && onboardingData["onboarding_data"]}
                        showLoader={isLoading}
                        onReloadData={() => onReloadData()}
                    />
                </section>
            )}
            {user.current_store?.warehouses[0]?.country === "MX" && (
                <section>
                    <div className="rounded-lg bg-yellow-200 text-yellow-700 p-7 mb-5 mt-5">
                        <div className="font-bold">🚨 PREPÁRATE PARA EL BUEN FIN 2024 🚨</div>
                        <div className="mt-1">Los siguientes servicios serán suspendidos del 1 al 23 de noviembre:
                            <ul className="list-disc ml-5">
                                <li>Recibos de inventario.</li>
                                <li>Logística inversa.</li>
                                <li>Pedidos REM.</li>
                                <li>Maquilas.</li>
                            </ul>
                        </div><div className="mt-1">
                            Sobre tiempos de procesamiento y entrega:
                            <ul className="list-disc ml-5">
                                <li>Procesaremos todos los pedidos en un máximo de 48 hrs.</li>
                                <li>Los tiempos de entrega se extenderán hasta 48 hrs adicionales.</li>
                            </ul>
                        </div>
                    </div>
                </section>
            )}

            {user.current_store?.warehouses[0]?.country === "BR" && (
                <section>
                    <div className="rounded-lg bg-yellow-200 text-yellow-700 p-7 mb-10 mt-5 flex flex-col">
                        <div>Prezado parceiro Cubbo,</div>
                        <div>
                            As entregas para o <b>Rio Grande do Sul</b> estão sendo gradualmente normalizadas, mas os prazos podem ser mais longos do que o habitual. Estamos trabalhando para minimizar os atrasos e contamos com sua compreensão.                        </div>
                        <div>
                            Para dúvidas ou problemas, entre em contato pelo e-mail suporte@cubbo.com.
                        </div>
                        <br></br>
                        <div>
                            Agradecemos pelo apoio.
                        </div>
                    </div>
                </section>
            )}

            <section>
                <OrderStatsContainer storeId={user.current_store?.id} />
            </section>

            <section>
                <MetricsContainer storeId={user.current_store?.id} />
            </section>
        </PageView>
    )
}
