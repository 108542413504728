import { useTranslation } from "react-i18next"
import { getStatementOrdersDetail } from "../../../services/storeBillingStatementService"
import { useContext, useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { NewTable } from "../../../components"
import { setOrdersDetailListPageSize } from "../../../redux/pageSizeSlice"
import { useDispatch, useSelector } from "react-redux"
import { Loader } from "../../../components/Loader"
import { SHOW_ORDERS } from "../../../navigation"
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom"
import { STORE_PATH } from "../../../navigation/constants"
import { UserContext } from "../../../hooks/UserContext"
import { DownloadIcon } from "@heroicons/react/solid"

const QUERY_STALE_TIME = 1000 * 60 * 5 // 5 minutes

const SERVICE_SHIPPING_LABEL = "SHIPPING_LABEL"
const SERVICE_SHIPPING_LABEL_EXTENDED_ZONE = "SHIPPING_LABEL_EXTENDED_ZONE"
const SERVICE_INTERNATIONAL_SHIPPING_LABEL = "INTERNATIONAL_SHIPPING_LABEL"
const SERVICE_SHIPPING_LABEL_PICKUP = "SHIPPING_LABEL_PICKUP"
const SERVICE_RETURN_SHIPPING_LABEL = "RETURN_SHIPPING_LABEL"
const SERVICE_PICK_AND_PACK = "PICK_AND_PACK"
const SERVICE_PICK_AND_PACK_INSERT = "PICK_AND_PACK_INSERT"
const SERVICE_PICK_AND_PACK_SERIAL_NUMBER = "PICK_AND_PACK_SERIAL_NUMBER"
const SERVICE_PICK_AND_PACK_FRAGILE = "PICK_AND_PACK_FRAGILE"
const SERVICE_PICK_AND_PACK_PACKAGE = "PICK_AND_PACK_PACKAGE"
const SERVICE_PACKING_MATERIAL = "PACKING_MATERIAL"
const SERVICE_SHIPPING_INSURANCE = "SHIPPING_INSURANCE"
const SERVICE_DIFAL = "DIFAL"

const StoreBillingOrderDetail = ({ currency = "",  selectedStatementId, onExport = () => {}, exportBusy = false}) => {
    const pageSize = useSelector((state) => state?.pageSize.ordersDetailList)
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const {user} = useContext(UserContext)
    const { url: storePath } = useRouteMatch({ path: STORE_PATH })

    const initialQueryParams = {
        page: 1,
        store_id: user?.current_store?.id,
        store_billing_statement_id: selectedStatementId,
        per_page: pageSize
    }
    const [queryParams, setQueryParams] = useState(initialQueryParams)

    const {
        data,
        isLoading,
        isError,
        isPreviousData,
        isFetching,
        error,
        refetch
    } = useQuery(['statement_orders_detail', queryParams], () => getStatementOrdersDetail(queryParams),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: true,
            staleTime: QUERY_STALE_TIME
        })
    
    const dataOrders = useMemo(() => {
        if(!data) return []
        return data.orders
    },[data])
    
    const paginationMeta = useMemo(() => {
        if(!data) return {}
        return data.meta
    },[data]) 

    useEffect(() => {
        const newQueryParams = {
            ...queryParams,
            page: 1,
            store_billing_statement_id: selectedStatementId
        }
        setQueryParams(newQueryParams)
    },[selectedStatementId])

    const ordersDetail = useMemo(() => {
        if (!dataOrders) return []


        return dataOrders.map((order) => {
            let order_origin = order?.channel_name === "manual" ? null : order?.channel_name
            let order_number = order.order_number
            let extended_zone = false
            let international_shipping = false
            let carrier_name = order.shipping_method.carrier_name
            let order_destination = `${order.shipping.city}, ${order.shipping.state}`
            let packages_types = []
            let shipping_coverage_category = null
            let billed_products_quantity = 0
            let billed_products_price = 0
            let billed_inserts_quantity = 0
            let billed_inserts_price = 0
            let billed_output_control_products_quantity = 0
            let billed_output_control_products_price = 0
            let billed_fragile_quantity = 0
            let billed_fragile_price = 0
            let billed_packages_quantity = 0
            let billed_packages_price = 0
            let billed_shipping_labels_price = 0
            let billed_extended_zone_price = 0
            let billed_total_price_without_tax = 0
            let billed_packing_material_quantity = 0
            let billed_packing_material_price = 0
            let billed_shipping_insurance_quantity = 0
            let billed_shipping_insurance_price = 0
            let billed_difal_quantity = 0
            let billed_difal_price = 0

            let grouped_usages = []

            order.billing_service_usages.forEach(service_usage => {
                if (service_usage.statement_id !== initialQueryParams.store_billing_statement_id) return
                grouped_usages.push(service_usage)
            })

            order.shipping_labels.forEach(shipping_label => {
                shipping_label.billing_service_usages.forEach(service_usage => {
                    if (service_usage.statement_id !== initialQueryParams.store_billing_statement_id) return
                    grouped_usages.push(service_usage)
                })
            })

            grouped_usages.forEach(grouped_usage => {
                switch (grouped_usage.store_billing_service?.service_type) {
                    case SERVICE_PICK_AND_PACK:
                        billed_products_quantity += parseInt(grouped_usage.billed_usage)
                        billed_products_price += parseFloat(grouped_usage.billed_total_price)
                        break

                    case SERVICE_PICK_AND_PACK_INSERT:
                        billed_inserts_quantity += parseInt(grouped_usage.billed_usage)
                        billed_inserts_price += parseFloat(grouped_usage.billed_total_price)
                        break

                    case SERVICE_PICK_AND_PACK_SERIAL_NUMBER:
                        billed_output_control_products_quantity += parseInt(grouped_usage.billed_usage)
                        billed_output_control_products_price += parseFloat(grouped_usage.billed_total_price)
                        break

                    case SERVICE_PICK_AND_PACK_FRAGILE:
                        billed_fragile_quantity += parseInt(grouped_usage.billed_usage)
                        billed_fragile_price += parseFloat(grouped_usage.billed_total_price)
                        break

                    case SERVICE_PICK_AND_PACK_PACKAGE:
                        let package_type = grouped_usage.store_billing_service.service_type_category
                        let volumetric_weight = grouped_usage.billed_details["package_weight"]
                        
                        let package_description = packages_types.find(p => p.package_type_name === package_type)
                        if(package_description){
                            package_description.quantity += 1
                        }
                        else {
                            package_description = {
                                package_type_name: package_type,
                                volumetric_weight: volumetric_weight,
                                quantity: 1
                            }
                            packages_types.push(package_description)
                        }

                        billed_packages_quantity += 1
                        billed_packages_price += parseFloat(grouped_usage.billed_total_price)
                        break

                    case SERVICE_SHIPPING_LABEL:
                        if (!grouped_usage.billed_details["return_by_carrier_duplicated_usage"]) {
                            let coverage_category = grouped_usage.store_billing_service.service_type_category

                            billed_shipping_labels_price += parseFloat(grouped_usage.billed_total_price)
                            shipping_coverage_category = coverage_category
                        }
                        break

                    case SERVICE_SHIPPING_LABEL_PICKUP:
                        if (!grouped_usage.billed_details["return_by_carrier_duplicated_usage"]) {
                            let coverage_category = null

                            billed_shipping_labels_price += parseFloat(grouped_usage.billed_total_price)
                            shipping_coverage_category = coverage_category
                        }
                        break

                    case SERVICE_INTERNATIONAL_SHIPPING_LABEL:
                        if (!grouped_usage.billed_details["return_by_carrier_duplicated_usage"]) {
                            let option_value = true
                            let coverage_category = grouped_usage.store_billing_service.service_type_category

                            billed_shipping_labels_price += parseFloat(grouped_usage.billed_total_price)
                            international_shipping = option_value
                            shipping_coverage_category = coverage_category
                        }
                        break

                    case SERVICE_SHIPPING_LABEL_EXTENDED_ZONE:
                        let option_value = true

                        billed_extended_zone_price += parseFloat(grouped_usage.billed_total_price)
                        extended_zone = option_value
                        break

                    case SERVICE_RETURN_SHIPPING_LABEL:
                        break

                    case SERVICE_PACKING_MATERIAL:
                        billed_packing_material_quantity += parseInt(grouped_usage.billed_usage)
                        billed_packing_material_price += parseFloat(grouped_usage.billed_total_price)
                        break

                    case SERVICE_SHIPPING_INSURANCE:
                        billed_shipping_insurance_quantity += parseInt(grouped_usage.billed_usage)
                        billed_shipping_insurance_price += parseFloat(grouped_usage.billed_total_price)
                        break

                    case SERVICE_DIFAL:
                        billed_difal_quantity += parseInt(grouped_usage.billed_usage)
                        billed_difal_price += parseFloat(grouped_usage.billed_total_price)
                        break

                    default:
                        break
                }

                billed_total_price_without_tax += parseFloat(grouped_usage.billed_total_price)
            })

            return {
                id: order.id,
                order_origin: order_origin,
                order_number: order_number,
                extended_zone: extended_zone,
                international_shipping: international_shipping,
                carrier_name: carrier_name,
                order_destination: order_destination,
                packages_types: packages_types,
                shipping_coverage_category: shipping_coverage_category,
                billed_products_quantity: billed_products_quantity,
                billed_products_price: billed_products_price,
                billed_inserts_quantity: billed_inserts_quantity,
                billed_inserts_price: billed_inserts_price,
                billed_output_control_products_quantity: billed_output_control_products_quantity,
                billed_output_control_products_price: billed_output_control_products_price,
                billed_fragile_quantity: billed_fragile_quantity,
                billed_fragile_price: billed_fragile_price,
                billed_packages_quantity: billed_packages_quantity,
                billed_packages_price: billed_packages_price,
                billed_shipping_labels_price: billed_shipping_labels_price,
                billed_extended_zone_price: billed_extended_zone_price,
                billed_total_price_without_tax: billed_total_price_without_tax,
                billed_packing_material_quantity: billed_packing_material_quantity,
                billed_packing_material_price: billed_packing_material_price,
                billed_shipping_insurance_quantity: billed_shipping_insurance_quantity,
                billed_shipping_insurance_price: billed_shipping_insurance_price,
                billed_difal_quantity: billed_difal_quantity,
                billed_difal_price: billed_difal_price
            }
        })
    }, [dataOrders, paginationMeta, selectedStatementId])


    const filtersInitialData = [
        {
            key: "order_number",
            label: "billing.orders_detail.filters.order_number",
            placeholder: "billing.orders_detail.filters.order_number",
            data: {
                visible: true,
                type: 'text',
                value: ''
            },
        }
    ]

    const [filtersData, setFiltersData] = useState(filtersInitialData)

    const getTableData = () => ordersDetail?.map(order => {
        return {
            object: order,
            order_number: (
                <div>
                    <div>
                        <div className="hover:underline cursor-pointer text-blue-500">
                            {order.order_number}
                        </div>
                        <div className="text-sm text-gray-400 mt-1">{order.order_origin ? order.order_origin : i18n.t("billing.orders_detail.cubbo")}</div>
                    </div>
                </div>
            ),
            order_destination: (
                <div>
                    <div>{order.order_destination}</div>
                    <div className="text-sm text-gray-400 mt-1">{order.international_shipping ? i18n.t("billing.orders_detail.order_destination_categories.international") : i18n.t("billing.orders_detail.order_destination_categories.national")}</div>
                    {order.extended_zone &&
                        <div className="text-sm text-gray-400 mt-1">{i18n.t("billing.orders_detail.order_destination_categories.extended_zone")}</div>
                    }
                </div>
            ),
            shipping_method: (
                <div>
                    <div>{order.shipping_coverage_category ? i18n.t(`billing.orders_detail.shipping_coverage_categories.${order.shipping_coverage_category}`) : i18n.t("billing.orders_detail.shipping_coverage_categories.NONE_APPLIES")}</div>
                    <div className="text-sm text-gray-400 mt-1">{order.carrier_name}</div>
                </div>
            ),
            pick_and_pack: (
                <div>
                    <div><b>{currency}</b> {(order.billed_products_price + order.billed_inserts_price + order.billed_output_control_products_price + order.billed_packages_price + order.billed_fragile_price + order.billed_packing_material_price).toFixed(2)}</div>
                    <div className="text-sm text-gray-400 mt-1">
                        {order.billed_products_quantity > 0 &&
                            <div>{order.billed_products_quantity} {order.billed_products_quantity > 1 ? i18n.t("billing.orders_detail.picking_and_packing.products_quantity") : i18n.t("billing.orders_detail.picking_and_packing.product_quantity")}</div>
                        }
                        {order.billed_inserts_quantity > 0 &&
                            <div>{order.billed_inserts_quantity} {order.billed_inserts_quantity > 1 ? i18n.t("billing.orders_detail.picking_and_packing.inserts_quantity") : i18n.t("billing.orders_detail.picking_and_packing.insert_quantity")}</div>
                        }
                        {order.billed_output_control_products_quantity > 0 &&
                            <div>{order.billed_output_control_products_quantity} {order.billed_output_control_products_quantity > 1 ? i18n.t("billing.orders_detail.picking_and_packing.output_control_products_quantity") : i18n.t("billing.orders_detail.picking_and_packing.output_control_product_quantity")}</div>
                        }
                        {order.packages_types.length > 0 &&
                            order.packages_types.map((package_type, index) =>
                                <div>{package_type.quantity} {package_type.quantity > 1 ? i18n.t("billing.orders_detail.boxes") : i18n.t("billing.orders_detail.box") } {package_type.volumetric_weight}kg</div>
                            )
                        }
                        {order.billed_packing_material_quantity > 0 &&
                            <div>{order.billed_packing_material_quantity} {order.billed_packing_material_quantity > 1 ? "materiales de empaque" : "material de empaque"}</div>
                        }
                    </div>
                </div>
            ),
            shipping: (
                <div>
                    <div><b>{currency}</b> {(order.billed_shipping_labels_price + order.billed_extended_zone_price + order.billed_difal_price + order.billed_shipping_insurance_price).toFixed(2)}</div>
                    {order.billed_packages_quantity > 0 &&
                        <div className="text-sm text-gray-400 mt-1">{order.billed_packages_quantity} {order.billed_packages_quantity > 1 ? i18n.t("billing.orders_detail.packages") : i18n.t("billing.orders_detail.package")} {order.billed_fragile_quantity > 0 ? order.billed_fragile_quantity > 1 ? i18n.t("billing.orders_detail.picking_and_packing.fragiles_quantity") : i18n.t("billing.orders_detail.picking_and_packing.fragile_quantity") : ""}</div>
                    }
                    {order.billed_difal_quantity > 0 &&
                        <div className="text-sm text-gray-400 mt-1">{order.billed_difal_quantity} cargo por difal</div>
                    }
                    {order.billed_shipping_insurance_quantity > 0 &&
                        <div className="text-sm text-gray-400 mt-1">{order.billed_shipping_insurance_quantity} cargo por seguro de envío</div>
                    }
                </div>
            ),
            total: <div><b>{currency}</b> {(order.billed_total_price_without_tax).toFixed(2)}</div>
        }
    })

    const table_columns = [
        {
            Header: 'billing.orders_detail.order_number',
            accessor: 'order_number',
            searchAs: 'order_number',
            disableSortBy: true,
        },
        {
            Header: 'billing.orders_detail.order_destination',
            accessor: 'order_destination',
            searchAs: 'order_destination',
            disableSortBy: true,
        },
        {
            Header: 'billing.orders_detail.shipping_method',
            accessor: 'shipping_method',
            searchAs: 'shipping_method',
            disableSortBy: true,
        },
        {
            Header: 'billing.orders_detail.pick_and_pack',
            accessor: 'pick_and_pack',
            searchAs: 'pick_and_pack',
            disableSortBy: true,
        },
        {
            Header: 'billing.orders_detail.shipping',
            accessor: 'shipping',
            searchAs: 'shipping',
            disableSortBy: true,
        },
        {
            Header: 'billing.orders_detail.total',
            accessor: 'total',
            searchAs: 'total',
            disableSortBy: true,
        }
    ]

    const memoizedColumns = useMemo(() => table_columns)

    const showOrderDetail = (order) => {
        window.open(storePath + SHOW_ORDERS.replace(":id", order.id), '_blank')
    }

    const updateFiltersData = (updatedData, filter, override = false) => {
        setFiltersData((prev) => {
            return prev.map((item) => {
                if (item.key == filter)
                    return {
                        ...item,
                        data: updatedData
                    }
                else return item
            }).sort((a, b) => a.data.visible > b.data.visible ? -1 : 1) // Sort to preserve the order of the filters
        })
        let value = updatedData.value

        if (!value) {
            setQueryParams((prev) => {
                // Delete filter key from queryParams
                const newSearchParams = { ...prev, page: 1 }
                delete newSearchParams[[filter]]
                return newSearchParams
            })
        }
        else {
            setQueryParams((prev) => ({ ...prev, [filter]: value, page: 1 }))
        }

    }

    const onFilterChanged = (updatedData, filter) => {
        updateFiltersData(updatedData, filter)
    }

    const handleResetFilters = () => {
        setQueryParams(initialQueryParams)
    }

    return (
        <div className="bg-white min-h-10 relative">
            {
                !isPreviousData && isLoading ? (
                    <><Loader show={true}></Loader></>
                ): isError ? (
                    <>Error: {error.message}</>
                ):
                (
            <NewTable
                data={getTableData()}
                columns={memoizedColumns}
                showLoader={isFetching && isPreviousData}
                showPaginationOnFooter
                isFetching={isFetching}
                emptyTableText={i18n.t("billing.orders_detail.no_data")}
                paginationMeta={data?.meta}
                onPaginationChange={requestedPage => setQueryParams({ ...queryParams, page: requestedPage })}
                onPageSizeChange={(page) => {
                    setQueryParams({ ...queryParams, per_page: page.id, page: 1 })
                    dispatch(setOrdersDetailListPageSize(page.id))
                
                }}
                handleResetFilters={handleResetFilters}
                filtersData={filtersData}
                onFilterChanged={onFilterChanged}
                footerLabel={i18n.t("billing.orders_detail.footer")}
                hasExport={true}
                openExportDialog={() => {onExport()}}
                exportBusy={exportBusy}
                exportButtonChildren={<>
                    <DownloadIcon className="inline mb-0.5 mr-1 h-4 w-4" aria-hidden="true" />
                    {exportBusy ? i18n.t("billing.statement.exporting_services_summary") : i18n.t("billing.statement.export_services_summary")}{" "}
                    {i18n.t("billing.summaries_options.ORDER_DETAIL")}
                </>}
                backgroundgColor="bg-white"
                rowProps={row => ({
                    onClick: (e) => showOrderDetail(row.original?.object)
                })}
            />
                )}
        </div>
    )
}

export default StoreBillingOrderDetail
