export const pt = {
    login: {
        title: "Faça login na sua conta",
        detail: "Por favor insira os dados abaixo",
        email: "Email",
        password: "Senha",
        required_field:"Este campo é obrigatório",
        recover_pwd: "Recuperar senha",
        error_message: "nome de usuário / senha inválidos",
        button: "Faça login na sua conta",
        get_in: "Entrando...",
        create_account: "Criar uma conta"
    },

    forgotPassword: {
        recover_password: "Recupere sua senha",
        enter_your_email: "Digite seu e-mail e enviaremos um e-mail para recuperar sua senha",
        required_field: "Este campo é obrigatório",
        back_to_login: "Volte ao login",
        entering: "Entrando...",
        error: "Não foi!",
        check_your_email:"Verifique seu e-mail!",
        password_recovery: "Recupere sua senha",

    },

    signup: {
        title: "Criar uma conta Cubbo",
        subtitle: "Por favor insira os dados abaixo",
        country: "País",
        brand_name: "O nome da sua marca",
        user_name: "Teu nome",
        user_email: "Email",
        user_pwd: "Senha",
        confirm_pwd: "Repita a senha",
        required_field:"Este campo é obrigatório",
        invalid_address: "Endereço inválido",
        pwd_characters: "A senha deve ter 8 caracteres",
        pwd_no_coincide: "As senhas não correspondem",
        pass_strength: "A senha deve atender a pelo menos 3 dos seguintes: uma maiúscula, uma minúscula, um número e/ou um caractere especial (!@#$%^&*)",
        back_to_login: "Volte ao login",
        button: "Voltar para o login",
        creating_account: "Criando conta..."
    },

    topMenu: {
        stores: "Lojas",
        home: "Inicio",
        orders: "Pedidos",
        returns: "Devoluções",
        inventory: "Estoque",
        replenishments: "Remessas de Estoque",
        work_orders: "Maquilas",
        integrations: "Integrações",
        settings: "Configurações",
        invoices: 'Notas fiscais',
        billing: 'Faturação',
        help: "Ajuda",
        recover_pwd: "Mudar senha",
        logout: "Fechar Sessão",
        spanish: "Espanhol",
        portuguese: "Português",
        english: "Inglês",
    },

    stores: {
        store: "Loja",
        stores: "Lojas",
        products: "Produtos",
        orders: "Pedidos",
        account: "Conta",
        created: "Criada",
        favorite: "Favorito",
        go_store: "Acesse sua loja",
        status: "Status",
        enable_store: "Ativar Loja",
        disable_store: "Desativar loja",
        details: "Ver detalhe",
        select_as_favorite: "Adicionar aos favoritos",
        default_store: "Sim",
        save: "Salvando",
        save_as_favorite: "Salvo como favorito.",
        nothing_to_show: "Produto não encontrado.",
        store_detail: "Detalhes da Loja"
    },

    store_detail_container: {
        name: "Nome",
        desc: "Descrição",
        created: "Criada",
        go_to_store: "Ir para loja"

    },
    formatted_relative_time: {
        today: "Hoje",
        yesterday: "Ontem",
        tomorrow: "Amanhã",
        date: "{{day}} de {{month}}",
        from_now: "Há {{days}} dias"
    },
    search_input: {
        search: "Buscar"
    },

    home: {
        order_stats:{
            orders: "Pedidos",
            inventory: "Estoque",
            returns: "Devoluções",
            replenishments: "Remessas de estoque",

        },
        title: "Bem vindo a Cubbo, {{store_name}}!",
        delayed_orders: "Atrasados",
        pending_orders: "Pendentes para envio",
        missing_info: "Informação pendente",
        returning_orders: "Em devolução a Cubbo",
        returns_to_check: "Decisão pendente",
        expected_replenishments: "Esperados",
        expected_replenishments_info: "Remessas agendadas ou não que chegarão a Cubbo em breve",
        processing_replenishments: "Processando",
        orders_without_stock: "Sem estoque",
        products_without_stock: "Produtos fora de estoque",
        damaged_stock: "Itens avariados",
        expired_stock: "Itens expirados",
        reserved_by_returns_stock: "Reservado por devoluções",
        packed_or_on_route: "Pedidos a caminho ou embalados",
        shipped_this_week: "Pedidos enviados nesta semana",
        pending_receipts: "Entradas de Estoque Pendentes",
        table_id: "ID",
        table_status: "Status",
        estimated_date: "Data prevista de chegada",
        delivery_date: "Data de chegada",
        percentage: "% CONCLUÍDO",
        connect_store: "Conecte sua loja",
        inventory: "Registre seu Inventário",
        ship_pref: "Defina suas preferências de envio",
        replenish: "Enviar estoque para Cubbo",
        start_to_config: "Comece a configurar sua conta.",
        product_register: "Registre todos os seus produtos no Cubbo.",
        import_from_shopify: "Importar da Shopify",
        import_from_shop: "Trazemos automaticamente todos os seus produtos da sua loja da Shopify.",
        import_from_excel: "Importar do Excel",
        upload: "Carregue um arquivo csv, xls ou xlsx com seus produtos",
        manual_creation: "Criar produtos manualmente",
        create_one_by_one: "Crie seus produtos um por um",
        connect_store_to_cubbo: "Conecte sua loja ao Cubbo",
        assign_location: "A loja está conectada, agora você deve conectar um local ao Cubbo",
        assign_location_link: "-> ... -> Atribuir local",
        connect_everything: "Conecte seu Shopify, WooCommerce, VTEX ou se preferir pode integrar por API.",
        connect_my_store: "Conectar minha loja",
        recommended_same_day: "Recomendado - Same Day",
        sameday_ship: "Essa configuração sempre atribuirá a modalidade de envio mais rápido e barato possível.",
        packs: "Treggo, 99minutos e ServiEntrega",
        custom_packs: "Custom",
        custom_config: "Escolha esta configuração para configurar cada uma das suas modalidades de envio do seu jeito.",
        economy: "Economy",
        economy_config: "Essa configuração sempre atribuirá a modalidade de envio mais barata possível.",
        economy_packs: "Treggo, 99minutos e Estafeta",
        premium: "Premium",
        premium_config: "Esta configuração sempre atribuirá a modalidade de envio mais barata possível nas cidades, e DHL no nacional.",
        premium_packs: "Treggo, 99minutos y DHL",
        required_store_message: "É necessário ter uma loja conectada para poder configurar as modalidades de envio automático.",
        first_replenishment: "Crie sua primeira entrada de estoque",
        replenishment_details: "Diga-nos em que dia receberemos o seu inventário e os seus detalhes.",
        create_reple: "Criar Recibo",
        required_inventory_message: "*Para criar um recibo de inventário, você precisa primeiro registrar seu inventário.",
        min_stock: "Produtos com pouco estoque",
        Go_to_Integration: "Ir para Integração",
        no_pending_receipts: "Não há recebimentos de estoque pendentes.",
        coming_soon: "Em breve.",
        Operating_Metrics: "Métricas",
        configuring: "Configurando...",
        select: "Selecionar",
        metrics_inactive_description: "Sabemos o quanto os dados são importantes para a tomada de decisões no seu negócio. Portanto, quando enviarmos 2.000 pedidos mensais para você, você poderá acessar a seção de métricas gratuitamente.",
        metrics_inactive_action: "Ainda não envio 2.000 pedidos mensais, mas gostaria de obter esse benefício"
    },

    order_stats: {
        title: "Resumo da sua loja",
        subtitle: "Dados em tempo real da atividade na sua loja.",
    },

    metrics: {
        title: "Métricas",
        subtitle: "Selecione um período para ver informações dos pedidos que foram empacotados durante este período",
        historics_title: "Dados históricos com base no intervalo de datas selecionado",

        coming_soon: "Em breve você poderá visualizar suas métricas aqui. Aguarde em breve!",

        generic: {
            orders: "Pedidos",
            orders_with_delivery_attempt: "pedidos com tentativa de entrega",
            items: "Peças",
            item: "Peça",
            shipments: "Envios",
            shipped: "enviados",
            synced: "sincronizados",
            delivered: "entregues",
            durations: {
                seconds: 'segundos',
                minute: 'minuto',
                minutes: 'minutos',
                hour: 'hora',
                hours: 'horas',
                day: 'dia',
                days: 'dias',
                month: 'mês',
                months: 'meses',
            },
            on_average: "em média",
            no_data: "Sem dados",
            of: "de",
        },
        
        packed_on_time: {
            title: "Pedidos empacotados no prazo",
            subtitle: "Pedidos que cumpriram o prazo prometido pela Cubbo",
        },
        picking_accuracy: {
            title: "Pedidos sem erros de picking",
            subtitle: "Clientes que receberam exatamente o que pediram",
        },
        replenishments_on_time: {
            title: "Remessas feitas no prazo",
            subtitle: "Reposições de estoque completadas em menos de 24 horas",
        },
        
        orders_by_sales_channel_total: {
            title: "Canais de venda",
            main_column_label: "Integração",
            secondary_column_label: "Pedidos",
            grouped_rows_label: "Outras integrações",
        },
        top_national_destinations: {
            title: "Destinos nacionais com mais envios",
            main_column_label: "Estado",
            secondary_column_label: "Envios",
            grouped_rows_label: "Outros estados",
            INTERNATIONAL: "Internacional",
            view_others_label: "Ver outros estados",
            go_back_label: "Voltar",
        },
        shipping_methods_total: {
            title: "Transportadoras usadas para seus envios",
            main_column_label: "Transportadora",
            secondary_column_label: "Envios",
            grouped_rows_label: "Outras transportadoras",
        },
        stock_per_order_total: {
            title: "Peças vendidas por pedido",
            main_column_label: "Peças",
            secondary_column_label: "Pedidos",
            grouped_rows_label: "{{items}} peças ou mais",
            item_quantity: "{{items}} peças",
            item_quantity_single: "1 peça",
        },
        
        orders_packed_on_time_historic: {
            title: "Pedidos empacotados no prazo",
            subtitle: "Pedidos que cumpriram o prazo prometido pela Cubbo",
            late: "Atrasado",
            on_time: "No prazo",
        },
        shipping_methods_historic: {
            title: "Transportadoras usadas para seus envios",
            subtitle: "Volume de envios por transportadora",
        },
        orders_sales_channel_historic: {
            title: "Pedidos por canal de venda",
            subtitle: "Canais de venda que geraram seus pedidos",
            created_at_cubbo: "Criados na Cubbo",
        },
        stock_per_order_historic: {
            title: "Média de peças vendidas por pedido",
            subtitle: "Número médio de produtos comprados por seus clientes por pedido",
            legend: "Peças por pedido",
        },
        total_orders_historic: {
            title: "Pedidos enviados",
            highlighted: "{{total}} pedidos",
            highlighted_description: "no total",
            legend: "Pedidos enviados",
        },
        total_shipped_stock_historic: {
            title: "Total de peças vendidas",
            subtitle: "Número de peças enviadas pela Cubbo",
            legend: "Peças vendidas",
        },
        click_to_door: {
            title: "Click até entrega",
            subtitle: "Tempo desde a compra a entrega",
            average: {
                text: "{{average}} horas",
                description: "em média",
            },
            by_days: {
                title: "Tempo de entrega",
                less_than_1_day: "< 24 horas",
                day: " {{day}} dia",
                days: " {{days}} dias",
                main_column_label: "Tempo de entrega",
                secondary_column_label: "Envios",
                grouped_rows_label: "6+ dias",
            },
            historic: {
                title: "Tempo de entrega",
                day: "dia",
                days: "dias"
            }
        },
        click_to_packed: {
            title: "Pendente até empacotado",
            subtitle: "Tempo desde que o pedido foi registrado com estado pendente até ser empacotado",
            information_circle_text: "Um pedido é registrado com estado pendente quando não possui informação pendente e tem estoque disponível preparar-se",
            highlighted: "{{average}} horas",
            highlighted_description: "em média",
        },
        collected_to_door: {
            title: "Coleta até entrega",
            subtitle: "Tempo desde que a transportadora coletou até que foi entregue",
            highlighted: "{{average}} horas",
            highlighted_description: "em média",
            on_time: "No prazo",
            late: "Atrasado",
            sla_met_total_title: "Pedidos entregues no prazo",
            sla_first_attempt_met_total_title: "Pedidos com primeira tentativa de entrega no prazo",
            select: {
                all_carriers: "Todas as transportadoras",
                all_states: "Todos os destinos",
                selected_carriers: "Transportadoras selecionadas:",
                selected_states: "Estados selecionados:"
            },
        },
        sales_channel: {
            title: "Canais de venda",
            subtitle: "Por onde seus clientes mais compram",
        },
        stock_sold: {
            title: "Peças vendidas",
            subtitle: "Total enviado por Cubbo",
            total_stock_sold: {
                text: "{{total}} peças",
                description: "no total",
            },
            stock_per_order_average: {
                text: "{{average}} peças",
                description: "por pedido em média",
            },
        },
        replenishments: {
            title: "Remessas de estoque",
            subtitle: "Total de peças ingressadas a Cubbo",
            total_received: {
                text: "{{total}} peças",
                description: "recebidas",
            }
        },
        orders_delivered_on_time_by_states: {
            title: "Pedidos entregues no prazo por estado",
        },
        orders_delivered_on_time_by_carrier: {
            title: "Pedidos entregues no prazo por transportadora",
        },
        stuck_orders: {
            title: "Pedidos com bloqueio",
            subtitle: "Quanto tempo os pedidos com bloqueio passaram desde a compra até que o pedido passou a estado pendente",
            average_subtext: "Considerando apenas os pedidos com bloqueio",
            statuses: {
                backorder: "Sem estoque",
                error: "Falta de informação",
                unpaid: "Sem pagamento",
                hold: "Pause",
            },
            historic: {
                title: "Pedidos enviados",
                error_title: "Tempo com bloqueio por falta de informação",
                backorder_title: "Tempo com bloqueio por falta de estoque",
                unpaid_title: "Tempo com bloqueio por falta de pagamento",
                hold_title: "Tempo com bloqueio por pause",
                categories: {
                    error: "Pedidos com bloqueio por falta de informação",
                    backorder: "Pedidos com bloqueio por falta de estoque",
                    unpaid: "Pedidos com bloqueio por falta de pagamento",
                    hold: "Pedidos com bloqueio por pause",
                    valid: "Pedidos fulfilleados sem bloqueio",
                }
            },
            by_reason: {
                title: "Motivo de bloqueio",
                main_column_label: "Motivo",
                secondary_column_label: "Pedidos",
            }
        }
    },

    date_picker: {
        select_placeholder: "Selecione",
        relative_ranges: {
            last_7_days: 'Últimos 7 dias',
            last_30_days: 'Últimos 30 dias',
            last_month: 'Último mês',
            last_year: 'Último ano',
        },
    },

    paginator: {
        page: "Página",
        of: "de",
        total_elements: "Total de Itens",
        previous: "Anterior",
        next: "Seguindo",
        per_page: "por página"
    },

    orders: {
        tracking_link_without_label: "Rastrear pedido",
        title: "Detalhes do pedido",
        show_shipping_incidents: {
            from_date: ". Em ",
            evidence_type: {
                product_images:"Fotos detalhadas do incidente",
                label_images:"Fotos da etiqueta da envio",
                package_images:"Fotos da embalagem"
            },
            created_by: {
                RECIPIENT: "Criado pelo seu cliente ",
                CUBBO: "Criado por Cubbo ",
            },
            info_types:{
                important: "Importante: "
            },
            toggle_description: {
                hide: "Ocultar ",
                show: "Ver "
            },
            description_title: {
                PENDING: {
                    DELAYED: "informação anexada",
                    MISSING_REFERENCES: "endereço compartilhado",
                    CANCELED: "informação anexada",
                    DAMAGED: "evidência compartilhada",
                    MISSING_PRODUCT: "evidência compartilhada",
                    FALSE_DELIVERY: "informação anexada",
                    CHANGE_ADDRESS: "endereço modificado",
                    RESCHEDULE: "data de entrega reprogramada"
                },
                IN_REVIEW: {
                    DELAYED: "informação anexada",
                    MISSING_REFERENCES: "endereço compartilhado",
                    CANCELED: "informação anexada",
                    DAMAGED: "evidência compartilhada",
                    MISSING_PRODUCT: "evidência compartilhada",
                    FALSE_DELIVERY: "informação anexada",
                    CHANGE_ADDRESS: "endereço modificado",
                    RESCHEDULE: "data de entrega reprogramada"
                },
                RESOLVED: {
                    DELAYED: "informação anexada",
                    MISSING_REFERENCES: "endereço compartilhado",
                    CANCELED: "informação anexada",
                    DAMAGED: "evidência compartilhada",
                    MISSING_PRODUCT: "evidência compartilhada",
                    FALSE_DELIVERY: "informação anexada",
                    CHANGE_ADDRESS: "endereço modificado",
                    RESCHEDULE: "data de entrega reprogramada"
                },
            },
            categories:{
                DELAYED: "Incidente: Atraso na entrega",
                MISSING_REFERENCES: "Incidente: Completar endereço",
                CANCELED: "Incidente: Cancelamento de entrega",
                DAMAGED: "Incidente: Produto danificado",
                MISSING_PRODUCT: "Incidente: Produtos faltando",
                FALSE_DELIVERY: "Incidente: Entrega incorreta",
                CHANGE_ADDRESS: "Incidente: Alterar endereço",
                RESCHEDULE: "Incidente: Reagendar data de entrega"
            },
            messages:{
                info:{
                    PENDING: {
                        DELAYED: "Promessa de resolução em 48-72 horas",
                        MISSING_REFERENCES: "",
                        CANCELED: "",
                        DAMAGED: "Promessa de resolução em 48-72 horas",
                        MISSING_PRODUCT: "Promessa de resolução em 48-72 horas",
                        FALSE_DELIVERY: "A transportadora iniciará uma investigação sobre este incidente, o que pode levar até 5 dias",
                        CHANGE_ADDRESS: "",
                        RESCHEDULE: ""
                    },
                    IN_REVIEW: {
                        DELAYED: "Promessa de resolução em 48-72 horas",
                        MISSING_REFERENCES: "",
                        CANCELED: "",
                        DAMAGED: "Promessa de resolução em 48-72 horas",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "A transportadora iniciará uma investigação sobre este incidente, o que pode levar até 5 dias",
                        CHANGE_ADDRESS: "",
                        RESCHEDULE: ""
                    },
                    RESOLVED: {
                        DELAYED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        MISSING_REFERENCES: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        CANCELED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        DAMAGED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        MISSING_PRODUCT: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        FALSE_DELIVERY: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        CHANGE_ADDRESS: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        RESCHEDULE: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                    },
                },
                tips:{
                    PENDING: {
                        DELAYED: "Para evitar uma entrega duplicada, recomendamos esperar a resposta da transportadora antes de fazer um novo pedido",
                        MISSING_REFERENCES: "",
                        CANCELED: "Você pode monitorar o pedido através do status de envio ou do guia de rastreamento",
                        DAMAGED: "",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "Para evitar uma entrega duplicada, recomendamos esperar a resposta da transportadora antes de fazer um novo pedido",
                        CHANGE_ADDRESS: "",
                        RESCHEDULE: ""
                    },
                    IN_REVIEW: {
                        DELAYED: "Para evitar uma entrega duplicada, recomendamos esperar a resposta da transportadora antes de fazer um novo pedido",
                        MISSING_REFERENCES: "",
                        CANCELED: "Você pode monitorar o pedido através do status de envio ou do guia de rastreamento",
                        DAMAGED: "",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "Para evitar uma entrega duplicada, recomendamos esperar a resposta da transportadora antes de fazer um novo pedido",
                        CHANGE_ADDRESS: "",
                        RESCHEDULE: ""
                    },
                    RESOLVED: {
                        DELAYED: {
                            STOLEN: "Lamentamos informar que esses casos não são reembolsáveis. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            LOST: "Sugerimos iniciar uma disputa e duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            NON_REFUNDABLE_DAMAGED: "A transportadora nos notificou que o pedido foi danificado durante o envio e não será possível concluir a entrega. Infelizmente, este caso não é elegível para reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            RETURNED: "O pedido foi devolvido à Cubbo, você poderá visualizá-lo na página de devoluções assim que o recebermos",
                            DELIVERED: "Sugerimos confirmar com seu cliente que ele já recebeu sua compra. Caso contrário, entre em contato com o Suporte Cubbo para obter ajuda",
                            REFUNDABLE: "Por favor, inicie uma disputa para processar o reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado"
                        },
                        MISSING_REFERENCES: {
                            STOLEN: "Lamentamos informar que esses casos não são reembolsáveis. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            LOST: "Sugerimos iniciar uma disputa e duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            NON_REFUNDABLE_DAMAGED: "A transportadora nos notificou que o pedido foi danificado durante o envio e não será possível concluir a entrega. Infelizmente, este caso não é elegível para reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            RETURNED: "O pedido foi devolvido à Cubbo, você poderá visualizá-lo na página de devoluções assim que o recebermos",
                            DELIVERED: "Sugerimos confirmar com seu cliente que ele já recebeu sua compra. Caso contrário, entre em contato com o Suporte Cubbo para obter ajuda",
                            REFUNDABLE: "Por favor, inicie uma disputa para processar o reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado"
                        },
                        CANCELED: {
                            STOLEN: "Lamentamos informar que esses casos não são reembolsáveis. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            LOST: "Sugerimos iniciar uma disputa e duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            NON_REFUNDABLE_DAMAGED: "A transportadora nos notificou que o pedido foi danificado durante o envio e não será possível concluir a entrega. Infelizmente, este caso não é elegível para reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            RETURNED: "O pedido foi devolvido à Cubbo, você poderá visualizá-lo na página de devoluções assim que o recebermos",
                            DELIVERED: "Sugerimos confirmar com seu cliente que ele já recebeu sua compra. Caso contrário, entre em contato com o Suporte Cubbo para obter ajuda",
                            REFUNDABLE: "Por favor, inicie uma disputa para processar o reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado"
                        },
                        DAMAGED: {
                            STOLEN: "Lamentamos informar que esses casos não são reembolsáveis. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            LOST: "Sugerimos iniciar uma disputa e duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            NON_REFUNDABLE_DAMAGED: "A transportadora nos notificou que o pedido foi danificado durante o envio e não será possível concluir a entrega. Infelizmente, este caso não é elegível para reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            RETURNED: "O pedido foi devolvido à Cubbo, você poderá visualizá-lo na página de devoluções assim que o recebermos",
                            DELIVERED: "Sugerimos confirmar com seu cliente que ele já recebeu sua compra. Caso contrário, entre em contato com o Suporte Cubbo para obter ajuda",
                            REFUNDABLE: "Por favor, inicie uma disputa para processar o reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado"
                        },
                        MISSING_PRODUCT: {
                            STOLEN: "Lamentamos informar que esses casos não são reembolsáveis. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            LOST: "Sugerimos iniciar uma disputa e duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            NON_REFUNDABLE_DAMAGED: "A transportadora nos notificou que o pedido foi danificado durante o envio e não será possível concluir a entrega. Infelizmente, este caso não é elegível para reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            RETURNED: "O pedido foi devolvido à Cubbo, você poderá visualizá-lo na página de devoluções assim que o recebermos",
                            DELIVERED: "Sugerimos confirmar com seu cliente que ele já recebeu sua compra. Caso contrário, entre em contato com o Suporte Cubbo para obter ajuda",
                            REFUNDABLE: "Por favor, inicie uma disputa para processar o reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado"
                        },
                        FALSE_DELIVERY: {
                            STOLEN: "Lamentamos informar que esses casos não são reembolsáveis. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            LOST: "Sugerimos iniciar uma disputa e duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            NON_REFUNDABLE_DAMAGED: "A transportadora nos notificou que o pedido foi danificado durante o envio e não será possível concluir a entrega. Infelizmente, este caso não é elegível para reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            RETURNED: "O pedido foi devolvido à Cubbo, você poderá visualizá-lo na página de devoluções assim que o recebermos",
                            DELIVERED: "Sugerimos confirmar com seu cliente que ele já recebeu sua compra. Caso contrário, entre em contato com o Suporte Cubbo para obter ajuda",
                            REFUNDABLE: "Por favor, inicie uma disputa para processar o reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado"
                        },
                        CHANGE_ADDRESS: {
                            STOLEN: "Lamentamos informar que esses casos não são reembolsáveis. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            LOST: "Sugerimos iniciar uma disputa e duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            NON_REFUNDABLE_DAMAGED: "A transportadora nos notificou que o pedido foi danificado durante o envio e não será possível concluir a entrega. Infelizmente, este caso não é elegível para reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            RETURNED: "O pedido foi devolvido à Cubbo, você poderá visualizá-lo na página de devoluções assim que o recebermos",
                            DELIVERED: "Sugerimos confirmar com seu cliente que ele já recebeu sua compra. Caso contrário, entre em contato com o Suporte Cubbo para obter ajuda",
                            REFUNDABLE: "Por favor, inicie uma disputa para processar o reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado"
                        },
                        RESCHEDULE: {
                            STOLEN: "Lamentamos informar que esses casos não são reembolsáveis. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            LOST: "Sugerimos iniciar uma disputa e duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            NON_REFUNDABLE_DAMAGED: "A transportadora nos notificou que o pedido foi danificado durante o envio e não será possível concluir a entrega. Infelizmente, este caso não é elegível para reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado",
                            RETURNED: "O pedido foi devolvido à Cubbo, você poderá visualizá-lo na página de devoluções assim que o recebermos",
                            DELIVERED: "Sugerimos confirmar com seu cliente que ele já recebeu sua compra. Caso contrário, entre em contato com o Suporte Cubbo para obter ajuda",
                            REFUNDABLE: "Por favor, inicie uma disputa para processar o reembolso. Sugerimos duplicar o pedido para enviar ao seu cliente o que foi solicitado"
                        },
                    },
                },
                important_info:{
                    PENDING: {
                        DELAYED: "",
                        MISSING_REFERENCES: "A informação foi compartilhada com a transportadora. No entanto, isso não garante que o entregador receba os dados antes da tentativa de entrega, pois já está em movimento",
                        CANCELED: "Foi enviada uma solicitação à transportadora para cancelar a entrega do pedido. No entanto, isso não garante que o pedido não seja entregue, pois já está em movimento",
                        DAMAGED: "",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "",
                        CHANGE_ADDRESS: "A informação foi compartilhada com a transportadora. No entanto, isso não garante que o entregador receba os dados antes da tentativa de entrega, pois já está em movimento",
                        RESCHEDULE: "",
                    },
                    IN_REVIEW: {
                        DELAYED: "",
                        MISSING_REFERENCES: "A informação foi compartilhada com a transportadora. No entanto, isso não garante que o entregador receba os dados antes da tentativa de entrega, pois já está em movimento",
                        CANCELED: "Foi enviada uma solicitação à transportadora para cancelar a entrega do pedido. No entanto, isso não garante que o pedido não seja entregue, pois já está em movimento",
                        DAMAGED: "",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "",
                        CHANGE_ADDRESS: "A informação foi compartilhada com a transportadora. No entanto, isso não garante que o entregador receba os dados antes da tentativa de entrega, pois já está em movimento",
                        RESCHEDULE: "",
                    },
                    RESOLVED: {
                        DELAYED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        MISSING_REFERENCES: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        CANCELED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        DAMAGED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        MISSING_PRODUCT: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        FALSE_DELIVERY: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        CHANGE_ADDRESS: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        RESCHEDULE: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                    },
                }
            },
            resolutions_complements: {
                LOST: {
                    DELAYED: ", pedido perdido",
                    MISSING_REFERENCES: ", pedido perdido",
                    CANCELED: ", pedido perdido",
                    DAMAGED: ", pedido perdido",
                    MISSING_PRODUCT: ", pedido perdido",
                    FALSE_DELIVERY: ", pedido perdido",
                    CHANGE_ADDRESS: ", pedido perdido",
                    RESCHEDULE: ", pedido perdido",
                },
                STOLEN: {
                    DELAYED: ", pedido roubado",
                    MISSING_REFERENCES: ", pedido roubado",
                    CANCELED: ", pedido roubado",
                    DAMAGED: ", pedido roubado",
                    MISSING_PRODUCT: ", pedido roubado",
                    FALSE_DELIVERY: ", pedido roubado",
                    CHANGE_ADDRESS: ", pedido roubado",
                    RESCHEDULE: ", pedido roubado",
                },
                NON_REFUNDABLE_DAMAGED: {
                    DELAYED: ", pedido danificado, não reembolsável",
                    MISSING_REFERENCES: ", pedido danificado, não reembolsável",
                    CANCELED: ", pedido danificado, não reembolsável",
                    DAMAGED: ", pedido danificado, não reembolsável",
                    MISSING_PRODUCT: ", pedido danificado, não reembolsável",
                    FALSE_DELIVERY: ", pedido danificado, não reembolsável",
                    CHANGE_ADDRESS: ", pedido danificado, não reembolsável",
                    RESCHEDULE: ", pedido danificado, não reembolsável",
                },
                RETURNED: {
                    DELAYED: ", devolvido para a Cubbo",
                    MISSING_REFERENCES: ", devolvido para a Cubbo",
                    CANCELED: ", devolvido para a Cubbo",
                    DAMAGED: ", devolvido para a Cubbo",
                    MISSING_PRODUCT: ", devolvido para a Cubbo",
                    FALSE_DELIVERY: ", devolvido para a Cubbo",
                    CHANGE_ADDRESS: ", devolvido para a Cubbo",
                    RESCHEDULE: ", devolvido para a Cubbo",
                },
                DELIVERED: {
                    DELAYED: ", pedido entregue",
                    MISSING_REFERENCES: ", pedido entregue",
                    CANCELED:", pedido entregue",
                    DAMAGED: ", pedido entregue",
                    MISSING_PRODUCT: ", pedido entregue",
                    FALSE_DELIVERY: ", pedido entregue",
                    CHANGE_ADDRESS: ", pedido entregue",
                    RESCHEDULE: ", pedido entregue",
                },
                REFUNDABLE: {
                    DELAYED: ", será reembolsado",
                    MISSING_REFERENCES: ", será reembolsado",
                    CANCELED: ", será reembolsado",
                    DAMAGED: ", será reembolsado",
                    MISSING_PRODUCT: ", será reembolsado",
                    FALSE_DELIVERY: ", será reembolsado",
                    CHANGE_ADDRESS: ", será reembolsado",
                    RESCHEDULE: ", será reembolsado",
                },
            },
            status: {
                PENDING: "Pendente",
                IN_REVIEW: "Em revisão",
                RESOLVED: "Resolvido"
            },
        },

        orderErrors: {
            // general
            ORDER_NUMBER_NOT_UNIQUE: "O número do pedido {order_number} já existe para outro pedido",
            MISSING_SHIPPING_FIELD: "Há dados faltantes no endereço do cliente",
            MISSING_TAX_FIELD: "Há informações faltantes nos dados de faturamento",
            // order_lines
            UNIDENTIFIED_PRODUCT: "O produto com SKU '{sku}' deste pedido não existe na Cubbo",
            UNIDENTIFIED_PRODUCT_SPECIFIC: "O produto com SKU '{sku}' não existe na Cubbo",
            DUPLICATE_STORE_SKU: "Há mais de um produto na Cubbo com o mesmo SKU '{sku}'",
            DUPLICATE_STORE_SKU_SPECIFIC: "Há mais de um produto com o mesmo SKU '{sku}' na Cubbo",
            MISSING_PRODUCT_SKU: "A integração enviou produtos sem SKU",
            MISSING_PRODUCT_SKU_SPECIFIC: "A integração enviou este produto sem SKU",
            // shipping info
            UNRECOGNIZED_COUNTRY: "Não foi possível identificar o país de destino",
            INVALID_ZIPCODE: "O código postal de destino é inválido",
            UNRECOGNIZED_CITY_OR_STATE: "Não foi possível identificar a cidade ou estado de destino",
            // integration shipping method
            NO_MATCHING_INTEGRATION_SHIPPING_METHOD: "O método de envio de {integration_type} chamado '{integration_shipping_method_name}' não foi encontrado na Cubbo",
            NO_MATCHING_INTEGRATION_SHIPPING_METHOD_NO_NAME: "O {integration_type} não forneceu o método de envio que deve ser usado para este pedido",
            // shipping groups
            NO_SHIPPING_GROUP_ASSIGNED: "O método de envio da integração não tem uma configuração de envio atribuída",
            // shipping method
            MISSING_SHIPPING_METHOD: "Não foi possível atribuir uma transportadora ao pedido devido a informações faltantes",
            INTERNATIONAL_SHIPPING_METHOD_EXPECTED: "O pedido tem um destino internacional, mas a transportadora selecionada é nacional",
            COD_SHIPPING_METHOD_EXPECTED: "O pedido é pagamento contra entrega, mas a transportadora selecionada não suporta esse serviço",
            MISSING_EXTERNAL_LABEL: "O pedido requer rótulo externo, mas nenhum foi fornecido",
            SHIPPING_METHOD_HAS_NO_COVERAGE: "A transportadora não tem cobertura para o destino",
            UNKNOWN_ORDER_WEIGHT: "Não foi possível determinar o peso total do pedido",
            SHIPPING_COST_UNAVAILABLE: "Não foram encontrados preços de envio para este destino",
            BLACKLISTED_ZIP_CODE: "O código postal de destino não possui temporariamente cobertura por nenhuma empresa de transporte",
            MISSING_COD_VALUE: "O pedido é pagamento contra entrega, mas o valor de cobrança não foi fornecido",
            // taxing info
            BILLING_TAX_ID_WRONG_LENGTH: "O número de identificação fiscal fornecido está incorreto",
            INVALID_PRODUCT_INVOICING_PRICE: "O produto com SKU '{sku}' possui um preço de faturamento inválido",
            INVALID_PRODUCT_INVOICING_PRICE_SPECIFIC: "Este produto possui um preço de faturamento inválido",
            INVALID_PRODUCT_NCM: "O NCM do produto com SKU '{sku}' é inválido",
            INVALID_PRODUCT_NCM_SPECIFIC: "Este produto não possui configuração fiscal",
            TOTAL_ORDER_VALUE_MISMATCH: "Há diferenças no preço total do pedido, {channel_integration_type} relatou {expected_total_price} e Cubbo calculou {calculated_total_price}, é necessária confirmação de preços para faturamento",
            MELI_ORDER_NOT_READY_TO_SHIP: "O Mercado Livre ainda não gerou a etiqueta de envio. Assim que for gerada, atualizaremos este pedido.",
            INVALID_TOTAL_ORDER_VALUE: "Valor total do pedido inválido",
            NO_INVOICEABLE_PRODUCTS: "Nenhum produto neste pedido é faturável",

            // DIFAL
            MISSING_DIFAL_PAYMENT_RECEIPT: "O pedido requer comprovante de pagamento da DIFAL"
        },

        orderErrorsSolutions: {
            // general
            ORDER_NUMBER_NOT_UNIQUE: "Escolha outro número para identificar este pedido",
            MISSING_SHIPPING_FIELD: "Edite o pedido e insira os dados de envio faltantes para poder processar o pedido",
            MISSING_TAX_FIELD: "Edite o pedido e insira os dados de faturamento faltantes para poder processar o pedido",
            // order_lines
            UNIDENTIFIED_PRODUCT: "Crie o produto na Cubbo ou edite o pedido para removê-lo do pedido e poder processá-lo",
            UNIDENTIFIED_PRODUCT_SPECIFIC: "",
            DUPLICATE_STORE_SKU: "Entre em contato com o suporte para identificar a origem do erro e solucioná-lo",
            DUPLICATE_STORE_SKU_SPECIFIC: "",
            MISSING_PRODUCT_SKU: "Pode haver algo para modificar em sua integração para corrigir este erro. Entre em contato com o suporte caso necessite de apoio adicional",
            MISSING_PRODUCT_SKU_SPECIFIC: "Edite o pedido e modifique o país do endereço do cliente para poder identificar o destino correto",
            // shipping info
            UNRECOGNIZED_COUNTRY: "Edite o pedido e modifique o país do endereço do cliente para poder identificar o destino correto",
            INVALID_ZIPCODE: "Edite o pedido e modifique o CEP do endereço do cliente para poder identificar o destino correto",
            UNRECOGNIZED_CITY_OR_STATE: "Edite o pedido e modifique a cidade e/ou estado do endereço do cliente para poder identificar o destino correto",
            // integration shipping method
            NO_MATCHING_INTEGRATION_SHIPPING_METHOD: "Vá para as configurações da integração e sincronize os métodos de envio para identificar aquele que foi atribuído a este pedido e poder atribuir uma transportadora", // dúvida
            NO_MATCHING_INTEGRATION_SHIPPING_METHOD_NO_NAME: "Edite o pedido e atribua um método de envio",
            // shipping groups
            NO_SHIPPING_GROUP_ASSIGNED: "Escolha a configuração de envio do método de envio atribuído a este pedido", // dúvida
            // shipping method
            MISSING_SHIPPING_METHOD: "Edite o pedido e atribua uma transportadora para enviar este pedido", // dúvida
            INTERNATIONAL_SHIPPING_METHOD_EXPECTED: "Edite o pedido e modifique a transportadora atribuída a este pedido", // dúvida
            COD_SHIPPING_METHOD_EXPECTED: "Edite o pedido e selecione outra transportadora para poder processar o pedido",
            MISSING_EXTERNAL_LABEL: "Edite o pedido e adicione os documentos de envio necessários",
            SHIPPING_METHOD_HAS_NO_COVERAGE: "Edite o pedido e selecione outra transportadora para enviar este pedido",
            UNKNOWN_ORDER_WEIGHT: "Edite o produto com SKU {sku} e adicione o peso correspondente do produto", // dúvida
            SHIPPING_COST_UNAVAILABLE: "Verifique se o peso dos produtos do pedido está correto, caso contrário, entre em contato com o suporte para ajudá-lo a resolver o erro", // dúvida
            BLACKLISTED_ZIP_CODE: "Entre em contato com a equipe de suporte para obter mais informações",
            MISSING_COD_VALUE: "Edite o pedido e insira o valor a ser cobrado neste pedido", // dúvida
            // taxing info
            BILLING_TAX_ID_WRONG_LENGTH: "Edite o pedido e modifique o número de identificação fiscal para poder processar o pedido",
            INVALID_PRODUCT_INVOICING_PRICE: "Edite o pedido e atribua um preço por item a este produto",
            INVALID_PRODUCT_INVOICING_PRICE_SPECIFIC: "",
            INVALID_PRODUCT_NCM: "Clique no nome do produto para editá-lo e modifique a configuração do NCM atribuído",
            INVALID_PRODUCT_NCM_SPECIFIC: "",
            TOTAL_ORDER_VALUE_MISMATCH: "Edite o pedido para ajustar os preços de acordo com o valor total desejado a ser faturado",
            MELI_ORDER_NOT_READY_TO_SHIP: "Não é necessário realizar nenhuma ação adicional, pois a atualização será automática",
            INVALID_TOTAL_ORDER_VALUE: "O valor do pedido deve ser maior que 0, ou possuir 100% de desconto",
            NO_INVOICEABLE_PRODUCTS: "Pelo menos um produto do pedido deve ser faturável",

            // DIFAL
            MISSING_DIFAL_PAYMENT_RECEIPT: "A liberação deste pedido ocorrerá de forma automática. Se após 15 minutos o pedido ainda não estiver como pendente, verifique o saldo no Banco Útil.",
        },

        addContainer: {
            no_address: "Endereço não está cadastrado",
            same_address: "Mesmo endereço do envio",
            existing_order: "Este número de ordem já existe, tente outro",
            shipping_not_available: "Esta modalidade de envio não está disponível para esse CEP",
            unknown_error: "Erro desconhecido",
            shipping_method: "Necessário escolher uma modalidade de envio",
            external: "Externo",
            shipping_market: "Mercado Envíos",
            edit_order: "Editar Pedido",
            create_order: "Criar Pedido",
            keep_order: "Deseja salvar o pedido?",
            create_orders: "Deseja criar um pedido?",
            shipping_address: "Editar endereço de entrega",
            edit_invoicing: "Editar endereço de faturamento",
            details: "Detalhes",
            method_of_shipment: "Modalidade de envio",
            shipping_market:"Mercado Envios",
            order: "Nº de pedido",
            remove_products: "Produtos para retirar",
            tooltip_order_number: "Este sufijo não é editável e será adicionado ao número de ordem para identificá-lo como retirada de inventário",
            order_number_placeholder: "Escreva um número identificador do pedido",
            customer_address: "Endereço do cliente",
            edit: "Editar",
            billing_address: "Endereço de cobrança",
            products: "Produtos",
            only_numbers: "Quantidade tem que ser sempre em números.",
            no_whitespace: "Não pode conter espaços.",
            imports_products: "Importar produtos para este pedido",
            imported: "Importados!",
            import: "Importar",
            import_orders: "Vários pedidos de um excel",
            quantity: "Quantidade",
            add_product: "Adicionar produto",
            is_being: "Estamos",
            processing: "processando",
            cannot_be_modified: "o pedido e os produtos já não podem ser modificados.",
            attachments: "Anexos",
            attach_document: "A modalidade de envio selecionada requer que os documentos de envio sejam anexados.",
            shipping_documents: "Documentos de envio",
            Label: "Etiqueta",
            document: "Documento",
            cancel: "Cancelar",
            save: "Salvar",
            change_donation: "¿Este pedido é uma doação?",
            donation_description: "Este pedido será definido como doação",
            prices: "Preços",
            total_per_item: "Total por itens: ",
            shipping_price: "Preço de envio: ",
            discount_price: "Desconto: ",
            total_price: "Total do pedido: ",
            cod: "Este pedido é à vista?",
            paqs_cod: "Verifique se o pacote deve ser cobrado ao entregar este pedido",
            total_price_cod: "Preço total de compra",
            coin: "Moeda",
            product_select: "Selecione um ou mais produtos para o seu pedido:",
            product_select_remove_stock: "Selecione os produtos que pretende retirar do armazém:",
            limit_products: "Nós limitamos a quantidade de produtos a um máximo de 1000 unidades por pedido.",
            mandatory_field: "Conheça o preço total dos seus produtos e adicione o valor do frete:",
            stock_status_to_remove: "Estado do estoque para retirar:",
            available_stock: "Em bom estado",
            damaged_stock: "Danificado",
            expired_stock: "Expirado",
            add_all_available_products: "Adicionar todos os produtos",
            add_all_expired_products: "Adicionar todos os produtos expirados",
            add_all_damaged_products: "Adicionar todos os produtos danificados",
            all_available_added: "Todos os produtos disponíveis foram adicionados.",
            no_damaged_products_to_add: "Não há produtos danificados",
            no_expired_products_to_add: "Não há produtos expirados",
            all_damaged_added: "Todos os produtos danificados disponíveis foram adicionados.",
            all_expired_added: "Todos os produtos expirados disponíveis foram adicionados.",
            rem_same_condition_products: "Um pedido de remoção de estoque só pode conter produtos do mesmo estado.",
            import_not_available: "Importar produtos não está disponível para este tipo de pedido",
            add_all_not_available: "Adicionar todos os produtos não está disponível para este tipo de pedido",
        },
        fileFields: {
            order_number: "N° de Pedido",
            sku:"SKU",
            quantity: "Quantidade",
            unit_price: "Valor Unitário",
            shipping_price: "Valor de Frete",
            discount_per_item: "Desconto de produto",
            total_price: "Valor Total",
            name: "Nome Cliente",
            last_name: "Sobrenome",
            cpf_cnpj: "CPF/CNPJ",
            state_estadual: "Inscrição Estadual",

            shipping_email: "Email",
            shipping_phone: "Telefone",
            shipping_address1: "Logradouro",
            shipping_address2:"Complemento",
            shipping_number:"Número",
            shipping_neighborhood:"Bairro",
            shipping_city:"Cidade",
            shipping_state:"Estado",
            shipping_zip_code:"CEP",
            shipping_country:"País",

            shipping_configuration: "Configuração de Envio",
            is_cod: "É pagamento na entrega?",
            coin: "Moeda",
            billing_tax_regime:"Pessoa Física ou Jurídica",
            order_type:"Venda ou Doação",

        },
        addresView: {
            name: "Nome",
            surname: "Sobrenome",
            phone: "Telefone",
            address: "Endereço",
            billing_tax_id: "Identificação fiscal",
            billing_company: "Companhia",
            billing_tax_regime: "Regime fiscal",
            billing_state_registration: "Número de registro estadual",
            apartment: "Apartamento, local, colônia, etc. (opcional)",
            billing_number: "Número",
            billing_neighborhood: "Bairro",
            country: "País",
            city: "Cidade",
            state: "Estado",
            postal_code: "CEP",
            save: "Salvar",
            cancel:"Cancelar",
            required_field: "Este campo é obrigatório.",
            postal_code_req_br: "Campo obrigatório, mínimo de 8 caracteres, sem hífens (-)",
            postal_code_req_br_wrong: "Máximo 8 caracteres"
        },

        duplicate: {
            no_address: "Endereço não fornecido",
            same_address: "Mesmo endereço de entrega",
            existing_order: "O número do pedido já existe, tente outro",
            cannot_be_duplicated: "Este pedido não pode ser duplicado, pois os produtos não foram encontrados na Cubbo",
            shipping_not_available: "A modalidade de envio SAMEDAY não está disponível para o seu CEP",
            unknown_error: "Erro desconhecido",
            shipping_method: "Você deve selecionar uma modalidade de envio",
            create_duplicate: "Duplicar",
            sure_to_duplicate: "Deseja duplicar esse pedido?",
            edit_address: "Editar endereço de entrega",
            edit_invoicing: "Editar endereço de cobrança",
            details: "Detalhamento",
            method_of_shipment: "Modalidade de envio",
            order: "Nº de pedido",
            customer_address: "Endereço do cliente",
            edit: "Editar",
            billing_address: "Endereço de cobrança",
            products: "Produtos",
            quantity: "Quantidade",
            only_numbers: "Quantidade tem que ser sempre em números.",
            imports_products: "Importar produtos para este pedido",
            imported: "Importados!",
            import: "Importar",
            add_product: "Adicionar produto",
            is_being: "Estamos",
            processing: "processando",
            cannot_be_modified: "o pedido e os produtos já não podem ser modificados.",
            cancel: "Cancelar",
            save: "Salvar",
        },

        productTable: {
            name: "Nome",
            quantity: "Quantidade",
            discount_per_item: "Desconto por item",
            no_products: "Você não adicionou produtos.",
            available: "Disponível",
            damaged: "Danificado",
            expired: "Expirado",
            total_qty: "Total de unidades a retirar:",
            total_value: "Valor total da retirada:",
            invalid_discount: "O desconto deve ser menor que o preço"
        },

        selectProductDialog: {
            name: "Nome",
            sku: "SKU",
            no_added_products: "Você não adicionou produtos.",
            cancel: "Cancelar",
            add: "Adicionar",
            products: "Produtos"
        },

        claimContainer: {
            initiate_dispute: "Iniciar Contestação",
            create_disput: "Confirmar que deseja criar uma Contestação", 
            the_order: "para pedido com número",
            confirm: "Confirmar",
            cancel: "Cancelar",
            error: "Ocorreu um erro inesperado:",
            disputes: "Só é permitido abrir uma Contestação para pedidos que foram enviados", 
            initiate_disputes: "Só é permitido abrir uma Contestação até 30 dias após a data de envio do pedido.", 
            subsequent_disputes: "Você pode abrir uma Contestação até 30 dias após o envio do pedido.",
            order_number: "Número de Pedido",
            dispute: "Contestação",
            products: "Valor dos produtos a reembolsar",
            important: "IMPORTANTE: Não inclua os custos relacionados ao envio ou manuseio do pedido neste campo. Este campo deve ser utilizado exclusivamente para inserir o custo de produtos extraviados ou guias internacionais pagas pelo cliente para devolver o produto. Se a Contestação for  autorizada, o custo de envio e manuseio relacionado ao pedido será reembolsado automaticamente, bem como qualquer custo adicional relacionado à geração de guias para devolução ou envio de produtos faltantes. Qualquer valor inserido que não seja justificado pode resultar na recusa da Contestação, mesmo que seja legítima.", 
            compensation_testing: "Documentos que Comprovem a Contestação",
            cancel: "Cancelar",
            save: "Salvar",
            duplicate_and_resend: "Duplique e reenvie o pedido antes que a disputa seja resolvida.",
        },

        remove_stock:{
            create:{
                title: "Ordem de remoção de estoque gerada!",
                subtitle: "Você pode localizá-lo entre seus pedidos com o número:",
                when_pickup:{
                    title: "Quando posso retirar?",
                    subtitle:"Sendo processado como pedido de remoção de estoque, o tempo de espera dependerá do volume de peças a serem retiradas.",
                    information: "Você pode verificar um aproximado:",
                    link: " aqui."
                },
                when_is_ready:{
                    title:"Como vou saber quando o pedido está pronto?",
                    subtitle: "Quando a encomenda tiver o estado 'Enviada' significa que já pode deslocar-se ao armazém para a levantar.",
                    information: "Nós o notificaremos por e-mail quando isso acontecer."
                },
                make_appointment:{
                    title: "Tenho de marcar hora para o levantar?",
                    subtitle: "Não. Lembre-se apenas que você deve retirar o pedido entre 9h e 16h com o número do pedido e o nome da sua loja."
                },
                send_information:{
                    title:"Enviamos-lhe esta informação por correio!"
                }
            }
        },

        filters: {
            order_number: "Número do pedido",
            sku: "Que contenham o SKU",
            shipping_number: "Etiqueta de envio",
            channel_name: "Canal de venda",
            carrier_name: "Método de envio",
            status: "Estado do pedido",
            shipping_status: "Estado de envio",
            shipping_name: "Comprador o email",
            shipping_date: "Data de envio",
            channel_created_at: "Data de criação",
            channel_name_placeholder: "Selecione um canal de venda",
            shipping_method_placeholder: "Selecione um método de envio",
            shipping_status_placeholder: "Selecione um estado de envio",
            status_placeholder: "Selecione um estado do pedido",
            is_delayed: "Atrasados",
            order_click_and_collect: "Click and collect",
            shipping_incident: "Incidente com encomenda",
            shipping_incident_category_placeholder: "Qualquer categoria",
            shipping_incident_status_placeholder: "Qualquer estado",
        },

        list: {
            requiring_resolution: "Requerem resolução",
            requiring_monitoring: "Requerem monitoramento",
            eta: "Entrega estimada",
            eta_rescheduled: "Nova entrega estimada",
            original_eta: "Entrega estimada original",
            time_zone_info: "Data em horário local ({{time_zone}})",
            order_number: "Nº DO PEDIDO",
            channel_name_items: "Canal de venda | Itens",
            item: "peça",
            items: "itens",
            shipping_method: "Método de envio",
            status: "Estado do pedido",
            status_desc: "Nas mãos de Cubbo",
            shipping_status: "Estado de envio",
            shipping_status_desc: "Nas mãos da transportadora",
            full_name: "Comprador",
            no_information: "Sem Status",
            no_pieces: "Sem produtos para enviar",
            shipping: "MODALIDADE DE ENVIO",
            all_stores: "Todas as lojas",
            orders: "Pedidos",
            disputes: "Contestação",
            export_button: "Exportar",
            export_invoice_button: "Download",
            export_title: 'Exportar pedidos',
            shipping_date: "enviado",
            show_orders: "Ver pedido",
            create_remove_stock: "Gerar remoção de estoque",
            duplicate_order: "Duplicar pedido",
            duplicate_order_action: "Duplicar",
            edit_order_action: "Editar pedido",
            config_shipping_method: "Configurar",
            pause_order_action: "Pausar",
            cancel_order_action: "Cancelar",
            edit_order: "Editar pedido",
            edit_order_remove_stock: "Editar ordem para retirada de inventário",
            pay_order: "Marcar como pago",
            view_return: "Ver devolução",
            interrupt_order: "Interromper pedido",
            sure_to_interrupt_shipment: "Tem certeza de que deseja interromper o envio?",
            interrupt_shipment_disclaimer: "<p><strong>Importante: Não é garantido o cancelamento do envio</strong>, pois o pedido já está na área de embarque.</p><p>Se o envio for cancelado, <strong>o serviço de Pick and Pack será cobrado</strong> e será reingressado como uma devolução ao seu inventário.</strong></p>",
            interrupt_notes: "Retorno criado por interrupção do envio do pedido #{{order_number}} (id: {{order_id}}). Interrupção feita pelo usuário {{user_email}}.",
            cancel_order: "Cancelar pedido",
            sure_to_cancel: "Tem certeza de que deseja cancelar o pedido?",
            sure_to_cancel_with_difal: "Aviso: Este pedido possui o DIFAL pago",
            pause_order: "Pausar pedido",
            sure_to_pause: "Tem certeza que deseja pausar o pedido?",
            reset_order: "Retomar pedido",
            sure_to_pay: "Tem certeza de marcar o pedido como pago?",
            sure_to_reboot: "Tem certeza que deseja retomar o pedido?",
            create_claim: "Abrir Contestação",
            claim_created: 'Disputa gerada!',
            create_return: "Criar Devolução",
            no_orders_to_show: "Nenhum produto a mostrar.",
            footer_label: "pedidos",
            create_order: "Criar pedido",
            create_orders: "Criar pedidos",
            shipping_date_placeholder: {
                start: "Começar",
                end: "O fim"
            },
            since: "Desde",
            at: "Em",
            few_moments: "Há alguns instantes",
            ago: "Há",
            days: "dias",
            day: "dia",
            created_at: "Criado em",
            shipped_at: "Enviado em",
            pending_at: "Pendente em",
            report_delay: "Reportar atraso",
            external_label_desc: "Com etiqueta externa, rastreie o pedido direto com a transportadora",
            export_dialog: {
                order_number: 'Com número de pedido igual ou semelhante a: ',
                tracking_number: 'Com número de etiqueta de envio igual a: ',
                channel_name: 'Que foram criados em: ',
                status_one: 'Cujo estado do pedido é ',
                status_other: 'Cujo pedido está em qualquer um dos estados: ',
                shipping_status_one: 'Cujo envio está no estado de ',
                shipping_status_other: 'Cujo envio é em qualquer um dos estados: ',
                shipping_name: 'Com destinatário igual ou semelhante a: ',
                shipping_date: 'Que foram enviados entre ',
                shipping_date_join: ' e ',
                channel_created_at: 'Que tenham sido criados entre ',
                channel_created_at_join: ' e o ',
                shipping_method: 'Com método de envio: ',
                sku: 'Que contenham o SKU: ',
                intro: 'Serão exportados os pedidos que atenderem às seguintes condições: ',
                confirm: 'Baixar',
                cancel: 'Cancelar',
                default_description: 'Todos os pedidos em sua conta serão exportados. Se desejar, use os filtros para obter um relatório específico.'
            },
            extended_zone: "Zona estendida",
            extended_zone_tooltip: "Pedidos para zonas estendidas costumam ter um maior prazo de entrega e possuem custos extras"
        },

        action_bar: {
            select_action: "Selecione uma ação",
            orders_selected: "Pedidos selecionados",
            orders: "Pedidos",
            deselect_all_orders: "Limpar seleção",
            actions: {
                CANCEL_ORDERS: "Cancelar pedidos",
                HOLD_ORDERS: "Pausar pedidos",
                UNHOLD_ORDERS: "Reiniciar pedidos",
                MARK_AS_PAYED: "Marcar como pago"
            },
            notification: {
                canceling_orders: "Cancelando pedidos",
                orders_canceled: "<strong>{{canceled}} de {{total}}</strong> pedidos selecionados foram cancelados"
            },
            errors: {
                codes: {
                    ORDER_CANCELED: "Já estão cancelados",
                    ORDER_SHIPPED: "Não é possível cancelá-los",
                    ORDER_ON_HOLD: "Já estão pausados",
                    ORDER_CANT_BE_HOLD: "Não é possível pausá-los",
                    ORDER_CANT_BE_UNHOLD: "Não é possível reiniciá-los",
                    ORDER_CANT_MARK_PAID: "Não é possível marcá-los como pago",
                    valid: {
                        CANCEL_ORDERS: "Serão cancelados",
                        HOLD_ORDERS: "Serão pausados",
                        UNHOLD_ORDERS: "Serão reiniciados",
                        MARK_AS_PAYED: "Será marcados como pago"
                    }
                },
                error_modal: {
                    title: "Revisar pedidos",
                    confirmations: {
                        CANCEL_ORDERS: 'Tem certeza de que deseja cancelar os {{order_count}} pedidos selecionados?',
                        HOLD_ORDERS: 'Tem certeza de que deseja pausar os {{order_count}} pedidos selecionados?',
                        UNHOLD_ORDERS: 'Tem certeza de que deseja reiniciar os {{order_count}} pedidos seleccionados?',
                        MARK_AS_PAYED: 'Tem certeza de que deseja marcar como pago os {{order_count}} pedidos selecionados?',
                        no_valid_elements: 'Não há pedidos válidos para esta ação',
                        omit_errors_and_submit: "Confirmar {{action}}",
                        warning_cancel_with_difal: 'Aviso: Pedidos {{orders}} possuem o DIFAL pago'
                    },
                    messages: {
                        action_not_possible: 'Para {quantity_errors} de {quantity} pedidos selecionados, não será possível realizar esta ação',
                        omit_errors_and_submit_question: 'Deseja ignorar esses pedidos e aceitar o restante da seleção?',
                        order_numbers_with_errors: 'Número dos pedidos com erro',
                        no_valid_elements_tooltip: 'Não foi possível realizar esta ação para nenhum dos pedidos selecionados',
                    },
                    action_type: {
                        CANCEL_ORDERS: "cancelamento",
                        HOLD_ORDERS: "pausa",
                        UNHOLD_ORDERS: "reinício",
                        MARK_AS_PAYED: "pagamento"
                    },
                    collapse: {
                        show: "Ver número dos pedidos",
                        hide: "Ocultar",
                        description: "O estado do pedido não é compatível com esta ação"
                    }
                }
            },
        },

        shipping_incident : {
            status : {
                PENDING: "Pendente",
                IN_REVIEW: "Sob revisão",
                RESOLVED: "Resolvido",
            },
            category : {
                DELAYED: "Entrega atrasada",
                MISSING_REFERENCES: "Complemento de endereço",
                CANCELED: 'Cancelar entrega',
                DAMAGED: 'Pedido avariado',
                MISSING_PRODUCT: 'Faltam produtos',
                FALSE_DELIVERY: 'Entrega incorreta',
                CHANGE_ADDRESS: "Endereço alterado",
                RESCHEDULE: "Entrega reprogramada"
            },
            resolution: {
                STOLEN: 'Pedido roubado',
                LOST: 'Pedido extraviado',
                RETURNED: 'Regressando ao armazém',
                DELIVERED: 'Pedido entregue',
                NON_REFUNDABLE_DAMAGED: 'Produto avariado não reembolsável',
                REFUNDABLE: 'O pedido será reembolsado'
            },
            reported_to_carrier: "Reportado à transportadora",
            with_shipping_incident: 'Com incidente'
        },

        lineItem: {
            dropshipping: "Este produto não é encaminhado via Cubbo",
            fragile_product: "Produto é Frágil",
            create_product: "Criar Produto",
            presale: "Pré-Venda",
            no_stock: "Sem Estoque",
            not_enough_stock:"Estoque incompleto",
            damaged: "Avariado",
            item_generated: "Este item foi gerado pelo sistema, caso não haja produto em estoque, o pedido será enviado sem este item.",
            generating: "Criando",
            generated: "Gerado por regra de negócio",
            quantity: "Quantidade ",
            generated_info: "Este produto foi gerado pelo sistema, se o produto não estiver disponível em estoque, o pedido será enviado sem ele",
        },

        pricesContainer: {
            price_concept: "Conceito",
            price_amount: "Valor a cobrar",
            price_amount_desc: "A transportadora deve cobrar ",
            price_amount_desc_continuation: " ao entregar este pedido, já que o tipo do envio é Cash on Delivery.",
            total_items_price: "Preço total dos itens",
            discount: "Desconto",
            shipping_price: "Preço do envio",
            extra_fees_price: "Despesas acessórias",
            total_order_price: "Total do pedido",
        },

        dateTime: {
            minute: "minuto",
            minutes: "minutos",
            hour: "hora",
            hours: "horas",
            day: "dia",
            days: "dias",
        },

        missingDataContainer: {
            solution: "Solução: ",
            missingData: "Dados faltantes",
        },

        orderMovementContainer: {
            orderMovement: "Movimento do pedido",
            estimatedDelivery: "Entrega estimada",
            estimatedDeliveryNotAvailable: "Sem informação",
            estimatedDeliveryAccuracy: "Estimativa baseada no Código Postal",
            incidents: "Incidentes reportados ao transportador"
        },

        orderHistory: {
            title: "Histórico de pedidos",
            status_title: "Estado",
            action_by: "Por",
            no_history: "Sem informação",
            by_integration: 'a integração',
            from_tracking: 'Por meio da página de rastreamento',
            timelineElements: {
                initial: {
                    title: "Sincronizando",
                    titleAlternative: "Sincronizado",
                },
                empty: {
                    title: "Pedido vazio",
                    description: "O pedido foi recebido pela integração sem produtos atribuídos para o cumprimento",
                },
                pending: {
                    title: "Pendente",
                },
                reset_pending: {
                    title: "Conta reativada",
                },
                error: {
                    title: "Informação faltando",
                    description: "Dados faltando",
                    codes: {
                        // general
                        ORDER_NUMBER_NOT_UNIQUE: "Número de pedido duplicado",
                        MISSING_SHIPPING_FIELD: "Dados faltantes no endereço do cliente",
                        MISSING_TAX_FIELD: "Informação faltante nos dados de faturamento",

                        // order_lines
                        UNIDENTIFIED_PRODUCT: "Produto com SKU '{sku}' não existe na Cubbo",
                        DUPLICATE_STORE_SKU: "SKU duplicado: '{sku}'",
                        MISSING_PRODUCT_SKU: "Produtos sem SKU enviados pela integração",

                        // shipping info
                        UNRECOGNIZED_COUNTRY: "País não identificado",
                        INVALID_ZIPCODE: "CEP inválido",
                        UNRECOGNIZED_CITY_OR_STATE: "Cidade ou estado não identificado",

                        // integration shipping method
                        NO_MATCHING_INTEGRATION_SHIPPING_METHOD: "Método de envio atribuído não encontrado na Cubbo",
                        NO_MATCHING_INTEGRATION_SHIPPING_METHOD_NO_NAME: "Sem atribuição de método de envio",

                        // shipping groups
                        NO_SHIPPING_GROUP_ASSIGNED: "Método de envio sem configuração de envio atribuída",

                        // shipping method
                        MISSING_SHIPPING_METHOD: "Sem método de envio atribuído por falta de informação",
                        INTERNATIONAL_SHIPPING_METHOD_EXPECTED: "Método de envio atribuído sem cobertura internacional",
                        COD_SHIPPING_METHOD_EXPECTED: "Método de envio atribuído não disponível para pagamento na entrega",
                        MISSING_EXTERNAL_LABEL: "Falta etiqueta externa",
                        SHIPPING_METHOD_HAS_NO_COVERAGE: "Método de envio sem cobertura",
                        UNKNOWN_ORDER_WEIGHT: "Peso do pedido não determinado",
                        SHIPPING_COST_UNAVAILABLE: "Preço de envio indisponível",
                        BLACKLISTED_ZIP_CODE: "Código postal temporariamente sem cobertura",
                        MISSING_COD_VALUE: "Valor de cobrança não fornecido",

                        // taxing info
                        BILLING_TAX_ID_WRONG_LENGTH: "Número de identificação fiscal incorreto",
                        INVALID_PRODUCT_INVOICING_PRICE: "Preço inválido para SKU: '{sku}'",
                        INVALID_PRODUCT_NCM: "NCM inválido para SKU: '{sku}'",
                        TOTAL_ORDER_VALUE_MISMATCH: "Há diferenças no preço total do pedido, {channel_integration_type} relatou {expected_total_price} e Cubbo calculou {calculated_total_price}",
                        MELI_ORDER_NOT_READY_TO_SHIP: "Etiqueta de envio do Mercado Livre não gerada",
                        // INVALID_TOTAL_ORDER_VALUE: "Invalid total order value. Order value must be more than 0, or completely discounted",
                        // NO_INVOICEABLE_PRODUCTS: "No products in this order are invoiceable. At least one product of the order must be invoiceable",
                        INVALID_TOTAL_ORDER_VALUE: "Valor total do pedido inválido. O valor do pedido deve ser maior que 0, ou completamente descontado",
                        NO_INVOICEABLE_PRODUCTS: "Nenhum produto neste pedido é faturável. Pelo menos um produto do pedido deve ser faturável",
                        
                        // DIFAL
                        MISSING_DIFAL_PAYMENT_RECEIPT: "Falta comprovante de pagamento da DIFAL",
                        WEBMANIA_ERROR: "Erro ao gerar NF-e. Erro: {error}"
                    },

                    missingInfoList: {
                        invalid_sku: 'SKU: {sku} não existe em cubbo',
                        sku: 'existem produtos sem SKU',
                        phone: 'telefone',
                        email: 'e-mail',
                        name: 'nome do cliente',
                        cp: 'CEP inválido',
                        state: 'Estado',
                        city: 'Cidade',
                        address: 'Endereço',
                    }
                },
                canceled: {
                    title: 'Cancelado',
                    title_tooltip: 'Veja a automação ativa no painel de configuração',
                },
                backorder: {
                    title: 'Sem estoque',
                    description: "SKUs com estoque incompleto: {sku}",
                    paragraph: {
                        title: 'Para fornecer uma solução ao seu cliente, você poderia:',
                        hide: 'Ocultar sugestão',
                        content:{
                            p1: {
                                title: 'Esperar e reabastecer o produto com peça faltante',
                                description: 'Sugerimos que você notifique seu cliente sobre o tempo de espera extraordinário',
                            },
                            p2: {
                                title: 'Enviar pedido parcial',
                                description: 'Duplicando este pedido excluindo a peça sem estoque e cancelando este pedido. Além disso, você deve coordenar o reembolso parcial com seu cliente',
                            },
                            p3: {
                                title: 'Cancelar e reembolsar',
                                description: 'Se não estiver nos seus planos reabastecer a peça faltante ou enviar o pedido parcial, você pode cancelar o pedido e reembolsar a compra ao seu cliente',
                            }
                        }
                    }
                },
                unpaid: {
                    title: 'Não pago',
                },
                paid: {
                    by_user: 'Marcado como pago',
                },
                cancelation_window: {
                    title: 'Na janela de cancelamento',
                    description: "O pedido ainda pode ser cancelado pela integração. O tempo que seus pedidos permanecerão nesta janela depende da sua configuração na integração",
                },
                holded: {
                    title: 'Pausado',
                },
                unhold: {
                    title: 'Retomado',
                },
                interrupted: {
                    title: 'Interrompido',
                    description: "O cumprimento do pedido foi cancelado enquanto estava sendo preparado. Não enviaremos este pedido, mas a taxa de retirada e embalagem será cobrada",
                },
                shipment_interrupted: {
                    title: 'Interrumpido',
                    description: "O pedido foi cancelado enquanto estava sendo preparado. Se o pedido for interrompido antes do envio, a taxa de retirada e embalagem será cobrada",
                },
                entirely_dropshipping: {
                    title: 'Realizado externamente',
                    description: "A Cubbo não armazena o produto deste pedido, portanto não haverá cumprimento deste pedido",
                },
                edit: {
                    title: 'Editado',
                },
                tracking_edit: {
                    title: 'Endereço editado'
                },
                duplicate: {
                    title: 'Duplicado',
                    description: '{link}',
                },
                create: {
                    title: 'Criado',
                },
                create_from: {
                    title: 'Criado como um duplicado',
                    title_complement: 'de {link}',
                    title_complement_link: 'Ver automação configurada'
                },
                processing: {
                    title: 'Processando',
                },
                shipped: {
                    title: 'Aguardando coleta',
                    title_pickup: 'Enviado',
                    description: "Se após 36 horas o pedido ainda não tiver sido enviado, escreva para o Suporte Cubbo para ajudá-lo",
                },
                shipment_collected: {
                    title: 'Enviado',
                    description: 'O pedido passa para as mãos da transportadora',
                },
                shipment_external_label: {
                    title: 'Com etiqueta externa, rastreie o pedido diretamente com a transportadora',
                },
                shipment_canceled: {
                    title: 'Entrega cancelada',
                    description: 'Quando o pedido retornar à Cubbo, o status do pedido será atualizado para "Reinserido"',
                },
                shipment_in_transit: {
                    title: 'Em trânsito',
                },
                shipment_out_for_delivery: {
                    title: 'Em rota de entrega',
                    description: 'O pedido será entregue nas próximas horas',
                },
                shipment_with_incident: {
                    title: 'Incidente',
                    title_delivery_attempt: 'Tentativa de entrega sem sucesso'
                },
                shipment_to_be_self_collected: {
                    title: 'Coletar na agência',
                    description: 'O pedido está na agência da transportadora aguardando a retirada pelo seu cliente. Para mais informações, entre em contato com a equipe de suporte',
                },
                shipment_delivered: {
                    title: 'Entregue',
                },
                returning: {
                    title: 'Retornando para Cubbo',
                    types: {
                        SIMPLE_RETURN: "Por devolução solicitada",
                        EXCHANGE: "Por devolução solicitada",
                        IMMEDIATE_EXCHANGE: "Por devolução solicitada",
                        RETURNED_BY_CARRIER: "Por entrega sem sucesso",
                        TO_BE_DETERMINED: "",
                        SHIPMENT_INTERRUPTED: "Por interrupção do envio",
                    },
                },
                returned: {
                    title: 'Recebendo retorno',
                    types: {
                        SIMPLE_RETURN: "Por devolução solicitada",
                        EXCHANGE: "Por devolução solicitada",
                        IMMEDIATE_EXCHANGE: "Por devolução solicitada",
                        RETURNED_BY_CARRIER: "Por entrega sem sucesso",
                        TO_BE_DETERMINED: "",
                    },
                },
                reentered: {
                    title: 'Reinserido',
                    types: {
                        SIMPLE_RETURN: "Por devolução solicitada",
                        EXCHANGE: "Por devolução solicitada",
                        IMMEDIATE_EXCHANGE: "Por devolução solicitada",
                        RETURNED_BY_CARRIER: "Por entrega sem sucesso",
                        TO_BE_DETERMINED: "",
                    },
                },
                return_arrived: {
                    title: 'Retornado para Cubbo',
                    types: {
                        SIMPLE_RETURN: "Por devolução solicitada",
                        EXCHANGE: "Por devolução solicitada",
                        IMMEDIATE_EXCHANGE: "Por devolução solicitada",
                        RETURNED_BY_CARRIER: "Por entrega sem sucesso",
                        TO_BE_DETERMINED: "",
                    },
                },
                return: {
                    title: 'Reinserido',
                    by_client: 'Retornado pelo seu cliente',
                    by_carrier: 'Retornado pela transportadora, por entrega não realizada',
                },
                shipment_returned: {
                    title: 'Retornado para Cubbo',
                    types: {
                        SIMPLE_RETURN: "Retornado por devolução solicitada",
                        EXCHANGE: "Retornado por troca solicitada",
                        IMMEDIATE_EXCHANGE: "Retornado por troca imediata solicitada",
                        RETURNED_BY_CARRIER: "Retornado por transportadora",
                        TO_BE_DETERMINED: "",
                    },
                },
                claim: {
                    title: 'Disputa',
                    statuses: {
                        PENDING: 'Pendente',
                        REJECTED: 'Rejeitada',
                        ACCEPTED: 'Aceita',
                    },
                    categories: {
                        UNWANTED_PRODUCTS: 'Por envio de produto sobressalente',
                        MISSING_PRODUCTS: 'Por produto faltante',
                        WRONG_ORDER: 'Por envio de pedido incorreto',
                        PACKAGE_LOST: 'Por pedido extraviado',
                        DAMAGED_PRODUCT: 'Por produto danificado',
                        DELIVERY_DELAY: 'Por atraso na entrega',
                        FALSE_DELIVERY: 'Por entrega incorreta do pedido',
                        PACKAGE_STOLEN: 'Por pacote roubado',
                        DELIVERY_OTHERS: 'Outros - Serviço de encomendas',
                    }
                }
            }
        },

        pod:{
            download: "Baixar",
            received_by: "Recebido por",
            title: "Comprovante de entrega",
            not_provided: "Esta transportadora não fornece comprovante de entrega",
            unavailable: "Em breve você poderá acessá-lo através desta seção.",
            proof: "Comprovante",
            download_success: "Comprovante de entrega baixado com sucesso",
            download_error: "Não foi possível baixar o comprovante de entrega, tente novamente mais tarde",
        },

        showContainer: {
            is_first_time_buyer: "Novo Cliente!",
            is_click_and_collect: "Coletar no ponto físico",
            click_and_collect_data: "Dados do ponto de coleta",
            click_and_collect_location_name: "Nome da propriedade ",
            kit_title: "Produtos que compõem o kit",
            file: "Arquivo",
            file_name: "Nome",
            name: "Nome ",
            send_to: "Enviar a ",
            printing_type: "Tipo de impressão",
            actions: "Ações",
            shipping_Market: "Mercado Envios",
            external: "Externo",
            configure_shipping: "Configurar modalidade de envio",
            edit: "Editar Pedido",
            missing_information: "Faltam informações no seu pedido, verifique as informações abaixo.",
            assign_shipment: "Atribuir modalidade de envio",
            order_number: "Número do pedido",
            cart_id: "Carrinho de compras",
            store: "Loja",
            state: "Estado ",
            status:  "Status do pedido",
            date_created: "Data de criação",
            shipping_information: "Informações de envio",
            shipping_pickup: "Informações da coleção",
            customer_address: "Endereço do cliente",
            shipping_order_information: "Informações do pedido",
            picking_order_information: "Dados de entrega",
            shipping_method: "Modalidade de envio",
            type_of_shipment: "Tipo de envio",
            cash_on_delivery: "Dinheiro na entrega",
            shipment_status: "Status de Entrega",
            date_of_creation:"Data de criação",
            no_information: "Sem informação",
            products: "Produtos",
            orderProducts: "Produtos do pedido",
            product_title: "Nome do produto",
            sku_title: "SKU",
            price_title: "Preço",
            lot_title: "Lote",
            imei_title: "Número de série",
            labels_title: "Etiquetas",
            requested_product_number: "Peças solicitadas",
            reserved_product_number: "Peças reservadas para este pedido",
            available_product_number_for_sale: "Inventário disponível para venda",
            available_product_number_for_sale_tooltip: "Total de peças ainda disponíveis na Cubbo para venda, após subtrair o que foi reservado para seus pedidos",
            imei: "Número de série: ",
            lot: "Lote: ",
            expiration: "Validade: ",
            product_dont_exist: "O produto não existe na Cubbo",
            price: "Preço:",
            sales_channel_pieces: "Canal de vendas | peças",
            tracking_number: "Número de rastreamento",
            attachments: "Arquivos anexos",
            download: "Download",
            sendTo: "Enviar para ",
            phone: "Telefone ",
            email: "Mail ",
            address: "Direção ",
            number: "Número ",
            neighborhood: "Bairro ",
            city: "Cidade ",
            zip_code: "CEP ",
            cpf: "CPF ",
            billing_info: "Dados de faturamento",
            tax_regime: "Regimen fiscal ",
            edit_order: "Editar pedido",
            generate_label: "Gerar etiqueta",
            tracking_number_input_label: "Código de rastreamento para",
            channel: "Canal",
            remove_stock_type: "Remover estoque en buen estado",
            remove_damaged_stock_type: "Remover estoque avariado",
            remove_expired_stock_type: "Remover estoque expirado",
            here: "aqui",
            previous_delivery_date: "Data de entrega anterior",
            new_address: "Novo endereço de entrega",
            pick_and_ship: "PickNShip",
        },
        create_bulk: {
            view_orders: "Ver Pedidos",
            ok: "De acordo",
            was_a_problem: "Tem havido um problema!",
            zero_orders_imported: "Não foi possível importar seus pedidos, entre em contato com o suporte e nos envie a planilha que você tentou importar.",
            thanks_for_waiting: "Obrigado pelo tempo de espera.",
            error_orders_reminder: "Lembre-se que os pedidos marcados com erro na etapa anterior não serão importados.",
            orders_imported: "{{orders_number}} novos pedidos foram importados para sua loja!",
            not_all_orders_imported: "Importado com sucesso {{imported_orders}} de {{total_orders}} pedidos de previsão.",
            we_are_importing: "Estamos importando {{orders_number}} novos pedidos para sua loja!",
            orders: "PEDIDOS",
            imported: "IMPORTADOS"
        }
    },

    return: {
        container: {
            return: "Devolução",
            awaiting_return: "Aguardaremos essa devolução em nossos Armazéns.",
            change: "Troca",
            replacement_products: "Os itens de substituição disponíveis serão reservados. Assim que recebermos a devolução, será gerado o pedido correspondente.",
            immediate_change: "Troca Imediata",
            immediate_change_help: "Geraremos um pedido imediatamente com os produtos para substituição.",
            yes: "sim",
            no: "não",
            not_readable: "Erro: o pedido está em um estado que não é elegível para devolução.",
            order_number: "Número de pedido",
            store: "Loja",
            return_type: "Tipo de Devolução",
            return_guide: "Gerar código de devolução?",
            generate_guide: "Um código de devolução será gerado usando",
            return_instructions: "Serão fornecidas instruções para que o cliente possa enviar a devolução por conta própria.",
            returnees: "Devolvidos",
            add_another_product: "Adicionar outro produto da loja",
            products: "Produto",
            quantity: "Quantidade",
            damaged_parts: "(Itens Avariados)",
            add_part: "Adicionar item avariado",
            remove_product: "Remover produto",
            replacement: "Reposição",
            cancel: "Cancelar",
            save: "Salvar",
            confirm: "Confirmar",
            total_return: "Confirmar que será devolvido um total de",
            product: "produto",
            confirm_return: "Confirmar Devolução",
            notes: "Observações",
        },
        productsDialog: {
            stock_release: "Confirmar Liberação de estoque",
            release_the_products: "Você está prestes a liberar os produtos que foram reservados para atender o pedido que será criado após o recebimento da devolução ",
            confirmation: "Deseja confirmar?",
            loading: "Carregando",
            confirm: "Confirmar",
            cancel: "Cancelar",
            close: "Fechar",
        },

        returnedProduct: {
            name: "Nome",
            no_sku: "Sem SKU",
            no_upc: "Sem UPC",
            store_inventory: "Inventário da loja",
            products:"Produtos",
            no_products_to_show: "Nenhum produto a mostrar",
            accept: "Aceitar", 
            cancel: "Cancelar",
        }

    },
    
    claims: {
        list: {
            value: "VALOR SOLICITADO",
            accepted_value: "VALOR APROVADO", 
            created_at: "Data de Criação",
            resolved_at: "Data de Resolução",
            see_detail: "Ver detalhamento",
            see_dispute: "Ver Contestação" , 
            show_orders: "Ver Pedido",
            pending: "Pendente",
            rejected: "Recusada",
            Disputes:"Contestação",
            all_stores: "Todas as lojas",
        },

        detail: {
            status: "Status",
            resolution: "Resposta",
            accepted_values: "Valor Reembolsado",
            orders_number: "Número de Pedido",
            value_of_products: "Valor total solicitado",
            dispute: "Contestação", 
            Compensation_Testing: "Documentos que Comprovem a Contestação",
        },
        errors: {
            related_unresolved_existing_claim: "Esta disputa já foi criada e está pendente de resolução"
        },
        create: {
            order_number: 'Número de Pedido',
            order_status: 'Status',
            shipping_method: 'Transportadora',
            tracking_number: 'Código de Rastreamento',
            close_button: 'Fechar',
            save_button: 'Salvar',
            files_field_placeholder: 'Clique aqui, ou arraste e solte seus arquivos PDF.',
            generic:{
                form_title: 'Para podermos analisar este caso, precisamos que você nos dê suporte com alguns dados...',
                form_subtitle: 'Lembre-se de que a resolução desta disputa depende da veracidade desta evidência.',
                invoice_file: 'Favor anexar a fatura em PDF com o IVA detalhado dos produtos do pedido para os quais se solicita reembolso, para que possamos solicitá-lo à transportadora.'
            },
            problems_list: {
                edit_selection: 'Editar seleção',
                title: 'Qual é o problema?',
                delivery_delay: 'Pedido foi coletado pela transportadora mas está sem movimentação.',
                false_delivery: 'O status do pedido indica que o pedido foi entregue, mas meu cliente não o recebeu.',
                package_stolen: 'O status do pedido indica que foi roubado.',
                package_lost: 'O status do pedido indica que foi extraviado ou roubado.',
                damaged_product: 'Pedido foi entregue avariado.',
                incorrect_content: 'Pedido entregue não corresponde ao que o cliente encomendou.',
                none_of_the_above: 'Nenhuma das opções acima.'
            },
            currency_input: {
                title: 'Indicar o valor do produto a ser reembolsado',
                help: 'IMPORTANTE: Indique APENAS o valor dos produtos a serem reembolsados. Qualquer outro valor inserido que não seja justificado pode resultar na rejeição da contestação, mesmo que seja legítima.'
            },
            currency_preloaded: {
                title: 'Preço de venda dos produtos, obtido da integração',
                subtitle: 'Será realizada uma investigação sobre o estado do pedido e será determinado quais dos seguintes conceitos são elegíveis para reembolso:',
                processing_cost: "Custo de processamento do pedido",
                shipping_labels_cost: "Custo da etiqueta de envio",
                cost_return_shipping: "Custo da etiqueta de retorno",
                products_price: "Preço de venda dos produtos",
                concept: "Conceito",
                amount: "Valor",                     
                without_iva: "IMPORTANTE: Valor sem IVA"
            },
            notes_input: {
                title: 'Notas',
                help: 'Confirme se o endereço de entrega está correto no pedido, referências adicionais de local de entrega e um número de telefone celular do destinatário. '
            },
            delivery_delay: {
                review_window: {
                    title: 'Quanto tempo levará para resolver esta disputa?',
                    subtitle: 'Apresentaremos um relatório com o correio e esperaremos no máximo 72 horas por sua resposta para resolver a disputa. Nós o notificaremos pelo correio sobre qual é a resolução final.'
                },
                refund_details: {
                    title: 'Quanto serei reembolsado?',
                    subtitle: 'Se o pedido for classificado como perdido, ele será reembolsado:',
                    refund_elements: [
                        'Custo operacional da Cubbo',
                        'Custo do lead de pedido',
                        'Valor indicado do produto'
                    ]
                },
                advice: {
                    title: 'O que fazer enquanto a disputa está sendo resolvida?',
                    subtitle: 'Recomendamos que você notifique seu cliente que o pedido está sob investigação.',
                    text: 'Duplicar o pedido somente se após 72 horas após a disputa ter sido salva ainda não houver solução, caso contrário, poderá ocorrer dupla entrega.'
                }
            },
            false_delivery: {
                review_window: {
                    title: 'Quanto tempo levará para resolver esta disputa?',
                    subtitle: 'Apresentaremos um relatório com o correio e esperaremos no máximo 72 horas por sua resposta para resolver a disputa. Nós o notificaremos pelo correio sobre qual é a resolução final.'
                },
                refund_details: {
                    title: 'Quanto serei reembolsado?',
                    subtitle: 'Se o pedido for classificado como perdido, ele será reembolsado:',
                    refund_elements: [
                        'Custo operacional da Cubbo',
                        'Custo do lead de pedido',
                        'Valor indicado do produto'
                    ]
                },
                advice: {
                    title: 'O que fazer enquanto a disputa está sendo resolvida?',
                    subtitle: 'Recomendamos que você notifique seu cliente que o pedido está sob investigação.',
                    text: 'Duplicar o pedido somente se após 72 horas após a disputa ter sido salva ainda não houver solução, caso contrário, poderá ocorrer dupla entrega.'
                }
            },
            package_stolen: {
                sorry_text: {
                    title: 'Pedimos desculpas pelo incômodo...',
                    help: 'Infelizmente, sob contrato e devido às políticas da parcela, estipulamos que estes casos não são reembolsáveis. Nem o valor do produto, nem o custo operacional do pedido.'
                },
                advice: {
                    title: 'Para proporcionar uma melhor experiência ao seu cliente, sugerimos...',
                    help: 'Duplique o pedido para que você receba os produtos que encomendou.'
                }
            },
            package_lost: {
                review_window: {
                    title: 'Quanto tempo levará para resolver esta disputa?',
                    subtitle: 'Apresentaremos um relatório com o correio e esperaremos no máximo 72 horas por sua resposta para resolver a disputa. Nós o notificaremos pelo correio sobre qual é a resolução final.'
                },
                refund_details: {
                    title: 'Quanto serei reembolsado?',
                    subtitle: 'Se a disputa for aceita, ela será reembolsada:',
                    refund_elements: [
                        'Custo operacional da Cubbo',
                        'Custo do lead de pedido',
                        'Valor indicado do produto'
                    ]
                },
                advice: {
                    title: 'O que fazer enquanto a disputa está sendo resolvida?',
                    subtitle: 'O que fazer enquanto a disputa está sendo resolvida?',
                }
            },
            form_title: 'A fim de acelerar o processo, precisamos que nos apóiem com alguns dados...',
            form_title_preloaded: 'Valor a reembolsar',
            duplicate_order_button: 'Pedido em duplicata',
            new_order_button: 'Criar pedido',
            damaged_product: {
                form_title: 'Para podermos analisar este caso, precisamos que você nos dê suporte com alguns dados...',
                form_subtitle: 'Lembre-se de que a resolução desta disputa depende da veracidade desta evidência.',
                damaged_images: 'Anexar uma fotografia de cada um dos produtos que foram recebidos em más condições.',
                label_image: 'Anexar uma fotografia das Etiquetas DANFE PDF e Endereçamento.',
                package_images: 'Anexar uma fotografia de cada um dos 6 lados da embalagem.',
                review_window: {
                    title: 'Quanto tempo levará para resolver esta disputa?',
                    subtitle: 'A equipe da Cubbo apresentará um relatório à empresa de encomendas para solicitar um reembolso dos produtos afetados. Nós o notificaremos pelo correio sobre a resolução final.'
                },
                refund_details: {
                    title: 'Quanto serei reembolsado?',
                    subtitle: 'Se a disputa for aceita, ela será reembolsada:',
                    refund_elements: [
                        'Custo operacional da Cubbo',
                        'Valor indicado do produto',
                        'Custo de orientação para o envio de produtos em falta'
                    ]
                },
                advice: {
                    title: 'O que fazer enquanto a disputa está sendo resolvida?',
                    subtitle: 'Recomendamos que você crie um novo pedido com os produtos recebidos em mau estado, a fim de enviar os substitutos ao seu cliente.',
                }
            },
            incorrect_content: {
                problems_list: {
                    title: 'Conte-nos mais...',
                    missing_products: 'Faltaram produtos no pedido',
                    unwanted_products: 'Produtos não encomendados foram recebidos',
                    wrong_order: 'Meu cliente recebeu um pedido completo que não era dele.'
                },
                missing_products: {
                    problems_list: {
                        title: 'O produto que faltava era...',
                        paid_product: '...algo que meu cliente comprou.',
                        promotional_product: '...algo que deveria ter sido enviado como um produto promocional.'
                    },
                    paid_product: {
                        form_title: 'Para podermos analisar este caso, precisamos que você nos dê suporte com alguns dados...',
                        form_subtitle: 'Lembre-se de que a resolução desta disputa depende da veracidade desta evidência.',
                        label_image: 'Anexar uma fotografia das Etiquetas DANFE PDF e Endereçamento.',
                        package_images: 'Anexar uma fotografia de cada um dos 6 lados da embalagem.',
                        review_window: {
                            title: 'Quanto tempo levará para resolver esta disputa?',
                            subtitle: 'A equipe da Cubbo verificará se o incidente ocorreu em nosso processo de cumprimento ou em trânsito com a encomenda. Nós o notificaremos pelo correio sobre a resolução final.'
                        },
                        refund_details: {
                            title: 'Quanto serei reembolsado?',
                            subtitle: 'Se a disputa for aceita, ela será reembolsada:',
                            refund_elements: [
                                'Custo operacional da Cubbo',
                                'Custo de orientação para o envio de produtos em falta'
                            ]
                        },
                        advice: {
                            title: 'O que fazer enquanto a disputa está sendo resolvida?',
                            subtitle: 'Recomendamos que você crie um novo pedido o mais rápido possível com os produtos que faltam e os envie ao seu cliente.',
                        }
                    },
                    promotional_product: {
                        sorry_text: {
                            title: 'Pedimos desculpas pelo incômodo...',
                            help: 'Não podemos reembolsar esse tipo de caso, no entanto, pedimos que você abra um ticket de atendimento a fim de identificar a razão desta falha. É possível que haja algo para modificar em suas regras de marketing ou que não tenhamos mais itens disponíveis deste material.'
                        },
                    }
                },
                unwanted_products: {
                    form_title: 'Para podermos analisar este caso, precisamos que você nos dê suporte com alguns dados...',
                    form_subtitle: 'Lembre-se de que a resolução desta disputa depende da veracidade desta evidência.',
                    unwanted_products_images: 'Anexar uma fotografia de cada um dos produtos que foram recebidos e não encomendados.',
                    label_image: 'Anexar uma fotografia das Etiquetas DANFE PDF e Endereçamento.',
                    package_images: 'Anexar uma fotografia de cada um dos 6 lados da embalagem.',
                    review_window: {
                        title: 'Quanto tempo levará para resolver esta disputa?',
                        subtitle: 'A equipe da Cubbo verificará se o incidente ocorreu em nosso processo de cumprimento ou em trânsito com a encomenda. Nós o notificaremos pelo correio sobre a resolução final.'
                    },
                    refund_details: {
                        title: 'Quanto serei reembolsado?',
                        subtitle: 'Se a disputa for aceita, ela será reembolsada:',
                        refund_elements: [
                            'Custo operacional da Cubbo',
                            'Custo de orientação para o envio de produtos em falta',
                            'Custo do waybill de devolução de produto incorreto'
                        ]
                    },
                    advice: {
                        title: 'O que fazer enquanto a disputa está sendo resolvida?',
                        subtitle: 'Recomendamos que você crie um novo pedido com o produto que não tenha sido enviado no pedido original. Além de iniciar a devolução do produto que foi enviado e não encomendado.',
                    }
                },
                wrong_order: {
                    form_title: 'Para podermos analisar este caso, precisamos que você nos dê suporte com alguns dados...',
                    form_subtitle: 'Lembre-se de que a resolução desta disputa depende da veracidade desta evidência.',
                    unwanted_products_images: 'Anexar uma fotografia de cada um dos produtos que foram recebidos e não encomendados.',
                    label_image: 'Anexar uma fotografia das Etiquetas DANFE PDF e Endereçamento.',
                    package_images: 'Anexar uma fotografia de cada um dos 6 lados da embalagem.',
                    official_id_images: 'Anexar uma fotografia do documento de identidade do destinatário.',
                    review_window: {
                        title: 'Quanto tempo levará para resolver esta disputa?',
                        subtitle: 'A equipe da Cubbo verificará se o incidente ocorreu em nosso processo de cumprimento ou em trânsito com a encomenda. Nós o notificaremos pelo correio sobre a resolução final.'
                    },
                    refund_details: {
                        title: 'Quanto serei reembolsado?',
                        subtitle: 'Se a disputa for aceita, ela será reembolsada:',
                        refund_elements: [
                            'Custo operacional da Cubbo',
                            'Custo de orientação para o envio de produtos em falta',
                            'Custo do waybill de devolução de produto incorreto'
                        ]
                    },
                    advice: {
                        title: 'O que fazer enquanto a disputa está sendo resolvida?',
                        subtitle: 'Recomendamos que você duplique o pedido o mais rápido possível para proporcionar uma melhor experiência para seu cliente. ',
                    }
                }

            },
            none_of_the_above: {
                sorry_text: {
                    title: 'Pedimos desculpas pelo incômodo.',
                    help: 'Ajude-nos abrindo um ticket para que alguém da nossa equipe de Customer Success possa te auxiliar neste caso.'
                },
                add_ticket_button: 'Aumentar bilhete'
            },
            product_selector_table: {
                products_prices: "Preços dos Produtos",
                all_products: "Todos os Produtos",
                some_products: "Alguns Produtos",
                products_to_reimburse: "Produtos a Reembolsar:",
                amount_to_reimburse: "Valor dos Produtos a Reembolsar:",
                table: {
                    filter_placeholder: "Buscar por nome ou SKU",
                    headers: {
                        name: "Nome",
                        sku: "SKU",
                        price: "Preço",
                        sent: "Enviado",
                        reimbursed: "Reembolsado"
                    }
                }
            }
        }
    },

    integrations_list: {
        active: {
            configuring: "Configurando",
            configure: "Configurar",
            active: "Ativa",
            state: "estado",
            error: "Erro - credenciais ou permissões incorretas",
            deleted: "Desativada",
            shipping_methods: "Modalidades de envio",
            assign_a_location:"Escolher Localização",
            finance: "Finanças",
            authorize_app_shopee_products: "Autorizar o aplicativo a sincronizar produtos Shopee",
            authorize_app_shopee_finance: "Autorizar o aplicativo a sincronizar finanças Shopee",
            import_vtex_products: "Importar Produtos",
            products_sync: "Sincronizar Produtos",
            updated_shipping_methods: "Modalidades de envios atualizadas",
            updated_inventory: "Inventário Atualizado",
            all_stores: "Todas as lojas",
            assign_location: "Atribuir Localização",
            no_integration_to_show: "Não há integrações para mostrar.",
            Sync_shipping_methods: "Sincronizar modalidades de envio",
            integration: "Integrações",
            create_integration: "Criar Integração",
            pause_sync: "Pausar Sincronização automática",
            activate_sync: "Ativar Sincronização Automática",
            location: "Localização",
            sync_activated: "Sincronização Automática Ativada",
            sync_paused: "Sincronização Automática Pausada",
            token: "Credenciais",
            generate_new_token: "Gerar novas credenciais"
        },

        empty_list: {
            first_integration: "Você ainda não criou sua primeira integração",
            select_preferred_integration: "Selecione seu canal de vendas preferido para se conectar com Cubbo"
        },
    
        shipping: {
            Integration_Delivery_Method: "Modalidade de Envio de Integração",
            Type_shipping_method: "Tipo de modalidade de envio",
            Add_Priority_Shipping_Methods: "Adicionar prioridade de Modalidades de Envio",
            shipping_method_cubbo: "Modalidade de Envio Cubbo",
            type: "Tipo",
            option: "Opção",
            all_stores: "Todas as lojas",
            Save_Configuration: "Salvar Configuração",
            Add_Shipping_Method: "Adicionar Modalidade de Envio",
            methods_of_shipment: "Modalidade de Envio",
            add_new_method: "Adicionar nova Modalidade de Envio",
            no_shipping_methods: "Não existem modalidades de envio nesta integração.",
            Sync_shipping_methods: "Sincronize suas modalidades de envio na lista de integrações",
            add_option: "Adicionar opção",
            select_type: "Selecionar tipo",
            add_priority: "Adicionar prioridade:",
            national: "Nacional",
            international: "Internacional",
            cash_on_delivery: "Dinheiro na entrega",
            priority_to_shipping_method:" Atribuir prioridades para modalidade de envio",
            integration_shipping_method_prefix: "Modalidade de Envio de",
            shipping_group: "Configurações de Envio",
            integration_shipping_types: {
                national: {
                    label: 'Nacional',
                    tip: "Envio com alcance nacional"
                },
                international: {
                    label: 'Internacional',
                    tip: "Envío com alcance internacional"
                },
                cash_on_delivery: {
                    label: 'Pagamento Contra Entrega',
                    tip: "Envio nacional pago durante a entrega"
                },
            },
            success_sync_integration_shipping_methods: "Os métodos de envio da sua integração foram sincronizados com o Cubbo.",
            error_sync_integration_shipping_methods: "Ocorreu um erro ao tentar sincronizar os modalidades de envio de sua integração com Cubbo.",
            assign_shipping_group: "Atribuir Configurações de Envio",
            unassign_shipping_group: "Remover configurações de envio",
            column_help: {
                integration_shipping_method: "O nome do seu modalidade de envio na sua integração",
                shipping_type: "Definir a maneira na qual se manejará este modalidade de envio",
                shipping_group: "Escolha os transportes que podem ser usados para enviar pedidos com este modalidade de envio"
            },
            success_update_shipping_type: "O modalidade de envio foi atualizado.",
            error_update_shipping_type: "Ocorreu um erro ao tentar atualizar seu modalidade de envio.",
            missing_shipping_group_tip: "Este modalidade de envio no tiene configuración, los pedidos no serán enviados y se encontrarán en estado 'Informação Pendente'",
            return_to_integrations: "Retornar",
            empty_shipping_methods: "Não há modalidade de envio",
            empty_shipping_methods_create: "crie um modalidade de envio para sua API",
            empty_shipping_methods_sync: "sincronize os modalidades de envio da sua integração",
        },

        shopify: {
            add_Barcode_in_Shopify: "Falta adicionar o código de barras na Shopify",
            SKU_missing_in_Shopify: "Falta adicionar o SKU na Shopify",
            missing: "Pendente ",
            import: "Importar",
            products: "produtos",
            barcodes_in_shopify: "códigos de barras no Shopify",
            imported: "Importado",
            no_matter: "Não importar",
            barcode: "Código de barras",
            Updated_Inventory: "Inventário Atualizado",
            Error_importing: "Erro ao importar",
            Import_Products: "Importar Produtos",
            notice: "Aviso",
            yes: "Sim",
            SKU_or_UPC_only: "Para evitar problemas operacionais, a Cubbo não pode importar produtos que NÃO tenham um código SKU e UPC/EAN configurados no Shopify.",
            add_SKU_or_UPC: "Você pode adicionar SKU ou UPC clicando neste link",
            import_excel_via_email: "ou se preferir, pode importar os produtos em excel, enviando-nos um email para integraciones@cubbo.com",
        },

        vtex: {
            image: "Imagem",
            name: "Nome",
            imported: "Importado",
            no_matter: "Não importar",
            name: "Nome",
            status: "Status",
            Updated_Inventory: "Inventário Atualizado",
            Error_importing: "Erro ao importar",
            import_of_products: "Importação de produtos em processo, pode demorar alguns segundos para finalizar.",
            error: "Erro ao importar",
            import: "Importar",
            products: "produtos",
            yes: "Sim",
            import_all_products: "Importar Todos os Produtos",
            import_missing_products:"Importar todos os produtos pendentes",
            import_shortage_products: "Importar Produtos Pendentes",
            import_all: "Importar Todos",
            import_Producti: "Importar Produtos",
            import_selection: "Importar Produtos Selecionados",
            no_import: "Não importar",
            preparing_import: "Preparando importação...",
            notice: "Aviso",
            SKU_or_UPC_only: "Para evitar problemas operacionais, a Cubbo não pode importar produtos que NÃO tenham SKU ou código UPC/EAN configurados na VTEX. Por restrições da integração da VTEX, não podemos mostrar na tabela se os códigos estão configurados ou não. Por favor, tenha certeza de que estejam.",
            import_excel_via_email: "ou se preferir, pode importar os produtos via excel, nos mandando um email para integraciones@cubbo.com",
            nothing_to_show: "Não há produtos para mostrar.",
        }
    },
    invoicing_list: {
        key: "Chave de Acesso",
        operation: "Operação",
        adjustment: "Ajustamento",
        print: "Imprimir",
        no_files: "Nenhum anexo",
        invoices: "Faturas",
        invoices_message: "Faturas geradas para recebimento de estoque",
        operation_nature: "Natureza da Operação",

        not_invoices: "Não há faturas para mostrar"
     },
    
    shipping_groups: {
        priorities: {
            CHEAPEST: "Escolherá o melhor preço",
            QUALITY: "Escolherá a entrega mais rápida ao menor preço",
        },
        fallback: "Substituto",
        fallback_tip: "No caso de não conseguir obter preços ou não ter cobertura para os métodos de envio anteriores, este será sempre usado como substituto.",
        without_fallback: "Sem Substituto",
        without_fallback_warning: "Sem Substituto: pedidos sem cobertura não serão enviados e estarão em estado 'Falta Informação'",
        incompatible_shipping_type: "Alguns dos métodos de envio da sua configuração serão omitidos ao escolher o envio para os seus pedidos, pois não são compatíveis com o tipo de método de envio da sua integração.",
        all_incompatible_shipping_methods: "Nenhum dos métodos de envio da sua configuração é compatível com o tipo de envio da integração, recomenda-se escolher outra configuração.",
        allows_extended_zones: {
            yes: "Permitir zonas estendidas",
            no: "Não permitir zonas estendidas",
            tip: "Entregas em zonas remotas podem incorrer em uma taxa adicional. Esta opção indica se essa cobertura deve ser usada para os seus pedidos, o custo adicional será considerado ao selecionar o melhor transportador para os seus pedidos."
        },
        allows_pobox_delivery: {
            yes: "Permitir entrega em caixas postais",
            no: "Não permitir entrega em caixas postais",
            tip: "Algumas entregas em zonas remotas não podem ser entregues no endereço exato e devem ser recolhidas em uma localização próxima. Esta opção indica se essa cobertura deve ser usada para os seus pedidos."
        }
    },

    shipping_labels: {
        shipping_labels: "Etiquetas de Envio",
        generated_labels: "Etiquetas Geradas",
        label_types: {
            Shipping: "Etiqueta de envio",
            Return: "Etiqueta de retorno"
        }
    },

    cnabs: {
        status: {
            WITHOUT_PAYMENT_RETURN: "Aguardando arquivo retorno",
            WITH_PAYMENT_RETURN: "Pago"
        },
        footer: "CNABs",
        showContainer: {
            title: "Ver CNAB",
            cnab_information: "Informação do CNAB",
            reference_id: 'ID',
            total_amount: "Valor",
            num_of_items: "Pedidos incluídos",
            created_at: "Data de criação",
            payment_bill: "Arquivos",
            document_type: "Tipo",
            replenishment_type: "Arquivo remessa",
            return_type: "Arquivo retorno",
            payment_receipt: "Comprobante de pago",
            payment_receipt_description: "Carregue aqui o arquivo de retorno gerado pelo banco"
        }
    },

    invoices: {
        invoices: "Notas fiscais",
        footer_invoice_name: "notas fiscais",
        generated_invoices: "Notas Fiscais Geradas",
        list: {
            from_orders: {
                tab_name: 'De pedidos',
                table_title: 'Faturas de pedidos',
                columns: {
                    order_number: 'Nº de pedido',
                    billing_name: 'Cliente',
                    billing_email: 'Email',
                }
            },
            from_replenishments: {
                tab_name: 'De remessas de estoque',
                table_title: 'Faturas de remessa de estoque',
                columns: {
                    replenishment_id: 'ID de reposição de estoque'
                }
            },
            from_returns: {
                tab_name: 'De devoluções',
                table_title: 'Faturas de devoluções',
                columns: {
                    order_number: 'Nº de Pedido',
                    billing_name: 'Cliente',
                    billing_email: 'Email',
                    return_id: 'ID de Devolução',
                }
            },
            from_cnabs: {
                tab_name: 'CNAB',
                table_title: 'Faturas de DIFALs',
                columns: {
                    reference_id: 'ID',
                    reference_filename: "Nome",
                    num_of_items: "Nº de pedidos",
                    total_amount: "Valor"
                }
            },
            created_at: 'Data de emissão',
            creation_date_placeholder: {
                start: 'Começo',
                end: 'Fim'
            },
            type: 'Tipo de operação',
            attachments: 'Ficheros',
            noData: 'Não há notas para mostrar',
            serial_number: 'Número',
            key: 'Chave',
            status: 'Status',
            filter_descriptor: {
                order_number: {
                    description: 'Com número de pedido'
                },
                returned_order_number: {
                    description: 'Com número de pedido'
                },
                operation_type: {
                    description: 'With type'
                },
                created_at: {
                    descriptionValue: '{{from}} - {{to}}',
                    description: 'Com data de emissão'
                },
                all_from_orders: {
                    description: 'Notas Fiscais de',
                    descriptionValue: 'Todas as NFs de pedidos'
                },
                all_from_replenishments: {
                    description: 'Invoices from',
                    descriptionValue: 'All the NFs from remessa de estoque'
                },
                all_warning: 'Lembrete: é possível filtrar p/ baixar NFs específicas',
                key: {
                    description: 'Com chave'
                },
                id: {
                    description: 'Com id de recibo'
                },
                purposes: {
                    description: 'Com finalidade de fatura'
                },
                status: {
                    description: 'Com status'
                },
                series_and_serial_number: {
                    description: 'Com número'
                },
            }
        },
        process_types: {
            sell: 'Venda',
            donation: 'Doação',
            symbolic_return: 'Retorno simbólico',
            remove_stock: 'Remoção de inventário',
            inventory_replenishment: 'Remessa para Depósito Temporário',
            replenishment_adjustment_addition: 'Remessa para Depósito Temporário - Ajuste',
            replenishment_adjustment_removal: 'Retorno para Depósito Temporário'

        },
        operation_types: {
            entry: 'Entrada',
            egress: 'Saida'
        },
        status_types: {
            approved: 'Aprovado',
            cancelled: 'Cancelado'
        },
        filters: {
            order_number: 'Número do Pedido',
            status: 'Status da Nota Fiscal',
            key: 'Chave de Acesso',
            operation_type: 'Tipo de Operação',
            purpose: 'Tipo de Operação',
            created_at: 'Criado em',
            serial_number: 'Número',
        }
    },

    shipping_methods: {
        delivery_type: 'Entrega',
        shipping_type: 'Tipo',
        services: 'Serviços',
        carrier: 'Transportadora',
        delivery_types: {
            SameDay: 'Mesmo Dia',
            NextDay: 'Dia Seguinte',
            Ground: 'Terrestre',
            Pickup: 'Retirada',
            International: 'Internacional',
        },
        shipping_types: {
            national: 'Nacional',
            international: 'Internacional',
            pickup: 'Recolección',
        },
        incompatible_shipping_type: "Esta modalidade de envio não é compatível com o envio de sua integração. Será pulado ao fazer a seleção."
    },

    files: {
        open: "Abrir",
        print: "Imprimir"
    },

    async_task_notification: {
        working: 'Carregando arquivo',
        done: 'Arquivo pronto',
        download: 'Download'
    },

    export_overview: {
        intro: 'Download',
        export_descriptions: {

            INVOICES: {
                SALE_INVOICE: {
                    title: 'NFs - Venda de produto'
                },
                DONATION_INVOICE: {
                    title: 'NFs - Outras saídas'
                },
                SYMBOLIC_RETURN_INVOICE: {
                    title: 'NFs - Retorno Simbólico de Depósito Temporario',
                },
                REMOVE_STOCK_INVOICE:{
                    title: 'NFs - Retorno de Estoque',
                },
                REPLENISHMENT_INVOICE: {
                    title: 'NFs - Remessa para Depósito Temporário',
                },
                REPLENISHMENT_ADJUSTMENT_ADDITION: {
                    title: 'NFe - Remessa para Depósito Temporário - Ajustes',
                    subtitle: 'Quando recebemos um item extra no reabastecimento.'
                },
                REPLENISHMENT_ADJUSTMENT_INVOICE: {
                    title: 'NFs - Retorno de Depósito Temporario',
                    subtitle: 'Quando falta um item na reposição.'
                }
            }
        },
        format: 'Formato',
        type: 'Tipo',
        confirm_button: 'Confirmar',
        cancel_button: 'Cancelar'
    },

    integrations: {
        select_view: {
            Select_store_location: "Selecione a localização padrão da sua loja para Cubbo.",
            No_locations_toassign: "Não existem locais a atribuir para esta integração",
            select: "Selecionar",
            cancel: "Cancelar",
            save: "Salvar",
            only_read_location_orders: "Você quer que o Cubbo trabalhe apenas ordens de serviço que tenham este local atribuído?"
        },

        shipping_view: {
            Integration: "Integração",
            No_shipping_methods: "Não existem modalidades de envio nesta integração.",
            Sync_shipping_methods: "(Sincronize suas modalidades de envio na lista de integrações)",
            international: "Internacional:",
            Assign_by_priority: "Atribuir por prioridade:",
            Shipping_method: "Modalidade de Envio",
            priorities_shipment_method: "Atribuir prioridade para esta modalidade de envio",
            option: "Opção",
            add_option: "Adicionar Opção",
            cancel: "Cancelar",
            save: "Salvar",
            loading: "Carregando",
        },

        shopify_public: {
            install_shopify: "Instalar Shopify",
            install_Cubbo_in_Shopify: "Acesse a loja de aplicativos Shopify e instale o aplicativo da Cubbo por lá", 
            Existing_sales_channel: "Canal de vendas já existe",
            In_construction: "Em construção: esperando o link da app store do Shopify",
        },

        show_available: {
            Integrations: "Integrações",
            import_ecommerce: "Tudo o que você precisa para integrar seu e-commerce",
            select_channel: "Selecione o canal de vendas para integrar",
        }
    },

    integration: {
        shopify: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            Configure_Shopify: "Configurar Shopify",
            Create_private_app_Shopify: "Crie um app privado no Shopify e insira as credenciais aqui.",
            integrate_shopify: "Integrar Shopify",
            company_website: "seudominio.shopify.com",
        },

        ventiapp: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            Integrate_your_store: "Integre sua loja Ventiapp",
            enter_credentials: "Insira suas credenciais para a plataforma VentiApp aqui",
            integrate_ventiapp: "Integrar Ventiapp",
        },
        
        vtex: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            Integrate_your_store: "Integre sua loja Vtex",
            account_management: "Na sua loja VTEX, acesse Gerenciamento de conta > Conta > Gerar chave de acesso e senha / token",
            store_name: "Nome da Loja (App Name)",
            integrate_vtex: "Integrar Vtex",
            company_website: "ex: brinquedosBR",
            is_seller: "Sua conta vtex é uma conta do tipo seller?"
        },

        amazon: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            Integrate_your_store: "Integre sua loja Amazon",
            store_name: "Nome da Loja Amazon",
            integrate_amazon: "Integrar Amazon",
            refresh_token : "Token"
        },

        liverpool: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            Integrate_your_store: "Integra sua loja Liverpool",
            account_management: "Você pode encontrar instruções sobre esta integração neste",
            link: "link",
            integrate: "Integrar Liverpool"
        },
        coppel: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            Integrate_your_store: "Integra sua loja Coppel",
            account_management: "Você pode encontrar instruções sobre esta integração neste",
            link: "link",
            integrate: "Integrar Coppel"
        },
        bling: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Por favor, verifique se os dados estão corretos.",
            Integrate_your_store: "Integre seu Bling",
            enter_credentials: "Digite suas credenciais da Plataforma Bling aqui",
            integrate: "Integrar Bling",
            store_name: "Nome da sua loja",
            codigo_chave: "Código chave API"
        },

        woocommerce: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            Integrate_your_store: "Integre sua loja Woocommerce",
            integrate_woocommerce: "Integrar Woocommerce",
            add_woocommerce: "Integrar Woocommerce com API keys",
            your_brand: "suamarca.com.br",
        },

        shein: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            Integrate_your_store: "Integre sua loja Shein",
            integrate_shein: "Integrar Shein"
        },

        f1commerce: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            Integrate_your_store: "Integre sua loja  F1commerce",
            integrate_f1commerce: "Integrar F1commerce",
            your_brand: "suamarca.com",
            codigo_chave: "Chave de serviço"
        },

        walmart: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            Integrate_your_store: "Integre sua loja Walmart",
            store_name: "Nome da loja",
            company_website: "ex: brinquedosBR",
            integrate_walmart: "Integrar Walmart",
        },

        traycorp: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            account_management: "Na sua loja Tray Corp, acesse Integrações > Tokens > Adicionar Token",
            Integrate_your_store: "Integre sua loja Tray Corp",
            integrate_traycorp: "Integrar Tray Corp",
        },
        vnda: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            Integrate_your_store: "Integre sua loja Vnda.",
            integrate_vnda: "Integrar Vnda.",
            company_website: "domain.vnda.com.br",
        },
        stripe: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            account_management: "Na sua loja Stripe Corp, acesse Desenvolvedores > Chaves de API > Senha secreta",
            Integrate_your_store: "Integre sua loja Stripe.",
            integrate_stripe: "Integrar Stripe.",
            store_name: "Nome da loja",
        },
        tray: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            account_management: "No painel da sua loja Tray visite Aplicativos > Producre Cubbo > Acessar",
            Integrate_your_store: "Integre sua loja Tray.",
            integrate_tray: "Integrar Tray.",
            company_website: "domain.com.br/web_api",
        },

        shopee: {
            Existing_sales_channel: "Canal de vendas já existe",
            Error_creating_integration: "Erro ao criar integração. Verifique se os dados estão corretos.",
            Integrate_your_store: "Integre sua loja Shopee",
            integrate_shopee: "Integrar Shopee"
        },
    },

    addProduct: {
        optionRow: {
            option: "Opção",
            size: "Ex. Tamanho",
            required_field: "Campo obrigatório",
            delete: "Deletar",
            press_enter: "Digite e pressione Enter",
        },

        container: {
            barCode: "Código de barras",
            required_field: "Campo obrigatório",
            required_field_sku: "Este campo é obrigatório, clique em Enter quando terminar",
            type_sth: "Digite algo e aperte enter...",
            variants: "Variantes",
            without_variants: "Um item promocional não pode ter variantes.",
            multiple_options: "Este produto possui múltiplas opções, como diferentes tamanhos ou cores.",
            operational_errors: "Para evitar erros operacionais, não é possível editar um kit, se houver alguma modificação, sugerimos criar um novo kit.",
            Dropshipping: "Este produto é administrado por um terceiro? (Dropshipping)",
            packageless_title: "Este produto já está pré-embalado?",
            packageless_description: "Ative esta função se o produto não precisa ser embalado para enviá-lo. A etiqueta será colada no exterior do produto.",
            important: "Importante",
            has_imei_title: "Este produto requer controle de saída?",
            has_imei_description: "Ative esta função se for necessário registrar o número de série do produto antes de enviá-lo.",
            has_imei_warning: "O produto deve estar etiquetado com o número de série para que possa ser escaneado durante o processo de embalagem. Caso contrário, não será possível enviá-lo.",
            unmanaged: "Assinale se seu produto não será gerenciado pela Cubbo.",
            fragile_product: "Este produto é frágil?",
            special_care: "Assinale se seu produto requer cuidados especiais",
            promotional_product: "Este produto é um item promocional?",
            no_barcode_scanning: "Assinale se seu produto é um item promocional que não pode ser escaneado através de um leitor de código de barras.",
            drag_image: "Clique aqui ou arraste a imagem",
            required_image: "Se o produto não for escaneável por um leitor de código de barras, a imagem do produto é obrigatória",
            product_image: "Imagem do Produto",
            Save_product: "Salvar produto",
            add_product: "Deseja adicionar produto",
            name: "Nome",
            price: "Preço do produto",
            weight: "Peso (KG)",
            billing_name: "Descrição de produto internacional",
            Description: "Descrição",
            Add_attribute: "Adicionar atributo",
            Add_variant: "Adicionar variante",
            products: "Produtos",
            Add_new_product: "Adicionar produto",
            Cancel: "Cancelar",
            Save: "Salvar",
            ncm_code: "Código NCM",
            no_supported: "Não suportamos este produto.",
            total: "Total:",
            product_selected: "Selecione dois ou mais produtos para o seu kit:",
            product_has_expiration: "Tem uma data de expiração",
            product_expiration: "Verifique se o seu produto requer manuseio de lote de expiração",
            freshness: "Dias frescos antes do expiração",
            buffer_stock: "Estoque de Segurança",
            buffer_stock_description: "Reserve unidades disponíveis do estoque para reduzir a probabilidade de overselling",
            add_ncm: "Adicionar NCM à loja",
            no_exist_ncm: "Você ainda não possui este NCM cadastrado em sua loja. Verifique se os números estão corretos ou defina-o para ativar.",
            no_change_product: "Não é possível transformar um item do Estoque Fiscal em um item promocional."
        },

        kitsTable: {
            quantity: "Quantidade",
            sku: "SKU",
            no_products_added: "Nenhum produto adicionado.",
            name: "Nome",
        },

        readTable: {
            Loading_products: "Carregando produtos",
        },

        variantRow: {
            variant_name: "Nome da variante",
            example: "ex: (M-Vermelho)",
            required_field: "Campo obrigatório",
            delete: "Deletar",
            barCode: "Código de Barras",
            type_sth: "Digite algo e aperte enter..."
        }
    },

    inventory: {
        listProducts: {
            image: "Imagem",
            name: "Nome",
            stock: "Em Estoque",
            available: "Disponível para venda",
            unavailable: "Não disponível",
            by_returns: "Por devoluções",
            by_buffer: "Por segurança",
            buffer_tip: {
                text: "Este estoque de segurança é usado para evitar vender mais itens do que há em estoque. Apesar de os itens estarem em estoque, eles não são listadas como disponíveis para venda.",
                buffer_tip_link: 'Ver mais informações.',
                buffer_tip_url: 'https://cubbohelp.zendesk.com/hc/pt-br/articles/10629999739277',
            },
            presale: "Pré-venda",
            orders: "Reservado para pedidos",
            damaged: "Avariado",
            holded: "Pausado",
            expired: "Expirado",
            status: "Status",
            active: "Ativo",
            for_selling: "À venda",
            shipped: "Enviado",
            fragile: "Ativo",
            dropshipping: "Dropshipping",
            packageless: "Pré-embalado",
            has_imei: "Controle de saída",
            kit: "Kit",
            promoInsert: "Item Promocional",
            edit_product: "Editar produto",
            inventory: "Inventário",
            all_stores: "Todas as lojas",
            name: "Nome",
            description: "Descrição",
            name_invoices: "Nome na Nota Fiscal",
            import_products: "Importar produtos na loja",
            import: "Importar do Excel",
            download_inventory: "Baixar inventário",
            download_previous_inventory: "Histórico de inventário",
            download_inventory_success: "Exportação CSV completa",
            download_inventory_error: "Ocorreu um erro, tente novamente mais tarde",
            download_inventory_select_date: "Selecione o dia que deseja consultar o inventário",
            download_inventory_cancel_button: "Cancelar",
            download_inventory_download_button: "Baixar",
            no_previous_inventory: "Ainda não há histórico de inventário, tente novamente amanhã",
            select_inventory_date: "Selecione a data que deseja consultar",
            create_product: "Criar Produto",
            create_products: "Criar Produtos",
            create_kit: "Criar Kit/Combo",
            no_products_to_show: "Nenhum produto a mostrar.",
            see_kit: "Ver Kit/Combo",
            imported: "Importados!",
            confirm: "Confirmar",
            delete_product: "Excluir produto",
            sure_to_delete: "Tem certeza de que deseja excluir o produto? ",
            product_is_part_of_kit: "O produto faz parte de um kit",
            product_has_stock: "O produto ainda tem stock",
            product_used_in_current_orders: "Quaisquer pedidos com este produto ainda estão sendo processados. Por favor, tente novamente mais tarde.",
            products: "Produtos",
            inWarehouse: "em armazém",
            inPresale: "em pré-venda",
            inReturns: "em devolução",
            holdedLot: "Lote pausado",
            expiredLot: "Lote expirado",
            byHoldedLot: "Por lote pausado",
            byExpiredLot: "Por lote expirado",
            updated_price: "As vírgulas foram eliminadas",
            duplicated_bar_code: "Este {{codigo}} corresponde ao UPC de um produto já criado em sua loja.",
            duplicated_sku: 'Este {{codigo}} corresponde ao SKU de um produto já criado em sua loja.',
            price_error: 'Somente números, no máximo 2 casas decimais.',
            weight_error: 'Somente números, no máximo 10 casas decimais.'
        },
        create_bulk: {
            view_inventory: "Ver Inventário",
            ok: "De acordo",
            was_a_problem: "Tem havido um problema!",
            zero_imported: "Não foi possível importar seus produtos, entre em contato com o suporte e nos envie a planilha que você tentou importar.",
            thanks_for_waiting: "Obrigado pelo tempo de espera.",
            error_reminder: "Lembre-se que os produtos marcados com erro na etapa anterior não serão importados.",
            products_imported: "{{products_count}} novos produtos foram importados para sua loja!",
            not_all_imported: "Importado com sucesso {{products_count}} de {{total}} produtos de previsão.",
            we_are_importing: "Estamos importando {{total_products}} novos produtos para sua loja!",
            products: "PRODUTOS",
            imported: "IMPORTADOS"
        }
    },

    onboardings: {
        select_store: "Selecione a loja que quer realizar a instalação:",
        cancel_installation: "Cancelar instalação"

    },

    inputPlaceholder: {
        pdfFiles: "Clique aqui ou arraste seus arquivos PDF.",
        addImage: "Clique aqui ou arraste a imagem.",
        select: "Selecionar",
        required_field: "Campo obrigatório"
    },

    currencyInput: {
        max: "Máximo",
        dec: "Decimais",
        integer_digits: "dígitos inteiros.",
        minimum_value: "Valor mínimo",
        maximum_value: "Valor máximo"
    },

    confirmDialog: {
        loading: "Carregando"
    },

    fileInputField: {
        not_pdf: "O arquivo não é um PDF",
        max_file: "O arquivo é muito grande. Máximo",
        mb: "MB",
        min_file: "O arquivo é muito pequeno. Mínimo ",
        max_images: "Só é possível adicionar até",
        max_images_text: "Imagem"
    },

    imageInputField: {
        not_an_image: "Imagem não é um arquivo .PNG/JPG/JPEG",
        max_image: "A imagem é muito grande. Máximo",
        mb: "MB.",
        min_image: "A imagem é muito pequena. Mínimo",
        max_images: "Só é possível adicionar até",
        max_images_text: "Imagem"
    },

    replenishments: {
        addContainer: {
            yes: "Sim",
            no: "Não",
            product_not_found: "Produto não encontrado na reposição",
            cancel_receipts: "Sua remessa de estoque se encontra em pré-venda, não é possível editá-la. Se for necessário alterações, sugerimos que a cancele e crie uma nova",  
            closing: "FECHANDO",
            closing2: "FECHANDO 2",
            receipt: "Criar remessa de estoque",
            keep_receipt: "Deseja criar a remessa de estoque?", 
            activate_presale: "Deseja ativar a pré-venda dessa remessa de estoque?",
            products: "Produtos",
            quantity: "Quantidade",
            only_number: "Quantidade tem que ser sempre em números.",
            imports_products: "Importar produtos e quantidades a esta remessa de estoque",
            imported: "Importados!",
            import: "Importar",
            add_product: "Adicionar produto",
            arrival_date: "Data de chegada",
            declared_value: "Valor total declarado para a Nota Fiscal (R$)",
            cancel: "Cancelar",
            save: "Salvar",
            schedule_an_appointment: "Agendar Entrega",
            can_not_edit_big_replenishments: "Não permitimos a edição de remessa de estoque com mais de 1000 skus, recomendamos cancelar o remessa de estoque e enviá-lo novamente.",
            view_replenishment_products: "Ver Produtos",
            x_number_of_products_imported: "{{quantity}} produtos foram importados.",
            skus_doesnt_exist: "Este SKU não existe em seu inventário."
        },

        canlendlyView: {
            do_not_schedule: "Não Agendar",
            close: "Fechar",
        },

        orderTable: {
            name: "Nome",
            quantity: "Quantidade",
            no_added_products: "Nenhum produto adicionado.",
        },

         container: {
            store: "LOJA",
            products: "Nº PRODUTOS",
            notes: "OBSERVAÇÕES",
            requested: "SOLICITADO",
            appointment: "ENTREGA",
            status: "STATUS",
            completed_percentage: "% concluída",
            go_to_appointment: "Ir ao agendamento",
            schedule: "Agendar",
            print_invoices: "Imprimir NFe",
            edit: "Editar",
            cancel: "Cancelar",
            reschedule: "Reagendar",
            view_products: "Ver produtos detalhados",
            cancel_receipt: "Cancelar remessa",
            edit_receipt: "Editar remessa",
            view_invoices: "Ver faturas",
            cancel_inventory_receipt: "Deseja cancelar Remessa de Estoque?",
            close: "Fechar",
            cancel_receipt_dialog: "Cancelar",
            inventory_receipt: "Remessa de Estoque",
            all_stores: "Todas as lojas",
            create_inventory_receipt: "Criar remessa de estoque",
            export_button: "Exportar",
            no_receipts_to_show: "Nenhuma remessa de estoque para mostrar.",
            edit_inventory_receipt: "Editar Remessa de Estoque",
            completed_inventory_receipt: "Remessa de Estoque Concluída",
            schedule_an_appointment: "Agendar entrega",
            replenishments_page: "Remessas",
            schedule_message: "Agende sua entrega para recebermos sua remessa de estoque",
            we_received: "Recebemos ",
            received_bulks_SLA: " caixas cumprindo com o agendamento da remessa. ",
            received_bulks_not_attended: " caixas descumprindo com o agendamento da remessa. ",
            received_SLA: "Começaremos a processar em até 24 horas.",
            received_not_attended: "É possível que o processamento comece após 24 horas.",
            processing_message: "Estamos contando e armazenando seu inventário.",
            late_processing: "Atrasado por descumprimento do agendamento",
            on_date: "em",
            expected: "itens esperados",
            received: "itens contados",
            stored: "itens armazenados",
            counting: "- Estamos contando!",
            finished_count: "- Contagem finalizada!",
            not_ready_to_sell: "Não estarão disponíveis para venda até serem armazenados",
            ready_to_sell: "Já disponíveis para venda!",
            storing: "- Ainda não acabamos!",
            finished_storing: "- Armazenamento finalizado!",
            processing_pending: "Processamento pendente",
            damaged: "avariados",
            completed_both: "Você etiquetou e separou seus produtos corretamente. Continue assim!",
            completed_labeled: "Você etiquetou seus produtos corretamente, mas eles foram separados incorretamente.",
            completed_segregated: "Você separou seus produtos corretamente, mas eles foram etiquetados incorretamente.",
            completed_both_wrong: "Você etiquetou e separou seus produtos incorretamente.",
            check: "Verifique",
            here: "aqui",
            how_to_send: "como os seus produtos devem ser enviados.",
            no_replenishments_to_show: "Nenhuma remessa de estoque"
         },

        export_dialog: {
            title: 'Exportar remessas de estoque',
            intro: 'Serão exportadas as remessas de estoque que atenderem às seguintes condições: ',
            default_description: 'Serão exportadas todas as remessas de estoque em sua conta. Se desejar, use os filtros para obter um relatório específico.',
            replenishment_id: 'Com número de remessa igual a: ',
            sku: 'Com remessas que contenham o SKU: ',
            status: 'Cujo estado seja: ',
            completed_at: 'Que foram concluídas entre ',
            completed_at_join: ' e ',
            type: 'Cujo tipo seja: ',
            confirm: 'Baixar',
            cancel: 'Cancelar',
        },

         viewReplenishment: {
             name: "Nome",
             expected_quantity: "Esperados",
             quantity_processed: "Processados",
             quantity_given: "Avariados",
             loading_summary: "Carregando resumo...",
             detail_parts_received: "Detalhamento de itens recebidos:",
             replenishment_detail: "Este é o resumo de sua remessa de estoque:"
        },

        viewReplenishmentInfo: {
            name: "Nome",
            sku: "SKU",
            expected_quantity: "Esperados",
            received_quantity: "Recebidos",
            stored_quantity: "Armazenados",
            damaged_quantity: "Avariados",
            loading_summary: "Carregando resumo...",
            replenishment_detail: "Este é o resumo de sua remessa de estoque:",
        },

        header: {
            id_type_notes: 'ID | Tipo | Notas',
            status: 'Estado',
            pieces_summary: "Resumo de itens",
            replenishment_summary: "Resumo do recibo",
            filter: "Filtrar",
            reset_filters: "Limpar filtros"
        },

        search: {
            id: "ID",
            sku: "Que contenham o SKU",
            type: "Tipo",
            status: "Estado",
            completed_at: "Data de conclusão",
            from_to: "de-até"
        },

        types: {
            all: "Pré-venda/Regular",
            presale: "Pré-venda",
            regular: "Regular"
        },

        footer_navigation : {
            replenishments: "remessas"
        }

    },

    lots: {
        view_lots: "Lotes",
        view_lots_detailed: "Estes são os lotes deste produto",
        lot_support_actions: "Se você deseja pausar um lote, cancelar um lote ou obter uma lista de todos os compradores em um lote, crie um tíquete de suporte usando o widget no canto inferior direito",
        no_lots: "Este produto não possui controle de lote configurado. Se você quiser saber mais sobre essa funcionalidade, entre em contato com a equipe de suporte."
    },

    returns: {
        container: {
            order_number: "Nº DE PEDIDO DEVOLVIDO",
            new_order: "NOVO Nº DE PEDIDO",
            type: "Tipo",
            status: "Status",
            reserved_parts: "Itens reservados",
            creation_date: "Data de Criação",
            return_details: "Ver detalhes da devolução",
            review_return: "Revisar devolução",
            see_returned_order: "Ver pedido devolvido",
            see_order_created: "Ver pedido criado",
            not_applicable: "Não se aplica",
            no_reserved_parts: "Nenhum item reservado",
            returns: "Devoluções",
            no_returns: "Nenhuma devolução.",
            see_order: "Ver Pedido",
            return_detail: "Detalhamento da Devolução",
            all_stores: "Todas as lojas"
        },

        listItem: {
            damaged_parts: "(Itens avariados)",
            add_damaged_part: "Adicionar item avariado",
            remove_product: "Remover Item",
        },

        newOrder: {
            not_applicable: "Não se aplica",
            awaiting_order: "Aguardando Pedido",
            forwarding_cancelled: "Reenvio Cancelado",
            decision_pending: "Decisão Pendente",
            awaiting_review: "Aguardando Revisão",
            waiting_reception: "Aguardando Recepção",
            arrived_at_warehouse: "Chegou ao armazém",

        },

        table: {
        name: "Nome",
        quantity: "Quantidade",
        no_products_added: "Você não adicionou nenhum produto.",
        },

        types:{
            unknown: "Desconhecido",
            return: "Devolução",
            change: "Troca",
            immediate_change: "Troca Imediata",
            parcel: "Transportadora",
            awaiting_review: "Aguardando Revisão",
            waiting_reception: "Waiting Reception",
            unidentified: "Não identificado",
        },

        externalContainer: {
            unchecked_return: "Devolução não pode ser analisada",
            order_number: "Número de pedido",
            store: "Loja",
            notes: "Observações",
            type_of_return: "Tipo de Devolução",
            return_guide:"Gerar guia de devolução",
            original_content:"Conteúdo original",
            products_to_return:"Itens a devolver",
            add_product: "Adicionar outro produto/item da loja",
            product: "Item",
            quantity: "Quantidade",
            send_replacement: "Produtos a enviar como substituição",
            reject: "Recusar",
            accept: "Aceitar",
            cancel: "Cancelar", 
            confirm: "Confirmar",
            refuse_return: "Deseja recusar esta devolução?",
            return: "Recusar Devolução",
            total_returned: "Confirmar que será devolvido um total de",
            product: "produto(s).",
            accept_return: "Confirmar Devolução",
        },

        returnContainer: {
            returned_order: "Número do pedido devolvido",
            store: "Loja",
            type: "Tipo",
            state: "Estado",
            return_date: "Data de devolução",
            notes: "Observações",
            return_of_order: "Pedido criado pela devolução",
            order_returned: "É importante confirmar o que pretende fazer com o pedido devolvido, estamos separando os produtos necessários para reenviá-lo.",
            cancel_forwarding: "Cancelar Reenvio",
            resend_order: "Reenviar Pedido Devolvido",
            returned_products: "Produtos devolvidos ",
            order_not_returned: "Pedido não devolvido.",
            reserved_parts: "Itens reservados",
            release_reserved_parts: "Liberar itens reservados",
            no_parts_reserved: "Nenhum item reservado",
            parts_at_order: "Peças atribuídas ao novo pedido",
            directory: "Seu cliente receberá um e-mail com o código ",
            print: "Imprimir RMA",
            return_guide: "Imprimir Autorização de Postagem para Reversa",
            forwarding_cancellation: "Confirmar Cancelamento de Reenvio",
            reserved_inventory: "O estoque reservado para o reenvío deste pedido será liberado.",
            confirm: "Confirmar",
            cancel: "Cancelar",
            confirm_order_forwarding: "Confirmar Reenvio de Pedido",
            duplicate_returned_order: "O pedido devolvido será duplicado. Substituiremos os itens ausentes ou avariados pelo disponível em estoque.",
            unidentified: "Não identificado",
            return_label: "Etiqueta de Devolução",
            no_return_label: "Não há etiqueta de devolução",
            arrived_at_warehouse_description: "O pacote chegou, mas está aguardando ser recebido e processado por um operador"
        },

    },

    statuses: {
        order_statuses: {
            unknow: "STATUS_NO_DEF",
            initial: "Sincronizando",
            empty: "Pedido vazio",
            pending: "Pendente",
            processing: "Processando",
            shipped: "Enviado",
            returning: "Em devolução a Cubbo",
            return_arrived: "Retornado para Cubbo",
            returned: "Recebendo retorno",
            canceled: "Cancelado",
            error: "Informação Pendente",
            entirely_dropshipping: "Dropshipping",
            backorder: "Sem Estoque",
            unpaid: "Pagamento pendente",
            cancelation_window: "Janela de Cancelamento",
            hold: "Pausado",
            interrupted: "Interrompido",
            waiting_pick_up: "Aguardando coleta",
            awaiting_pick_up: "Retirada pendente",
            picked_up: "Coletado",
            in_warehouse: "Recebido em armazém",
            delayed: "Atrasado",
            reentered: "Reinserido",
        },

        replenishment_statuses: {
            unknown: "Sem Status",
            select: "Selecione um estado",
            without_scheduling: "Criado sem agendamento",
            generating_invoices: "Gerando fatura",
            requested: "Agendado",
            pending: "Pendente / Em progresso",
            processing: "Processando",
            in_putaway: "Processando",
            received: "Recebido no armazém",
            completed: "Concluído",
            completed_with_differences: "Concluído com diferenças",
            in_transit: "Em trânsito",
            canceled: "Cancelado"
        },

        claims_statuses: {
            unknown: "Sem Status",
            pending: "Pendente",
            rejected: "Recusada",
            accepted: "Solicitação aceita"
        },

        returns_statuses: {
            unknow: "Sem Status",
            requested: "Aguardando retorno",
            pending: "Decisão pendente",
            cancelled: "Reenvio cancelado",
            ordered: "Reenviar pedido",
            received: "Recebido no armazém",
            reentered: "Reinserido",
            arrived_at_warehouse: "Chegou ao armazém",
        },
        tracking_statuses: {
            unknown: "Sem Status",
            in_transit: "Em trânsito",
            shipment_delivered: "Entrega Realizada",
            shipment_with_incident: 'Incidente',
            shipment_canceled: 'Entrega Cancelada',
            shipment_to_be_self_collected: 'Retire na agência',
            shipment_out_for_delivery: 'Na rota de entrega'
        },

        call_to_action: {
            shipment_to_be_self_collected: 'Instruções',
            error: 'Ver dados faltantes',
            backorder: 'Ver as possíveis ações'
        },

        tooltip: {
            cancelation_window: 'O comprador pode cancelar o pedido através da VTEX, quando a janela de cancelamento configurada em sua VTEX terminar, prepararemos o pedido.',
            interrupted: 'O cumprimento do pedido foi cancelado enquanto estava sendo preparado. Não enviaremos este pedido, mas a taxa de pick and pack será cobrada.',
            entirely_dropshipping: 'Cubbo não armazena NENHUM dos produtos deste pedido',
            empty: 'O pedido não possui peças atribuídas para cumprir',
            no_stock: 'Não foram atribuídas peças suficientes para enviar o total solicitado deste produto'
        },
        
        tracking_descriptions: {
            second_try: 'Segunda tentativa',
            third_try: 'Terceira tentativa',
            client_requested: 'Solicitação do comprador',
            missing_references: 'Referências ausentes',
            not_at_home: 'O comprador estava ausente',
            refused_payment: 'O comprador se recusou a pagar',
            refused_package: 'O comprador recusou o pacote',
            cubbo_requested: 'Solicitação do Cubbo',
            carrier_requested: 'Solicitação de operadora',
            stolen: 'Roubaram',
            lost: 'Perda',
            damaged: 'Danificado',
            returned_by_carrier: 'Por entrega sem sucesso',
            returned_by_client: 'Por devolução solicitada',
            in_return: "Em devolução pela transportadora",
        }
    },

    settings: {
        packing_options: {
            title: "Preferências de Embalagem",
            description: "Configure a embalagem que deseja habilitar para sua loja",
            container: {
                title: "Preferências de Embalagem",
                description: "Selecione a embalagem que deseja habilitar para enviar os pedidos da sua loja",
                save_options: "Salvar alterações",
                save_processing: "Processando",
                save_success_title: "Suas preferências de embalagem foram atualizadas.",
                save_error_title: "Ocorreu um erro ao atualizar suas preferências de embalagem.",
                save_error_description: "Por favor, tente novamente mais tarde. Se o problema persistir, entre em contato com a equipe de suporte.",
                not_available_option: "Em breve",
                non_fragile_packing: {
                    title: "Configuração geral",
                    title_description: "Para qualquer pedido que não se aplique à configuração específica, será embalado seguindo esta configuração",
                    description: "Será embalado com a opção mais econômica de acordo com o volume do pedido e disponibilidade do material",
                    options: {
                        parcel_bag: {
                            title: "Embalagem de plástico",
                            description: "Para produtos pequenos sem risco de danos por impacto",
                        },
                        jiffy_envelope: {
                            title: "Envelope Jiffy",
                            description: "Para produtos muito pequenos com proteção moderada",
                        },
                        cubbo_box: {
                            title: "Caixa Cubbo",
                            description: "Para produtos pequenos e médios",
                            disabled_hint: "Não é possível desabilitar esta opção, pois é a embalagem de backup padrão",
                        },
                        multi_package_with_cubbo_box: {
                            title: "Embalagem múltipla com Caixa Cubbo",
                            description: "Quando os produtos do pedido não cabem em apenas uma embalagem",
                        }
                    }
                },
                fragile_packing: {
                    title: "Configuração específica",
                    title_complement: "Priorizado sobre a configuração geral",
                    title_description: "Somente se o pedido atender às condições descritas, será empacotado seguindo esta configuração",
                    description: "Quando o pedido incluir {BOLD_START}um ou mais produtos frágeis{BOLD_END}, será empacotado com a melhor opção da seleção a seguir",
                    sub_description: 'Quando o pedido incluir um ou mais produtos configurados como {BOLD_START}frágeis{BOLD_END}.',
                    options: {
                        jiffy_envelope: {
                            title: "Envelope Jiffy",
                            description: "Com etiqueta frágil fixada no exterior",
                        },
                        cubbo_box: {
                            title: "Caixa Cubbo",
                            description: "Com etiqueta frágil fixada no exterior",
                            disabled_hint: "Não é possível desabilitar esta opção, pois é a embalagem de backup padrão",
                        }
                    }
                },
                fragile_packing_materials: {
                    titles: {
                        for_fragile: "É adicionado {BOLD_START}material de proteção{BOLD_END} quando o pacote inclui um ou mais produtos configurados como {BOLD_START}frágeis{BOLD_END}.",
                        for_not_fragile: "O pedido será embalado utilizando o material de proteção selecionado."
                    },
                    for_fragile: {
                        BUBBLE_WRAP: {
                            title: "Plástico bolha",
                            description: "Proteção moderada"
                        },
                        KRAFT_PAPER: {
                            title: "Papel Kraft",
                            description: "Mais econômico. Respeitoso com o meio ambiente"
                        },
                        CORN_EXTRUDATE: {
                            title: "Extrusado de Milho",
                            description: "Alta proteção. Respeitoso com o meio ambiente. Aplicam-se taxas adicionais"
                        }
                    },
                    for_not_fragile: {
                        BUBBLE_WRAP: {
                            title: "Plástico bolha",
                            description: "Proteção moderada"
                        },
                        KRAFT_PAPER: {
                            title: "Papel Kraft",
                            description: "Mais econômico. Respeitoso com o meio ambiente"
                        },
                        CORN_EXTRUDATE: {
                            title: "Extrusado de Milho",
                            description: "Alta proteção. Respeitoso com o meio ambiente. Aplicam-se taxas adicionais"
                        },
                        UNSELECTED: {
                            title: "Sem material de proteção",
                            description: "Gratuito"
                        }
                    }
                },
                virtual: {
                    packageless: {
                        sub_description: 'Quando todo o pedido consistir apenas em um produto configurado como {BOLD_START}pré-empacotado{BOLD_END}',
                        sub_description_hint: 'Para configurar um produto como pré-empacotado, edite o produto e ative o botão com essa característica.',
                        options: {
                            packageless: {
                                title: "Enviar sem embalagem",
                                description: "A etiqueta será colada no exterior do produto",
                                disabled_hint: "Não é possível desabilitar esta opção. Aplica-se apenas a produtos configurados como pré-empacotados",
                            }
                        }
                    }
                }
            }
        },
        automation_settings: {
            title: "Automações",
            sub_title: "Configure ações automáticas para situações cotidianas."
        },
        automation_container: {
            unpaid_order_cancelation_panel: {
                title: "Cancelar pedidos sem pagamento",
                description: "Define o tempo máximo que um pedido pode ficar aguardando pagamento.",
                payment_window_units: {
                    days_one: 'Dia',
                    days_other: 'Dias',
                    months_one: 'Mes',
                    months_other: 'Meses'
                },
                help_text: '{{payment_window}} após a criação do pedido, cancelaremos se não tiver sido pago.',
                shopify_help_text: 'Os pedidos da Shopify também serão cancelados no site.'
            },
            carrier_returns_cancellation_panel: {
                immediately: 'Imediatamente',
                title: 'Cancelar devoluções de encomendas',
                awaiting_review: 'Decisão Pendente',
                description_left: 'Número de dias que uma devolução de encomenda permanecerá ',
                description_right: ' antes de ser cancelada automaticamente.',
                place_holder: 'Dias de espera',
                days_one: 'Dia',
                days_other: 'Dias',
                help_text: '{{review_window}} após o retorno ao Cubbo, iremos cancelá-lo.'
            },
            enabled_result: 'ativado',
            disabled_result: 'desativado',
            successfully_saved: 'Automação {{status}}.'
        },
        store_settings: {
            config_title: "Configuração",
            user_config: "Configuração de usuários",
            description: "Aqui você pode ver as informações dos usuários associados à sua loja",
        },

        tracking_emails: {
            config_title: "Notificações de rastreamento",
            config_subtitle: "Defina em quais eventos deseja enviar e-mails para seus clientes",
            title: "Notificações de rastreamento",
            subtitle: "Um e-mail será enviado ao comprador notificando o status de envio do pedido, desde que a notificação esteja ativada neste painel.",
            save: "Salvar alterações",
            saving: "Salvando alterações",
            cancel: "Cancelar",
            tracking_type: "Emails regulares",
            shipping_incident_type: "Emails ocasionais",
            reported_shipping_incident_to_carrier_type: "Sobre incidentes reportados à transportadora",
            reported_to_carrier_and_resolved_by_carrier: "Incidente reportado à transportadora",
            reported_to_carrier_and_resolved_by_carrier_description: "Um e-mail será enviado quando um incidente for criado ou resolvido",
            click_and_collect_type: "Click n Collect",
            in_transit_to_pickup_point: "Em trânsito para o ponto de retirada",
            at_pickup_point: "No ponto de retirada",
            packed: "Empacotado, aguardando coleta",
            packed_description: "A integração também envia uma notificação ao seu cliente sobre este evento",
            in_transit: "Em trânsito",
            out_for_delivery: "Em entrega",
            delivered: "Entregue",
            returned: "Devolvido",
            failed_attempt: "Tentativa de entrega sem sucesso",
            to_be_self_collected: "Retirada em agência",
            cancelled: "Entrega cancelada",
            stolen: "Pedido roubado",
            lost: "Pedido extraviado",
            send: "Enviar",
            send_test: "Enviar e-mail de teste",
            test_sent: "Um e-mail de teste foi enviado para o seu e-mail",
            test_error: "Não foi possível enviar o e-mail de teste",
            invalid_email: " não é um e-mail válido",
            update_success: "Configuração atualizada com sucesso",
            update_error: "Não foi possível atualizar a configuração",
        },

        user_settings: {
            success_message: "Usuário registrado com sucesso",
            error_message: "O registro do usuário é inválido",
            title: "Usuários",
            subtitle: "convidar membros para sua loja",
            required_field: "campo obrigatório",
            invalid_email: "e-mail inválido",
            processing: "Processando sua solicitação...",
            invite_user: "Convidar usuário",
            error: "Erro",
            done: "Concluído",
            actual_users: "Usuários atuais",
            invite_user_placeholder: "Digite um email",
            disable_user_errors: {
                UNAUTHORIZED: "Você não está autorizado para realizar esta ação"
            },
            invite_user_errors: {
                INVALID_EMAIL: "Este email é inválido",
                EMAIL_EXISTS: "Este email já foi cadastrado em Cubbo",
                DEFAULT: "Ocorreu um erro inesperado. Entre em contato com o suporte técnico"
            },
            list: {
                name: "Nome",
                email: "Email",
                disable_user_action: "Desativar usuário",
                no_users_to_show: "Não há usuários para mostrar",
                users: "usuários"
            },
            disable_user_dialog: {
                title: "Desativar usuário",
                description: "Tem certeza de que deseja desativar o usuário {{user}}?",
            }
        },
        tax_settings: {
            tax_config: "Configuração de dados fiscais",
            description: "Aqui você pode editar a informação fiscal da sua empresa"
        },

        tax_fields: {
            success_message: "Suas informações foram atualizadas corretamente",
            error_message: "A informação é inválida",
            processing: "Processando",
            tax_id: "CNPJ", 
            tax_regime: "Regime de Tributação ",
            tax_company_name: "Razão Social",
            tax_company_name_short: "Empresa ",
            tax_address: "Endereço",
            tax_address_number: "Número",
            tax_address_neighborhood: "Bairro",
            tax_city: "Cidade",
            state: "Estado",
            tax_zipcode: "CEP",
            tax_state_registration: "Inscrição Estadual - IE ",
            tax_state_registration_short: "Registro estadual ",
            tax_state_phone: "Telefone",
            send_form: "Salvar Configuração",
            tax_regime_physical: "Físico",
            tax_regime_legal: "Legal"
        },
        billing_config:{
            filscal_data: "Dados Fiscais",
            filscal_data_description: "Editar as informações fiscais da sua loja.",
            ncms_settings: "Configurações NCMs",
            ncms_settings_description: "Adicione à sua loja os NCMs que correspondem aos seus produtos e configure seus impostos.",
            operation_nature: 'Configurar a "Natureza de Operação"',
            operation_nature_description: 'Configurar a "Natureza de Operação" para cada tipo de Nfe.',
            observation_invoice: "Adicionar informações no campo de “Observação” das Nfs",
            observation_invoice_description: "Adicione observações às suas Nfs, dependendo do tipo de nota.",
            optional: "(Opcional)"
        },
        types_invoices:{
            symbolic_return: "Retorno simbólico de produto",
            remove_stock: "Remoção de produto",
            sale: "Venda de produto",
            donation: "Doação de produto",
            return_donation: "Retorno de doações",
            return: "Devolução de produtos",
            replenishment: "Remessa de Estoque",
            replenishment_adjustment: "Ajuste de remessa de estoque"

        },
        customize_bills:{
            orders_invocices_title:"Possíveis Nfs processadas em um pedido",
            return_invocices_title:"Nfs processadas em uma devolução de pedido",
            replenishment_invocices_title:"Possíveis Nfs processadas em ajustes de remessas de estoque",
            symbolic_return_description: "Nfe de retorno simbólico de produtos à marca para liberação de item para venda.",
            sale_description: "Nfe de venda de produto para seu cliente.",
            remove_stock_description: "Remova parte do seu inventário do Cubbo.",
            donation_description: "Nfe de doação /  bonificação / brinde para seu cliente.",
            return_description: "Possíveis Nfs processadas em ajustes de remessas de estoque.",
            replenishment_description: "Transferência de estoque para a sua loja Cubbo.",
            replenishment_adjustment_description: "Recebemos uma quantidade inferior à esperada, portanto, retiraremos fiscalmente esses produtos do seu estoque Cubbo.",
            invoice_table_title: "NOTA FISCAL",
            operation_nature_table_title: "NATUREZA DE OPERAÇÃO",
            reason_table_title: "MOTIVO DA EMISSÃO",
            observation_table_title: "OBSERVAÇÃO",
        },
        validate_changes:{
            title: "Você quer sair?",
            question: "Suas alterações serão perdidas",
            saved_data: "Dados salvos",
            unsaved_data: "Dados não salvos",
            yes: "Sim",
            no: "Não",
        },
        ncm_configurations:{
            tax_rate: "Alíquota",
            tax_rate_credit: "Alíquota de crédito",
            tax_regime_store : "Regime fiscal da sua loja",
            more_matches: "Há mais correspondências... ",
            taxes: "Impostos",
            tax_situation: "Situação tributária",
            tax_situation_other_state: "Situação tributária para pedidos interestaduais",
            tax_situation_international: "Situação tributária para pedidos internacionais",
            order_invoices: "Nfs de pedido",
            return_invoices: "Nfs devolução de pedido",
            replenihsment_invoices: "Nfs de remessas de estoque",
            same_state_entity: "Operação dentro do estado",
            other_state_entity: "Operação interestadual",
            international: "Operação internacional",
            framing_code: "Código de enquadramento",
            complete_data_message: "Preencha todos os campos antes de salvar.",
            cancel: "Cancelar",
            edit_ncm: "Editar NCM",
            new_ncm: "Configurando novo NCM",
            parameter_configuration: "Configuração de parâmetros fiscais",
            dont_apply: "Não se aplica",
            ncm_configurations_store: "NCMs configurados em sua loja:",
            no_ncms_configured: "Você ainda não tem NCMs configurados.",
            invoice_table_title: "NOTA FISCAL",
            add_ncm:"Adicionar NCM",
            edit_tax: "Editar imposto",
            nfs_note: "(Observação: o cfop em notas de devolução simbólicas não é personalizável. Mostramos os dados como informativos.)",
            note: "(Observação: o cfop nas faturas de recebimento não é personalizável. Mostramos os dados como informativos.)",
            origin: "Origem"
        },

        automation_container: {
            unpaid_order_cancellation_panel: {
                title: "Cancelar pedidos sem pagamento",
                description: "Defina o tempo máximo que uma ordem pode estar aguardando pagamento.",
                payment_window_units: {
                    days_one: 'Dia',
                    days_other: 'Dias',
                    months_one: 'Mês',
                    months_other: 'Meses'
                },
                help_text: '{{payment_window}} após o pedido ter sido criado, nós o cancelaremos se não foi pago.',
                shopify_help_text: 'Os pedidos do Shopify também serão cancelados no site.'
            },
            carrier_returns_cancellation_panel: {
                immediately: 'Imediatamente',
                title: 'Cancelar retornos de encomenda',
                awaiting_review: 'Decisão pendente',
                description_left: 'Quantidade de dias que um retorno de encomenda permanecerá em ',
                description_right: ' antes de ser automaticamente cancelado.',
                place_holder: 'Dias de espera',
                days_one: 'Dia',
                days_other: 'Dias',
                help_text: '{{review_window}} após o retorno ter retornado ao Cubbo, nós o cancelaremos.'
            },
            enabled_result: 'ativado',
            disabled_result: 'desativado',
            success_message: 'Automação {{status}}.'
        },

        store_br: {
            title_sbr: "Regras de negócio",                                 
            sub_title_sbr: "Crie instruções automatizadas para os seus pedidos com condições específicas"                                  
        },

        brand_identity: {
            title_sbr: "Identidade de marca",
            sub_title_sbr: "Dê presença à sua marca na página de rastreamento e nas notificações de rastreamento que seus clientes receberão",
        },
        brand_identity_container: {
            logo_title:"Logotipo",
            logo_description: "Anexe uma imagem PNG ou JPG",
            logo_uploaded: "Logotipo carregado",
            name_title:"Nome da marca",
            name_description: "Escreva a maneira como deseja que sua loja seja chamada.",
            contact_title:"E-mail de suporte",
            contact_description: "Escreva o e-mail onde seus clientes podem contatá-lo para acompanhamento de casos específicos como pedidos roubados, perdidos ou danificados.",
            logo_preview: "Pré-visualização de uso",
            preview_tracking: "Página de rastreamento",
            preview_email: "E-mails de rastreamento",
            logo_recomendation:"Máximo de 5MB. Dimensões recomendadas: pelo menos 600 x 400 pixels. Formato recomendado: Fundo transparente."
        },

        automatic_reports: {
            title: "Relatórios Automáticos",
            sub_title: "Ative o envio automático de informações específicas da sua loja",
            not_defined: "Não definido",
            and: "e",
            more: "mais",
            category: "Categoria",
            no_reports_to_show: "Sem relatórios para mostrar",
            export_report: "Exportar Relatório",
            error: "Ocorreu um erro, tente novamente mais tarde",
            error_insert_email_recipients: "Adicione pelo menos 1 destinatário",
            report_table_columns: {
                name: "Nome",
                status: "Estado",
                category: "Categoria",
                frequency: "Frequência",
                recipients: "Destinatários"
            },
            filters: {
                name: "Nome",
                category: "Categoria",
                recipients: "Destinatários"
            },
            report_names: {
                inventory_detail: "Inventário",
                lots_detail: "Lotes",
                inventory_kardex: "Histórico de Inventário",
                shipped_products: "Produtos Enviados",
                shipped_orders_detail: "Pedidos Enviados",
                backorder_products: "Produtos em Falta",
                returned_products: "Produtos Devolvidos",
                claims_detail: "Disputas",
                replenishments_detail: "Recibos",
                return_invoices_detail: "Faturas de Devolução",
                order_invoices_detail: "Faturas de Pedidos",
                replenishment_invoices_detail: "Faturas de Recebimento",
                billing_statement_email_recipients: "Extrato de Conta"
            },
            report_categories: {
                INVENTORY: "Inventário",
                SALES: "Vendas",
                ORDERS: "Pedidos",
                RETURNS: "Devoluções",
                CLAIMS: "Disputas",
                REPLENISHMENTS: "Recebimentos",
                INVOICES: "Faturas",
                BILLING: "Faturação"
            },
            report_frequency: {
                DAILY: "Diariamente",
                WEEKLY: "Semanalmente",
                MONTHLY: "Mensalmente",
                FORTNIGHTLY: "Quinzenalmente",
                QUARTERLY: "Trimestralmente",
                report_frequency_descriptions: {
                    DAILY: "no final do dia",
                    WEEKLY: "aos domingos no final do dia",
                    MONTHLY: "no último dia do mês no final do dia",
                    FORTNIGHTLY: "no 15º e último dia do mês",
                    QUARTERLY: "no último dia do mês",
                },
                STATEMENT: "Cada corte"
            },
            report_details: {
                about_the_report_title: "Sobre o Relatório",
                delivery_status: "Status do Envio",
                true: "Ativo",
                false: "Inativo",
                frequency_title: "Frequência de Envio",
                frequency_placeholder: "Escolha a frequência de envio para este relatório",
                recipients_title: "Destinatários",
                recipients_placeholder: "Digite os endereços de e-mail das pessoas que deseja receber este relatório",
                report_descriptions: {
                    inventory_detail: "Este relatório ajudará você a acompanhar seu inventário, identificando peças danificadas, vencidas, disponíveis e indisponíveis para venda. Você também pode encontrar este relatório no painel de inventário ao exportar informações.",
                    lots_detail: "Este relatório irá ajudá-lo a rastrear seu inventário de lote, identificando peças expiradas e ativas.",
                    inventory_kardex: "Este relatório será útil para monitorar os movimentos de entrada ou saída do seu inventário.",
                    shipped_products: "Neste relatório, você terá um resumo de todos os produtos incluídos nos pedidos enviados. Isso permitirá que você monitore seu inventário e faça cálculos para reabastecimento, análise de vendas, entre outras opções.",
                    shipped_orders_detail: "Através deste relatório, você pode medir várias variáveis dos seus envios, como por exemplo, qual incidente é mais comum nos seus envios e com qual courier ocorre, número de pedidos entregues com atraso, tentativas de entrega que resultaram em um pedido devolvido, entre outros. Você também pode baixar estas informações no painel de pedidos, filtrando pelo status do pedido 'Enviado' e pela data de envio.",
                    backorder_products: "Este relatório será útil para tomar medidas sobre os pedidos que estão retidos devido à falta de estoque. Você pode fazer planos de reabastecimento com base nas peças em falta ou gerar estratégias de compensação para seus clientes pelo tempo extra de espera.",
                    returned_products: "Revise a condição em que as peças das suas devoluções foram recebidas. Este relatório é especialmente útil para pedidos com ",
                    claims_detail: "Este relatório permitirá que você quantifique o valor total a ser reembolsado por disputas aceitas do mês e identificar facilmente o motivo pelo qual o restante das disputas foi rejeitado.",
                    replenishments_detail: "Verifique quantas peças foram recebidas do total de peças esperadas e quantas das peças recebidas foram recebidas como danificadas. Você também encontrará recibos criados pela Cubbo para reintroduzir devoluções no inventário com um guia externo ou não identificado.",
                    return_invoices_detail: "Receba automaticamente todas as faturas de devolução emitidas para o período de frequência de envio selecionado. Você também pode encontrar este mesmo relatório no painel de faturas.",
                    order_invoices_detail: "Receba automaticamente todas as faturas dos seus pedidos emitidos para o período de frequência de envio selecionado. Você também pode encontrar este mesmo relatório no painel de faturas.",
                    replenishment_invoices_detail: "Receba automaticamente todas as faturas de recebimento de inventário emitidas para o período de frequência de envio selecionado. Você também pode encontrar este mesmo relatório no painel de faturas.",
                    billing_statement_email_recipients: "Este relatório é enviado a cada corte e contém anexado um detalhamento detalhado do estado de conta do período."
                },
                report_buttons: {
                    cancel: "Cancelar",
                    save: "Salvar",
                    show_details: "Mostrar Detalhes",
                    deactivate: "Desativar Relatório",
                    activate: "Ativar Relatório",
                    pause_report: "Pausar Envio",
                    restart_report: "Retomar Envio",
                    download_report: "Baixar Amostra"
                },
                report_notifications: {
                    status_done: "Status de entrega do relatório salvo",
                    done: "Configuração salva",
                    error: "Erro ao salvar a configuração do relatório"
                }
            },
        },        

        alerts: {
            title_sbr: "Alertas",
            sub_title_sbr: "Configure para quem devemos enviar notificações sobre sua loja",
            sub_title: "Uma notificação por e-mail será enviada para a lista de assinantes de cada categoria quando o evento descrito ocorrer.",
            },
        
        alerts_container: {
            categories:{
                product_expirations_email_notification:{
                    title: "Controle de Lotes",
                    label: "Você será notificado quando um lote expirar"
                },
                replenishments_email_notification:{
                    title: "Recebimento de Inventário",
                    label: "Confirmação será enviada quando um novo recebimento de inventário for agendado e quando for concluído"
                },
                shipping_incident_created_by_cubbo_email_notification:{
                    title: "Incidentes",
                    label: "Você será notificado quando um ou mais incidentes forem gerados para qualquer um dos seus pedidos"
                },
                returns_email_notification:{
                    title: "Devoluções",
                    label: "Você será notificado quando uma devolução for iniciada e quando for recebida na Cubbo"
                },
                claims_email_notification:{
                    title: "Disputas",
                    label: "Você será notificado sobre a resolução de disputas geradas"
                },
                maquila:{
                    title: "Maquila",
                    label: "Você será notificado sobre o status da maquila solicitada (Pendente, Em andamento, Concluída)"
                }
            },
            place_holder: "Digite os endereços de e-mail das pessoas que devem receber essa notificação",
            error_message_email: "O e-mail adicionado não é válido",
            done: "Configuração salva",
            error: "Erro ao salvar a configuração de alertas"
        },
        business_rule_creator: {
            button: "Adicionar regra",
            path: "Adicionar regra",
            title: "Nova regra de negócio",
            title_preview: "Você está prestes a criar esta regra de negócio",
            cancel_creation: {
                button: "Descartar",
                dialog_title: "As informações serão perdidas",
                dialog_description: "Tem certeza de que deseja sair sem salvar?",
                dialog_confirm_button: "Sim, desejo sair",
                dialog_cancel_button: "Não, voltar para a edição",
                on_cancel: "Criação de regra de negócio cancelada"
            },
            create_rule: {
                preview_rule_button: "Pré-visualizar regra",
                save_rule_button: "Criar regra",
                edit_rule_button: "Voltar para a edição",
                dialog_title: "Criar regra de negócio",
                dialog_description: "Tem certeza de que deseja criar a regra de negócio?",
                dialog_confirm_button: "Sim, desejo criá-la",
                dialog_cancel_button: "Não, voltar para a edição",
                on_error: "Ocorreu um erro, tente novamente mais tarde",
                on_success: "A regra de negócio foi criada"
            },
            confirm_rule: {
                success: "A regra de negócio foi criada",
                error: "Ocorreu um erro ao criar a regra de negócio, tente novamente mais tarde"
            },
            add_condition_button: "Adicionar outra condição",
            condition_types: {
                AND: {
                    title: "Adicional à anterior",
                    description: "O pedido deve cumprir todas as condições definidas para aplicar a instrução",
                    display_name: "e..."
                },
                OR: {
                    title: "Opcional à anterior",
                    description: "Se o pedido cumprir alguma das condições opcionais, a instrução será aplicada",
                    display_name: "ou..."
                }
            },
            general_business_rule_settings: {
                title: "Dados gerais",
                description: "Defina o nome e o período de vigência desta regra",
                alias_field: "Alias da regra",
                alias_placeholder: "Digite um alias para identificar facilmente esta regra",
                validity: "Vigência",
                validity_time_zone: "A zona horária corresponde ao armazém",
                validity_start_field: "Ativar a partir de",
                validity_start_placeholder: "Hoje, ao salvar",
                validity_end_field: "Até",
                without_validity_end: "Sem data de expiração",
                confirm_button_missing_all_required: "Defina um alias e a vigência para continuar",
                confirm_button_missing_only_alias: "Defina um alias para continuar",
                confirm_button_missing_only_validity: "Defina a vigência para continuar",
                confirm_button: "Continuar para definir uma ação",
                cancel_edit_button: "Cancelar",
                confirm_edit_button: "Salvar alterações",
                remaining_alias_character_counter: {
                    no_remaining: "Limite de caracteres disponíveis atingido",
                    one_remaining: "Resta 1 caractere disponível",
                    multiple_remaining: "Restam {QUANTITY} caracteres disponíveis"
                },
                completed_step: {
                    from_today_with_no_limit: "Ativar a partir de hoje ao salvar, por tempo indeterminado",
                    from_today_with_limit: "Ativar a partir de hoje ao salvar, até {END_DATE}",
                    from_some_day_with_no_limit: "Ativar a partir de {START_DATE}, por tempo indeterminado",
                    from_some_day_with_limit: "Ativar a partir de {START_DATE}, até {END_DATE}"
                },
                validity_error_message: {
                    missing_validity_end: "Defina a data de vigência",
                    invalid_validity: "A data de ativação deve ser menor ou igual à data de vigência"
                }
            },
            business_rule_action_settings: {
                title: "O que você deseja que aconteça com o pedido?",
                description: "Na próxima seção, você definirá quando essa ação deve ser aplicada",
                change_option: "Alterar",
                action_options_title: "Selecione uma ação",
                product_insert_options_title: "Se o pedido já contém algumas das peças que desejam adicionar, o que devemos fazer?",
                cancel_edit_button: "Cancelar",
                confirm_edit_button: "Salvar alterações",
                confirm_edit_dialog: {
                    title: "Salvar alterações",
                    description: "As alterações feitas serão salvas e as alterações nas etapas subsequentes serão perdidas",
                    cancel_button: "Cancelar",
                    confirm_button: "Salvar alterações"
                },
                action_options: {
                    ADD_SHIPPING_PACKAGE_OPTION: {
                        title: "Usar determinados acessórios para embalar o pedido",
                        description: "Por exemplo, caixas, papel de seda, adesivos, folhetos, etc."
                    },
                    ADD_PRODUCT_OPTION: {
                        title: "Adicionar produtos ou kits de brinde ao pedido",
                        description: "Por exemplo, brinde promocional 2x1, brinde na compra mínima, etc."
                    },
                    ADD_INSERT_OPTION: {
                        title: "Adicionar itens promocionais ao pedido",
                        description: "Por exemplo, adesivos, folhetos, etc."
                    },
                    PAUSE_ORDER_OPTION: {
                        title: "Pausar o pedido"
                    },
                    CANCEL_ORDER_OPTION: {
                        title: "Cancelar o pedido"
                    }
                },
                confirmation_button_unselected_action: "Selecione uma ação para continuar",
                confirmation_button: "Continue para definir uma condição",
                product_selector: {
                    ADD_SHIPPING_PACKAGE_OPTION: {
                        title: "Quais acessórios deseja usar para embalar?",
                        add_product_button: "Selecionar acessório",
                        add_another_product_button: "Adicionar outro acessório",
                        confirmation_button_missing_product: "Selecione um item para continuar",
                        confirmation_button: "Continue para definir quando deve ser embalado com esses acessórios"
                    },
                    ADD_PRODUCT_OPTION: {
                        title: "Quais produtos deseja adicionar ao pedido?",
                        description: "Selecione um produto, kit ou produto não escaneável (acessório)",
                        add_product_button: "Adicionar produto",
                        add_another_product_button: "Adicionar outro produto"
                    },
                    ADD_INSERT_OPTION: {
                        title: "Quais itens promocionais deseja adicionar ao pedido?",
                        description: "Selecione um item promocional",
                        add_product_button: "Adicionar item",
                        add_another_product_button: "Adicionar outro item"
                    }
                },
                product_insert_options: {
                    ENSURE_PRODUCT: {
                        title: "Adicionar apenas as peças em falta para alcançar a quantidade desejada",
                        description: "Por exemplo, se desejar adicionar 2 maçãs e o pedido já tiver 2 maçãs, não serão adicionadas mais maçãs. Se o pedido tiver 1 maçã, será adicionada 1 maçã."
                    },
                    INCLUDE_PRODUCT: {
                        title: "Independentemente de o pedido já conter as peças, adicionar a quantidade desejada",
                        description: "Por exemplo, se desejar adicionar 2 maçãs e o pedido já tiver 3 maçãs, ainda assim serão adicionadas 2 maçãs."
                    }
                },
                completed_step: {
                    PAUSE_ORDER_OPTION: "Pausar o pedido",
                    CANCEL_ORDER_OPTION: "Cancelar o pedido",
                    ADD_SHIPPING_PACKAGE_OPTION: {
                        description_start: "Para embalar, usar ",
                        product: "{QUANTITY} de {SKU}",
                        last_product_join: " e "
                    },
                    ADD_PRODUCT_OPTION: {
                        description_start: "Adicionar ao pedido ",
                        insert_options: {
                            ENSURE_PRODUCT: "somente se o pedido ainda não o contiver ",
                            INCLUDE_PRODUCT: "mesmo que o pedido já o contenha "
                        },
                        product: "{QUANTITY} de {SKU}",
                        last_product_join: " e ",
                        description_end: " como presente dentro do pedido"
                    },
                    ADD_INSERT_OPTION: {
                        description_start: "Adicionar ao pedido ",
                        insert_options: {
                            ENSURE_PRODUCT: "somente se o pedido ainda não o contiver ",
                            INCLUDE_PRODUCT: "mesmo que o pedido já o contenha "
                        },
                        product: "{QUANTITY} de {SKU}",
                        last_product_join: " e ",
                        description_end: " como presente dentro do pedido"
                    }
                }
            },
            business_rule_statement_condition: {
                title: "Defina a condição",
                title_template: "Você está criando esta condição:",
                save_button: "Guardar condição",
                save_button_update: "Atualizar condição",
                cancel_button: "Descartar alterações",
                unsaved_changes: "Dados não salvos",
                completed_condition: {
                    HAS_FRAGILE_ITEMS: "Quando o pedido incluir produtos frágeis",
                    CHANNEL_TYPE_NAME: "Quando for um pedido de {{channel_name}}",
                    IS_FIRST_TIME_BUYER: "Quando for um pedido de novo comprador",
                    IS_POTENTIAL_FRAUD: "Quando o pedido for detectado como fraude pelo Shopify",
                    VALIDATE_PRODUCT: {
                        validationOption:{
                            INCLUDE_PRODUCT: "Quando o pedido incluir ",
                            NOT_INCLUDE_PRODUCT: "Quando o pedido não incluir "
                        },
                        quantityOption: {
                            MINIMUM: "pelo menos {MIN_QUANTITY}",
                            MAXIMUM: "até {MAX_QUANTITY}",
                            MINIMUM_AND_MAXIMUM: "de {MIN_QUANTITY} a {MAX_QUANTITY}",
                            EXACTLY: "exatamente {EXACT_QUANTITY}"
                        },
                        exclusive: " e nenhum outro produto",
                        any_sku: " de qualquer produto da sua loja",
                        specified_sku: " de algum destes produtos...",
                        all_orders: "Em todos os pedidos"
                    },
                    FOR_EVERY_PRODUCT: {
                        every_quantity: "Para cada vez que o pedido incluir {QUANTITY}",
                        with_max_quantity: " (até {MAX_QUANTITY})",
                        any_sku: " de qualquer produto da sua loja",
                        specified_sku: " de algum destes produtos..."
                    }
                }
            },
            business_rule_additional_statement_condition: {
                title: "Selecione uma condição",
                options_placeholder: "Quando o pedido..."
            },
            business_rule_statements: {
                custom: {
                    validations: {
                        INCLUDE_PRODUCT: "Incluir produto",
                        NOT_INCLUDE_PRODUCT: "Não incluir produto"
                    },
                    quantities: {
                        MINIMUM: "Mínimo",
                        MAXIMUM: "Máximo",
                        MINIMUM_AND_MAXIMUM: "Mínimo e Máximo",
                        EXACTLY: "Exatamente"
                    },
                    title_validations: "Quando o pedido...",
                    title_business_rules_per_integration: "Quando for um pedido de:",
                    title_quantities: "Esta quantidade...",
                    title_sku: "De algum destes produtos...",
                    button_add_product: "Adicionar produto",
                    from_quantity_to_quantity: "a",
                    any_product: "Qualquer produto",
                    any_product_placeholder: "Qualquer produto da sua loja",
                    not_any_product_placeholder: "Selecione ou cole o SKU de algum produto",
                    exclusive: "... desde que o pedido não contenha nenhum outro produto adicional à lista",
                    single_sku_error: "{QUANTITY} SKU inválido. Corrija ou remova antes de continuar",
                    multiple_sku_error: "{QUANTITY} SKUs inválidos. Corrija ou remova antes de continuar",
                    error_messages: {
                        unselected_validation_option: "Selecione uma opção",
                        unselected_quantity_option: "Selecione uma opção",
                        missing_quantity: "Digite uma quantidade",
                        invalid_range_quantity: "A quantidade mínima deve ser menor que a quantidade máxima",
                        missing_products: "Cole ou adicione SKU(s) de pelo menos um produto"
                    }
                },
                for_every_product: {
                    title_for_every_product: "Para cada...",
                    title_from_sku: "de algum destes produtos...",
                    title_complement_from_sku: "que o pedido inclua.",
                    title_max_quantity: "Máximo de peças a adicionar por produto no pedido",
                    option_max_quantity: "Sem limite",
                    button_add_product: "Adicionar produto",
                    any_product: "Qualquer produto",
                    any_product_placeholder: "Qualquer produto da sua loja",
                    not_any_product_placeholder: "Selecione ou cole o SKU de algum produto",
                    single_sku_error: "{QUANTITY} SKU inválido. Corrija ou remova antes de continuar",
                    multiple_sku_error: "{QUANTITY} SKUs inválidos. Corrija ou remova antes de continuar",
                    error_messages: {
                        missing_for_every_quantity: "Digite uma quantidade",
                        missing_products: "Cole ou adicione SKU(s) de pelo menos um produto",
                        invalid_maximum_quantity: "Digite uma quantidade ou especifique se não deseja definir um limite de peças"
                    }
                }
            },
            business_rule_statement_settings: {
                title: "Quando devemos aplicar a ação previamente definida?",
                description: "Defina quais condições o pedido deve cumprir para que a ação seja aplicada",
                display_less_options: "Ver menos casos",
                display_more_options: "Ver mais casos",
                statement_options: {
                    ALL_ORDERS: {
                        title: "Em {BOLD_START}todos os pedidos{BOLD_END}"
                    },
                    CHANNEL_TYPE_NAME: {
                        title: "Quando for um pedido de {HIGHLIGHTED_START}certo canal de venda{HIGHLIGHTED_END}"
                    },
                    IS_FIRST_TIME_BUYER: {
                        title: "Quando for um pedido de {BOLD_START}novo comprador{BOLD_END}"
                    },
                    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY: {
                        title: "Quando o pedido incluir {BOLD_START}pelo menos{BOLD_END} {HIGHLIGHTED_START}uma quantidade{HIGHLIGHTED_END} de {HIGHLIGHTED_START}certos produtos{HIGHLIGHTED_END}"
                    },
                    SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY: {
                        title: "Quando o pedido incluir {BOLD_START}de{BOLD_END} {HIGHLIGHTED_START}uma quantidade{HIGHLIGHTED_END} {BOLD_START}a{BOLD_END} {HIGHLIGHTED_START}outra quantidade{HIGHLIGHTED_END} de {HIGHLIGHTED_START}certos produtos{HIGHLIGHTED_END}"
                    },
                    FOR_EVERY_PRODUCT: {
                        title: "{BOLD_START}Para cada{BOLD_END} {HIGHLIGHTED_START}uma quantidade{HIGHLIGHTED_END} de {HIGHLIGHTED_START}certos produtos{HIGHLIGHTED_END} que o pedido incluir"
                    },
                    FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: {
                        title: "{BOLD_START}Para cada{BOLD_END} {HIGHLIGHTED_START}uma quantidade{HIGHLIGHTED_END} de {HIGHLIGHTED_START}certos produtos{HIGHLIGHTED_END} que o pedido incluir {BOLD_START}, desde que não inclua{BOLD_END} {HIGHLIGHTED_START}certos produtos{HIGHLIGHTED_END}"
                    },
                    NOT_INCLUDING_SPECIFIED_PRODUCT: {
                        title: "Quando o pedido incluir {BOLD_START}qualquer produto que não seja{BOLD_END} {HIGHLIGHTED_START}certos produtos{HIGHLIGHTED_END}"
                    },
                    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: {
                        title: "Quando o pedido incluir {HIGHLIGHTED_START}uma quantidade{HIGHLIGHTED_END} de {HIGHLIGHTED_START}certos produtos{HIGHLIGHTED_END} {BOLD_START}mas não incluir{BOLD_END} {HIGHLIGHTED_START}certos produtos{HIGHLIGHTED_END}"
                    },
                    SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE: {
                        title: "Quando o pedido incluir {BOLD_START}a quantidade exata de{BOLD_END} {HIGHLIGHTED_START}uma quantidade{HIGHLIGHTED_END} de {HIGHLIGHTED_START}certos produtos{HIGHLIGHTED_END} {BOLD_START}e nenhum outro produto adicional{BOLD_END}"
                    },
                    SPECIFIED_PRODUCT_EXACT_QUANTITY: {
                        title: "Quando o pedido incluir {BOLD_START}a quantidade exata de{BOLD_END} {HIGHLIGHTED_START}uma quantidade{HIGHLIGHTED_END} de {HIGHLIGHTED_START}certos produtos{HIGHLIGHTED_END}; pode incluir outros produtos"
                    },
                    CUSTOM_STATEMENT: {
                        title: "{BOLD_START}Criar condição personalizada{BOLD_END}"
                    },
                    FRAUD_ORDER_DETECTED: {
                        title: "Quando o pedido for {BOLD_START}detectado como fraude{BOLD_END} pelo Shopify"
                    },
                    HAS_FRAGILE_ITEMS: {
                        title: "Quando o pedido incluir {BOLD_START}produtos frágeis{BOLD_END}"
                    }
                }
            },
            products_table: {
                product_image_header: "Imagem",
                product_name_header: "Nome",
                product_sku_header: "SKU",
                product_quantity_header: "Quantidade desejada"
            }
        },
        store_business_container: {
            market_insert: "Adicionar enxerto",                                    
            hold_order: "Ordem de pausa",                                    
            cancel_order: "Cancelar perdido",
            cancel_order: "Cancelar perdido",
            package_order: "Utilizar estes acessórios ao embalar...",
            include_order: "Adicionar estes produtos, mesmo que o pedido já os contenha...",
            ensure_order: "Adicionar estes produtos, apenas se o pedido ainda não os contiver...",
            info_tooltip: "Agrega instruciones personalizadas para seus pedidos, también conocidas como Reglas de Negocio.",                                    
            rule_type: "Adicionar enxerto",                                    
            no_rules: "Você ainda não tem regras cadastradas",                                   
            defined_rule: "Regla Predeterminada",                                   
            order_has: "Quando o pedido...",                                   
            no_registers: "Registros do pecado",                                  
            hold_orders: "Ordem de pausa",                                   
            add: "Adicionar",                                   
            instruction: "Instrução",                                 
            quantity: "Quantidade",                                   
            of: "de",    
            some_of_this: "de qualquer um desses produtos",
                not_some_of_this: "produto(s) que não qualquer um destes",                               
            skus: "SKUs",                                   
            title: "Regalo de verano",                                  
            active: "Ativa",                                   
            inactive: "Desativada",
            and: "e...",                                    
            or: "o...",                                 
            all_skus: "Qualquer SKU na loja.",
            include_at_least:"pelo menos ",
            not_include_at_least:"pelo menos ",
            include_exactly: "exatamente ",                                  
            not_include_exactly: "exatamente ",                                   
            include_range: "de ",                                 
            not_include_range: "de ",                                  
            include_up_to: "hasta ",                                  
            not_include_up_to: "hasta ",
            channel_name: "Seja de {{channel_name}}",                             
            is_potencial_fraud: "É marcado como fraude pela Shopify",     
            is_first_time_buyer: "Seja de um novo comprador",                            
            has_fragile_items: "Tem produtos frágeis",
            except: "Desde que não sejam ",                                  
            to: "para",
            has_not: "Não tenha ",
            of_the_next: "de qualquer um dos seguintes produtos:",
            exactly: "exatamente ",
            at_least: "pelo menos ",
            deleted_products: 'Produtos eliminados',
            includes: "Inclui",
            does_not_include: "Não inclui",
            confirm_dialog: {
                title_enable: "Ativar regra de negócio",
                title_disable: "Desativar regra de negócio",
                description: "Será aplicado em pedidos sincronizados após a confirmação",
                confirm: "Confirmar",
                cancel: "Cancelar",
                error : {
                    RULE_ENABLED_TRUE: 'Esta regra já foi ativada',
                    RULE_ENABLED_FALSE: 'Esta regra já foi desativada',
                    undefined: "Ocorreu um erro ao tentar confirmar esta operação"
                }
            },
            time_validity: {
                no_start_and_no_end_date: "sem data de início, sem data de expiração",
                no_start_date: "sem data de início",
                no_end_date: "sem data de expiração",
                from_date: "de",
                to_date: "até",
                is_expired: "Expirado",
                is_not_expired: "Período de validade",
                last_update: "Última atualização",
            },
            products_to_add_table_columns: {
                desired_product: "Produto desejado",
                quantity_to_add: "Quantidade a adicionar",
                max_pieces_to_add: "Máximo de peças a adicionar",
            },
            many_pieces_to_add: "peças",
            single_piece_to_add: "peça",
            sku: "SKU",
            kit: "KIT",
            without_max_limit: "Sem limite",
            exclusive: "e nenhuma mais",
            for_every_product: "Para cada vez que o pedido...",
            as_long_as_the_order: "Desde que o pedido...",
            note_max_times_rule_applied: "NOTA: O número máximo de vezes que esta regra se aplicará no mesmo pedido depende do máximo de peças a adicionar.",
            no_name_business_rule: "Regra de negócio sem nome criada em",
            products_quantity: "Quantidade",
            max_products_quantity: "Máximo de Peças",
            rule_without_name: "Regra sem nome criada em",
            filters: {
                active: "Ativo",
                inactive: "Inativo",
                add_product: "Adicionar Produto",
                cancel_order: "Cancelar Pedido",
                use_package: "Usar Pacote",
                hold_order: "Pausar Pedido",
                category: "Categoria",
                add_this_sku: "SKU na ação",
                that_contains_this_sku: "SKU na condição",
                alias: "Alias",
                estado: "Estado",
                expired: "Expiradas"
            }
        },
    },
    filter: {
        button: "Filtrar",
        reset: "Limpar filtros",
        select: "Selecione um estado",
        from_to: "de-até",
        export: "Exportar",
        dropdownExport: {
            breakdown_by_product: "Detalhamento por Produto",
            breakdown_by_order: "Detalhamento por Pedido"
        }
    },
    dialog: {
        yes: "Sim",
        no: "Não",
        cancel: "Cancelar"
    },
    export_notification: {
        generating: "Gerando relatório",
        generated: "Relatório gerado",
        download: "Baixar"
    },
    success_notification: {
        check_your_email: "Verifique seu email!",
        instructions_with_password: "Enviamos um email com orientações para alterar sua senha."
    },
    work_orders: {
        button_navigation: "Ir para a seção de maquilas",
        work_orders: "Maquilas",
        button: "Criar Maquilas",
        path: "Criar Maquilas",
        title: "Você está criando uma solicitação de maquila",
        title_preview: "Você está prestes a criar esta solicitação de maquila",
        no_work_orders: "Nenhuma maquila para mostrar",
        all_stores: "Todas as Lojas",
        replenishment_id: "ID do Recibo de Inventário",
        rem_order_id: "ID do Pedido de Retirada de Inventário",
        on_date: "em",
        header: {
            id: "Nº maquila",
            work_order_types: "Tipo de Maquila",
            statuses: 'Status',
            work_order_histories: "Resumo de Maquila",
            filter: "Filtrar",
            reset_filters: "Limpar Filtros"
        },
        subheader: {
            id: "Notas",
            work_order_types: "Origem das Peças",
            statuses: "Observações",
            work_order_histories: ""
        },
        footer: {
            total: "Total",
            work_orders: "maquilas"
        },
        work_order_statuses: {
            REQUESTED: {
                title: "Solicitada",
                description: "Vamos revisar sua solicitação em até 72 horas"
            },
            IN_VALIDATION: {
                title: "Em Validação",
                description: "Vamos garantir que tenhamos todos os recursos necessários para processar"
            },
            SCHEDULED: {
                title: "Próxima a iniciar",
                description1: "Sua maquila foi aprovada e está na fila para começar o processamento.",
                description2: "Terá um custo estimado de",
                description3: "O custo será definido ao finalizar a maquila."
            },
            IN_PROGRESS: {
                title: "Em Progresso",
                description1: "Existem",
                description2: "pessoas designadas para sua maquila, você verá as peças processadas entrarem no seu inventário através do recibo"
            },
            COMPLETED: {
                title: "Concluída",
                description1: "As peças totais necessárias foram processadas",
                description2: "o custo desta maquila será de",
                description3: "As peças serão inseridas no recibo de inventário"
            },
            REJECTED: {
                title: "Rejeitada"
            },
            CANCELED: {
                title:"Cancelada"
            }
        },
        filters: {
            reset_filters: "Limpar filtros",
            search: {
                id: "Nº maquila",
                work_order_types: "Tipo de Maquila",
                statuses: "Status"
            },
            id: "Nº maquila",
            statuses: "Selecione um status",
            work_order_types: "Selecione um tipo de maquila"
        },
        cancel_creation: {
            button: "Descartar",
            dialog_title: "As informações serão perdidas",
            dialog_description: "Tem certeza de que deseja sair sem salvar?",
            dialog_confirm_button: "Sim, quero sair",
            dialog_cancel_button: "Não, voltar à edição",
            on_cancel: "Criação de maquila cancelada"
        },
        create_work_order: {
            preview_work_order_button: "Visualizar Maquilas",
            save_work_order_button: "Criar Maquilas",
            edit_work_order_button: "Voltar para a edição",
            dialog_title: "Criar Maquilas",
            dialog_description: "Tem certeza de que deseja criar a maquila?",
            dialog_confirm_button: "Sim, quero criar",
            dialog_cancel_button: "Não, voltar à edição",
            on_error: "Ocorreu um erro, tente novamente mais tarde",
            on_success: "Ordem de trabalho criada"
        },
        confirm_work_order: {
            success: "Ordem de trabalho criada",
            error: "Ocorreu um erro ao criar a maquila, tente novamente mais tarde"
        },
        work_order_detail: {
            title: "Ver Solicitação de Maquilas",
            notes: "Notas",
            order_details: {
                title: "Detalhes da Solicitação",
                work_order_id: "Nº da Maquila",
                solicitant: "Solicitante",
                work_order_status: "Status da Maquilas"
            },
            product_origin: {
                title: "Origem do Material Necessário",
                rem_order_id: "Retirada de Inventário",
                no_rem_order: "Esta maquila não possui pedido de retirada de inventário"
            },
            work_order_info: {
                title: "Solicitação de Maquilas",
                work_order_type: "Tipo de Processo",
                notes: "Notas",
                instruction: "Instrução",
                activity: "Atividade"
            },
            replenishment: {
                title: "Entrada de Produto Processado",
                replenishment_id: "Recibo de Inventário do Produto Processado",
                replenishment_note: "A partir deste recibo de inventário, você pode acompanhar o progresso da entrada do produto processado em seu inventário"
            }
        },
        creation_sections: {
            work_order_type: {
                title: "Tipo de Maquilas",
                select_work_order: {
                    title: "Selecione a maquila desejada",
                    description: "Observe que todo o material necessário terá que ser fornecido à equipe de operações para processamento",
                    options: {
                        LABELING_PLASTISEAL: {
                            title: "Etiquetagem ou Plastificação",
                            subtitle: "Reetiquetar e/ou selar uma bolsa com calor"
                        },
                        PRODUCT_VALIDATION: {
                            title: "Validação de Produto",
                            subtitle: "Contagem de produtos ou verificação de sua condição"
                        },
                        KIT_ASSEMBLY_SHRINKWRAP: {
                            title: "Montagem de Kits ou Aplicação de Termoencolhimento",
                            subtitle: "Embalagem de mais de um produto na mesma unidade e/ou aplicação de termoencolhimento"
                        },
                        PACKAGING: {
                            title: "Embalagem",
                            subtitle: "Colocação de um produto dentro de sua embalagem"
                        },
                        PRODUCT_SEGREGATION: {
                            title: "Segregação de Produto",
                            subtitle: "Separação de mais de um SKU para armazenamento"
                        },
                    }
                }
            },
            required_material: {
                title: "Material Necessário",
                product_origin: {
                    title: "Origem dos Produtos e Insumos Necessários",
                    description: "Como você enviará o material necessário para completar a maquila",
                    options: {
                        ALL_INVENTORY_AT_WAREHOUSE: {
                            title: "Todo o material está armazenado no meu inventário na Cubbo disponível para venda",
                            completed_description: "O inventário está armazenado na Cubbo e será retirado através do"
                        },
                        MATERIAL_IN_TRANSIT_TO_WAREHOUSE: {
                            title: "O material está a caminho do armazém",
                            completed_description: "O material está a caminho do armazém"
                        },
                        PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE: {
                            title: "Parte do material está armazenado na Cubbo e parte está a caminho do armazém",
                            completed_description: "Parte do material está armazenado na Cubbo e será retirado através do"
                        }
                    }
                },
                removal_order: {
                    title: "ID do Pedido de Retirada de Inventário do Material Necessário",
                    description: "Digite o número do pedido em que você indica quais produtos retirar do seu inventário para processamento",
                    create_rem_order: "Ainda não criei o pedido de retirada de inventário",
                    removal_order: "pedido de retirada de inventário",
                    continue_button: "Continuar para definir instruções de maquila"
                }
            },
            work_order_instructions: {
                title: "Instruções para Processamento",
                download_file: {
                    title: "Baixe este arquivo e preencha-o com os detalhes da sua solicitação",
                    subtitle: "Observe que todo o material necessário terá que ser fornecido à equipe de operações para processamento",
                    files_placeholder: "Clique aqui ou arraste seus arquivos PDF ou Excel ou XML",
                    attach_document: "Anexar o arquivo de instruções",
                    notes: "Observações sobre sua maquila",
                    completed_description_notes: "Observações",
                    continue_button: "Continuar para registrar a entrada do produto processado",
                    file_name: "Anexe um guia detalhado de embalagem com imagens de exemplo."
                }
            },
            work_order_replenishment: {
                title: "Entrada de Maquilas Concluída",
                replenishment: {
                    title: "ID do Recibo de Inventário do Produto Processado",
                    description: "Digite o número do inventário onde registraremos a entrada do produto processado",
                    completed_description_entry: "A entrada dos produtos processados será registrada através do",
                    completed_description_replenishment: "recibo de inventário"
                }
            }
        }
    },
    
    billing: {
        page_title: "Faturação",
        download_report: "Baixe este relatório aqui",
        downloading_report: "Baixando relatório...",
        statement: {
            cutoff_at: "Corte: {DATE}",
            select_statement: "Ver período",
            options: {
                LOADING: "Carregando períodos...",
                CURRENT: "Do {FIRST_DATE} até hoje",
                FIRST: "Primeiro corte em {LAST_DATE}",
                NORMAL: "Do {FIRST_DATE} até {LAST_DATE}",
                SHORTENED: "Do {FIRST_DAY} ao {LAST_DAY} de {MONTH}"
            },
            export_statement_summary: "Exportar resumo",
            exporting_statement_summary: "Exportando resumo",
            export_success_statement_summary: "Exportação do resumo completa",
            export_error_statement_summary: "Ocorreu um erro ao exportar o resumo, por favor, tente novamente mais tarde",
            export_services_summary: "Exportar CSV",
            exporting_services_summary: "Exportando CSV",
            export_success_services_summary: "Exportação CSV completa",
            export_error_services_summary: "Ocorreu um erro ao exportar o CSV, por favor, tente novamente mais tarde"
        },
        totals: {
            statement_summary: "Resumo do corte",
            total: "Total a pagar",
            total_to_date: "Total até à data",
            important: "Importante: ",
            no_statement: "Os saldos totais finais serão refletidos até a data de corte.",
            payment_limit_date: "Data limite de pagamento",
            next_cutoff_date: "Data de corte seguinte",
            charges_without_taxes: "Encargos sem IVA",
            charges: "Encargos",
            total_discount: "Saldo a favor",
            tax_IVA: "IVA",
            percentage_IVA: "% do total"
        },
        averages: {
            order_fulfillment: "Execução de pedido",
            dtc_orders: "Execução de pedido Direto ao Consumidor",
            marketplace_orders: "Execução de pedido do Marketplace",
            shipping: "Envios",
            simple_returns: "Retornos por devolução",
            carrier_returns: "Retornos por serviço de transporte",
            extended_zone: "Zona estendida",
            pick_and_pack: "Pick & Pack",
            package: "Embalagem",
            difal: "Difal",
            shipping_insurance: "Seguro de envio",
            average_costs_per_order: "Custos médios por pedido antes de impostos",
            average_costs_per_order_without_taxes: "Custos médios por pedido",
            additional_services: "Serviços adicionais",
            order_average_description: "A média não inclui custos associados a pedidos de retirada de inventário, fabricação, recebimentos, armazenamento, encargos adicionais e saldos a favor.",
            average_with_additional_services: "com serviços adicionais",
            averages_descriptions: {
                simple_returns: "Custos associados à recepção de peças e logística reversa.",
                carrier_returns: "Custos associados à recepção de peças e logística reversa.",
                pick_and_pack: "Custos associados ao picking de produtos, adição de inserções e controle de saída.",
                package: "Custos associados a caixas, materiais de empacotamiento e embalagens frágeis."
            }
        },
        currencies:{
            MXN: "MXN",
            BRL: "BRL"
        },
        summaries_options: {
            SERVICES_SUMMARY: "Desagregação por conceito",
            ORDER_DETAIL: "Detalhe de pedidos",
            RETURN_DETAIL: "Detalhe de retornos",
            CLAIM_DETAIL: "Detalhe de disputas"
        },
        services_summary_table: {
            not_available: "Não disponível",
            no_data: "Não há informações disponíveis",
            volumetric_weight_unit: "{VOLUMETRIC_WEIGHT} kg volumétrico",
            headers: {
                TITLE: "Conceito",
                UNIT: "Unidade",
                UNIT_VALUE: "Valor unitário",
                QUANTITY: "Quantidade",
                TOTAL: "Total"
            },
            quantity_notes: {
                STORAGE_BY_LOCATION: "Uso médio",
                STORAGE_BY_PRODUCT: "Uso médio"
            },
            tables_sections: {
                PICK_AND_PACK: "Escolha",
                PICK_AND_PACK_INSERT: "Adicionar um inserção",
                PICK_AND_PACK_SERIAL_NUMBER: "Controlo de saída por número de série",
                PICK_AND_PACK_FRAGILE: "Embalagem de pacote frágil",
                PICK_AND_PACK_PACKAGE: {
                    parcel_bag: "Embalagem - Saco de encomendas",
                    jiffy_envelope: "Embalagem - Envelope jiffy",
                    cubbo_box: "Embalagem - Caixa Cubbo",
                    customer_box: "Embalagem - Caixa do cliente"
                },
                PACKING_MATERIAL: "Materiais de empacotamiento",
                REPLENISHMENT: "Receção de peças",
                REPLENISHMENT_LOTS: "Receção de peças com controlo de lote",
                RETURN_ORDER: "Receção de peças devolvidas",
                RETURN_ORDER_LOTS: "Receção de peças devolvidas com controlo de lote",
                RETURN_SHIPPING_LABEL: "Guia de retorno",
                SHIPPING_LABEL_PICKUP: "Carga por pedido",
                SHIPPING_LABEL: {
                    SP_CAP: "SP Capital",
                    SP_INT: "SP Interior",
                    RJ_CAP: "RJ Capital",
                    RJ_INT: "RJ Interior",
                    ES_CAP: "ES Capital",
                    ES_INT: "ES Interior",
                    MG_CAP: "MG Capital",
                    MG_INT: "MG Interior",
                    BA_CAP: "BA Capital",
                    BA_INT: "BA Interior",
                    SE_CAP: "SE Capital",
                    SE_INT: "SE Interior",
                    PE_CAP: "PE Capital",
                    PE_INT: "PE Interior",
                    AL_CAP: "AL Capital",
                    AL_INT: "AL Interior",
                    PB_CAP: "PB Capital",
                    PB_INT: "PB Interior",
                    RN_CAP: "RN Capital",
                    RN_INT: "RN Interior",
                    CE_CAP: "CE Capital",
                    CE_INT: "CE Interior",
                    PI_CAP: "PI Capital",
                    PI_INT: "PI Interior",
                    MA_CAP: "MA Capital",
                    MA_INT: "MA Interior",
                    PA_CAP: "PA Capital",
                    PA_INT: "PA Interior",
                    AP_CAP: "AP Capital",
                    AP_INT: "AP Interior",
                    AM_CAP: "AM Capital",
                    AM_INT: "AM Interior",
                    RR_CAP: "RR Capital",
                    RR_INT: "RR Interior",
                    AM_INT_2: "AM Interior 2",
                    AC_CAP: "AC Capital",
                    AC_INT: "AC Interior",
                    DF_CAP: "DF Capital",
                    DF_INT: "DF Interior",
                    GO_CAP: "GO Capital",
                    DF_INT_2: "DF Interior 2",
                    GO_CAP_2: "GO Capital 2",
                    GO_INT: "GO Interior",
                    RO_CAP: "RO Capital",
                    RO_INT: "RO Interior",
                    TO_CAP: "TO Capital",
                    TO_INT: "TO Interior",
                    MT_CAP: "MT Capital",
                    MT_INT: "MT Interior",
                    RO_INT_2: "RO Interior 2",
                    RO_INT_3: "RO Interior 3",
                    MS_CAP: "MS Capital",
                    MS_INT: "MS Interior",
                    PR_CAP: "PR Capital",
                    PR_INT: "PR Interior",
                    SC_CAP: "SC Capital",
                    SC_INT: "SC Interior",
                    RS_CAP: "RS Capital",
                    RS_INT: "RS Interior",
                    SUPER_SAVER: "SUPER SAVER",
                    LOCAL: "LOCAL",
                    PREMIUM: "PREMIUM",
                    ECONOMY: "ECONOMY"
                },
                SHIPPING_LABEL_EXTENDED_ZONE: {
                    SUPER_SAVER: "Zona estendida SUPER SAVER",
                    LOCAL: "Zona estendida LOCAL",
                    PREMIUM: "Zona estendida PREMIUM",
                    ECONOMY: "Zona estendida ECONOMY"
                },
                STORAGE_BY_LOCATION: "Armazenamento",
                STORAGE_BY_PRODUCT: "Armazenamento",
                INTERNATIONAL_SHIPPING_LABEL: {
                    NORTH_AMERICA: "América do Norte",
                    LATIN_AMERICA: "América Latina",
                    CARIBBEAN: "Caribe",
                    EUROPE: "Europa",
                    ASIA: "Ásia",
                    AFRICA: "África"
                },
                CLAIM: "Disputas",
                UNIDENTIFIED_RETURN: "Devoluções não identificadas",
                SHIPMENTS_RETURN_BY_CARRIER: "Devoluções por serviço de transporte",
                SHIPPING_INSURANCE: "do valor do pedido"
            },
            tables_titles: {
                PICK_AND_PACK: "Escolha e Embalagem",
                PICK_AND_PACK_PACKAGE: "Embalagem",
                REPLENISHMENT: "Receção",
                RETURN: "Devoluções",
                SHIPPING_LABEL: "Envios",
                STORAGE_BY_LOCATION: "Armazenamento",
                STORAGE_BY_PRODUCT: "Armazenamento",
                INTERNATIONAL_SHIPPING_LABEL: "Geração de guia internacional",
                OTHERS: "Outros",
                CREDIT: "Saldo de crédito"
            },
            no_packages: "Sem pacotes",
            units: {
                STORAGE_BY_LOCATION: {
                    PER_UNIT: "Carga por local ocupado"
                },
                STORAGE_BY_PRODUCT: {
                    PER_UNIT: "Carga por peça armazenada"
                },
                SHIPPING_LABEL_PICKUP: {
                    TOTAL: "Carga por pedido"
                },
                SHIPPING_LABEL: {
                    TOTAL: "Carga por guia",
                    PER_UNIT_EXCESS: "Carga por guia"
                },
                SHIPPING_LABEL_EXTENDED_ZONE: {
                    TOTAL: "Carga por guia",
                },
                INTERNATIONAL_SHIPPING_LABEL: "Carga para envio internacional",
                RETURN_ORDER: {
                    PER_UNIT: "Carga por peça devolvida",
                    TOTAL: "Carga por devolução",
                    PER_UNIT_EXCESS: "Carga por peça devolvida",
                    TOTAL_EXCESS: "Carga por devolução"
                },
                RETURN_ORDER_LOTS: {
                    PER_UNIT: "Carga por peça devolvida",
                    TOTAL: "Carga por devolução",
                    PER_UNIT_EXCESS: "Carga por peça devolvida",
                    TOTAL_EXCESS: "Carga por devolução"
                },
                RETURN_SHIPPING_LABEL: {
                    TOTAL: "Carga por guia",
                    PER_UNIT_EXCESS: "Carga por guia"
                },
                PICK_AND_PACK: {
                    PER_UNIT: "Carga por peça",
                    TOTAL: "Carga por pedido",
                    PER_UNIT_EXCESS: "Carga por peça",
                    TOTAL_EXCESS: "Carga por pedido"
                },
                PICK_AND_PACK_INSERT: {
                    PER_UNIT: "Carga por peça",
                    TOTAL: "Carga por pedido",
                    PER_UNIT_EXCESS: "Carga por peça",
                    TOTAL_EXCESS: "Carga por pedido"
                },
                PICK_AND_PACK_SERIAL_NUMBER: {
                    PER_UNIT: "Carga por peça",
                    TOTAL: "Carga por pedido",
                    PER_UNIT_EXCESS: "Carga por peça",
                    TOTAL_EXCESS: "Carga por pedido"
                },
                PICK_AND_PACK_FRAGILE: {
                    TOTAL: "Carga por pacote"
                },
                PICK_AND_PACK_PACKAGE: {
                    TOTAL: "Carga por pacote",
                    TOTAL_EXCESS: "",
                },
                REPLENISHMENT: {
                    PER_UNIT: "Carga por peça recebida",
                    TOTAL: "Carga por recibo",
                    PER_UNIT_EXCESS: "Carga por peça recebida",
                    TOTAL_EXCESS: "Carga por recibo",
                },
                REPLENISHMENT_LOTS: {
                    PER_UNIT: "Carga por peça recebida",
                    TOTAL: "Carga por recibo",
                    PER_UNIT_EXCESS: "Carga por peça recebida",
                    TOTAL_EXCESS: "Carga por recibo",
                },
                CLAIM: {
                    PER_UNIT: "Por disputa"
                },
                CUSTOM: {
                    TOTAL_DISCOUNT: "Desconto único",
                    TOTAL_CHARGE: "Encargo único"
                },
                REFUND: "Reembolso único",
                WORK_ORDER: {
                    PER_UNIT: "Pessoas por dia"
                },
                UNIDENTIFIED_RETURN: {
                    TOTAL: "Carga por pedido",
                },
            },
            usage_ranges_details: {
                general: {
                    PIECES: {
                        singular_from: "mais de {FROM} peça",
                        plural_from: "mais de {FROM} peças",
                        singular_from_to: "de {FROM} a {TO} peça",
                        plural_from_to: "de {FROM} a {TO} peças"
                    },
                    ORDERS_PIECES: {
                        singular_from: "encomendas com mais de {FROM} peça",
                        plural_from: "encomendas com mais de {FROM} peças",
                        singular_from_to: "encomendas com {FROM} a {TO} peça",
                        plural_from_to: "encomendas com {FROM} a {TO} peças"
                    },
                    WEIGHT: {
                        singular_from: "mais de {FROM} kg",
                        plural_from: "mais de {FROM} kg",
                        singular_from_to: "de {FROM} kg a {TO} kg",
                        plural_from_to: "de {FROM} kg a {TO} kg"
                    },
                    ORDERS_INSERTS: {
                        singular_from: "encomendas com mais de {FROM} inserção",
                        plural_from: "encomendas com mais de {FROM} inserções",
                        singular_from_to: "encomendas com {FROM} a {TO} inserção",
                        plural_from_to: "encomendas com {FROM} a {TO} inserções",
                    }
                },
                PICK_AND_PACK_FRAGILE: "Suprimentos de embalagem frágil",
                SHIPPING_LABEL_PICKUP: "Recoger em armazém / Guia externo",
                SHIPPING_LABEL_EXTENDED_ZONE: {
                    SUPER_SAVER: "Zona estendida SUPER SAVER",
                    LOCAL: "Zona estendida LOCAL",
                    PREMIUM: "Zona estendida PREMIUM",
                    ECONOMY: "Zona estendida ECONOMY"
                },
                STORAGE_BY_LOCATION: {
                    LOST: "Perdido",
                    PALLET: "Palete",
                    RECEPTION: "Receção",
                    PRESALE: "Pré-venda",
                    RELOCATION: "Recolocação",
                    OFF_PREMISES: "Fora das instalações",
                    PICKING_MINIBIN: "Mini-bin",
                    PICKING_STANDARD: "Padrão",
                    DAMAGED: "Danificado"
                },
                STORAGE_BY_PRODUCT: {
                    LOST: "Perdido",
                    PALLET: "Palete",
                    RECEPTION: "Receção",
                    PRESALE: "Pré-venda",
                    RELOCATION: "Recolocação",
                    OFF_PREMISES: "Fora das instalações",
                    PICKING_MINIBIN: "Mini-bin",
                    PICKING_STANDARD: "Padrão",
                    DAMAGED: "Danificado"
                },
                INTERNATIONAL_SHIPPING_LABEL: {
                    NORTH_AMERICA: "América do Norte",
                    LATIN_AMERICA: "América Latina",
                    CARIBBEAN: "Caribe",
                    EUROPE: "Europa",
                    ASIA: "Ásia",
                    AFRICA: "África"
                },
                CLAIM: "Disputas",
                WORK_ORDER: "Maquilas",
                UNIDENTIFIED_RETURN: "Retorno não identificado",
            }
        },
        orders_detail: {
            order_number: "Número do Pedido | Canal de Vendas",
            order_destination: "Destino",
            shipping_method: "Método de Envio | Transportadora",
            pick_and_pack: "Picking e Embalagem | Conceitos",
            shipping: "Envio | Conceitos",
            total: "Total",
            no_data: "Nenhum pedido para exibir",
            footer: "pedidos",
            packages: "pacotes",
            package: "pacote",
            cubbo: "Cubbo",
            box: "caixa",
            boxes: "caixas",
            shipping_coverage_categories: {
                SP_CAP: "SP Capital",
                SP_INT: "SP Interior",
                RJ_CAP: "RJ Capital",
                RJ_INT: "RJ Interior",
                ES_CAP: "ES Capital",
                ES_INT: "ES Interior",
                MG_CAP: "MG Capital",
                MG_INT: "MG Interior",
                BA_CAP: "BA Capital",
                BA_INT: "BA Interior",
                SE_CAP: "SE Capital",
                SE_INT: "SE Interior",
                PE_CAP: "PE Capital",
                PE_INT: "PE Interior",
                AL_CAP: "AL Capital",
                AL_INT: "AL Interior",
                PB_CAP: "PB Capital",
                PB_INT: "PB Interior",
                RN_CAP: "RN Capital",
                RN_INT: "RN Interior",
                CE_CAP: "CE Capital",
                CE_INT: "CE Interior",
                PI_CAP: "PI Capital",
                PI_INT: "PI Interior",
                MA_CAP: "MA Capital",
                MA_INT: "MA Interior",
                PA_CAP: "PA Capital",
                PA_INT: "PA Interior",
                AP_CAP: "AP Capital",
                AP_INT: "AP Interior",
                AM_CAP: "AM Capital",
                AM_INT: "AM Interior",
                RR_CAP: "RR Capital",
                RR_INT: "RR Interior",
                AM_INT_2: "AM Interior 2",
                AC_CAP: "AC Capital",
                AC_INT: "AC Interior",
                DF_CAP: "DF Capital",
                DF_INT: "DF Interior",
                GO_CAP: "GO Capital",
                DF_INT_2: "DF Interior 2",
                GO_CAP_2: "GO Capital 2",
                GO_INT: "GO Interior",
                RO_CAP: "RO Capital",
                RO_INT: "RO Interior",
                TO_CAP: "TO Capital",
                TO_INT: "TO Interior",
                MT_CAP: "MT Capital",
                MT_INT: "MT Interior",
                RO_INT_2: "RO Interior 2",
                RO_INT_3: "RO Interior 3",
                MS_CAP: "MS Capital",
                MS_INT: "MS Interior",
                PR_CAP: "PR Capital",
                PR_INT: "PR Interior",
                SC_CAP: "SC Capital",
                SC_INT: "SC Interior",
                RS_CAP: "RS Capital",
                RS_INT: "RS Interior",
                ECONOMY: "Economia",
                LOCAL: "Local",
                SUPER_SAVER: "Super Saver",
                PREMIUM: "Premium",
                NONE_APPLIES: "Não se Aplica"
            },
            order_destination_categories: {
                national: "Nacional",
                international: "Internacional",
                extended_zone: "Na Zona Estendida"
            },
            picking_and_packing: {
                products_quantity: "peças separadas",
                product_quantity: "peça separada",
                inserts_quantity: "inserções adicionadas",
                insert_quantity: "inserção adicionada",
                output_control_products_quantity: "produtos com controle de saída",
                output_control_product_quantity: "produto com controle de saída",
                fragiles_quantity: "frágeis",
                fragile_quantity: "frágil"
            },
            filters: {
                order_number: "Número do pedido"
            }
        }
    }    
}