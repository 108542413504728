export const en = {
    login: {
        title: "Log in to your Account",
        detail: "Please enter the following data",
        email: "Email",
        password: "Password",
        required_field:"This field is required",
        recover_pwd: "Recover Password",
        error_message: "Invalid username/password",
        button: "Log in to your account",
        get_in: "Getting in...",
        create_account: "Create an account"
    },

    forgotPassword: {
        recover_password: "Recover your Password",
        enter_your_email: "Please enter your email and we will send you an email to recover your password",
        required_field: "This field is required",
        back_to_login: "Back to Login",
        entering: "Getting in...",
        error: "Couldn't do it!",
        check_your_email:"Check your email!",
        password_recovery: "Password Recovery",
    },

    signup: {
        title: "Create an account on Cubbo",
        subtitle: "Please enter the following information",
        country: "Country",
        brand_name: "Your brand name",
        user_name: "Your name",
        user_email: "Email",
        user_pwd: "Password",
        confirm_pwd: "Repeat Password",
        required_field:"This field is required",
        invalid_address: "Invalid address",
        pwd_characters: "Password must be 8 characters",
        pwd_no_coincide: "Passwords do not match",
        pass_strength: "Password must meet at least 3 of the following: one uppercase letter, one lowercase letter, one number and/or one special character (!@#$%^&*)",
        back_to_login: "Back to Login",
        button: "Create your account",
        creating_account: "Creating account..."
    },
    
    topMenu: {
        stores: "Stores",
        home: "Home",
        orders: "Orders",
        returns: "Returns",
        inventory: "Inventory",
        work_orders: "Work Orders",
        replenishments: "Inventory Replenishment",
        integrations: "Integrations",
        invoices: 'Invoices',
        billing: 'Billing',
        settings: "Settings",
        help: "Support",
        recover_pwd: "Recover password",
        logout: "Log out",
        spanish: "Spanish",
        portuguese: "Portuguese",
        english: "English",
    },

    stores: {
        store: "Store",
        stores: "Stores",
        products: "Products",
        orders: "Orders",
        account: "Account",
        created: "Created",
        favorite: "FAVORITE",
        go_store: "Go to store",
        status: "Status",
        enable_store: "Enable store",
        disable_store: "Disable store",
        details: "See details",
        select_as_favorite: "Make store favorite",
        default_store: "Yes",
        save: "Saved",
        save_as_favorite: "Your default store preference has been saved.",
        nothing_to_show: "No products to show.",
        store_detail: "Store Detail"
    },
    

    store_detail_container: {
        name: "Name",
        desc: "Description",
        created: "Created",
        go_to_store: "Go to store..."
    
    },
    formatted_relative_time: {
        today: "Today",
        yesterday: "Yesterday",
        tomorrow: "Tomorrow",
        date: "{{month}} {{day}}",
        from_now: "{{days}} days ago"
    },
    search_input: {
        search: "Search"
    },

    home: {
        order_stats:{
            orders: "Orders",
            inventory: "Inventory",
            returns: "Returns",
            replenishments: "Inventory replenishments",

        },
        title: "Welcome to Cubbo, {{store_name}}!",
        delayed_orders: "Delayed",
        pending_orders: "Pending to ship",
        missing_info: "Missing Information",
        returns_to_check: "Decision pending",
        returning_orders: "Returning to Cubbo",
        expected_replenishments: "Expected",
        expected_replenishments_info: "Unscheduled and scheduled replenishments that will arrive soon at Cubbo",
        processing_replenishments: "Processing",
        orders_without_stock: "Without Stock",
        products_without_stock: "Products without Stock",
        damaged_stock: "Damaged pieces",
        expired_stock: "Expired pieces",
        reserved_by_returns_stock: "Reserved by returns",
        packed_or_on_route: "Orders on route or packed",
        shipped_this_week: "Orders shipped this week",
        pending_receipts: "Pending Inventory Replenishments",
        table_id: "ID",
        table_status: "Status",
        estimated_date: "Expected Arrival Date",
        delivery_date: "Delivery Date",
        percentage: "% COMPLETED",
        connect_store: "Connect your store",
        inventory: "Register your Inventory",
        ship_pref: "Configure your Shipping Preferences",
        replenish: "Send Inventory to Cubbo",
        start_to_config: "Start setting up your account.",
        product_register: "Register all your products on Cubbo.",
        import_from_shopify: "Import from Shopify",
        import_from_shop: "We automatically bring all your products from your Shopify store.",
        import_from_excel: "Import from Excel",
        upload: "Upload a csv, xls or xlsx file with your products",
        manual_creation: "Manually Create Products",
        create_one_by_one: "Create your products one by one.",
        connect_store_to_cubbo: "Connect your store to Cubbo",
        assign_location: "The store is connected, now you must connect a location to Cubbo",
        assign_location_link: "-> ... -> Assign Location",
        connect_everything: "Connect your Shopify, WooCommerce, VTEX or if you prefer you can integrate through API.",
        connect_my_store: "Connect my Store",
        recommended_same_day: "Recommended - Same Day",
        sameday_ship: "This setting will always assign the fastest and most economical shipping method possible.",
        packs: "Treggo, 99minutos and ServiEntrega",
        custom_packs: "Custom",
        custom_config: "Choose this setting to configure each of your shipping methods your way.",
        economy: "Economy",
        economy_config: "This setting will always assign the most economical shipping method possible.",
        economy_packs: "Treggo, 99minutos and Estafeta",
        premium: "Premium",
        premium_config: "This setting will always assign the most economical shipping method possible in cities, and DHL nationwide.",
        premium_packs: "Treggo, 99minutos and DHL",
        required_store_message: "A connected store is required to configure automatic shipping methods.",
        first_replenishment: "Create your first Inventory Replenishment",
        replenishment_details: "Tell us which day we will receive your inventory and its details.",
        create_reple: "Create Replenishment",
        required_inventory_message: "*To create an inventory replenishment, you must first register your inventory.",
        min_stock: "Products with low stock",
        Go_to_Integration: "Go to Integration",
        no_pending_receipts: "There are no pending inventory replenishments.",
        coming_soon: "Coming soon.",
        Operating_Metrics: "Metrics for you",
        configuring: "Configuring...",
        select: "Select",
        metrics_inactive_description: "We know how important data is to make decisions in your business. Therefore, when we send 2000 orders per month for you, you will be able to access the metrics section for free.",
        metrics_inactive_action: "I haven't sent 2000 orders per month yet, but I would like to get this benefit"
    },

    order_stats: {
        title: "Your store's summary",
        subtitle: "Real-time data on your store's activity.",
    },

    metrics: {
        title: "Metrics",
        subtitle: "Choose a date range to view information about orders that were packed during that range",
        historics_title: "Historical data based on the selected date range",

        coming_soon: "Soon you will be able to view your metrics here. Wait for it soon!",

        generic: {
            orders: "Orders",
            orders_with_delivery_attempt: "orders with delivery attempt",
            items: "Items",
            item: "Item",
            shipments: "Shipments",
            shipped: "shipped",
            synced: "synced",
            delivered: "delivered",
            durations: {
                seconds: 'seconds',
                minute: 'minute',
                minutes: 'minutes',
                hour: 'hour',
                hours: 'hours',
                day: 'day',
                days: 'days',
                month: 'month',
                months: 'months',
            },
            on_average: "on average",
            no_data: "No data",
            of: "of",
        },
        
        packed_on_time: {
            title: "Orders packed on time",
            subtitle: "Orders that met Cubbo's promised time",
        },
        picking_accuracy: {
            title: "Orders without picking errors",
            subtitle: "Customers who received exactly what they ordered",
        },
        replenishments_on_time: {
            title: "Replenishments received on time",
            subtitle: "Inventory replenishments completed within 24 hours",
        },
        
        orders_by_sales_channel_total: {
            title: "Sales channels",
            main_column_label: "Integration",
            secondary_column_label: "Orders",
            grouped_rows_label: "Other integrations",
        },
        top_national_destinations: {
            title: "Top national destinations",
            main_column_label: "State",
            secondary_column_label: "Shipments",
            grouped_rows_label: "Other states",
            INTERNATIONAL: "International",
            view_others_label: "View other states",
            go_back_label: "Go back",
        },
        shipping_methods_total: {
            title: "Carriers used for your shipments",
            main_column_label: "Carrier",
            secondary_column_label: "Shipments",
            grouped_rows_label: "Other carriers",
        },
        stock_per_order_total: {
            title: "Pieces sold per order",
            main_column_label: "Pieces",
            secondary_column_label: "Orders",
            grouped_rows_label: "{{items}} pieces or more",
            item_quantity: "{{items}} pieces",
            item_quantity_single: "1 pieces",
        },
        
        orders_packed_on_time_historic: {
            title: "Orders packed on time",
            subtitle: "Orders that met Cubbo's promised time",
            late: "Late",
            on_time: "On time",
        },
        shipping_methods_historic: {
            title: "Carriers used for your shipments",
            subtitle: "Volume of shipments by carrier",
        },
        orders_sales_channel_historic: {
            title: "Orders by sales channel",
            subtitle: "Sales channels that generated your orders",
            created_at_cubbo: "Created at Cubbo",
        },
        stock_per_order_historic: {
            title: "Average number of pieces sold per order",
            subtitle: "Average number of items your customers buy per order",
            legend: "Pieces per order",
        },
        total_orders_historic: {
            title: "Orders shipped",
            highlighted: "{{total}} orders",
            highlighted_description: "in total",
            legend: "Orders shipped",
        },
        total_shipped_stock_historic: {
            title: "Total pieces sold",
            subtitle: "Number of items shipped by Cubbo",
            legend: "Pieces sold",
        },
        click_to_door: {
            title: "Click to door",
            subtitle: "Time from purchase to delivery",
            average: {
                text: "{{average}} hours",
                description: "on average",
            },
            by_days: {
                title: "Delivery time",
                less_than_1_day: "< 24 hours",
                day: " {{day}} day",
                days: " {{days}} days",
                main_column_label: "Delivery time",
                secondary_column_label: "Shipments",
                grouped_rows_label: "6+ days",
            },
            historic: {
                title: "Delivery time",
                day: "day",
                days: "days"
            }
        },
        click_to_packed: {
            title: "Pending to packed",
            subtitle: "Time from when the order was registered with pending status until it was packed",
            information_circle_text: "An order is registered with a pending status when it has no missing information and has available stock to start preparing",
            highlighted: "{{average}} hours",
            highlighted_description: "on average",
        },
        collected_to_door: {
            title: "Collected to door",
            subtitle: "Time from collected by carrier until delivered",
            highlighted: "{{average}} hours",
            highlighted_description: "on average",
            on_time: "On Time",
            late: "Late",
            sla_met_total_title: "Orders delivered on time",
            sla_first_attempt_met_total_title: "Orders with first delivery attempt on time",
            select: {
                all_carriers: "All carriers",
                all_states: "All destinations",
                selected_carriers: "Selected carriers:",
                selected_states: "Selected states:"
            },
        },
        sales_channel: {
            title: "Sales channels",
            subtitle: "Where your customers buy the most",
        },
        stock_sold: {
            title: "Stock sold",
            subtitle: "Total sent by Cubbo",
            total_stock_sold: {
                text: "{{total}} pieces",
                description: "in total",
            },
            stock_per_order_average: {
                text: "{{average}} pieces",
                description: "per order on average",
            },
        },
        replenishments: {
            title: "Replenishments",
            subtitle: "Total pieces entered into Cubbo",
            total_received: {
                text: "{{total}} pieces",
                description: "received",
            }
        },
        orders_delivered_on_time_by_states: {
            title: "Orders delivered on time by state",
        },
        orders_delivered_on_time_by_carrier: {
            title: "Orders delivered on time by carrier",
        },
        stuck_orders: {
            title: "Stuck orders",
            subtitle: "How long did the stuck orders spend from the purchase until the order went to the pending state",
            average_subtext: "Considering only stuck orders",
            statuses: {
                backorder: "Without stock",
                error: "Missing information",
                unpaid: "Unpaid",
                hold: "Paused",
            },
            historic: {
                title: "Shipped orders",
                error_title: "Time stuck due to lack of information",
                backorder_title: "Time stuck due to lack of stock",
                unpaid_title: "Time stuck due to lack of payment",
                hold_title: "Time stuck due being on hold",
                categories: {
                    error: "Orders stuck due to lack of information",
                    backorder: "Orders stuck due to lack of stock",
                    unpaid: "Orders stuck due to lack of payment",
                    hold: "Orders stuck due being on hold",
                    valid: "Order fulfilled without being stuck",
                }
            },
            by_reason: {
                title: "Reasons for being stuck",
                main_column_label: "Reason",
                secondary_column_label: "Orders",
            }
        }
    },

    date_picker: {
        select_placeholder: 'Select',
        relative_ranges: {
            last_7_days: 'Last 7 days',
            last_30_days: 'Last 30 days',
            last_month: 'Last month',
            last_year: 'Last year',
        },
    },
    
    paginator: {
        page: "Page",
        of: "of",
        total_elements: "total elements",
        previous: "Previous",
        next: "Next",
        per_page: " per page",
    },

    orders: {
        tracking_link_without_label: "Track order",
        title: "Order details",
        show_shipping_incidents: {
            from_date: ". On ",
            evidence_type: {
                product_images:"Detail photos of the incident",
                label_images:"Shipping label photos",
                package_images:"Package photos"
            },
            created_by: {
                RECIPIENT: "Created by your customer ",
                CUBBO: "Created by Cubbo ",
            },
            info_types:{
                important: "Important: "
            },
            toggle_description: {
                hide: "Hide ",
                show: "Show "
            },
            description_title: {
                PENDING: {
                    DELAYED: "Attached information",
                    MISSING_REFERENCES: "Shared address",
                    CANCELED: "Attached information",
                    DAMAGED: "Shared evidence",
                    MISSING_PRODUCT: "Shared evidence",
                    FALSE_DELIVERY: "Attached information",
                    CHANGE_ADDRESS: "Address modified",
                    RESCHEDULE: "Delivery date rescheduled"
                },
                IN_REVIEW: {
                    DELAYED: "Attached information",
                    MISSING_REFERENCES: "Shared address",
                    CANCELED: "Attached information",
                    DAMAGED: "Shared evidence",
                    MISSING_PRODUCT: "Shared evidence",
                    FALSE_DELIVERY: "Attached information",
                    CHANGE_ADDRESS: "Address modified",
                    RESCHEDULE: "Delivery date rescheduled"
                    },
                RESOLVED: {
                    DELAYED: "Attached information",
                    MISSING_REFERENCES: "Shared address",
                    CANCELED: "Attached information",
                    DAMAGED: "Shared evidence",
                    MISSING_PRODUCT: "Shared evidence",
                    FALSE_DELIVERY: "Attached information",
                    CHANGE_ADDRESS: "Address modified",
                    RESCHEDULE: "Delivery date rescheduled"
                },
            },
            categories:{
                DELAYED: "Incident: Delay in delivery",
                MISSING_REFERENCES: "Incident: Complete address",
                CANCELED: "Incident: Cancel delivery",
                DAMAGED: "Incident: Damaged product",
                MISSING_PRODUCT: "Incident: Missing products",
                FALSE_DELIVERY: "Incident: Incorrect delivery",
                CHANGE_ADDRESS: "Incident: Address modified",
                    RESCHEDULE: "Incident: Delivery date rescheduled"
            },
            messages:{
                info:{
                    PENDING: {
                        DELAYED: "Promise time of resolution is 48-72 hours",
                        MISSING_REFERENCES: "",
                        CANCELED: "",
                        DAMAGED: "Promise time of resolution is 48-72 hours",
                        MISSING_PRODUCT: "Promise time of resolution is 48-72 hours",
                        FALSE_DELIVERY: "The courier will initiate an investigation into this incident, which could take up to 5 days.",
                        CHANGE_ADDRESS: "",
                        RESCHEDULE: ""
                    },
                    IN_REVIEW: {
                        DELAYED: "Promise time of resolution is 48-72 hours",
                        MISSING_REFERENCES: "",
                        CANCELED: "",
                        DAMAGED: "Promise time of resolution is 48-72 hours",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "The courier will initiate an investigation into this incident, which could take up to 5 days.",
                        CHANGE_ADDRESS: "",
                        RESCHEDULE: ""
                    },
                    RESOLVED: {
                        DELAYED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        MISSING_REFERENCES: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        CANCELED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        DAMAGED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        MISSING_PRODUCT: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        FALSE_DELIVERY: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        CHANGE_ADDRESS: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        RESCHEDULE: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                    },
                },
                tips:{
                    PENDING: {
                        DELAYED: "To avoid a double delivery, we suggest you wait for the courier's response before duplicating the order",
                        MISSING_REFERENCES: "",
                        CANCELED: "You can monitor the order through the shipping status or from the tracking guide",
                        DAMAGED: "",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "To avoid a double delivery, we suggest you wait for the courier's response before duplicating the order",
                        CHANGE_ADDRESS: "",
                        RESCHEDULE: "",
                    },
                    IN_REVIEW: {
                        DELAYED: "To avoid a double delivery, we suggest waiting for a response from the carrier before duplicating the order",
                        MISSING_REFERENCES: "",
                        CANCELED: "You can monitor the order through the shipping status or from the tracking guide",
                        DAMAGED: "",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "To avoid a double delivery, we suggest waiting for a response from the carrier before duplicating the order",
                        CHANGE_ADDRESS: "",
                        RESCHEDULE: "",
                    },
                    RESOLVED: {
                        DELAYED: {
                            STOLEN: "We regret to inform you that these cases are not refundable. We suggest duplicating the order to send your customer what they ordered",
                            LOST: "We suggest initiating a dispute and duplicating the order to send your customer what they ordered",
                            NON_REFUNDABLE_DAMAGED: "The courier notified us that the order was damaged during shipping and it will not be possible to complete the delivery. Unfortunately, this case is not eligible for a refund. We suggest duplicating the order to send your customer what they ordered",
                            RETURNED: "The order has been returned to Cubbo, you will be able to view it from the returns page once we receive it",
                            DELIVERED: "We suggest confirming with your customer that they have received their purchase. Otherwise, contact Cubbo Support for assistance",
                            REFUNDABLE: "Please initiate a dispute to process the refund. We suggest duplicating the order to send your customer what they ordered"
                        },
                        MISSING_REFERENCES: {
                            STOLEN: "We regret to inform you that these cases are not refundable. We suggest duplicating the order to send your customer what they ordered",
                            LOST: "We suggest initiating a dispute and duplicating the order to send your customer what they ordered",
                            NON_REFUNDABLE_DAMAGED: "The courier notified us that the order was damaged during shipping and it will not be possible to complete the delivery. Unfortunately, this case is not eligible for a refund. We suggest duplicating the order to send your customer what they ordered",
                            RETURNED: "The order has been returned to Cubbo, you will be able to view it from the returns page once we receive it",
                            DELIVERED: "We suggest confirming with your customer that they have received their purchase. Otherwise, contact Cubbo Support for assistance",
                            REFUNDABLE: "Please initiate a dispute to process the refund. We suggest duplicating the order to send your customer what they ordered"
                        },
                        CANCELED: {
                            STOLEN: "We regret to inform you that these cases are not refundable. We suggest duplicating the order to send your customer what they ordered",
                            LOST: "We suggest initiating a dispute and duplicating the order to send your customer what they ordered",
                            NON_REFUNDABLE_DAMAGED: "The courier notified us that the order was damaged during shipping and it will not be possible to complete the delivery. Unfortunately, this case is not eligible for a refund. We suggest duplicating the order to send your customer what they ordered",
                            RETURNED: "The order has been returned to Cubbo, you will be able to view it from the returns page once we receive it",
                            DELIVERED: "We suggest confirming with your customer that they have received their purchase. Otherwise, contact Cubbo Support for assistance",
                            REFUNDABLE: "Please initiate a dispute to process the refund. We suggest duplicating the order to send your customer what they ordered"
                        },
                        DAMAGED: {
                            STOLEN: "We regret to inform you that these cases are not refundable. We suggest duplicating the order to send your customer what they ordered",
                            LOST: "We suggest initiating a dispute and duplicating the order to send your customer what they ordered",
                            NON_REFUNDABLE_DAMAGED: "The courier notified us that the order was damaged during shipping and it will not be possible to complete the delivery. Unfortunately, this case is not eligible for a refund. We suggest duplicating the order to send your customer what they ordered",
                            RETURNED: "The order has been returned to Cubbo, you will be able to view it from the returns page once we receive it",
                            DELIVERED: "We suggest confirming with your customer that they have received their purchase. Otherwise, contact Cubbo Support for assistance",
                            REFUNDABLE: "Please initiate a dispute to process the refund. We suggest duplicating the order to send your customer what they ordered"
                        },
                        MISSING_PRODUCT: {
                            STOLEN: "We regret to inform you that these cases are not refundable. We suggest duplicating the order to send your customer what they ordered",
                            LOST: "We suggest initiating a dispute and duplicating the order to send your customer what they ordered",
                            NON_REFUNDABLE_DAMAGED: "The courier notified us that the order was damaged during shipping and it will not be possible to complete the delivery. Unfortunately, this case is not eligible for a refund. We suggest duplicating the order to send your customer what they ordered",
                            RETURNED: "The order has been returned to Cubbo, you will be able to view it from the returns page once we receive it",
                            DELIVERED: "We suggest confirming with your customer that they have received their purchase. Otherwise, contact Cubbo Support for assistance",
                            REFUNDABLE: "Please initiate a dispute to process the refund. We suggest duplicating the order to send your customer what they ordered"
                        },
                        FALSE_DELIVERY: {
                            STOLEN: "We regret to inform you that these cases are not refundable. We suggest duplicating the order to send your customer what they ordered",
                            LOST: "We suggest initiating a dispute and duplicating the order to send your customer what they ordered",
                            NON_REFUNDABLE_DAMAGED: "The courier notified us that the order was damaged during shipping and it will not be possible to complete the delivery. Unfortunately, this case is not eligible for a refund. We suggest duplicating the order to send your customer what they ordered",
                            RETURNED: "The order has been returned to Cubbo, you will be able to view it from the returns page once we receive it",
                            DELIVERED: "We suggest confirming with your customer that they have received their purchase. Otherwise, contact Cubbo Support for assistance",
                            REFUNDABLE: "Please initiate a dispute to process the refund. We suggest duplicating the order to send your customer what they ordered"
                        },
                        CHANGE_ADDRESS: {
                            STOLEN: "We regret to inform you that these cases are not refundable. We suggest duplicating the order to send your customer what they ordered",
                            LOST: "We suggest initiating a dispute and duplicating the order to send your customer what they ordered",
                            NON_REFUNDABLE_DAMAGED: "The courier notified us that the order was damaged during shipping and it will not be possible to complete the delivery. Unfortunately, this case is not eligible for a refund. We suggest duplicating the order to send your customer what they ordered",
                            RETURNED: "The order has been returned to Cubbo, you will be able to view it from the returns page once we receive it",
                            DELIVERED: "We suggest confirming with your customer that they have received their purchase. Otherwise, contact Cubbo Support for assistance",
                            REFUNDABLE: "Please initiate a dispute to process the refund. We suggest duplicating the order to send your customer what they ordered"
                        },
                        RESCHEDULE: {
                            STOLEN: "We regret to inform you that these cases are not refundable. We suggest duplicating the order to send your customer what they ordered",
                            LOST: "We suggest initiating a dispute and duplicating the order to send your customer what they ordered",
                            NON_REFUNDABLE_DAMAGED: "The courier notified us that the order was damaged during shipping and it will not be possible to complete the delivery. Unfortunately, this case is not eligible for a refund. We suggest duplicating the order to send your customer what they ordered",
                            RETURNED: "The order has been returned to Cubbo, you will be able to view it from the returns page once we receive it",
                            DELIVERED: "We suggest confirming with your customer that they have received their purchase. Otherwise, contact Cubbo Support for assistance",
                            REFUNDABLE: "Please initiate a dispute to process the refund. We suggest duplicating the order to send your customer what they ordered"
                        },
                    },
                },
                important_info:{
                    PENDING: {
                        DELAYED: "",
                        MISSING_REFERENCES: "The information was shared with the carrier. However, this does not guarantee that the delivery person will receive the data before the delivery attempt, as they are already in motion",
                        CANCELED: "A request was sent to the carrier to cancel the delivery of the order. However, this does not guarantee that the order will not be delivered, as it is already in motion",
                        DAMAGED: "",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "",
                        CHANGE_ADDRESS: "The information was shared with the carrier. However, this does not guarantee that the delivery person will receive the data before the delivery attempt, as they are already in motion",
                        RESCHEDULE: "",
                    },
                    IN_REVIEW: {
                        DELAYED: "",
                        MISSING_REFERENCES: "The information was shared with the carrier. However, this does not guarantee that the delivery person will receive the data before the delivery attempt, as they are already in motion",
                        CANCELED: "A request was sent to the carrier to cancel the delivery of the order. However, this does not guarantee that the order will not be delivered, as it is already in motion",
                        DAMAGED: "",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "",
                        CHANGE_ADDRESS: "The information was shared with the carrier. However, this does not guarantee that the delivery person will receive the data before the delivery attempt, as they are already in motion",
                        RESCHEDULE: "",
                    },
                    RESOLVED: {
                        DELAYED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        MISSING_REFERENCES: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        CANCELED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        DAMAGED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        MISSING_PRODUCT: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        FALSE_DELIVERY: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        CHANGE_ADDRESS: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        RESCHEDULE: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                    },
                }
            },
            resolutions_complements: {
                LOST: {
                    DELAYED: ", lost order",
                    MISSING_REFERENCES: ", lost order",
                    CANCELED: ", lost order",
                    DAMAGED: ", lost order",
                    MISSING_PRODUCT: ", lost order",
                    FALSE_DELIVERY: ", lost order",
                    CHANGE_ADDRESS: ", lost order",
                    RESCHEDULE: ", lost order",
                },
                STOLEN: {
                    DELAYED: ", stolen order",
                    MISSING_REFERENCES: ", stolen order",
                    CANCELED: ", stolen order",
                    DAMAGED: ", stolen order",
                    MISSING_PRODUCT: ", stolen order",
                    FALSE_DELIVERY: ", stolen order",
                    CHANGE_ADDRESS: ", stolen order",
                    RESCHEDULE: ", stolen order",
                },
                NON_REFUNDABLE_DAMAGED: {
                    DELAYED: ", damaged order, not refundable",
                    MISSING_REFERENCES: ", damaged order, not refundable",
                    CANCELED: ", damaged order, not refundable",
                    DAMAGED: ", damaged order, not refundable",
                    MISSING_PRODUCT: ", damaged order, not refundable",
                    FALSE_DELIVERY: ", damaged order, not refundable",
                    CHANGE_ADDRESS: ", damaged order, not refundable",
                    RESCHEDULE: ", damaged order, not refundable",
                },
                RETURNED: {
                    DELAYED: ", returned to Cubbo",
                    MISSING_REFERENCES: ", returned to Cubbo",
                    CANCELED: ", returned to Cubbo",
                    DAMAGED: ", returned to Cubbo",
                    MISSING_PRODUCT: ", returned to Cubbo",
                    FALSE_DELIVERY: ", returned to Cubbo",
                    CHANGE_ADDRESS: ", returned to Cubbo",
                    RESCHEDULE: ", returned to Cubbo",
                },
                DELIVERED: {
                    DELAYED: ", delivered order",
                    MISSING_REFERENCES: ", delivered order",
                    CANCELED:", delivered order",
                    DAMAGED: ", delivered order",
                    MISSING_PRODUCT: ", delivered order",
                    FALSE_DELIVERY: ", delivered order",
                    CHANGE_ADDRESS: ", delivered order",
                    RESCHEDULE: ", delivered order",
                },
                REFUNDABLE: {
                    DELAYED: ", will be refunded",
                    MISSING_REFERENCES: ", will be refunded",
                    CANCELED: ", will be refunded",
                    DAMAGED: ", will be refunded",
                    MISSING_PRODUCT: ", will be refunded",
                    FALSE_DELIVERY: ", will be refunded",
                    CHANGE_ADDRESS: ", will be refunded",
                    RESCHEDULE: ", will be refunded",
                },
            },
            status: {
                PENDING: "Pending",
                IN_REVIEW: "In review",
                RESOLVED: "Resolved"
            },
        },
        orderErrors: {
            // general
            ORDER_NUMBER_NOT_UNIQUE: "The order number {order_number} already exists for another order",
            MISSING_SHIPPING_FIELD: "There is missing information in the customer's shipping address",
            MISSING_TAX_FIELD: "There is missing information in the billing data",
            // order_lines
            UNIDENTIFIED_PRODUCT: "The product with SKU '{sku}' in this order does not exist in Cubbo",
            UNIDENTIFIED_PRODUCT_SPECIFIC: "The product with SKU '{sku}' does not exist in Cubbo",
            DUPLICATE_STORE_SKU: "There is more than one product in Cubbo with the same SKU '{sku}'",
            DUPLICATE_STORE_SKU_SPECIFIC: "There is more than one product with the same SKU '{sku}' in Cubbo",
            MISSING_PRODUCT_SKU: "The integration sent products without SKU",
            MISSING_PRODUCT_SKU_SPECIFIC: "The integration has sent this product without SKU",
            // shipping info
            UNRECOGNIZED_COUNTRY: "The destination country could not be identified",
            INVALID_ZIPCODE: "The destination zip code is invalid",
            UNRECOGNIZED_CITY_OR_STATE: "The destination city or state could not be identified",
            // integration shipping method
            NO_MATCHING_INTEGRATION_SHIPPING_METHOD: "The {integration_type} shipping method named '{integration_shipping_method_name}' was not found in Cubbo",
            NO_MATCHING_INTEGRATION_SHIPPING_METHOD_NO_NAME: "{integration_type} has not provided the shipping method that should be used for this order",
            // shipping groups
            NO_SHIPPING_GROUP_ASSIGNED: "The integration's shipping method does not have a shipping configuration assigned",
            // shipping method
            MISSING_SHIPPING_METHOD: "A shipping carrier could not be assigned to the order due to missing information",
            INTERNATIONAL_SHIPPING_METHOD_EXPECTED: "The order has an international destination, but a national shipping carrier was selected",
            COD_SHIPPING_METHOD_EXPECTED: "The order is cash on delivery, but the selected shipping carrier does not support this service",
            MISSING_EXTERNAL_LABEL: "The order requires an external label, but none has been provided",
            SHIPPING_METHOD_HAS_NO_COVERAGE: "The shipping carrier does not have coverage for the destination",
            UNKNOWN_ORDER_WEIGHT: "The total weight of the order could not be determined",
            SHIPPING_COST_UNAVAILABLE: "Shipping prices for this destination could not be found",
            BLACKLISTED_ZIP_CODE: "The destination postal code currently does not have coverage from any courier service",
            MISSING_COD_VALUE: "The order is cash on delivery, but the collection value has not been provided",
            // taxing info
            BILLING_TAX_ID_WRONG_LENGTH: "The provided tax identification number is incorrect",
            INVALID_PRODUCT_INVOICING_PRICE: "The product with SKU '{sku}' has an invalid invoicing price",
            INVALID_PRODUCT_INVOICING_PRICE_SPECIFIC: "This product has an invalid invoicing price",
            INVALID_PRODUCT_NCM: "The NCM of the product with SKU '{sku}' is invalid",
            INVALID_PRODUCT_NCM_SPECIFIC: "This product does not have fiscal configuration",
            TOTAL_ORDER_VALUE_MISMATCH: "The total price of the order does not match, {channel_integration_type} reported {expected_total_price} and Cubbo calculated {calculated_total_price}, price confirmation is needed for correct invoicing",
            MELI_ORDER_NOT_READY_TO_SHIP: "Mercadolibre has not yet generated the shipping label. We will update this order as soon as it is generated.",
            INVALID_TOTAL_ORDER_VALUE: "Invalid total order value",
            NO_INVOICEABLE_PRODUCTS: "No products in this order are invoiceable",

            // DIFAL
            MISSING_DIFAL_PAYMENT_RECEIPT: "This order requires DIFAL payment receipt"
        },
        
        orderErrorsSolutions: {
            // general
            ORDER_NUMBER_NOT_UNIQUE: "Choose another number to identify this order",
            MISSING_SHIPPING_FIELD: "Edit the order and enter the missing shipping information to process the order",
            MISSING_TAX_FIELD: "Edit the order and enter the missing billing information to process the order",
            // order_lines
            UNIDENTIFIED_PRODUCT: "Create the product in Cubbo or edit the order to remove it from the order and process the order",
            UNIDENTIFIED_PRODUCT_SPECIFIC: "",
            DUPLICATE_STORE_SKU: "Contact support to identify the source of the error and solve it",
            DUPLICATE_STORE_SKU_SPECIFIC: "",
            MISSING_PRODUCT_SKU: "There may be something to modify in your integration to correct this error. Contact support if you require additional assistance",
            MISSING_PRODUCT_SKU_SPECIFIC: "Edit the order and modify the customer's address country to identify the correct destination",
            // shipping info
            UNRECOGNIZED_COUNTRY: "Edit the order and modify the customer's address country to identify the correct destination",
            INVALID_ZIPCODE: "Edit the order and modify the customer's address postal code to identify the correct destination",
            UNRECOGNIZED_CITY_OR_STATE: "Edit the order and modify the customer's address city and/or state to identify the correct destination",
            // integration shipping method
            NO_MATCHING_INTEGRATION_SHIPPING_METHOD: "Go to the integration settings and synchronize the shipping methods to identify the one assigned to this order and be able to assign a courier", // duda
            NO_MATCHING_INTEGRATION_SHIPPING_METHOD_NO_NAME: "Edit the order and assign a shipping method",
            // shipping groups
            NO_SHIPPING_GROUP_ASSIGNED: "Choose the shipping configuration of the shipping method assigned to this order", // duda
            // shipping method
            MISSING_SHIPPING_METHOD: "Edit the order and assign a carrier to ship this order", // duda
            INTERNATIONAL_SHIPPING_METHOD_EXPECTED: "Edit the order and modify the carrier assigned to this order", // duda
            COD_SHIPPING_METHOD_EXPECTED: "Edit the order and select another carrier to process the order",
            MISSING_EXTERNAL_LABEL: "Edit the order and add the necessary shipping documents",
            SHIPPING_METHOD_HAS_NO_COVERAGE: "Edit the order and select another carrier to ship this order",
            UNKNOWN_ORDER_WEIGHT: "Edit the product with SKU {sku} and add the corresponding product weight", // duda
            SHIPPING_COST_UNAVAILABLE: "Verify that the weight of the products in the order is correct, if so, contact support to help you solve the error", // duda
            BLACKLISTED_ZIP_CODE: "Contact the support team for more information",
            MISSING_COD_VALUE: "Edit the order and enter the amount to be charged for this order", // duda
            // taxing info
            BILLING_TAX_ID_WRONG_LENGTH: "Edit the order and modify the tax identification number to process the order",
            INVALID_PRODUCT_INVOICING_PRICE: "Edit the order and assign an item price to this product",
            INVALID_PRODUCT_INVOICING_PRICE_SPECIFIC: "",
            INVALID_PRODUCT_NCM: "Click on the product name to edit it and modify the assigned NCM configuration",
            INVALID_PRODUCT_NCM_SPECIFIC: "",
            TOTAL_ORDER_VALUE_MISMATCH: "Edit the order to adjust the prices according to the desired amount to be invoiced",
            MELI_ORDER_NOT_READY_TO_SHIP: "No further action is required as the update will be automatic",
            INVALID_TOTAL_ORDER_VALUE: "Order value must be more than 0, or completely discounted",
            NO_INVOICEABLE_PRODUCTS: "At least one product of the order must be invoiceable",

            // DIFAL
            MISSING_DIFAL_PAYMENT_RECEIPT: "This order will be released automatically. If after 15 minutes the order is still not pending, check the balance at Banco Útil",
        },

        addContainer: {
            no_address: "Address not provided",
            same_address: "Same shipping address",
            existing_order: "The order number already exists, please try another one",
            shipping_not_available: "The selected shipping method is not available for your zip code: ",
            unknown_error: "Unknown error",
            shipping_method: "You must select a shipping method",
            external: "External",
            shipping_market: "Mercado Envíos",
            edit_order: "Edit Order",
            create_order: "Create Order",
            keep_order: "Do you want to save the order?",
            create_orders: "Do you want to create the order?",
            shipping_address: "Edit shipping address",
            edit_invoicing: "Edit invoicing address",
            details: "Details",
            method_of_shipment: "Shipping method",
            shipping_market:"Mercado Envíos",
            order: "Order number",
            remove_products: "Products to remove",
            tooltip_order_number: "This suffix is not editable and will be added to the order number to identify it as an inventory withdrawal",
            order_number_placeholder: "Enter an identifying order number",
            customer_address: "Customer address",
            edit: "Edit",
            billing_address: "Billing address",
            products: "Products",
            only_numbers: "Quantity must always be a number.",
            no_whitespace: "Cannot contain spaces.",
            imports_products: "Import products to this order",
            imported: "Imported!",
            import: "Import Excel",
            import_orders: "Multiple orders from an excel",
            quantity: "Quantity",
            add_product: "Add product",
            is_being: "We are",
            processing: "processing",
            cannot_be_modified: "the order, the products can no longer be modified.",
            attachments: "Attachments",
            attach_document: "The selected shipping method requires shipping documents to be attached.",
            shipping_documents: "Shipping documents",
            Label: "Label",
            document: "Document",
            cancel: "Cancel",
            save: "Save",
            change_donation: "Is this order a donation?",
            donation_description: "This order will be defined as a donation",
            prices: "Prices",
            total_per_item: "Total per item: ",
            shipping_price: "Shipping price: ",
            discount_price: "Discount: ",
            total_price: "Total order price: ",
            cod: "Is this a cash on delivery order?",
            paqs_cod: "Check if the shipping company should charge upon delivery",
            total_price_cod: "Total purchase price",
            coin: "Currency",
            product_select: "Select one or more products for your order:",
            product_select_remove_stock: "Select the products you want to remove from inventory:",
            limit_products: "We have limited the quantity of products to a maximum of 1000 units per order.",
            mandatory_field: "Know the total price of your products and add the shipping price:",
            stock_status_to_remove: "Stock status to remove:",
            available_stock: "In good condition",
            damaged_stock: "Damaged",
            expired_stock: "Expired",
            add_all_available_products: "Add all products",
            add_all_expired_products: "Add all expired products",
            add_all_damaged_products: "Add all damaged products",
            all_available_added: "All available products have been added.",
            no_damaged_products_to_add: "There are no damaged products",
            no_expired_products_to_add: "There are no expired products",
            all_damaged_added: "All available damaged products have been added.",
            all_expired_added: "All available expired products have been added.",
            rem_same_condition_products: "A withdrawal request can only contain products from the same condition.",
            import_not_available: "Import is not available for this type of order",
            add_all_not_available: "Add all products is not available for this type of order",
        },
        fileFields: {
            order_number: "Order Number",
            sku:"SKU",
            quantity: "Quantity",

            unit_price: "Unit Price",
            shipping_price: "Shipping Price",
            discount_per_item: "Product discount",
            total_price: "Total Price",
            cpf_cnpj: "CPF/CNPJ",
            state_estadual: "State Registration",
    
            name: "Customer Name",
            last_name: "Last Name",
    
            shipping_email: "Email",
            shipping_phone: "Phone",
            shipping_address1: "Address",
            shipping_address2:"Address 2",
            shipping_number:"Number",
            shipping_neighborhood:"Neighborhood",
            shipping_city:"City",
            shipping_state:"State",
            shipping_zip_code:"Zip Code",
            shipping_country:"Country",
            shipping_configuration : "Shipping Configuration",
            is_cod: "Is it payment upon delivery?",
            coin: "Currency",
    
            billing_tax_regime:"Natural or Legal Person",
            order_type:"Sale or Donation",
        },
        addresView: {
            name: "Name",
            surname: "Surname",
            phone: "Phone",
            address: "Address",
            billing_tax_id: "Tax ID",
            billing_company: "Company",
            billing_tax_regime: "Tax Regime",
            billing_state_registration: "State Registration Number",
            apartment: "Apartment, Office, Neighborhood, etc. (optional)",
            billing_number: "Number",
            billing_neighborhood: "Neighborhood",
            country: "Country",
            city: "City",
            state: "State / Province",
            postal_code: "Postal Code",
            save: "Save",
            cancel:"Cancel",
            required_field: "This field is required.",
            postal_code_req_br: "Required field, minimum 8 characters, without hyphens (-)",
            postal_code_req_br_wrong: "Maximum 8 characters",
            postal_code_req_co: "This field is required, minimum 6 characters.",
            postal_code_req_co_wrong: "Maximum 8 characters",
            postal_code_req_mx: "This field is required, 5 characters are needed.",
            postal_code_req_mx_wrong: "Maximum 5 characters"
        },
    
        duplicate: {
            no_address: "Address not provided",
            same_address: "Same shipping address",
            existing_order: "Order number already exists, please try another one",
            cannot_be_duplicated: "This order cannot be duplicated as the products are not available in Cubbo",
            shipping_not_available: "The SAMEDAY shipping method is not available for your postal code",
            unknown_error: "Unknown error",
            shipping_method: "You must select a shipping method",
            create_duplicate: "Create Duplicate",
            sure_to_duplicate: "Are you sure you want to duplicate the order?",
            edit_address: "Edit shipping address",
            edit_invoicing: "Edit billing address",
            details: "Details",
            method_of_shipment: "Method of Shipment",
            order: "Order",
            customer_address: "Customer's address",
            edit: "Edit",
            billing_address: "Billing address",
            products: "Products",
            quantity: "Quantity",
            only_numbers: "Quantity must always be a number.",
            imports_products: "Import products to this order",
            imported: "Imported!",
            import: "Import",
            add_product: "Add Product",
            is_being: "We are",
            processing: "processing",
            cannot_be_modified: "the order, the products can no longer be modified.",
            cancel: "Cancel",
            save: "Save",
        },

        productTable: {
            name: "Name",
            sku: "SKU",
            quantity: "Quantity",
            price_per_item: "Price per item",
            discount_per_item: "Discount per item",
            no_products: "You haven't added any products.",
            available: "Available",
            damaged: "Damaged",
            expired: "Expired",
            total_qty: "Total units to be withdrawn:",
            total_value: "Total withdrawal value:",
            invalid_discount: "The discount must be lower than the price"
        },

        selectProductDialog: {
            name: "Name",
            sku: "SKU",
            no_added_products: "You haven't added any products.",
            cancel: "Cancel",
            no_orders_to_show: "No orders to show.",
            add: "Add",
            products: "Products"
        },

        claimContainer: {
            initiate_dispute: "Initiate Dispute",
            create_disput: "Confirm that you want to create a dispute for",
            the_order: "for the order with number",
            confirm: "Confirm",
            cancel: "Cancel",
            error: "An unexpected error occurred:",
            disputes: "You can only initiate disputes for orders that have been shipped.",
            initiate_disputes: "You can only initiate disputes up to 30 days after the order shipping date.",
            subsequent_disputes: "You can initiate subsequent disputes up to 30 days after the order shipping date.",
            order_number: "Order Number",
            dispute: "Dispute",
            products: "Value of products to be compensated",
            important: "IMPORTANT: Do not include costs related to shipping or handling of the order in this field. This field should be used exclusively to enter the cost of lost products or international guides paid by the customer to return the product. If the dispute is accepted, the shipping and handling costs related to the order will be automatically refunded, as well as any additional costs related to the generation of guides for returning or shipping missing products. Any amount entered that is not justified may result in the rejection of the dispute, even if it is legitimate.",
            compensation_testing: "Compensation Testing",
            cancel: "Cancel",
            save: "Save",
            duplicate_and_resend: "Duplicate and resend the order before the dispute is resolved.",
        },
        remove_stock:{
            create:{
                title: "Inventory withdrawal order generated!",
                subtitle: "You can find it among your orders with the number:",
                when_pickup:{
                    title: "When can I pick it up?",
                    subtitle:"As it is processed as a withdrawal order, the waiting time will depend on the volume of items to be withdrawn.",
                    information: "You can check an estimate:",
                    link: " here."
                },
                when_is_ready:{
                    title:"How will I know when the order is ready?",
                    subtitle: "When the order is in the “Shipped” status, it means that you can now go to the warehouse to pick it up." ,
                    information: "We will notify you by email when this happens. "
                },
                make_appointment:{
                    title: "Do I have to make an appointment to pick it up?",
                    subtitle: "No. Just remember that you must pick up the order during a schedule of 9 am to 4 pm with your order number and the name of your store."
                },
                send_information:{
                    title: "We sent you this information by email!"
                }
            }
        },  

        filters: {
            order_number: "Order number",
            sku: "Containing SKU",
            shipping_number: "Tracking number",
            channel_name: "Sales channel",
            carrier_name: "Shipping method",
            status: "Order status",
            shipping_status: "Shipping status",
            shipping_name: "Buyer or email",
            shipping_date: "Shipping date",
            channel_created_at: "Creation date",
            channel_name_placeholder: "Select a sales channel",
            shipping_method_placeholder: "Select a shipping method",
            shipping_status_placeholder: "Select a shipping status",
            status_placeholder: "Select an order status",
            is_delayed: "Delayed",
            order_click_and_collect: "Click and collect",
            shipping_incident: "Incident with parcel",
            shipping_incident_category_placeholder: "Any category",
            shipping_incident_status_placeholder: "Any status",
        },

        list: {
            requiring_resolution: "Requiring resolution",
            requiring_monitoring: "Requiring monitoring",
            eta: "ETA",
            eta_rescheduled: "New ETA",
            original_eta: "Original ETA",
            time_zone_info: "Local time data ({{time_zone}})",            order_number: "ORDER NUMBER",
            channel_name_items: "Sales channel | Pieces",
            item: "piece",
            items: "pieces",
            shipping_method: "Shipping method",
            status: "Order status",
            status_desc: "In Cubbo's hands",
            shipping_status: "Shipping status",
            shipping_status_desc: "In the hands of the courier",
            full_name: "Buyer",
            no_information: "No shipping status",
            no_pieces: "No products to ship",
            shipping: "SHIPPING METHOD",
            all_stores: "All stores",
            orders: "Orders",
            create_remove_stock: "Order to remove inventory",
            disputes: "Disputes",
            export_button: "Export",
            export_invoice_button: "Download",
            export_title: 'Export orders',
            shipping_date: "shipped",
            show_orders: "View order",
            duplicate_order: "Duplicate order",
            duplicate_order_action: "Duplicate",
            edit_order_action: "Edit order",
            config_shipping_method: "Configure",
            pause_order_action: "Pause",
            cancel_order_action: "Cancel",
            pay_order: "Mark as paid",
            view_return: "View return",
            edit_order: "Edit order",
            edit_order_remove_stock: "Edit order for inventory withdrawal",
            interrupt_order: "Interrupt order",
            sure_to_interrupt_shipment: "Are you sure you want to interrupt the shipment?",
            interrupt_shipment_disclaimer: "<p><strong>Important: The cancellation of the shipment is not guaranteed</strong>, as the order is already in the shipping area.</p><p>If the shipment is canceled, <strong>the Pick and Pack service will be charged</strong> and it will be re-entered as a return to your inventory.</strong></p>",
            interrupt_notes: "Return created by interruption of the shipment of the order #{{order_number}} (id: {{order_id}}). Interrupted by the user {{user_email}}.",
            cancel_order: "Cancel order",
            sure_to_cancel: "Are you sure you want to cancel the order?",
            sure_to_cancel_with_difal: "Warning: This order have an already paid DIFAL",
            pause_order: "Pause order",
            sure_to_pause: "Are you sure you want to pause the order?",
            reset_order: "Reset order",
            sure_to_reboot:" Are you sure you want to reset the order?",
            sure_to_pay: "Are you sure you want to mark the order as paid?",
            create_claim: "Start dispute",
            claim_created: 'Dispute generated!',
            create_return: "Start return",
            no_orders_to_show: "No orders to show.",
            footer_label: "orders",
            create_order: "Create order",
            create_order_remove_stock: "Inventory withdrawal order",
            create_orders: "Create orders",
            shipping_date_placeholder: {
                start: "Start",
                end: "End"
            },
            since: "Since",
            at: "On",
            few_moments: "A few moments ago",
            ago: "Ago",
            days: "days",
            day: "day",
            created_at: "Created on",
            shipped_at: "Shipped on",
            pending_at: "Pending on",
            report_delay: "Report delay",
            external_label_desc: "With external guide, track the order directly with the courier",
            export_dialog: {
                order_number: 'With order number equal or similar to: ',
                tracking_number: 'With shipping guide number equal to: ',
                channel_name: 'That were created in: ',
                status_one: 'Whose status is ',
                status_other: 'Whose status is any of: ',
                shipping_status_one: 'Whose shipping status is: ',
                shipping_status_other: 'Whose shipping status is any of: ',
                shipping_name: 'With recipient equal or similar to: ',
                shipping_date: 'That have been shipped between ',
                shipping_date_join: ' and ',
                channel_created_at: 'That have been created between ',
                channel_created_at_join: ' and ',
                shipping_method: 'With shipping method: ',
                intro: 'Orders that meet the following conditions will be exported: ',
                confirm: 'Download',
                cancel: 'Cancel',
                default_description: 'All orders from your account will be exported. If you wish, use the filters to obtain a specific report.'
            },
            extended_zone: "Extended zone",
            extended_zone_tooltip: "Orders for extended zones usually have a longer delivery time and have extra costs",
        },

        action_bar: {
            select_action: "Select an action",
            orders_selected: "Selected orders",
            orders: "Orders",
            deselect_all_orders: "Clear selection",
            actions: {
                CANCEL_ORDERS: "Cancel orders",
                HOLD_ORDERS: "Pause orders",
                UNHOLD_ORDERS: "Resume orders",
                MARK_AS_PAYED: "Mark as paid"
            },
            notification: {
                canceling_orders: "Cancelling orders",
                orders_canceled_bold: "<strong>{{canceled}} of {{total}}</strong> selected orders have been canceled",
            },
            errors: {
                codes: {
                    ORDER_CANCELED: "Already cancelled",
                    ORDER_SHIPPED: "Unable to cancel",
                    ORDER_ON_HOLD: "Already paused",
                    ORDER_CANT_BE_HOLD: "It is not possible to pause them",
                    ORDER_CANT_BE_UNHOLD: "It is not possible to resume them",
                    ORDER_CANT_MARK_PAID: "It is not possible to mark them as paid",
                    valid: {
                        CANCEL_ORDERS: "Will be cancelled",
                        HOLD_ORDERS: "Will be paused",
                        UNHOLD_ORDERS: "Will be restarted",
                        MARK_AS_PAYED: "Will be marked as paid"
                    }
                },
                error_modal: {
                    title: "Review orders",
                    confirmations: {
                        CANCEL_ORDERS: 'Are you sure you want to cancel the selected {{order_count}} orders?',
                        HOLD_ORDERS: 'Are you sure you want to pause the selected {{order_count}} orders?',
                        UNHOLD_ORDERS: 'Are you sure you want to resume the selected {{order_count}} orders?',
                        MARK_AS_PAYED: 'Are you sure you want to mark the selected {{order_count}} orders as paid?',
                        no_valid_elements: 'There are no valid orders for this action',
                        omit_errors_and_submit: "Confirm {{action}}",
                        warning_cancel_with_difal: 'Warning: Orders {{orders}} have an already paid DIFAL'
                    },
                    messages: {
                        action_not_possible: 'For {quantity_errors} out of {quantity} selected requests, it will not be possible to perform this action',
                        omit_errors_and_submit_question: 'Do you want to ignore these requests and accept the rest of the selection?',
                        order_numbers_with_errors: 'Order numbers with error',
                        no_valid_elements_tooltip: 'It was not possible to perform this action for any of the selected orders'                    },
                    action_type: {
                        CANCEL_ORDERS: "cancellation",
                        HOLD_ORDERS: "pause",
                        UNHOLD_ORDERS: "resume",
                        MARK_AS_PAYED: "payment"
                    },
                    collapse: {
                        show: "Show order numbers",
                        hide: "Hide",
                        description: "Order status does not support this action"
                    }
                }
            },
        },

        shipping_incident : {
            status : {
                PENDING: "Pending",
                IN_REVIEW: "In review",
                RESOLVED: "Resolved",
            },
            category : {
                DELAYED: "Shipping Delay",
                MISSING_REFERENCES: "Missing References",
                CANCELED: 'Cancel delivery',
                DAMAGED: 'Order damaged',
                MISSING_PRODUCT: 'Missing Products',
                FALSE_DELIVERY: 'Incorrect Delivery',
                CHANGE_ADDRESS: 'Address modified',
                RESCHEDULE: 'Delivery date rescheduled'
            },
            resolution: {
                STOLEN: 'Order was stolen',
                LOST: 'Order was lost',
                RETURNED: 'Returning to warehouse',
                DELIVERED: 'Order delivered',
                NON_REFUNDABLE_DAMAGED: 'Non refundable damaged product',
                REFUNDABLE: 'Order will be refunded'
            },
            reported_to_carrier: "Reported to carrier",
            with_shipping_incident: 'With an incident'
        },

        lineItem: {
            dropshipping: "This product is not shipped by Cubbo",
            fragile_product: "product is fragile",
            create_product: "Create Product",
            presale: "Pre-sale",
            no_stock: "Out of stock",
            not_enough_stock:"Incomplete stock",
            damaged: "Damaged",
            item_generated: "This line item has been generated by the system, if there is no stock the order will be shipped without this line item.",
            generating: "Generating",
            generated: "Generated by business rule",
            quantity: "Quantity ",
            generated_info: "This product was generated by the system, if there is no stock the order will be sent without it",
        },

        pricesContainer: {
            price_concept: "Concept",
            price_amount: "Amount to be collected",
            price_amount_desc: "Carrier must collect ",
            price_amount_desc_continuation: " when delivering this order, since the shipment type is Cash on Delivery.",
            total_items_price: "Total price for items",
            discount: "Discount",
            shipping_price: "Shipping price",
            extra_fees_price: "Additional fees",
            total_order_price: "Total order",
        },

        dateTime: {
            minute: "minute",
            minutes: "minutes",
            hour: "hour",
            hours: "hours",
            day: "day",
            days: "days",
        },

        missingDataContainer: {
            solution: "Solution: ",
            missingData: "Missing data",
        },

        orderMovementContainer: {
            orderMovement: "Order movement",
            estimatedDelivery: "ETA",
            estimatedDeliveryNotAvailable: "No information",
            estimatedDeliveryAccuracy: "Estimation based on zip code",
            incidents: "Incidents reported to the carrier",
        },

        orderHistory: {
            title: "Order history",
            status_title: "Status",
            action_by: "By",
            no_history: "No information",
            by_integration: 'integration',
            from_tracking: 'Through the tracking page',
            timelineElements: {
                initial: {
                    title: "Synchronizing",
                    titleAlternative: "Synchronized",
                },
                empty: {
                    title: "Empty order",
                    description: "The order was received from the integration without products assigned for fulfillment",
                },
                pending: {
                    title: "Pending",
                },
                reset_pending: {
                    title: "Account reactivated",
                },
                error: {
                    title: "Missing information",
                    description: "Missing data",
                    codes: {
                        // general
                        ORDER_NUMBER_NOT_UNIQUE: "Duplicate order number",
                        MISSING_SHIPPING_FIELD: "Missing data in customer's address",
                        MISSING_TAX_FIELD: "Missing information in billing data",

                        // order_lines
                        UNIDENTIFIED_PRODUCT: "Product with SKU '{sku}' does not exist in Cubbo",
                        DUPLICATE_STORE_SKU: "Duplicate SKU: '{sku}'",
                        MISSING_PRODUCT_SKU: "Products without SKU sent by integration",

                        // shipping info
                        UNRECOGNIZED_COUNTRY: "Unrecognized country",
                        INVALID_ZIPCODE: "Invalid ZIP code",
                        UNRECOGNIZED_CITY_OR_STATE: "Unrecognized city or state",

                        // integration shipping method
                        NO_MATCHING_INTEGRATION_SHIPPING_METHOD: "Assigned shipping method not found in Cubbo",
                        NO_MATCHING_INTEGRATION_SHIPPING_METHOD_NO_NAME: "No shipping method assigned",

                        // shipping groups
                        NO_SHIPPING_GROUP_ASSIGNED: "Shipping method without assigned shipping configuration",

                        // shipping method
                        MISSING_SHIPPING_METHOD: "No shipping method assigned due to missing information",
                        INTERNATIONAL_SHIPPING_METHOD_EXPECTED: "Assigned shipping method without international coverage",
                        COD_SHIPPING_METHOD_EXPECTED: "Assigned shipping method not available for cash on delivery payment",
                        MISSING_EXTERNAL_LABEL: "Missing external label",
                        SHIPPING_METHOD_HAS_NO_COVERAGE: "Shipping method without coverage",
                        UNKNOWN_ORDER_WEIGHT: "Order weight not determined",
                        SHIPPING_COST_UNAVAILABLE: "Shipping cost not available",
                        BLACKLISTED_ZIP_CODE: "Postal code temporarily without coverage",
                        MISSING_COD_VALUE: "COD value not provided",

                        // taxing info
                        BILLING_TAX_ID_WRONG_LENGTH: "Incorrect tax identification number length",
                        INVALID_PRODUCT_INVOICING_PRICE: "Invalid price for SKU: '{sku}'",
                        INVALID_PRODUCT_NCM: "Invalid NCM for SKU: '{sku}'",
                        TOTAL_ORDER_VALUE_MISMATCH: "There are differences in the total order price, {channel_integration_type} reported {expected_total_price} and Cubbo calculated {calculated_total_price}",
                        MELI_ORDER_NOT_READY_TO_SHIP: "Mercado Libre shipping label not generated",
                        INVALID_TOTAL_ORDER_VALUE: "Invalid total order value. Order value must be more than 0, or completely discounted",
                        NO_INVOICEABLE_PRODUCTS: "No products in this order are invoiceable. At least one product of the order must be invoiceable",

                        // DIFAL
                        MISSING_DIFAL_PAYMENT_RECEIPT: "Missing DIFAL payment receipt",
                        WEBMANIA_ERROR: "Error generating NF-e. Error: {error}"
                    },

                    missingInfoList: {
                        invalid_sku: 'SKU: {sku} does not exist in Cubbo',
                        sku: 'there are products without SKU',
                        phone: 'phone',
                        email: 'email',
                        name: 'customer name',
                        cp: 'invalid postal code',
                        state: 'state',
                        city: 'city',
                        address: 'address',
                    }
                },
                canceled: {
                    title: 'Canceled',
                    title_tooltip: 'See active automation from the configuration panel',
                },
                backorder: {
                    title: 'Out of stock',
                    description: "SKUs with incomplete stock: {sku}",
                    paragraph: {
                        title: 'To provide a solution to your client, you could:',
                        hide: 'Hide suggestion',
                        content:{
                            p1: {
                                title: 'Wait and restock missing piece',
                                description: 'We suggest notifying your customer of the extraordinary wait time',
                            },
                            p2: {
                                title: 'Send partial order',
                                description: 'By duplicating this order, removing the out-of-stock item, and canceling this order. Additionally, you will need to coordinate partial refund with your customer',
                            },
                            p3: {
                                title: 'Cancel and refund',
                                description: 'If restocking the missing piece or sending a partial order is not in your plans, you could cancel the order and refund the purchase to your customer',
                            },
                        }
                    }
                },
                unpaid: {
                    title: 'Unpaid',
                },
                paid: {
                    by_user: 'Marked as paid',
                },
                cancelation_window: {
                    title: 'In cancellation window',
                    description: "The order can still be canceled from the integration. The time that your orders will remain in this window depends on your integration settings",
                },
                holded: {
                    title: 'On hold',
                },
                unhold: {
                    title: 'Resumed',
                },
                interrupted: {
                    title: 'Interrupted',
                    description: "The fulfillment of the order was canceled while being prepared. We will not ship this order, but the pick and pack fee will be charged",
                },
                shipment_interrupted: {
                    title: 'Interrumpido',
                    description: "The order was canceled while being processed. If the order is stopped before shipping, the Pick and Pack service will be charged",
                },
                entirely_dropshipping: {
                    title: 'Fulfilled externally',
                    description: "Cubbo does not store the product of this order, so fulfillment of this order will not be carried out",
                },
                edit: {
                    title: 'Edited',
                },
                tracking_edit: {
                    title: 'Edited address'
                },
                duplicate: {
                    title: 'Duplicated',
                description: '{link}',
                },
                create: {
                    title: 'Created',
                },
                create_from: {
                    title: 'Created as a duplicate',
                    title_complement: 'of {link}',
                    title_complement_link: 'View configured automation'
                },
                processing: {
                    title: 'Processing',
                },
                shipped: {
                    title: 'Waiting for pickup',
                    title_pickup: 'Shipped',
                    description: "If after 36 hours the order has not been shipped, contact Cubbo Support for assistance",
                },
                shipment_collected: {
                    title: 'Shipped',
                    description: 'The order is in the hands of the shipping company',
                },
                shipment_external_label: {
                    title: 'With external label, track the order directly with the shipping company',
                },
                shipment_canceled: {
                    title: 'Delivery canceled',
                    description: 'When the order returns to Cubbo, the order status will be updated to "Return received"'
                },
                shipment_in_transit: {
                    title: 'In transit',
                },
                shipment_out_for_delivery: {
                    title: 'Out for delivery',
                    description: 'The order will be delivered in the next few hours'
                },
                shipment_with_incident: {
                    title: 'Incident',
                    title_delivery_attempt: 'Delivery attempt failed'
                },
                shipment_to_be_self_collected: {
                    title: 'Pick up at office',
                    description: 'The order is at a shipping company office waiting to be picked up by your client. For more information, contact the Support team.',
                },
                shipment_delivered: {
                    title: 'Delivered',
                },
                returning: {
                    title: 'Returning to Cubbo',
                    types: {
                        SIMPLE_RETURN: "Due to buyer return request",
                        EXCHANGE: "Due to buyer return request",
                        IMMEDIATE_EXCHANGE: "Due to buyer return request",
                        RETURNED_BY_CARRIER: "Due to unsuccessful delivery",
                        TO_BE_DETERMINED: "",
                        SHIPMENT_INTERRUPTED: "Due to interruption of the shipment",
                    },
                },
                returned: {
                    title: 'Receiving return',
                    types: {
                        SIMPLE_RETURN: "Due to buyer return request",
                        EXCHANGE: "Due to buyer return request",
                        IMMEDIATE_EXCHANGE: "Due to buyer return request",
                        RETURNED_BY_CARRIER: "Due to unsuccessful delivery",
                        TO_BE_DETERMINED: "",
                    },
                },
                reentered: {
                    title: 'Reentered',
                    types: {
                        SIMPLE_RETURN: "Due to buyer return request",
                        EXCHANGE: "Due to buyer return request",
                        IMMEDIATE_EXCHANGE: "Due to buyer return request",
                        RETURNED_BY_CARRIER: "Due to unsuccessful delivery",
                        TO_BE_DETERMINED: "",
                    },
                },
                return_arrived: {
                    title: 'Returned to Cubbo',
                    types: {
                        SIMPLE_RETURN: "Due to buyer return request",
                        EXCHANGE: "Due to buyer return request",
                        IMMEDIATE_EXCHANGE: "Due to buyer return request",
                        RETURNED_BY_CARRIER: "Due to unsuccessful delivery",
                        TO_BE_DETERMINED: "",
                    },
                },
                return: {
                    title: 'Return received',
                    by_client: 'Returned by your client',
                    by_carrier: 'Returned by the shipping company, due to unsuccessful delivery',
                },
                shipment_returned: {
                    title: 'Returned to Cubbo',
                    types: {
                        SIMPLE_RETURN: "Returned for requested refund",
                        EXCHANGE: "Returned for requested exchange",
                        IMMEDIATE_EXCHANGE: "Returned for immediate requested exchange",
                        RETURNED_BY_CARRIER: "Returned by courier",
                        TO_BE_DETERMINED: "",
                    },
                },
                claim: {
                    title: 'Dispute',
                    statuses: {
                        PENDING: 'Pending',
                        REJECTED: 'Rejected',
                        ACCEPTED: 'Accepted',
                    },
                    categories: {
                        UNWANTED_PRODUCTS: 'For sending excess product',
                        MISSING_PRODUCTS: 'For missing product',
                        WRONG_ORDER: 'For sending the wrong order',
                        PACKAGE_LOST: 'For lost order',
                        DAMAGED_PRODUCT: 'For damaged product',
                        DELIVERY_DELAY: 'For delay in delivery',
                        FALSE_DELIVERY: 'For incorrect delivery of the order',
                        PACKAGE_STOLEN: 'For stolen package',
                        DELIVERY_OTHERS: 'Others - Parcel service',
                    }
                }
            }
        },

        pod:{
            download: "Download",
            received_by: "Received by",
            title: "Proof of delivery",
            not_provided: "The carrier provides no proof of delivery",
            unavailable: "Soon you will be able to access it from this section.",
            proof: "Proof",
            download_success: "The proof of delivery was downloaded successfully",
            download_error: "An error occurred while downloading the proof of delivery, please try again later",
        },

        showContainer: {
            is_first_time_buyer: "New Buyer!",
            is_click_and_collect: "Click & Collect",
            click_and_collect_data: "Click and Collect Details",
            click_and_collect_location_name: "Pick Up Point Name ",
            kit_title: "Products that make up the kit",
            file: "File",
            file_name: "Name",
            name: "Name ",
            send_to: "Send to ",
            printing_type: "Printing type",
            actions: "Actions",
            shipping_Market: "Mercado Envíos",
            external: "External",
            configure_shipping: "Configure shipping method",
            edit: "Edit Order",
            missing_information: "There is missing information in your order, please review the data below.",
            assign_shipment: "Assign shipping methods",
            order_number: "Order number",
            cart_id: "Shopping cart",
            store: "Store",
            state: "State ",
            status:  "Order status",
            date_created: "Creation date",
            shipping_information: "Shipping information",
            shipping_pickup: "Pickup information",
            customer_address: "Customer address",
            shipping_order_information: "Order information",
            picking_order_information: "Delivery information",
            shipping_method: "Shipping method",
            type_of_shipment: "Type of shipment",
            cash_on_delivery: "Cash on delivery",
            shipment_status: "Shipment status",
            date_of_creation:"Creation date",
            no_information: "No information",
            products: "Products",
            orderProducts: "Order products",
            product_title: "Product name",
            sku_title: "SKU",
            price_title: "Price",
            lot_title: "Lot",
            imei_title: "Serial number",
            labels_title: "Labels",
            requested_product_number: "Requested pieces",
            reserved_product_number: "Pieces reserved for this order",
            available_product_number_for_sale: "Available inventory for sale",
            available_product_number_for_sale_tooltip: "Total number of pieces still available at Cubbo for sale, after subtracting what has been reserved for your orders",
            imei: "Serial number: ",
            lot: "Lot: ",
            expiration: "Expiration: ",
            product_dont_exist: "The product doesn't exist in Cubbo",
            price: "Price: ",
            sales_channel_pieces: "Sales channel | pieces",
            tracking_number: "Tracking number",
            attachments: "Attached files",
            download: "Download",
            sendTo: "Send to ",
            phone: "Phone ",
            email: "Email ",
            address: "Address ",
            number: "Number ",
            neighborhood: "Neighborhood ",
            city: "City ",
            zip_code: "Postal Code ",
            cpf: "CPF ",
            billing_info: "Billing information",
            tax_regime: "Tax regime ",
            edit_order: "Edit order",
            generate_label: "Generate label",
            tracking_number_input_label: "Tracking code for",
            channel: "Channel",
            remove_stock_type: "Remove product in good condition",
            remove_damaged_stock_type: "Remove damaged stock",
            remove_expired_stock_type: "Remove expired stock",
            here: "here",
            previous_delivery_date: "Previous delivery date",
            new_address: "New delivery address",
            pick_and_ship: "PickNShip",
        },
        create_bulk: {
            view_orders: "View Orders",
            ok: "OK",
            was_a_problem: "There was a problem!",
            zero_orders_imported: "Your orders could not be imported, please contact support and send us the sheet you tried to import.",
            thanks_for_waiting: "Thank you for your patience.",
            error_orders_reminder: "Remember that orders marked with errors in the previous step will not be imported.",
            orders_imported: "{{orders_number}} new orders have been imported to your store!",
            not_all_orders_imported: "{{imported_orders}} of {{total_orders}} expected orders have been successfully imported",
            we_are_importing: "{{orders_number}} new orders are being imported to your store!",
            orders: "ORDERS",
            imported: "IMPORTED"
        }
    },

    return: {
        container: {
            return: "Return",
            awaiting_return: "We will be waiting for this return in our warehouses.",
            change: "Exchange",
            replacement_products: "The available replacement products will be reserved. Once we receive the return, the corresponding order will be generated.",
            immediate_change: "Immediate Exchange",
            immediate_change_help: "We will generate an order immediately with the replacement products.",
            yes: "yes",
            no: "no",
            not_readable: "Error: The order is in an ineligible state for return.",
            order_number: "Order number",
            store: "Store",
            return_type: "Return type",
            return_guide: "Generate return guide?",
            generate_guide: "A return guide will be generated using",
            return_instructions: "Instructions will be provided so that the customer can return the product on their own.",
            returnees: "Returnees",
            add_another_product: "Add another product from the store",
            products: "Product",
            quantity: "Quantity",
            damaged_parts: "(Damaged Parts)",
            add_part: "Add damaged part",
            remove_product: "Remove product",
            replacement: "Replacement",
            cancel: "Cancel",
            save: "Save",
            confirm: "Confirm",
            total_return: "Confirm that a total of",
            product: "product",
            confirm_return: "Confirm Return",
            notes: "Notes",
        },
            productsDialog: {
            stock_release: "Confirm Stock Release",
            release_the_products: "You are about to release the products that were reserved to fulfill the order that will be created upon receiving the return ",
            confirmation: "Do you wish to confirm?",
            loading: "Loading",
            confirm: "Confirm",
            cancel: "Cancel",
            close: "Close",
        },

        returnedProduct: {
            name: "Name",
            no_sku: "No SKU",
            no_upc: "No UPC",
            store_inventory: "Store Inventory",
            products: "Products",
            no_products_to_show: "No products to show",
            accept: "Accept",
            cancel: "Cancel",
        }

    },
    
    claims: {
        list: {
            value: "REQUESTED VALUE",
            accepted_value: "ACCEPTED VALUE",
            created_at: "Creation Date",
            resolved_at: "Resolution Date",
            see_detail: "See detail",
            see_dispute: "See dispute",
            show_orders: "View order",
            pending: "Pending",
            rejected: "Rejected",
            Disputes:"Disputes",
            all_stores: "All stores",
        },
            detail: {
            status: "Status",
            resolution: "Resolution",
            accepted_values: "Accepted Values",
            orders_number: "Order number",
            value_of_products: "Total requested value",
            dispute: "Dispute",
            Compensation_Testing: "Compensation Testing",
        },
        errors: {
            related_unresolved_existing_claim: "This claim has already been created and is pending resolution"
        },
        create: {
            order_number: 'Regarding the order with Order Number',
            order_status: 'Order status',
            shipping_method: 'Shipping company used for the order',
            tracking_number: 'Tracking number',
            close_button: 'Close',
            save_button: 'Save',
            files_field_placeholder: 'Click here or drag your PDF files.',
            generic:{
                form_title: 'To analyze this case, we need your help with some information...',
                form_subtitle: 'Remember that the resolution of this dispute depends on the veracity of this evidence.',
                invoice_file: 'Attach the invoice in PDF with the VAT broken down of the products of the order for which a refund is required in order to request it from the courier.',
            },
            problems_list: {
                edit_selection: 'Edit selection',
                title: 'What is the problem?',
                delivery_delay: 'The order has been picked up and has not moved',
                false_delivery: 'The status of the order indicates that it was delivered, but my customer did not receive it',
                package_stolen: 'The status of the order indicates that it was stolen.',
                package_lost: 'The status of the order indicates that it was lost.',
                damaged_product: 'My customer received damaged or defective products.',
                incorrect_content: 'What my customer received does not match what was ordered',
                none_of_the_above: 'None of the above'
            },
            currency_input: {
                title: 'Indicate the value of the product to be refunded',
                help: 'IMPORTANT: Only enter the cost of the products to be refunded or international shipping paid by the customer to return the product. Any other amount entered that is not justified may result in the rejection of the dispute, even if it is legitimate.'
            },
            currency_preloaded: {
                title: 'Product sales price, obtained from integration',
                subtitle: 'An investigation will be conducted on the order status and it will be determined which of the following concepts are eligible for reimbursement:',
                processing_cost: "Order processing cost",
                shipping_labels_cost: "Shipping label cost",
                cost_return_shipping: "Return label cost",
                products_price: "Product sales price",
                concept: "Concept",
                amount: "Amount",
                without_iva: "IMPORTANT: Amount without VAT"
            },
            notes_input: {
                title: 'Notes',
                help: 'Confirm the correct delivery address for your order, additional delivery location references, and a cell phone number for the recipient.'
            },
            delivery_delay: {
                review_window: {
                    title: 'How long will it take to resolve this dispute?',
                    subtitle: 'We will file a report with the courier and wait a maximum of 72 hours for their response to resolve this dispute. We will notify you by email of the final resolution.'
                },
                refund_details: {
                    title: 'How much will I be refunded?',
                    subtitle: 'If the order is categorized as lost, the following will be refunded:',
                    refund_elements: [
                        'Cubbo\'s operating cost',
                        'Order guide cost',
                        'Stated value of the product'
                    ]
                },
                advice: {
                    title: 'What to do while the dispute is being resolved?',
                    subtitle: 'We recommend notifying your customer that the order is being investigated.',
                    text: 'Duplicate the order only if 72 hours have passed since the dispute was saved and there is still no resolution, otherwise a double delivery could occur.'
                }
            },
            false_delivery: {
                review_window: {
                    title: 'How long will it take to resolve this dispute?',
                    subtitle: 'We will raise the report with the courier and wait a maximum of 72 hours for their response to resolve this dispute. We will notify you by email of the final resolution.'
                },
                refund_details: {
                    title: 'How much will I be refunded?',
                    subtitle: 'If the order is categorized as lost, the following will be refunded:',
                    refund_elements: [
                        'Cubbo\'s operating cost',
                        'Order guide cost',
                        'Indicated product value'
                    ]
                },
                advice: {
                    title: 'What should I do while the dispute is being resolved?',
                    subtitle: 'We recommend notifying your customer that the order is being investigated.',
                    text: 'Duplicate the order only if after 72 hours of saving the dispute there is still no resolution, otherwise a double delivery could occur.'
                }
            },
            package_stolen: {
                sorry_text: {
                    title: 'We apologize for the inconvenience...',
                    help: 'Unfortunately, due to courier policies, we have a contractual agreement that these cases are not refundable. Neither the product value nor the operational cost of the order.'
                },
                advice: {
                    title: 'To provide a better experience for your customer, we suggest...',
                    help: 'Duplicate the order so that they receive the products they ordered.'
                }
                },
                package_lost: {
                    review_window: {
                        title: 'How long will it take to resolve this dispute?',
                        subtitle: 'We will raise the report with the courier and wait a maximum of 72 hours for their response to resolve this dispute. We will notify you by email of the final resolution.'
                    },
                    refund_details: {
                        title: 'How much will I be refunded?',
                        subtitle: 'If the dispute is accepted, the following will be refunded:',
                        refund_elements: [
                            'Cubbo operating cost',
                            'Order guide cost',
                            'Indicated product value'
                        ]
                    },
                    advice: {
                        title: 'What should I do while the dispute is being resolved?',
                        subtitle: 'We recommend duplicating the order as soon as possible to provide a better experience for your customer.',
                    }
            },
            form_title: 'To speed up the process, we need your help with some information...',
            form_title_preloaded: 'Amount to refund',
            duplicate_order_button: 'Duplicate order',
            new_order_button: 'Create order',
            damaged_product: {
                form_title: 'To analyze this case, we need your help with some information...',
                form_subtitle: 'Remember that the resolution of this dispute depends on the veracity of this evidence.',
                damaged_images: 'Attach a photograph of each of the products received in poor condition',
                label_image: 'Attach a clear photograph of the order guide',
                package_images: 'Attach a photograph of each of the 6 sides of the package',
                review_window: {
                    title: 'How long will it take to resolve this dispute?',
                    subtitle: 'The Cubbo team will raise the report with the courier to request a refund for the affected products. We will notify you by email of the final resolution.'
                },
                refund_details: {
                    title: 'How much will I be refunded?',
                    subtitle: 'If the dispute is accepted, the following will be refunded:',
                    refund_elements: [
                        'Cubbo operating cost',
                        'Indicated product value',
                        'Guide cost to send missing products'
                    ]
                },
                advice: {
                    title: 'What should I do while the dispute is being resolved?',
                    subtitle: 'We recommend creating a new order with the products received in poor condition to send replacements to your customer. If you duplicate the order, remember to remove any unnecessary products.',
                }
            },
            incorrect_content: {
                problems_list: {
                    title: 'Cuéntanos más...',
                    missing_products: 'Faltaron productos en el pedido',
                    unwanted_products: 'Se recibieron productos no ordenados',
                    wrong_order: 'Mi cliente recibió un pedido completo que no era el suyo'
                },
                missing_products: {
                    problems_list: {
                        title: 'Tell us more...',
                        missing_products: 'Products were missing from the order',
                        unwanted_products: 'Unordered products were received',
                        wrong_order: 'My customer received a complete order that was not theirs'
                    },
                    paid_product: {
                        form_title: 'To analyze this case, we need your help with some information...',
                        form_subtitle: 'Remember that the resolution of this dispute depends on the veracity of this evidence.',
                        label_image: 'Attach a clear photograph of the order guide',
                        package_images: 'Attach a photograph of each of the 6 sides of the package',
                        review_window: {
                            title: 'How long will it take to resolve this dispute?',
                            subtitle: 'The Cubbo team will verify if the incident occurred in our fulfillment process or in transit with the courier. We will notify you by email of the final resolution.'
                        },
                        refund_details: {
                            title: 'How much will I be refunded?',
                            subtitle: 'If the dispute is accepted, the following will be refunded:',
                            refund_elements: [
                                'Cubbo operating cost',
                                'Guide cost to send missing products'
                            ]
                        },
                        advice: {
                            title: 'What should I do while the dispute is being resolved?',
                            subtitle: 'We recommend creating a new order as soon as possible with the missing products to send to your customer. If you duplicate the order, remember to remove any unnecessary products.',
                        }
                    },
                    promotional_product: {
                        sorry_text: {
                            title: 'We apologize for the inconvenience...',
                            help: 'We cannot refund this type of case, however, we ask you to raise a ticket with this case so that we can identify the reason for this missing item. It is possible that there is something to modify in your business rules or that we no longer have any more pieces of this item.'
                        },
                    }
                },
                unwanted_products: {
                    form_title: 'To analyze this case, we need your help with some information...',
                    form_subtitle: 'Remember that the resolution of this dispute depends on the veracity of this evidence.',
                    unwanted_products_images: 'Attach a photograph of each of the products received that were not ordered.',
                    label_image: 'Attach a clear photograph of the order guide',
                    package_images: 'Attach a photograph of each of the 6 sides of the package',
                    review_window: {
                        title: 'How long will it take to resolve this dispute?',
                        subtitle: 'The Cubbo team will verify if the incident occurred in our fulfillment process or in transit with the courier. We will notify you by email of the final resolution.'
                    },
                    refund_details: {
                        title: 'How much will I be refunded?',
                        subtitle: 'If the dispute is accepted, the following will be refunded:',
                        refund_elements: [
                            'Cubbo operating cost',
                            'Guide cost to send missing products',
                            'Cost of the guide to return the incorrect product'
                        ]
                    },
                    advice: {
                        title: 'What should I do while the dispute is being resolved?',
                        subtitle: 'We recommend creating a new order with the product that was not sent in the original order. In addition, initiate the return of the product that was sent and not ordered.',
                    }
                },
                wrong_order: {
                    form_title: 'To analyze this case, we need your help with some information...',
                    form_subtitle: 'Remember that the resolution of this dispute depends on the veracity of this evidence.',
                    unwanted_products_images: 'Attach a photograph of each of the products received that were not ordered.',
                    label_image: 'Attach a clear photograph of the order guide',
                    package_images: 'Attach a photograph of each of the 6 sides of the package',
                    official_id_images: 'Attach a photograph of the official ID of the person who received the order',
                    review_window: {
                        title: 'How long will it take to resolve this dispute?',
                        subtitle: 'The Cubbo team will verify if the incident occurred in our fulfillment process or in transit with the courier. We will notify you by email of the final resolution.'
                    },
                    refund_details: {
                        title: 'How much will I be refunded?',
                        subtitle: 'If the dispute is accepted, the following will be refunded:',
                        refund_elements: [
                            'Cubbo operating cost',
                            'Guide cost to send missing products',
                            'Cost of the guide to return the incorrect product'
                        ]
                    },
                    advice: {
                        title: 'What should I do while the dispute is being resolved?',
                        subtitle: 'We recommend duplicating the order as soon as possible to provide a better experience for your customer. ',
                    }
                }

            },
            none_of_the_above: {
                sorry_text: {
                    title: 'We apologize for the inconvenience...',
                    help: 'Please help us by raising a ticket so that someone from our Customer Success team can personally address this case and assist you.'
                },
                add_ticket_button: 'Raise ticket'
            },
            product_selector_table: {
                products_prices: "Product Prices",
                all_products: "All Products",
                some_products: "Some Products",
                products_to_reimburse: "Products to Reimburse:",
                amount_to_reimburse: "Amount of Products to Reimburse:",
                table: {
                    filter_placeholder: "Search by name or SKU",
                    headers: {
                        name: "Name",
                        sku: "SKU",
                        price: "Price",
                        sent: "Sent",
                        reimbursed: "Reimbursed"
                    }
                }
            }
        }
    },

    integrations_list: {
        active: {
            configuring: "Configuring",
            configure: "Configure",
            active: "Active",
            state: "state",
            error: "Error - incorrect credentials or permissions",
            deleted: "Disabled",
            shipping_methods: "Shipping Methods",
            assign_a_location:"Assign Location",
            finance: "Finance",
            authorize_app_shopee_products: "Authorize the app to sync products from Shopee",
            authorize_app_shopee_finance: "Authorize the app to sync financial information from Shopee",
            import_vtex_products: "Import Products",
            products_sync: "Sync Inventory",
            updated_shipping_methods: "Updated Shipping Methods",
            updated_inventory: "Inventory Updated",
            all_stores: "All Stores",
            assign_location: "Assign Location",
            no_integration_to_show: "No integrations to show.",
            Sync_shipping_methods: "Sync Shipping Methods",
            integration: "Integrations",
            create_integration: "Create Integration",
            pause_sync: "Pause Automatic Sync",
            activate_sync: "Activate Automatic Sync",
            location: "Location",
            sync_activated: "Automatic Sync Activated",
            sync_paused: "Automatic Sync Paused",
            token: "Credentials",
            generate_new_token: "Generate new credentials"
        },

        empty_list: {
            first_integration: "You haven't created your first integration yet",
            select_preferred_integration: "Select your preferred sales channel to connect it with Cubbo"
        },

        shipping: {
            Integration_Delivery_Method: "Integration Delivery Method",
            Type_shipping_method: "Type of Shipping Method",
            Add_Priority_Shipping_Methods: "Add Priority Shipping Methods",
            shipping_method_cubbo: "Cubbo Shipping Method",
            type: "Type",
            option: "Option",
            all_stores: "All Stores",
            Save_Configuration: "Save Configuration",
            Add_Shipping_Method: "Add Shipping Method",
            methods_of_shipment: "Shipping Methods",
            add_new_method: "Add a New Shipping Method",
            no_shipping_methods: "There are no shipping methods in this integration.",
            Sync_shipping_methods: "Sync your shipping methods in the integrations list",
            add_option: "Add Option",
            select_type: "Select Type",
            add_priority: "Add Priority:",
            national: "National",
            international: "International",
            cash_on_delivery: "Cash on Delivery",
            priority_to_shipping_method:"Assign Priorities to Shipping Method",
            integration_shipping_method_prefix: "Integration Shipping Method",
            shipping_group: "Shipping Configuration",
            integration_shipping_types: {
                national: {
                    label: 'National',
                    tip: "Nationwide shipping"
                },
                international: {
                    label: 'International',
                    tip: "Worldwide shipping"
                },
                cash_on_delivery: {
                    label: 'Cash on Delivery',
                    tip: "National shipping paid during delivery"
                },
            },
            success_sync_integration_shipping_methods: "Your integration's shipping methods have been synchronized with Cubbo.",
            error_sync_integration_shipping_methods: "An error occurred while trying to synchronize your integration's shipping methods with Cubbo.",
            assign_shipping_group: "Assign Shipping Configuration",
            unassign_shipping_group: "Unassign Shipping Configuration",
            column_help: {
                integration_shipping_method: "This is the name of your shipping method in your integration",
                shipping_type: "Defines how this shipping method will be handled",
                shipping_group: "Choose the carriers that can be used when shipping orders with this shipping method"
            },
            success_update_shipping_type: "The shipping method has been updated.",
            error_update_shipping_type: "An error occurred while trying to update your shipping method.",
            missing_shipping_group_tip: "This shipping method has no configuration, orders will not be shipped and will be in 'Missing Information' status",
            return_to_integrations: "Return",
            empty_shipping_methods: "There are no shipping methods",
            empty_shipping_methods_create: "create a shipping method for your API",
            empty_shipping_methods_sync: "sync your integration's shipping methods",
        },

        shopify: {
            add_Barcode_in_Shopify: "Missing Barcode in Shopify",
            SKU_missing_in_Shopify: "Missing SKU in Shopify",
            missing: "Missing ",
            import: "Import",
            products: "products",
            barcodes_in_shopify: " barcodes in Shopify",
            imported: "Imported",
            no_matter: "Not imported",
            barcode: "Barcode",
            Updated_Inventory: "Inventory Updated",
            Error_importing: "Error Importing",
            Import_Products: "Import Products",
            notice: "Notice",
            yes: "Yes",
            SKU_or_UPC_only: "To avoid operational issues, Cubbo cannot import products that do not have SKU and UPC/EAN code configured in Shopify.",
            add_SKU_or_UPC: "You can add SKU or UPC by clicking on this link",
            import_excel_via_email: "or if you prefer, you can import products in excel by sending us an email to integraciones@cubbo.com",
        },

        vtex: {
            image: "Image",
            name: "Name",
            imported: "Imported",
            no_matter: "Not imported",

            status: "Status",
            Updated_Inventory: "Updated Inventory",
            Error_importing: "Error importing",
            import_of_products: "Importing products, this may take several seconds to complete.",
            error: "Error",
            import: "Import",
            products: "products",
            yes: "Yes",
            import_all_products: "Import All Products",
            import_missing_products: "Import All Missing Products",
            import_shortage_products: "Import Shortage Products",
            import_all: "Import All",
            import_products: "Import Products",
            import_selection: "Import Selection",
            no_import: "Do Not Import",
            preparing_import: "Preparing import...",
            notice: "Notice",
            sku_or_upc_only: "To avoid operational issues, Cubbo cannot import products that do not have SKU and UPC/EAN code configured in VTEX. Due to restrictions of the VTEX integration, we cannot show you in this table if you have the codes configured or not. Please ensure that you have them.",
            import_excel_via_email: "Alternatively, you can import your products via Excel by sending an email to integraciones@cubbo.com",
            nothing_to_show: "There are no products to show.",
        }
    },
    
    invoicing_list: {
        key: "Access Key",
        operation: "Operation",
        adjustment: "Adjustment",
        print: "Print",
        no_files: "No attached file",
        invoices: "Invoices",
        invoices_message: "Invoices generated for the inventory replenishment",
        operation_nature: "Operation nature",
        not_invoices: "No invoices to display"

     },
    shipping_groups: {
        priorities: {
            CHEAPEST: "Choose the best price",
            QUALITY: "Choose the fastest delivery at the lowest price",
        },
        fallback: "Fallback",
        fallback_tip: "In case of not being able to obtain prices or not having coverage for the previous shipping methods, this one will always be used as a fallback.",
        without_fallback: "Without fallback",
        without_fallback_warning: "Without fallback: orders without coverage will not be shipped and will be in 'Missing Information' status",
        incompatible_shipping_type: "Some of the shipping methods in your configuration will be skipped when choosing the shipping for your orders since they are not compatible with the shipping method type of your integration.",
        all_incompatible_shipping_methods: "None of the shipping methods in your configuration are compatible with the shipping type of the integration, it is recommended to choose another configuration.",
        allows_extended_zones: {
            yes: "Allow extended zones",
            no: "Do not allow extended zones",
            tip: "Deliveries in remote areas may incur an additional charge. This option indicates whether to use this coverage for your orders, the additional cost will be considered when selecting the best carrier for your orders."
        },
        allows_pobox_delivery: {
            yes: "Allow delivery to pick-up points",
            no: "Do not allow delivery to pick-up points",
            tip: "Some deliveries in remote areas cannot be delivered to the exact address and must be picked up at a nearby location. This option indicates whether to use this coverage for your orders."
        }
    },

    shipping_labels: {
        shipping_labels: "Shipping Labels",
        generated_labels: "Generated Labels",
        label_types: {
            Shipping: "Shipping Label",
            Return: "Return Label"
        }
    },

    cnabs: {
        status: {
            WITHOUT_PAYMENT_RETURN: "Waiting payment return file",
            WITH_PAYMENT_RETURN: "Paid"
        },
        footer: "CNABs",
        showContainer: {
            title: "View CNAB",
            cnab_information: "CNAB information",
            reference_id: 'ID',
            total_amount: "Amount",
            num_of_items: "Included orders",
            created_at: "Creation date",
            payment_bill: "Files",
            document_type: "Type",
            replenishment_type: "Arquivo remessa",
            return_type: "Arquivo retorno",
            payment_receipt: "Payment receipt",
            payment_receipt_description: "Upload the return file generated by the bank here"
        }
    },

    invoices: {
        invoices: "Invoices",
        footer_invoice_name: "invoices",
        generated_invoices: "Generated Invoices",
        list: {
            from_orders: {
                tab_name: 'From orders',
                table_title: 'Order invoices',
                columns: {
                    order_number: 'Order #',
                    billing_name: 'Customer',
                    billing_email: 'Email',
                }
            },
            from_replenishments: {
                tab_name: 'From inventory replenishments',
                table_title: 'Inventory replenishment invoices',
                columns: {
                    replenishment_id: 'Replenishment ID'
                }
            },
            from_returns: {
                tab_name: 'From returns',
                table_title: 'Return invoices',
                columns: {
                    order_number: 'Order Number',
                    billing_name: 'Customer',
                    billing_email: 'Email',
                    return_id: 'Return ID',
                }
            },
            from_cnabs: {
                tab_name: 'CNAB',
                table_title: 'DIFALs invoices',
                columns: {
                    reference_id: 'ID',
                    reference_filename: "Name",
                    num_of_items: "Number of orders",
                    total_amount: "Amount"
                }
            },
            created_at: 'Issuance date',
            creation_date_placeholder: {
                start: 'Start',
                end: 'End'
            },
            type: 'Operation type',
            attachments: 'Attachments',
            noData: 'No invoices to display',
            serial_number: "Number",
            key: 'Access key',
            status: 'Status',
            filter_descriptor: {
                order_number: {
                    description: 'With order number'
                },
                returned_order_number: {
                    description: 'With order number'
                },
                operation_type: {
                    description: 'Of type'
                },
                created_at: {
                    descriptionValue: '{{from}} - {{to}}',
                    description: 'With issuance date'
                },
                all_from_orders: {
                    description: 'Invoices from',
                    descriptionValue: 'All NFe from orders'
                },
                all_from_replenishments: {
                    description: 'Invoices from',
                    descriptionValue: 'All NFe from replenishments'
                },
                all_warning: 'Remember that you can use the table filters to download specific NFe.',
                key: {
                    description: 'With key'
                },
                id: {
                    description: 'With replenishment id'
                },
                purposes: {
                    description: 'With invoice purpose'
                },
                status: {
                    description: 'With status'
                },
                series_and_serial_number: {
                    description: 'With number'
                },
            }
        },
        process_types: {
            sell: 'Sale',
            donation: 'Donation',
            symbolic_return: 'Symbolic Return',
            remove_stock: 'Inventory removal',
            inventory_replenishment: 'Temporary Deposit Invoice',
            replenishment_adjustment_addition: 'Temporary Deposit Invoice - Addition Adjustment',
            replenishment_adjustment_removal: 'Temporary Deposit Return'
        },
        operation_types: {
            entry: 'Entry',
            egress: 'Egress'
        },
        status_types: {
            approved: 'Active',
            cancelled: 'Cancelled'
        },
        filters: {
            order_number: 'Order number',
            status: 'Invoice status',
            key: 'Access key',
            operation_type: 'Operation type',
            purpose: 'Operation type',
            created_at: 'Created at',
            serial_number: "Number",
        }
    },

    shipping_methods: {
        delivery_type: 'Delivery',
        shipping_type: 'Type',
        services: 'Services',
        carrier: 'Carrier',
        delivery_types: {
        SameDay: 'Same Day',
        NextDay: 'Next Day',
        Ground: 'Ground',
        Pickup: 'Pickup',
        International: 'International',
        },
        shipping_types: {
        national: 'National',
        international: 'International',
        pickup: 'Pickup',
        },
        incompatible_shipping_type: "This shipping method is not compatible with your integration's shipping method. It will be skipped during the selection process."
    },

    files: {
        open: "Open",
        print: "Print"
    },
    
    async_task_notification: {
        working: 'Preparing download...',
        done: 'File generated',
        download: 'Download'
    },
    
    export_overview: {
        intro: 'You are downloading',
        export_descriptions: {
            INVOICES: {
                SALE_INVOICE: {
                    title: 'NFs - Product Sale'
                },
                DONATION_INVOICE: {
                    title: 'NFs - Product Donation'
                },
                SYMBOLIC_RETURN_INVOICE: {
                    title: 'NFs - Symbolic Return of Temporary Deposit'
                },
                REMOVE_STOCK_INVOICE: {
                    title: 'NFs - Inventory Withdrawal'
                },
                REPLENISHMENT_INVOICE: {
                    title: 'NFs - Temporary Deposit'
                },
                REPLENISHMENT_ADJUSTMENT_ADDITION: {
                    title: 'NFs - Temporary Deposit - Adjustment',
                    subtitle: 'When we receive an extra item in the inventory replenishment.'
                },
                REPLENISHMENT_ADJUSTMENT_INVOICE: {
                    title: 'NFs - Return of temporary deposit',
                    subtitle: 'When an item is missing in the inventory replenishment.'
                }
            }
        },
        format: 'Format',
        type: 'Type',
        confirm_button: 'Confirm',
        cancel_button: 'Cancel'
    },

    integrations: {
        select_view: {
            Select_store_location: "Select the default location of your store for Cubbo.",
            No_locations_toassign: "There are no locations to assign for this integration.",
            select: "Select",
            cancel: "Cancel",
            save: "Save",
            only_read_location_orders: "Do you want Cubbo to only work on orders with this location assigned?"
        },
    
        shipping_view: {
            Integration: "Integration",
            No_shipping_methods: "There are no shipping methods in this integration.",
            Sync_shipping_methods: "(Sync your shipping methods in the integration list)",
            international: "International:",
            Assign_by_priority: "Assign by priority:",
            Shipping_method: "Shipping method",
            priorities_shipment_method: "Assign priorities for this shipping method",
            option: "Option",
            add_option: "Add Option",
            cancel: "Cancel",
            save: "Save",
            loading: "Loading",
        },
    
        shopify_public: {
            install_shopify: "Install Shopify",
            install_Cubbo_in_Shopify: "Go to the Shopify app store and install Cubbo from there",
            Existing_sales_channel: "Sales channel already exists",
            In_construction: "Under construction: Waiting for the Shopify app store link",
        },
    
        show_available: {
            Integrations: "Integrations",
            import_ecommerce: "Everything you need to integrate your e-commerce",
            select_channel: "Select the sales channel to integrate",
        }
    },

    integration: {
        meli: {
            existent_chanel: "There is already an integration for this store.",
            Error_creating_integration: "Error creating integration.",
            integrate_meli: "Integrate Mercado Libre",
            authorize_cubbo: "We will redirect you to Mercado Libre to authorize the Cubbo App.",
            go_to_meli: "Go to Mercado Libre"
        },
        shopify: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Make sure the data is correct.",
            Configure_Shopify: "Configure Shopify",
            Create_private_app_Shopify: "Create a private app in Shopify and insert the credentials here.",
            integrate_shopify: "Integrate Shopify",
            company_website: "yourdomain.shopify.com",
        },
    
        ventiapp: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Make sure the data is correct.",
            Integrate_your_store: "Integrate your Ventiapp store",
            enter_credentials: "Enter your VentiApp platform credentials here",
            integrate_ventiapp: "Integrate Ventiapp",
        },
        
        vtex: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Make sure the data is correct.",
            Integrate_your_store: "Integrate your Vtex store",
            account_management: "In your VTEX store, visit Account Management > Account > Generate Access Key and Secret",
            store_name: "Name of your store (App Name)",
            integrate_vtex: "Integrate Vtex",
            company_website: "eg: toysmx",
            is_seller: "Is your VTEX account of Seller type?"
        },
        amazon: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Make sure the data is correct.",
            Integrate_your_store: "Integrate your Amazon store",
            store_name: "Name of your Amazon store",
            integrate_amazon: "Integrate Amazon",
            refresh_token: "Token"
        },
    
        liverpool: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Make sure the data is correct.",
            Integrate_your_store: "Integrate your Liverpool account",
            account_management: "You can find instructions for this integration on this",
            link: "link",
            integrate: "Integrate Liverpool"
        },
        coppel: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Make sure the data is correct.",
            Integrate_your_store: "Integrate your Coppel account",
            account_management: "You can find instructions for this integration on this",
            link: "link",
            integrate: "Integrate Coppel"
        },
        woocommerce: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Make sure the data is correct.",
            Integrate_your_store: "Integrate your Woocommerce store",
            integrate_woocommerce: "Integrate Woocommerce",
            add_woocommerce: "Integrate Woocommerce with API keys",
            your_brand: "yourbrand.com",
        },

        shein: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Make sure the data is correct.",
            Integrate_your_store: "Integrate your Shein store",
            integrate_shein: "Integrate Shein"
        },

        f1commerce: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Check that the data is correct.",
            Integrate_your_store: "Integrate your F1commerce store",
            integrate_f1commerce: "Integrate F1commerce",
            your_brand: "yourbrand.com",
            codigo_chave: "Service key"
        },


        t1paginas: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Check that the data is correct.",
            Integrate_your_store: "Integrate your T1Páginas store",
            integrate_t1paginas: "Integrate T1Páginas",
            your_brand: "yourbrand.com",
            codigo_chave: "Access key"
        },
    
        tiendanube: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Check that the data is correct.",
            Integrate_your_store: "Integrate your Tienda Nube store",
            integrate: "Integrate Tienda Nube",
            your_brand: "yourbrand.com",
        },
    
        bling: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Check that the data is correct.",
            Integrate_your_store: "Integrate your Bling",
            enter_credentials: "Enter your Bling platform credentials here",
            integrate: "Integrate Bling",
            store_name: "Your store name",
            codigo_chave: "API key"
        },
    
        walmart: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Check that the data is correct.",
            Integrate_your_store: "Integrate your Walmart store",
            store_name: "Your store name",
            company_website: "eg: toysmx",
            integrate_walmart: "Integrate Walmart",
        },
        vnda: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Check that the data is correct.",
            Integrate_your_store: "Integrate your Vnda store",
            integrate_vnda: "Integrate Vnda",
            company_website: "domain.vnda.com.br",
        },
        stripe: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Check that the data is correct.",
            account_management: "Na sua loja Stripe Corp, acesse Developers > API keys > Secret password",
            Integrate_your_store: "Integrate your stripe store",
            integrate_stripe: "Integrate Stripe",
            store_name: "Your store name",
        },
        shopee: {
            Existing_sales_channel: "Sales channel already exists",
            Error_creating_integration: "Error creating integration. Make sure the data is correct.",
            Integrate_your_store: "Integrate your Shopee store",
            integrate_shopee: "Integrate Shopee"
        },
    },

    addProduct: {
        optionRow: {
            option: "Option",
            size: "Ex. Size",
            required_field: "This field is required",
            delete: "Delete",
            press_enter: "Type and press enter",
        },
    
        container: {
            barCode: "Barcode",
            required_field: "This field is required",
            required_field_sku: "This field is required, click Enter when finished",
            type_sth: "Type something and press enter",
            variants: "Variants",
            without_variants: "A promotional insert cannot have variants.",
            multiple_options: "This Product has multiple options, such as different sizes or colors.",
            operational_errors: "To avoid operational errors, it is not possible to edit a kit. If you want to make any changes, we suggest creating a new kit.",
            Dropshipping: "Is this product managed by a third party? (Dropshipping)",
            packageless_title: "Is this product already pre-packaged?",
            packageless_description: "Enable this feature if the product does not require packaging for shipping. The label will be attached to the outside of the product.",
            important: "Important",
            has_imei_title: "Does this product require output control?",
            has_imei_description: "Enable this feature if scanning the serial number is required before shipping the product.",
            has_imei_warning: "The product must be labeled with the serial number to scan it during packaging. Otherwise, it cannot be shipped.",
            unmanaged: "Mark if your product will not be managed by Cubbo.",
            fragile_product: "Is this product fragile?",
            special_care: "Mark if your product requires special care",
            promotional_product: "Is this product a promotional insert?",
            no_barcode_scanning: "Mark if your product is a promotional insert that cannot be scanned using a barcode scanner.",
            drag_image: "Click here or drag an image.",
            required_image: "If your product is not scannable, the product image is required.",
            product_image: "Product Image",
            Save_product: "Save product",
            add_product: "Do you want to add the product",
            name: "Name",
            price: "Product price",
            weight: "Weight (KG)",
            billing_name: "International product description",
            Description: "Description",
            Add_attribute: "Add attribute",
            Add_variant: "Add variant",
            products: "Products",
            Add_new_product: "Add product",
            Cancel: "Cancel",
            Save: "Save",
            ncm_code: "NCM code",
            no_supported: "We do not support this product.",
            total: "Total:",
            product_selected: "Select two or more products for your kit:",
            product_has_expiration: "Requires lot control",
            product_expiration: "Mark if your product requires expiration lot handling",
            freshness: "Days of freshness before expiration",
            buffer_stock: "Cushion",
            buffer_stock_description: "Reserve available stock units to reduce the probability of overselling",
            add_ncm: "Add NCM to store",
            no_exist_ncm: "You have not registered this NCM in your store yet. Make sure the numbers are correct and configure it to activate it.",
            no_change_product: "It is not possible to turn a Fiscal Stock item into a promotional item."

        },
    
        kitsTable: {
            quantity: "Quantity",
            sku: "SKU",
            price_per_item: "Price per item",
            no_products_added: "You have not added any products.",
            name: "Name",
        },
    
        readTable: {
            Loading_products: "Loading products",
        },
    
        variantRow: {
            variant_name: "Variant name",
            example: "ex. (M-Red)",
            required_field: "This field is required",
            delete: "Delete",
            barCode: "Barcode",
            type_sth: "Type something and press enter..."
        }
    },

    inventory: {
        listProducts: {
            image: "Image",
            name: "Name",
            stock: "In stock",
            available: "Available for sale",
            unavailable: "Unavailable",
            by_returns: "By returns",
            by_buffer: "By buffer",
            buffer_tip: {
                text: "This buffer serves to avoid overselling, the pieces are in the warehouse but are not listed as available.",
                buffer_tip_link: 'See more information.',
                buffer_tip_url: 'https://cubbohelp.zendesk.com/hc/es/articles/10621327059597-Inventario-Colch%C3%B3n',
            },
            presale: "Presale",
            orders: "Reserved by orders",
            damaged: "Damaged",
            holded: "Paused",
            expired: "Expired",
            status: "Status",
            active: "Active",
            for_selling: "For Sale",
            shipped: "Shipped",
            fragile: "Fragile",
            dropshipping: "Dropshipping",
            packageless: "Pre-packaged",
            has_imei: "Exit control",
            kit: "Kit",
            promoInsert: "Insert",
            edit_product: "Edit product",
            inventory: "Inventory",
            all_stores: "All stores",
            name: "Name",
            description: "Description",
            name_invoices: "Name on invoices",
            import_products: "Import products to this store",
            import: "Import Excel",
            download_inventory: "Download Inventory",
            download_previous_inventory: "Inventory history",
            download_inventory_success: "CSV Export Complete",
            download_inventory_error: "An error occurred, please try again later",
            download_inventory_select_date: "Select the day you wish to view the inventory for",
            download_inventory_cancel_button: "Cancel",
            download_inventory_download_button: "Download",
            no_previous_inventory: "There is still no inventory history, try again tomorrow",
            select_inventory_date: "Select the date you wish to consult",
            create_product: "Create Product",
            create_products: "Create Products",
            create_kit: "Create Kit",
            no_products_to_show: "There are no products to show.",
            see_kit: "See Kit",
            imported: "Imported!",
            confirm: "Confirm",
            delete_product: "Delete product",
            sure_to_delete: "Are you sure you want to delete ",
            product_is_part_of_kit: "The product is part of a kit",
            product_has_stock: "The product still has stock",
            product_used_in_current_orders: "Some order with this product is still being processed. Try again later.",
            products: "Products",
            inWarehouse: "in warehouse",
            inPresale: "in presale",
            inReturns: "in returns",
            holdedLot: "Paused lot",
            expiredLot: "Expired lot",
            byHoldedLot: "By paused lot",
            byExpiredLot: "By expired lot",
            updated_price: "Commas were removed",
            duplicated_bar_code: "This {{codigo}} corresponds to the UPC of a product already created in your store",
            duplicated_sku: 'This {{codigo}} corresponds to the SKU of a product already created in your store',
            price_error: 'Only numbers, maximum 2 decimal places.',
            weight_error: 'Only numbers, maximum 10 decimal places.'
        },
        create_bulk: {
            view_inventory: "View Inventory",
            ok: "OK",
            was_a_problem: "There was a problem!",
            zero_imported: "Your products could not be imported, contact support and send us the sheet you tried to import.",
            thanks_for_waiting: "Thanks for waiting.",
            error_reminder: "Remember that products marked with an error in the previous step will not be imported.",
            products_imported: "{{products_count}} new products have been imported to your store!",
            not_all_imported: "{{products_count}} out of {{total}} planned products have been imported",
            we_are_importing: "We are importing {{total_products}} new products to your store!",
            products: "PRODUTS",
            imported: "IMPORTED"
        }
    },

    onboardings: {
        select_store: "Select the store where you want to perform the installation:",
        cancel_installation: "Cancel Installation"
    
    },
    
    inputPlaceholder: {
        pdfFiles: "Click here or drag your PDF files.",
        addImage: "Click here or drag images.",
        select: "Select",
        required_field: "This field is required"
    },
    
    currencyInput: {
        max: "Maximum",
        dec: "Decimal places",
        integer_digits: "integer digits.",
        minimum_value: "Minimum value",
        maximum_value: "Maximum value"
    },
    
    confirmDialog: {
        loading: "Loading"
    },
    
    fileInputField: {
        not_pdf: "The file is not a PDF",
        max_file: "The file is too big. Maximum",
        mb: "MB",
        min_file: "The file is too small. Minimum ",
        max_images: "You can only add up to",
        max_images_text: "images"
    },
    
    imageInputField: {
        not_an_image: "The file is not an image. PNG/JPG/JPEG",
        max_image: "The image is too big. Maximum",
        mb: "MB.",
        min_image: "The image is too small. Minimum",
        max_images: "You can only add up to",
        max_images_text: "images"
    },

    replenishments: {
        addContainer: {
            yes: "Yes",
            no: "No",
            product_not_found: "Product not found in replenishment products",
            cancel_receipts: "Your inventory replenishment is in presale, it is not possible to edit it. If you need to make modifications, we suggest canceling this receipt and creating a new one.",
            closing: "CLOSING",
            closing2: "CLOSING 2",
            receipt: "Save inventory replenishment",
            keep_receipt: "Do you want to keep the inventory replenishment?",
            activate_presale: "Do you want to activate presale for this inventory?",
            products: "Products",
            quantity: "Quantity",
            only_number: "Quantity has to always be a number.",
            imports_products: "Import products and quantities to this replenishment",
            imported: "Imported!",
            import: "Import Excel",
            add_product: "Add product",
            arrival_date: "Arrival date",
            declared_value: "Total declared value",
            cancel: "Cancel",
            save: "Save",
            schedule_an_appointment: "Schedule an Appointment",
            can_not_edit_big_replenishments: "We do not allow editing replenishments with more than 1000 SKUs. We recommend canceling the replenishment and reloading it again.",
            view_replenishment_products: "View products",
            x_number_of_products_imported: "{{quantity}} products were imported.",
            skus_doesnt_exist: "This SKU does not exist in your inventory."
        },
    
        canlendlyView: {
            do_not_schedule: "Do Not Schedule",
            close: "Close",
        },
    
        orderTable: {
            name: "Names",
            sku: "SKU",
            quantity: "Quantity",
            no_added_products: "You have not added any products.",
        },

        container: {
            store: "STORE",
            products: "PRODUCTS",
            notes: "NOTES",
            requested: "REQUESTED",
            appointment: "APPOINTMENT",
            status: "STATUS",
            completed_percentage: "% completed",
            go_to_appointment: "Go to appointment",
            schedule: "Schedule",
            print_invoices: "Print Invoices",
            edit: "Edit",
            cancel: "Cancel",
            reschedule: "Reschedule",
            view_products: "View product details",
            cancel_receipt: "Cancel replenishment",
            edit_receipt: "Edit replenishment",
            view_invoices: "View invoices",
            cancel_inventory_receipt: "Do you want to cancel this inventory replenishment?",
            close: "Close",
            cancel_receipt_dialog: "Cancel",
            inventory_receipt: "Inventory Replenishment",
            all_stores: "All stores",
            create_inventory_receipt: "Create inventory replenishment",
            export_button: "Export",
            no_receipts_to_show: "No inventory replenishments to show.",
            edit_inventory_receipt: "Edit Inventory Replenishment",
            completed_inventory_receipt: "Completed Inventory Replenishment",
            see_replenishment: "View Replenishment",
            schedule_an_appointment: "Schedule an appointment",
            replenishments_page: "Replenishments",
            schedule_message: "Schedule your appointment so we can receive your inventory",
            we_received: "We received ",
            received_bulks_SLA: " bulks complying with the replenishment appointment. ",
            received_bulks_not_attended: " bulks not complying with the replenishment appointment. ",
            received_SLA: "They will start being processed within 24 hours at most",
            received_not_attended: "Processing may start after 24 hours",
            processing_message: "We are counting and storing your inventory.",
            late_processing: "Delayed due to missed appointment",
            on_date: "on",
            expected: "expected pieces",
            received: "counted pieces",
            stored: "stored pieces",
            counting: "Still counting!",
            finished_count: "Counting finished!",
            storing: "Still storing!",
            finished_storing: "Storage finished!",
            not_ready_to_sell: "They will not be listed as available until they are stored",
            ready_to_sell: "Available for sale!",
            processing_pending: "Processing pending",
            damaged: "damaged",
            completed_both: "You labeled and segregated your products correctly. Keep it up!",
            completed_labeled: "You labeled your products correctly, but they were segregated incorrectly.",
            completed_segregated: "You segregated your products correctly, but they were labeled incorrectly.",
            completed_both_wrong: "You labeled and segregated your products incorrectly.",
            check: "Check",
            here: "here",
            how_to_send: "how to send us your products.",
            no_replenishments_to_show: "No replenishments to show"
         },
    
        export_dialog: {
            title: 'Export Inventory Replenishments',
            intro: 'The following inventory replenishments that meet the following conditions will be exported:',
            default_description: 'All inventory replenishments from your account will be exported. If you wish, use the filters to obtain a specific report.',
            replenishment_id: 'With replenishment number equal to: ',
            sku: 'With replenishments containing the SKU: ',
            status: 'Whose status is: ',
            completed_at: 'That have been completed between ',
            completed_at_join: ' and ',
            type: 'Whose type is: ',
            confirm: 'Download',
            cancel: 'Cancel',
        },

        viewReplenishment: {
             name: "Nombre",
             expected_quantity: "Esperadas",
             quantity_processed: "Procesadas",
             quantity_given: "Dañadas",
             loading_summary: "Cargando resumen...",
             detail_parts_received: "Este es el detalle de piezas recibidas:",

        },

        viewReplenishmentInfo: {
            name: "Name",
            sku: "SKU",
            expected_quantity: "Expected Quantity",
            received_quantity: "Received Quantity",
            stored_quantity: "Stored Quantity",
            damaged_quantity: "Damaged Quantity",
            loading_summary: "Loading summary...",
            replenishment_detail: "This is the summary of your inventory replenishment:",
        },
    
        header: {
            id_type_notes: 'ID | Type | Notes',
            status: 'Status',
            pieces_summary: "Pieces Summary",
            replenishment_summary: "Replenishment Summary",
            filter: "Filter",
            reset_filters: "Reset Filters"
        },
    
        search: {
            id: "ID",
            sku: "Containing SKU",
            type: "Presale/Regular",
            status: "Status",
            completed_at: "Completion Date",
            from_to: "from-to"
        },
    
        types: {
            all: "Presale/Regular",
            presale: "Presale",
            regular: "Regular",
        },

        footer_navigation : {
            replenishments: "replenishments"
        }

    },

    lots: {
        view_lots: "View Lots",
        view_lots_detailed: "These are the lots for this product",
        lot_support_actions: "If you want to pause a lot, cancel a lot, or get a list of all buyers for a lot, please create a support ticket using the widget at the bottom right.",
        no_lots: "This product does not have lot control configured. If you want to learn more about this functionality, contact the support team."
    },

    returns: {
        container: {
            order_number: "RETURNED ORDER NUMBER",
            new_order: "NEW ORDER NUMBER",
            type: "Type",
            status: "Status",
            reserved_parts: "Reserved Parts",
            creation_date: "Creation Date",
            return_details: "View Return Details",
            review_return: "Review Return",
            see_returned_order: "View Returned Order",
            see_order_created: "View Created Order",
            not_applicable: "Not Applicable",
            no_reserved_parts: "No Reserved Parts",
            returns: "Returns",
            no_returns: "No returns to show.",
            see_order: "View Order",
            return_detail: "Return Detail",
            all_stores: "All Stores",
        },
    
        listItem: {
            damaged_parts: "(Damaged Parts)",
            add_damaged_part: "Add Damaged Part",
            remove_product: "Remove Product",
        },
    
        newOrder: {
            not_applicable: "Not Applicable",
            awaiting_order: "Awaiting Order",
            forwarding_cancelled: "Forwarding Cancelled",
            decision_pending: "Decision Pending",
            awaiting_review: "Awaiting Review",
            waiting_reception: "Waiting Reception",
            arrived_at_warehouse: "Arrived at the Warehouse",
        },
    
        table: {
            name: "Name",
            quantity: "Quantity",
            no_products_added: "No products added.",
        },

        types:{
            unknown: "Unknown",
            return: "Return",
            change: "Exchange",
            immediate_change: "Immediate Exchange",
            parcel: "Parcel",
            awaiting_review: "Awaiting Review",
            unidentified: "Unidentified"
        },
    
        externalContainer: {
            unchecked_return: "This return cannot be reviewed.",
            order_number: "Order number",
            store: "Store",
            notes: "Notes",
            type_of_return:"Type of Return",
            return_guide:"Generate return guide?",
            original_content:"Original content",
            products_to_return:"Products to return",
            add_product: "Add another product from the store",
            product: "Product",
            quantity: "Quantity",
            send_replacement: "Products to send as replacement",
            reject: "Reject",
            accept: "Accept",
            cancel: "Cancel", 
            confirm: "Confirm",
            refuse_return: "Do you want to reject this return?",
            return: "Reject Return",
            total_returned: "Confirm that a total of",
            product: "product(s) will be returned.",
            accept_return: "Accept Return",
        },
    
        returnContainer: {
            returned_order: "Returned order number",
            store: "Store",
            type: "Type",
            state: "State",
            return_date: "Return date",
            notes: "Notes",
            return_of_order: "Order created by return",
            order_returned: "It is important to confirm what you want to do with the returned order, we are reserving the necessary products to resend the order.",
            cancel_forwarding: "Cancel Forwarding",
            resend_order: "Resend Returned Order",
            returned_products: "Returned products ",
            order_not_returned: "The order has not been returned.",
            reserved_parts: "Reserved parts",
            release_reserved_parts: "Release reserved parts",
            no_parts_reserved: "No parts reserved",
            parts_at_order: "Parts assigned to new order",
            directory: "Your customer will receive an email with the ",
            print: "Print RMA",
            return_guide: "Print Return Guide",
            forwarding_cancellation: "Confirm Forwarding Cancellation",
            reserved_inventory: "The inventory reserved for the forwarding of this order will be released.",
            confirm: "Confirm",
            cancel: "Cancel",
            confirm_order_forwarding: "Confirm Order Forwarding",
            duplicate_returned_order: "The returned order will be duplicated. We will replace any missing or damaged product with inventory in stock.",
            unidentified: "Unidentified",
            return_label: "Return Label",
            no_return_label: "No return label",
            arrived_at_warehouse_description: "The package has arrived but is awaiting reception and processing by an operator"
        },

    },

    statuses: {
        order_statuses: {
            unknown: "No status",
            initial: "Synchronizing",
            empty: "Empty order",
            pending: "Pending",
            processing: "Processing",
            shipped: "Shipped",
            returning: "Returning to Cubbo",
            return_arrived: "Returned to Cubbo",
            returned: "Receiving return",
            reentered: "Return reentered",
            canceled: "Canceled",
            error: "Missing information",
            entirely_dropshipping: "Externally fulfilled",
            backorder: "Out of stock",
            unpaid: "Unpaid",
            cancelation_window: "Cancellation window",
            hold: "On hold",
            interrupted: "Interrupted",
            waiting_pick_up: "Waiting for pickup",
            awaiting_pick_up: "Awaiting pickup",
            picked_up: "Picked up",
            in_warehouse: "Received in warehouse",
            delayed: "Delayed",
        },
    
        replenishment_statuses: {
            unknown: "No status",
            select: "Select a status",
            without_scheduling: "Created without appointment",
            generating_invoices: "Generating invoice",
            requested: "Scheduled",
            pending: "In progress",
            processing: "Processing",
            in_putaway: "Processing",
            received: "Received in warehouse",
            completed: "Completed",
            completed_with_differences: "Completed with differences",
            in_transit: "In transit",
            canceled: "Canceled"
        },
    
        claims_statuses: {
            unknown: "No status",
            pending: "Pending",
            rejected: "Rejected",
            accepted: "Accepted"
        },
    
        returns_statuses: {
            unknow: "No status",
            requested: "Awaiting return",
            pending: "Decision pending",
            cancelled: "Resend cancelled",
            ordered: "Resend ordered",
            received: "Rerceived in warehouse",
            reentered: "Reentered",
            arrived_at_warehouse: "Arrived at the warehouse",
        },
    
        tracking_statuses: {
            unknown: "No status",
            in_transit: "In transit",
            shipment_delivered: "Delivered",
            shipment_with_incident: 'Incident',
            shipment_canceled: 'Shipment Canceled',
            shipment_to_be_self_collected: 'Pickup at location',
            shipment_out_for_delivery: 'Out for delivery'
        },
    
        call_to_action: {
            shipment_to_be_self_collected: 'Instructions',
            error: 'View missing data',
            backorder: 'See the possible actions'
        },

        tooltip: {
            cancelation_window: 'The buyer can cancel the order from VTEX, when the cancellation window configured in your VTEX ends, we will prepare the order.',
            interrupted: 'Order fulfillment was canceled while being prepared. We will not ship this order, but the pick and pack fee will be charged.',
            entirely_dropshipping: 'Cubbo does not store ANY of the products for this order',
            empty: 'The order has no pieces assigned to fulfill',
            no_stock: 'Not enough pieces have been assigned to be able to send the total requested for this product'
        },

        tracking_descriptions: {
            second_try: 'Second attempt',
            third_try: 'Third attempt',
            client_requested: 'Buyer requested',
            missing_references: 'Missing references',
            not_at_home: 'Buyer not at home',
            refused_payment: 'Buyer refused to pay',
            refused_package: 'Buyer refused the package',
            cubbo_requested: 'Cubbo requested',
            carrier_requested: 'By courier',
            stolen: 'Stolen',
            lost: 'Lost',
            damaged: 'Damaged',
            returned_by_carrier: 'Due to unsuccessful delivery',
            returned_by_client: 'Due to buyer return request' 
        }
    },

    settings: {
        packing_options: {
            title: "Packaging Preferences",
            description: "Set up the packaging options you want to enable for your store",
            container: {
                title: "Packaging Preferences",
                description: "Select the packaging options you want to enable for shipping orders from your store",
                save_options: "Save Changes",
                save_processing: "Processing",
                save_success_title: "Your packaging preferences have been updated.",
                save_error_title: "An error occurred while updating your packaging preferences.",
                save_error_description: "Please try again later. If the problem persists, contact the support team.",
                not_available_option: "Coming Soon",
                non_fragile_packing: {
                    title: "General Configuration",
                    title_description: "For any order that does not apply to the specific configuration, it will be packaged following this configuration",
                    description: "It will be packed with the most economical option according to the volume of the order and material availability",
                    options: {
                        parcel_bag: {
                            title: "Parcel Bag",
                            description: "For small products without risk of impact damage",
                        },
                        jiffy_envelope: {
                            title: "Jiffy Envelope",
                            description: "For very small products with moderate protection",
                        },
                        cubbo_box: {
                            title: "Cubbo Box",
                            description: "For small and medium-sized products",
                            disabled_hint: "This option cannot be disabled as it is the default backup packaging",
                        },
                        multi_package_with_cubbo_box: {
                            title: "Multi-package with Cubbo Box",
                            description: "For when the products in the order do not fit in a single package",
                        }
                    }
                },
                fragile_packing: {
                    title: "Specific Configuration",
                    title_complement: "Given priority over general configuration",
                    title_description: "Only if the order meets the described conditions, it will be packed following this configuration",
                    description: "When the order includes {BOLD_START}one or more fragile products{BOLD_END}, it will be packed with the best option from the following selection",
                    sub_description: 'When the order includes one or more products configured as {BOLD_START}fragile{BOLD_END}.',
                    options: {
                        jiffy_envelope: {
                            title: "Jiffy Envelope",
                            description: "With fragile label attached to the exterior",
                        },
                        cubbo_box: {
                            title: "Cubbo Box",
                            description: "With fragile label attached to the exterior and protective material inside",
                            disabled_hint: "This option cannot be disabled as it is the default backup packaging",
                        }
                    }
                },
                fragile_packing_materials: {
                    titles: {
                        for_fragile: " {BOLD_START}Protective material{BOLD_END} is added when the package includes one or more products configured as {BOLD_START}fragile{BOLD_END}.",
                        for_not_fragile: "The order will be packed using the selected protective material."
                    },
                    for_fragile: {
                        BUBBLE_WRAP: {
                            title: "Bubble Wrap",
                            description: "Moderate protection"
                        },
                        KRAFT_PAPER: {
                            title: "Kraft Paper",
                            description: "More economical. Environmentally friendly"
                        },
                        CORN_EXTRUDATE: {
                            title: "Corn Extrudate",
                            description: "High protection. Environmentally friendly. Additional charges apply"
                        }
                    },
                    for_not_fragile: {
                        BUBBLE_WRAP: {
                            title: "Bubble Wrap",
                            description: "Moderate protection"
                        },
                        KRAFT_PAPER: {
                            title: "Kraft Paper",
                            description: "More economical. Environmentally friendly"
                        },
                        CORN_EXTRUDATE: {
                            title: "Corn Extrudate",
                            description: "High protection. Environmentally friendly. Additional charges apply"
                        },
                        UNSELECTED: {
                            title: "No protective material",
                            description: "Free of charge"
                        }
                    }
                },
                virtual: {
                    packageless: {
                        sub_description: 'When the entire order consists of only one product configured as {BOLD_START}packageless{BOLD_END}',
                        sub_description_hint: 'To configure a product as packageless, edit the product and enable the button with this feature.',
                        options: {
                        packageless: {
                                title: "Send without packaging",
                                description: "The shipping label will be attached to the exterior of the product",
                                disabled_hint: "This option cannot be disabled. It will only apply to products configured as packageless",
                            }
                        }
                    }
                }
            }
        },
        automation_settings: {
            title: "Automations",
            sub_title: "Configure automatic actions for everyday situations."
        },

        automation_container: {
            unpaid_order_cancellation_panel: {
                title: "Cancel unpaid orders",
                description: "Set the maximum time that an order can be waiting for payment.",
                payment_window_units: {
                    days_one: 'Day',
                    days_other: 'Days',
                    months_one: 'Month',
                    months_other: 'Months'
                },
                help_text: 'We will cancel it {{payment_window}} after the order was created, if it has not been paid.',
                shopify_help_text: 'Shopify orders will also be cancelled on the site.'
            },
            carrier_returns_cancellation_panel: {
                immediately: 'Immediately',
                title: 'Cancel carrier returns',
                awaiting_review: 'Decision pending',
                description_left: 'Number of days that a carrier return will stay in ',
                description_right: ' before it is automatically cancelled.',
                place_holder: 'Waiting days',
                days_one: 'Day',
                days_other: 'Days',
                help_text: 'We will cancel it {{review_window}} after the return has arrived at Cubbo.'
            },
            enabled_result: 'enabled',
            disabled_result: 'disabled',
            success_message: 'Automation {{status}}.'
        },
    
        store_br: {
            title_sbr: "Business rules",
            sub_title_sbr: "Create automated instructions for your orders with specific conditions"
        },
        brand_identity: {
            title_sbr: "Brand Identity",
            sub_title_sbr: "Give your brand presence on the tracking page and in the tracking notifications that your customers will receive",
        },
        brand_identity_container: {
            logo_title:"Logo",
            logo_description: "Attach an image PNG or JPG",
            logo_uploaded: "Logo uploaded",
            name_title:"Brand name",
            name_description: "Write the way you want your store to be called.",
            contact_title:"Support email",
            contact_description: "Write the email where your clients can reach out to you to follow up on specific cases such as stolen, lost or damaged orders.",
            logo_preview: "Preview of use",
            preview_tracking: "Tracking page",
            preview_email: "Tracking emails",
            logo_recomendation:"Maximum 5MB. Recommended dimensions: at least 600 x 400 pixels. Recommended format: Transparent background"
        },

        automatic_reports: {
            title: "Automatic Reports",
            sub_title: "Enable automatic sending of specific store information",
            not_defined: "Not defined",
            and: "and",
            more: "more",
            category: "Category",
            no_reports_to_show: "No reports to show",
            export_report: "Export Report",
            error: "An error occurred, please try again later",
            error_insert_email_recipients: "Add at least 1 recipient",
            report_table_columns: {
                name: "Name",
                status: "Status",
                category: "Category",
                frequency: "Frequency",
                recipients: "Recipients"
            },
            filters: {
                name: "Name",
                category: "Category",
                recipients: "Recipients"
            },
            report_names: {
                inventory_detail: "Inventory",
                lots_detail: "Lots",
                inventory_kardex: "Inventory History",
                shipped_products: "Shipped Products",
                shipped_orders_detail: "Shipped Orders",
                backorder_products: "Backordered Products",
                returned_products: "Returned Products",
                claims_detail: "Disputes",
                replenishments_detail: "Receipts",
                return_invoices_detail: "Return Invoices",
                order_invoices_detail: "Order Invoices",
                replenishment_invoices_detail: "Replenishment Invoices",
                billing_statement_email_recipients: "Billing report"
            },
            report_categories: {
                INVENTORY: "Inventory",
                SALES: "Sales",
                ORDERS: "Orders",
                RETURNS: "Returns",
                CLAIMS: "Disputes",
                REPLENISHMENTS: "Receipts",
                INVOICES: "Invoices",
                BILLING: "Billing"
            },
            report_frequency: {
                DAILY: "Daily",
                WEEKLY: "Weekly",
                MONTHLY: "Monthly",
                FORTNIGHTLY: "Fortnightly",
                QUARTERLY: "Quarterly",
                report_frequency_descriptions: {
                    DAILY: "at the end of the day",
                    WEEKLY: "on Sunday at the end of the day",
                    MONTHLY: "on the last day of the month at the end of the day",
                    FORTNIGHTLY: "on the 15th and last day of the month",
                    QUARTERLY: "on the last day of the month",
                },
                STATEMENT: "Each cutoff"
            },
            report_details: {
                about_the_report_title: "About the Report",
                delivery_status: "Report Delivery",
                true: "Active",
                false: "Inactive",
                frequency_title: "Delivery Frequency",
                frequency_placeholder: "Choose the delivery frequency for this report",
                recipients_title: "Recipients",
                recipients_placeholder: "Enter the email addresses of the people you want to receive this report",
                report_descriptions: {
                    inventory_detail: "This report will help you keep track of your inventory, identifying damaged, expired, available, and unavailable pieces for sale. You can also find this report in the inventory panel when exporting information.",
                    lots_detail: "This report will help you track your batch inventory, identifying expired and active parts.",
                    inventory_kardex: "This report will be useful for monitoring the movements of entry or exit from your inventory.",
                    shipped_products: "In this report, you will have a summary of all the products included in the shipped orders. This will allow you to monitor your inventory and make calculations for replenishment, sales analysis, among other options.",
                    shipped_orders_detail: "Through this report, you can measure various variables of your shipments, such as which incident is the most common in your shipments and with which courier it occurs, the number of orders delivered with delays, attempts to deliver that resulted in a returned order, among others. You can also download this information from the orders panel by filtering by order status 'Shipped' and by shipping date.",
                    backorder_products: "This report will be useful for taking action on orders that are held up due to a lack of stock. You can make replenishment plans based on missing pieces or generate compensation strategies for your customers for the extra wait time.",
                    returned_products: "Review the condition in which the pieces from your returns were received. This report is especially useful for orders with ",
                    claims_detail: "This report will allow you to quantify the total amount to be reimbursed for accepted disputes of the month and easily identify the reason why the rest of the disputes were rejected.",
                    replenishments_detail: "Check how many pieces we received out of the total expected pieces and how many of the received pieces were received as damaged. You will also find receipts created by Cubbo to re-enter returns into inventory with an external or unidentified guide.",
                    return_invoices_detail: "Automatically receive all return invoices issued for the selected delivery frequency period. You can also find this same report in the invoices panel.",
                    order_invoices_detail: "Automatically receive all invoices for your orders issued for the selected delivery frequency period. You can also find this same report in the invoices panel.",
                    replenishment_invoices_detail: "Automatically receive all inventory receipt invoices issued for the selected delivery frequency period. You can also find this same report in the invoices panel.",
                    billing_statement_email_recipients: "This report is sent with each cutoff, containing an attached detailed breakdown of the account statement for the period."
                },
                report_buttons: {
                    cancel: "Cancel",
                    save: "Save",
                    show_details: "Show Details",
                    deactivate: "Deactivate Report",
                    activate: "Activate Report",
                    pause_report: "Pause Delivery",
                    restart_report: "Resume Delivery",
                    download_report: "Download Sample"
                },
                report_notifications: {
                    status_done: "Report delivery status saved",
                    done: "Configuration saved",
                    error: "Error saving report configuration"
                }
            },
        },        

        alerts: {
                title_sbr: "Alerts",
                sub_title_sbr: "Configure who should receive notifications about your store",
                sub_title: "A notification will be sent by email to the subscriber list of each category when the described event occurs.",
            },
            
        alerts_container: {
            categories:{
                product_expirations_email_notification:{
                    title: "Batch Control",
                    label: "You will be notified when a batch has expired"
                },
                replenishments_email_notification:{
                    title: "Inventory Receipt",
                    label: "Confirmation will be sent when a new inventory receipt is scheduled and when it is completed"
                },
                shipping_incident_created_by_cubbo_email_notification:{
                    title: "Incidents",
                    label: "You will be notified when one or more incidents are generated for any of your orders"
                },
                returns_email_notification:{
                    title: "Returns",
                    label: "You will be notified when a return is initiated and when it is received at Cubbo"
                },
                claims_email_notification:{
                    title: "Disputes",
                    label: "You will be notified about the resolution of generated disputes"
                },
                maquila:{
                    title: "Maquila",
                    label: "You will be notified about the status of the requested maquila (Pending, In progress, Completed)"
                }
            },
            place_holder: "Enter the email addresses of the people who should receive this notification",
            error_message_email: "The added email is not valid",
            done: "Settings saved",
            error: "Error saving alerts configuration"
        },
        business_rule_creator: {
            button: "Add rule",
            path: "Add rule",
            title: "New business rule",
            title_preview: "You are about to create this business rule",
            cancel_creation: {
                button: "Discard",
                dialog_title: "Information will be lost",
                dialog_description: "Are you sure you want to exit without saving?",
                dialog_confirm_button: "Yes, I want to exit",
                dialog_cancel_button: "No, return to editing",
                on_cancel: "Business rule creation canceled"
            },
            create_rule: {
                preview_rule_button: "Preview rule",
                save_rule_button: "Create rule",
                edit_rule_button: "Return to editing",
                dialog_title: "Create business rule",
                dialog_description: "Are you sure you want to create the business rule?",
                dialog_confirm_button: "Yes, I want to create it",
                dialog_cancel_button: "No, return to editing",
                on_error: "An error occurred, please try again later",
                on_success: "Business rule created"
            },
            confirm_rule: {
                success: "Business rule created",
                error: "An error occurred while creating the business rule, please try again later"
            },
            add_condition_button: "Add another condition",
            condition_types: {
                AND: {
                    title: "Additional to the previous one",
                    description: "The order must meet all defined conditions to apply the instruction",
                    display_name: "and..."
                },
                OR: {
                    title: "Optional to the previous one",
                    description: "If the order meets any of the optional conditions, the instruction will apply",
                    display_name: "or..."
                }
            },
            general_business_rule_settings: {
                title: "General Information",
                description: "Define the name and validity period of this rule",
                alias_field: "Rule Alias",
                alias_placeholder: "Enter an alias for this rule to easily identify it",
                validity: "Validity",
                validity_time_zone: "The time zone corresponds to the warehouse",
                validity_start_field: "It will activate from",
                validity_start_placeholder: "Today, upon saving",
                validity_end_field: "Until",
                without_validity_end: "No expiration",
                confirm_button_missing_all_required: "Define an alias and validity to continue",
                confirm_button_missing_only_alias: "Define an alias to continue",
                confirm_button_missing_only_validity: "Define validity to continue",
                confirm_button: "Continue to define an action",
                cancel_edit_button: "Cancel",
                confirm_edit_button: "Save Changes",
                remaining_alias_character_counter: {
                    no_remaining: "The available character limit has been reached",
                    one_remaining: "1 character remaining",
                    multiple_remaining: "{QUANTITY} characters remaining"
                },
                completed_step: {
                    from_today_with_no_limit: "It will activate from today upon saving, indefinitely",
                    from_today_with_limit: "It will activate from today upon saving, until {END_DATE}",
                    from_some_day_with_no_limit: "It will activate from {START_DATE}, indefinitely",
                    from_some_day_with_limit: "It will activate from {START_DATE} until {END_DATE}"
                },
                validity_error_message: {
                    missing_validity_end: "Define the validity date",
                    invalid_validity: "The activation date must be less than or equal to the validity date"
                }
            },
            business_rule_action_settings: {
                title: "What do you want to happen with the order?",
                description: "In the next section, you will define when this action should be applied",
                change_option: "Change",
                action_options_title: "Select an action",
                product_insert_options_title: "If the order already contains some of the pieces you want to add, what should we do?",
                cancel_edit_button: "Cancel",
                confirm_edit_button: "Save Changes",
                confirm_edit_dialog: {
                    title: "Save Changes",
                    description: "The changes made will be saved, and changes in subsequent steps will be lost",
                    cancel_button: "Cancel",
                    confirm_button: "Save Changes"
                },
                action_options: {
                    ADD_SHIPPING_PACKAGE_OPTION: {
                        title: "Use specific inserts to package the order",
                        description: "For example, boxes, tissue paper, stickers, brochures, etc."
                    },
                    ADD_PRODUCT_OPTION: {
                        title: "Add products or gift kits to the order",
                        description: "For example, promotional gift 2x1, gift with minimum purchase, etc."
                    },
                    ADD_INSERT_OPTION: {
                        title: "Add inserts to the order",
                        description: "For example, promotional gift 2x1, gift with minimum purchase, etc."
                    },
                    PAUSE_ORDER_OPTION: {
                        title: "Pause the order"
                    },
                    CANCEL_ORDER_OPTION: {
                        title: "Cancel the order"
                    }
                },
                confirmation_button_unselected_action: "Select an action to continue",
                confirmation_button: "Continue to define a condition",
                product_selector: {
                    ADD_SHIPPING_PACKAGE_OPTION: {
                        title: "Which inserts do you want to use for packaging?",
                        add_product_button: "Select insert",
                        add_another_product_button: "Add another insert",
                        confirmation_button_missing_product: "Select an item to continue",
                        confirmation_button: "Continue to define when to package with these inserts"
                    },
                    ADD_PRODUCT_OPTION: {
                        title: "Which products do you want to add to the order?",
                        description: "Select a product, kit, or non-scannable product (insert)",
                        add_product_button: "Add product",
                        add_another_product_button: "Add another product"
                    },
                    ADD_INSERT_OPTION: {
                        title: "Which products do you want to add to the order?",
                        description: "Select a product, kit, or non-scannable product (insert)",
                        add_product_button: "Add product",
                        add_another_product_button: "Add another product"
                    }
                },
                product_insert_options: {
                    ENSURE_PRODUCT: {
                        title: "Add only the missing pieces to achieve the desired quantity",
                        description: "Example: If you want to add 2 apples and the order already has 2 apples, no more apples will be added. If the order has 1 apple, 1 apple will be added."
                    },
                    INCLUDE_PRODUCT: {
                        title: "Regardless of whether the order already contains them, add the desired quantity",
                        description: "Example: If you want to add 2 apples and the order already has 3 apples, 2 apples will still be added."
                    }
                },
                completed_step: {
                    PAUSE_ORDER_OPTION: "Pause the order",
                    CANCEL_ORDER_OPTION: "Cancel the order",
                    ADD_SHIPPING_PACKAGE_OPTION: {
                        description_start: "To package, use ",
                        product: "{QUANTITY} of {SKU}",
                        last_product_join: " and "
                    },
                    ADD_PRODUCT_OPTION: {
                        description_start: "Add to the order, ",
                        insert_options: {
                            ENSURE_PRODUCT: "only if the order doesn't already contain it ",
                            INCLUDE_PRODUCT: "even if the order already contains it "
                        },
                        product: "{QUANTITY} of {SKU}",
                        last_product_join: " and ",
                        description_end: " as a gift within the order"
                    },
                    ADD_INSERT_OPTION: {
                        description_start: "Add to the order, ",
                        insert_options: {
                            ENSURE_PRODUCT: "only if the order doesn't already contain it ",
                            INCLUDE_PRODUCT: "even if the order already contains it "
                        },
                        product: "{QUANTITY} of {SKU}",
                        last_product_join: " and ",
                        description_end: " as a gift within the order"
                    }
                }
            },
            business_rule_statement_condition: {
                title: "Define the condition",
                title_template: "You are creating this condition:",
                save_button: "Save condition",
                save_button_update: "Update condition",
                cancel_button: "Discard changes",
                unsaved_changes: "Unsaved data",
                completed_condition: {
                    HAS_FRAGILE_ITEMS: "When the order includes fragile products",
                    CHANNEL_TYPE_NAME: "When it's an order from {{channel_name}}",
                    IS_FIRST_TIME_BUYER: "When it's a first-time buyer's order",
                    IS_POTENTIAL_FRAUD: "When the order is detected as fraud by Shopify",
                    VALIDATE_PRODUCT: {
                        validationOption: {
                            INCLUDE_PRODUCT: "When the order includes ",
                            NOT_INCLUDE_PRODUCT: "When the order does not include "
                        },
                        quantityOption: {
                            MINIMUM: "at least {MIN_QUANTITY}",
                            MAXIMUM: "up to {MAX_QUANTITY}",
                            MINIMUM_AND_MAXIMUM: "from {MIN_QUANTITY} to {MAX_QUANTITY}",
                            EXACTLY: "exactly {EXACT_QUANTITY}"
                        },
                        exclusive: " and no other product",
                        any_sku: " of any product in your store",
                        specified_sku: " of any of these products...",
                        all_orders: "In all orders"
                    },
                    FOR_EVERY_PRODUCT: {
                        every_quantity: "For each time the order includes {QUANTITY}",
                        with_max_quantity: " (up to {MAX_QUANTITY})",
                        any_sku: " of any product in your store",
                        specified_sku: " of any of these products..."
                    }
                }
            },
            business_rule_additional_statement_condition: {
                title: "Select a condition",
                options_placeholder: "When the order..."
            },
            business_rule_statements: {
                custom: {
                    validations: {
                        INCLUDE_PRODUCT: "Include",
                        NOT_INCLUDE_PRODUCT: "Do not include"
                    },
                    quantities: {
                        MINIMUM: "At least",
                        MAXIMUM: "Maximum",
                        MINIMUM_AND_MAXIMUM: "From",
                        EXACTLY: "Exactly"
                    },
                    title_validations: "When the order...",
                    title_business_rules_per_integration: "When the order is from:",
                    title_quantities: "This quantity...",
                    title_sku: "Of any of these products...",
                    button_add_product: "Add product",
                    from_quantity_to_quantity: "to",
                    any_product: "Any product",
                    any_product_placeholder: "Any product from your store",
                    not_any_product_placeholder: "Select or paste the SKU of a product",
                    exclusive: "... as long as the order doesn't contain any other additional products listed",
                    single_sku_error: "There is {QUANTITY} invalid SKU. Correct or delete it before continuing",
                    multiple_sku_error: "There are {QUANTITY} invalid SKUs. Correct or delete them before continuing",
                    error_messages: {
                        unselected_validation_option: "Select an option",
                        unselected_quantity_option: "Select an option",
                        missing_quantity: "Enter a quantity",
                        invalid_range_quantity: "The minimum quantity must be less than the maximum quantity",
                        missing_products: "Paste or add SKUs for at least one product"
                    }
                },
                for_every_product: {
                    title_for_every_product: "For every...",
                    title_from_sku: "Of any of these products...",
                    title_complement_from_sku: "that the order includes.",
                    title_max_quantity: "Maximum number of items to add per product in the order",
                    option_max_quantity: "No limit",
                    button_add_product: "Add product",
                    any_product: "Any product",
                    any_product_placeholder: "Any product from your store",
                    not_any_product_placeholder: "Select or paste the SKU of a product",
                    single_sku_error: "There is {QUANTITY} invalid SKU. Correct or delete it before continuing",
                    multiple_sku_error: "There are {QUANTITY} invalid SKUs. Correct or delete them before continuing",
                    error_messages: {
                        missing_for_every_quantity: "Enter a quantity",
                        missing_products: "Paste or add SKUs for at least one product",
                        invalid_maximum_quantity: "Enter a quantity or specify if you do not want to set a limit on the number of items"
                    }
                }
            },
            business_rule_statement_settings: {
                title: "When should we apply the previously defined action?",
                description: "Define what conditions the order must meet for the action to be applied",
                display_less_options: "See fewer cases",
                display_more_options: "See more cases",
                statement_options: {
                    ALL_ORDERS: {
                        title: "In {BOLD_START}all orders{BOLD_END}"
                    },
                    CHANNEL_TYPE_NAME: {
                        title: "When it's an order from a {HIGHLIGHTED_START}certain sale channel{HIGHLIGHTED_END}"
                    },
                    IS_FIRST_TIME_BUYER: {
                        title: "When it's an order from a {BOLD_START}new buyer{BOLD_END}"
                    },
                    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY: {
                        title: "When the order includes {BOLD_START}at least{BOLD_END} {HIGHLIGHTED_START}a certain quantity{HIGHLIGHTED_END} of {HIGHLIGHTED_START}certain products{HIGHLIGHTED_END}"
                    },
                    SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY: {
                        title: "When the order includes {BOLD_START}from{BOLD_END} {HIGHLIGHTED_START}a certain quantity{HIGHLIGHTED_END} {BOLD_START}to{BOLD_END} {HIGHLIGHTED_START}a certain quantity{HIGHLIGHTED_END} of {HIGHLIGHTED_START}certain products{HIGHLIGHTED_END}"
                    },
                    FOR_EVERY_PRODUCT: {
                        title: "{BOLD_START}For every{BOLD_END} {HIGHLIGHTED_START}a certain quantity{HIGHLIGHTED_END} of {HIGHLIGHTED_START}certain products{HIGHLIGHTED_END} that the order includes"
                    },
                    FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: {
                        title: "{BOLD_START}For every{BOLD_END} {HIGHLIGHTED_START}a certain quantity{HIGHLIGHTED_END} of {HIGHLIGHTED_START}certain products{HIGHLIGHTED_END} that the order includes {BOLD_START}, as long as it does not include{BOLD_END} {HIGHLIGHTED_START}certain products{HIGHLIGHTED_END}"
                    },
                    NOT_INCLUDING_SPECIFIED_PRODUCT: {
                        title: "When the order includes {BOLD_START}any product that is not{BOLD_END} {HIGHLIGHTED_START}certain products{HIGHLIGHTED_END}"
                    },
                    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: {
                        title: "When the order includes {HIGHLIGHTED_START}a certain quantity{HIGHLIGHTED_END} of {HIGHLIGHTED_START}certain products{HIGHLIGHTED_END} {BOLD_START}but does not include{BOLD_END} {HIGHLIGHTED_START}certain products{HIGHLIGHTED_END}"
                    },
                    SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE: {
                        title: "When the order includes {BOLD_START}the exact quantity of{BOLD_END} {HIGHLIGHTED_START}a certain quantity{HIGHLIGHTED_END} of {HIGHLIGHTED_START}certain products{HIGHLIGHTED_END} {BOLD_START}and no other products{BOLD_END}"
                    },
                    SPECIFIED_PRODUCT_EXACT_QUANTITY: {
                        title: "When the order includes {BOLD_START}the exact quantity of{BOLD_END} {HIGHLIGHTED_START}a certain quantity{HIGHLIGHTED_END} of {HIGHLIGHTED_START}certain products{HIGHLIGHTED_END}; it may include other products"
                    },
                    CUSTOM_STATEMENT: {
                        title: "{BOLD_START}Create custom condition{BOLD_END}"
                    },
                    FRAUD_ORDER_DETECTED: {
                        title: "When the order is {BOLD_START}detected as fraud{BOLD_END} by Shopify"
                    },
                    HAS_FRAGILE_ITEMS: {
                        title: "When the order includes {BOLD_START}fragile products{BOLD_END}"
                    }
                }
            },
            products_table: {
                product_image_header: "Image",
                product_name_header: "Name",
                product_sku_header: "SKU",
                product_quantity_header: "Desired Quantity"
            }
        },
        store_business_container: {
            market_insert: "Add insert",
            hold_order: "Pause order",
            cancel_order: "Cancel order",
            include_order: "Add these products, even if the order already contains them...",
            ensure_order: "Add these products, only if the order does not already contain them...",
            package_order: "Use these inserts when packing...",
            info_tooltip: "Add custom instructions for your orders, also known as Business Rules.",
            rule_type: "Add insert",
            no_rules: "You don't have any rules registered yet",
            defined_rule: "Default Rule",
            order_has: "When the order...",
            no_registers: "No records",
            hold_orders: "Pause order",
            add: "Add",
            instruction: "Instruction",
            quantity: "Quantity",
            of: "of",
            some_of_this: "of any of these products",
            not_some_of_this: "product(s) that is not any of these",
            skus: "SKUs",
            title: "Summer gift",
            active: "Active",
            inactive: "Inactive",
            inactive: "Inactive",
            and: "and...",
            or: "or...",
            all_skus: "Any SKU from the store.",
            include_at_least:"at least ",
            not_include_at_least:"not at least ",
            include_exactly: "exactly ",
            not_include_exactly: "not exactly ",
            include_range: "from ",
            not_include_range: "not from ",
            include_up_to: "up to ",
            not_include_up_to: "not up to ",
            channel_name: "Is from {{channel_name}}",
            is_potencial_fraud: "Is flagged as fraud by Shopify",
            has_fragile_items: "Contains fragile products",
            is_first_time_buyer: "Is from a new buyer",
            except: "As long as they are not ",
            to: "to",
            has_not: "Does not have ",
            of_the_next: "of any of the following products:",
            exactly: "exactly ",
            at_least: "at least ",
            of_range: "of ",
            up_to: "up to ",
            deleted_products: 'Deleted products',
            includes: "Include",
            does_not_include: "Do not include",
            confirm_dialog: {
                title_enable: "Enable business rule",
                title_disable: "Disable business rule",
                description: "It will apply to orders that are synchronized after confirmation",
                confirm: "Confirm",
                cancel: "Cancel",
                error: {
                    RULE_ENABLED_TRUE: 'This rule has already been enabled',
                    RULE_ENABLED_FALSE: 'This rule has already been disabled',
                    undefined: "An error occurred while trying to confirm this operation"
                }
            },
            time_validity: {
                no_start_and_no_end_date: "no start date, no expiration",
                no_start_date: "no start date",
                no_end_date: "no expiration date",
                from_date: "from",
                to_date: "to",
                is_expired: "Expired",
                is_not_expired: "Validity period",
                last_update: "Last update",
            },
            products_to_add_table_columns: {
                desired_product: "Desired product",
                quantity_to_add: "Quantity to add",
                max_pieces_to_add: "Maximum pieces to add",
            },
            many_pieces_to_add: "pieces",
            single_piece_to_add: "piece",
            sku: "SKU",
            kit: "KIT",
            without_max_limit: "Without limit",
            exclusive: "and none more",
            for_every_product: "For every time the order...",
            as_long_as_the_order: "As long as the order...",
            note_max_times_rule_applied: "NOTE: The maximum times this rule will apply in the same order depends on the maximum pieces to add.",
            no_name_business_rule: "Unnamed business rule created on",
            products_quantity: "Quantity",
            max_products_quantity: "Maximum Pieces",
            rule_without_name: "Rule without a name created on",
            filters: {
                active: "Active",
                inactive: "Inactive",
                add_product: "Add Product",
                cancel_order: "Cancel Order",
                use_package: "Use Package",
                hold_order: "Hold Order",
                category: "Category",
                add_this_sku: "SKU in action",
                that_contains_this_sku: "SKU in condition",
                alias: "Alias",
                estado: "Status",
                expired: "Expired"
            }
        },
    
        store_settings: {
            config_title: "Settings",
            user_config: "User settings",
            description: "Here you can see the information of the users associated with your store.",
        },

        tracking_emails: {
            config_title: "Tracking Notifications",
            config_subtitle: "Define which events you want to send emails to your customers",
            title: "Tracking Notifications",
            subtitle: "An email will be sent to the buyer notifying the shipping status of the order, as long as the notification is enabled on this panel.",
            save: "Save changes",
            saving: "Saving changes",
            cancel: "Cancel",
            tracking_type: "Regular mail",
            shipping_incident_type: "Occasional mail",
            reported_shipping_incident_to_carrier_type: "About incidents reported to the shipping service",
            reported_to_carrier_and_resolved_by_carrier: "Incident reported to the shipping service",
            reported_to_carrier_and_resolved_by_carrier_description: "An email will be sent when an incident is created and resolved",
            click_and_collect_type: "Click n Collect",
            in_transit_to_pickup_point: "In transit to pickup point",
            at_pickup_point: "At pickup point",
            packed: "Packed, waiting for pickup",
            packed_description: "The integration also sends a notification to your customer about this event",
            in_transit: "In transit",
            out_for_delivery: "Out for delivery",
            delivered: "Delivered",
            returned: "Returned",
            failed_attempt: "Delivery attempt unsuccessful",
            to_be_self_collected: "To be self-collected at office",
            cancelled: "Cancelled delivery",
            stolen: "Shipment stolen",
            lost: "Shipment lost",
            send: "Send",
            send_test: "Send test email",
            test_sent: "A test email has been sent to your email address",
            test_error: "Test email could not be sent",
            invalid_email: " is not a valid email address",
            update_success: "Your settings have been updated",
            update_error: "Your settings could not be updated",
        },

        user_settings: {
            success_message: "User registered successfully",
            error_message: "Invalid user registration",
            title: "Users",
            subtitle: "Invite members to your store",
            required_field: "This field is required",
            invalid_email: "Invalid email address",
            processing: "Processing your request...",
            invite_user: "Invite user",
            error: "Error",
            done: "Done!",
            actual_users: "Current users:",
            invite_user_placeholder: "Type an email",
            disable_user_errors: {
                UNAUTHORIZED: "You are not authorized to perform this action"
            },
            invite_user_errors: {
                INVALID_EMAIL: "This email address is invalid",
                EMAIL_EXISTS: "This email address is already registered in Cubbo",
                DEFAULT: "An unexpected error occurred. Please contact technical support"
            },
            list: {
                name: "Name",
                email: "Email",
                disable_user_action: "Disable user",
                no_users_to_show: "No users to show",
                users: "users"
            },
            disable_user_dialog: {
                title: "Disable user",
                description: "Are you sure you want to disable user {{user}}?",
            }
        },
    
        tax_settings: {
            tax_config: "Invoicing",
            description: "Configure the necessary information to issue invoices from your store"
        },
    
        tax_fields: {
            success_message: "Your information was updated successfully",
            error_message: "Invalid information",
            processing: "Processing",
            tax_id: "Tax ID",
            tax_regime: "Tax regime ",
            tax_company_name: "Company tax name",
            tax_company_name_short: "Company ",
            tax_address: "Tax address",
            tax_address_number: "Tax address number",
            tax_address_neighborhood: "Tax address neighborhood",
            tax_city: "Tax city",
            state: "Tax state",
            tax_zipcode: "Tax zip code",
            tax_state_registration: "State registration code ",
            tax_state_registration_short: "State registration number ",
            tax_state_phone: "Tax phone",
            send_form: "Save changes",
            tax_regime_physical: "Physical",
            tax_regime_legal: "Legal"
        },
    
        billing_config:{
            filscal_data: "Tax Information",
            filscal_data_description: "Edit the tax information for your store.",
            ncms_settings: "NCM Configuration",
            ncms_settings_description: "Add the NCMs that correspond to your products to your store and customize their taxes.",
            operation_nature: "Customize the operation nature",
            operation_nature_description: "Edit the operation nature for each type of invoice.",
            observation_invoice: "Add observations to invoices",
            observation_invoice_description: "Add observations to your invoices according to the type of invoice.",
            optional: "(Optional)"
        },
    
        types_invoices:{
            symbolic_return: "Symbolic product return",
            remove_stock: "Remove stock",
            sale: "Product sale",
            donation: "Product donation",
            return_donation: "Donation return",
            return: "Product return",
            replenishment: "Inventory replenishment",
            replenishment_adjustment: "Inventory replenishment adjustment"
        },
    
        customize_bills:{
            orders_invocices_title:"Possible invoices issued during an order",
            return_invocices_title:"Invoices issued during a return",
            replenishment_invocices_title:"Possible invoices issued during an inventory replenishment",
            symbolic_return_description: "We symbolically return the products to your store, freeing availability for sale.",
            sale_description: "Products were sold to your customer.",
            donation_description: "Products were donated to your customer.",
            remove_stock_description: "You will remove part of your inventory from Cubbo.",
            return_description: "Your customer will return the products from their order to your store.",
            replenishment_description: "Transfer of inventory from the store to Cubbo.",
            replenishment_adjustment_description: "Cubbo received fewer products than expected in the replenishment, so an invoice will be issued declaring the return of the missing products to your store.",
            invoice_table_title: "INVOICE",
            operation_nature_table_title: "OPERATION NATURE",
            reason_table_title: "REASON FOR ISSUE",
            observation_table_title: "OBSERVATION",
        },
        validate_changes:{
            title: "Do you want to exit?",
            question: "Your changes will be lost",
            saved_data: "Saved data",
            unsaved_data: "Unsaved data",
            yes: "Yes",
            no: "No",
        },

        ncm_configurations:{
            tax_rate: "Tax rate",
            tax_rate_credit: "Credit tax rate",
            tax_regime_store: "Tax regime of your store",
            more_matches: "There are more matches...",
            taxes: "Taxes",
            tax_situation: "Tax situation",
            tax_situation_other_state: "Tax situation for interstate orders",
            tax_situation_international: "Tax situation for international orders",
            order_invoices: "Order invoices",
            return_invoices: "Return invoices",
            replenihsment_invoices: "Replenishment invoices",
            same_state_entity: "Same federal entity",
            other_state_entity: "Different federal entity",
            international: "International",
            framing_code: "Framing code",
            complete_data_message: "Complete all fields before saving.",
            cancel: "Cancel",
            edit_ncm: "Edit NCM",
            new_ncm: "New NCM configuration",
            parameter_configuration: "Tax parameter configuration",
            dont_apply: "Not applicable",
            ncm_configurations_store: "NCMs configured in your store:",
            no_ncms_configured: "You don't have any NCMs configured yet.",
            invoice_table_title: "INVOICE",
            add_ncm: "Add NCM",
            edit_tax: "Edit tax",
            nfs_note: "(Note: The cfop in symbolic return invoices is not customizable. We show you the data as informative.)",
            note: "(Note: The cfop in replenishment invoices is not customizable. We show you the data as informative.)",
            origin: "Origin"
        }
    },
    filter: {
        button: "Filter",
        reset: "Clear filters",
        select: "Select a state",
        from_to: "from-to",
        export: "Export",
        dropdownExport: {
            breakdown_by_product: "Breakdown by Product",
            breakdown_by_order: "Breakdown by Order"
        }
    },
    dialog: {
        yes: "Yes",
        no: "No",
        cancel: "Cancel"
    },
    export_notification: {
        generating: "Generating report",
        generated: "Report generated",
        download: "Download"
    },
    work_orders: {
        button_navigation: "Go to work orders section",
        work_orders: "Work Orders",
        button: "Create Work Order",
        path: "Create Work Order",
        title: "You are creating a work order request",
        title_preview: "You are about to create this work order request",
        no_work_orders: "No work orders to display",
        all_stores: "All Stores",
        replenishment_id: "Inventory Receipt ID",
        rem_order_id: "Inventory Withdrawal ID",
        on_date: "on",
        header: {
            id: "Work Order No.",
            work_order_types: "Work Order Type",
            statuses: 'Status',
            work_order_histories: "Work Order Summary",
            filter: "Filter",
            reset_filters: "Clear Filters"
        },
        subheader: {
            id: "Notes",
            work_order_types: "Origin of Parts",
            statuses: "Observations",
            work_order_histories: ""
        },
        footer: {
            total: "Total",
            work_orders: "work orders"
        },
        work_order_statuses: {
            REQUESTED: {
                title: "Requested",
                description: "We will review your request within 72 hours"
            },
            IN_VALIDATION: {
                title: "In Validation",
                description: "We will ensure we have all the necessary resources for processing"
            },
            SCHEDULED: {
                title: "About to start",
                description1: "Your work order has been approved and is queued to start processing.",
                description2: "It will have an estimated cost of",
                description3: "The cost will be determined upon completion of the work order."
            },
            IN_PROGRESS: {
                title: "In Progress",
                description1: "There are",
                description2: "people assigned to your work order, you will see the processed pieces enter your inventory through the receipt"
            },
            COMPLETED: {
                title: "Completed",
                description1: "The total required pieces have been processed",
                description2: "the cost of this work order will be",
                description3: "The pieces will be entered in the inventory receipt"
            },
            REJECTED: {
                title: "Rejected"
            },
            CANCELED: {
                title:"Canceled"
            }
        },
        filters: {
            reset_filters: "Clear Filters",
            search: {
                id: "Work order no.",
                work_order_types: "Work Order Type",
                statuses: "Status"
            },
            id: "Work order no.",
            statuses: "Select a status",
            work_order_types: "Select a work order type"
        },
        cancel_creation: {
            button: "Discard",
            dialog_title: "Information will be lost",
            dialog_description: "Are you sure you want to exit without saving?",
            dialog_confirm_button: "Yes, I want to exit",
            dialog_cancel_button: "No, go back to editing",
            on_cancel: "Work order creation canceled"
        },
        create_work_order: {
            preview_work_order_button: "Preview Work Order",
            save_work_order_button: "Create Work Order",
            edit_work_order_button: "Go back to editing",
            dialog_title: "Create Work Order",
            dialog_description: "Are you sure you want to create the work order?",
            dialog_confirm_button: "Yes, I want to create it",
            dialog_cancel_button: "No, go back to editing",
            on_error: "An error occurred, please try again later",
            on_success: "Work order created"
        },
        confirm_work_order: {
            success: "Work order created",
            error: "An error occurred while creating the work order, try again later"
        },
        work_order_detail: {
            title: "View Work Order Request",
            notes: "Notes",
            order_details: {
                title: "Request Details",
                work_order_id: "Work Order No.",
                solicitant: "Requester",
                work_order_status: "Work Order Status"
            },
            product_origin: {
                title: "Origin of Required Material",
                rem_order_id: "Inventory Withdrawal",
                no_rem_order: "This work order does not have an inventory withdrawal request"
            },
            work_order_info: {
                title: "Work Order Request",
                work_order_type: "Process Type",
                notes: "Notes",
                instruction: "Instruction",
                activity: "Activity"
            },
            replenishment: {
                title: "Entry of Processed Product",
                replenishment_id: "Processed Product Inventory Receipt",
                replenishment_note: "From this inventory receipt, you can track the progress of the entry of the processed product into your inventory"
            }
        },
        creation_sections: {
            work_order_type: {
                title: "Work Order Type",
                select_work_order: {
                    title: "Select the work order you need",
                    description: "Note that all required material will have to be provided to the operations team for processing",
                    options: {
                        LABELING_PLASTISEAL: {
                            title: "Labeling or Plastiseal",
                            subtitle: "Re-labeling and/or sealing a bag with heat"
                        },
                        PRODUCT_VALIDATION: {
                            title: "Product Validation",
                            subtitle: "Counting products or checking their condition"
                        },
                        KIT_ASSEMBLY_SHRINKWRAP: {
                            title: "Kit Assembly or Shrinkwrap Application",
                            subtitle: "Packing more than one product in the same unit and/or applying shrinkwrap"
                        },
                        PACKAGING: {
                            title: "Packaging",
                            subtitle: "Placing a product inside its packaging"
                        },
                        PRODUCT_SEGREGATION: {
                            title: "Product Segregation",
                            subtitle: "Separating more than one SKU for storage"
                        },
                    }
                }
            },
            required_material: {
                title: "Required Material",
                product_origin: {
                    title: "Origin of Required Products and Inserts",
                    description: "How will you send us the material needed to complete the work order",
                    options: {
                        ALL_INVENTORY_AT_WAREHOUSE: {
                            title: "All material is in my inventory stored in Cubbo available for sale",
                            completed_description: "The inventory is stored in Cubbo and will be withdrawn through the"
                        },
                        MATERIAL_IN_TRANSIT_TO_WAREHOUSE: {
                            title: "The material is on its way to the warehouse",
                            completed_description: "The material is on its way to the warehouse"
                        },
                        PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE: {
                            title: "Part of the material is stored in Cubbo and another part is on its way to the warehouse",
                            completed_description: "Part of the material is stored in Cubbo and will be withdrawn through the"
                        }
                    }
                },
                removal_order: {
                    title: "ID of the inventory withdrawal request for the required material",
                    description: "Enter the withdrawal request number where you indicate which products to withdraw from your inventory for processing",
                    create_rem_order: "I haven't created the inventory withdrawal request yet",
                    removal_order: "inventory withdrawal",
                    continue_button: "Continue to define work order instructions"
                }
            },
            work_order_instructions: {
                title: "Instructions for Processing",
                download_file: {
                    title: "Download this file and fill it out with the details of your request",
                    subtitle: "Note that all required material will have to be provided to the operations team for processing",
                    files_placeholder: "Click here or drag your PDF or Excel or XML files",
                    attach_document: "Attach the instructions file",
                    notes: "Notes about your work order",
                    completed_description_notes: "Notes",
                    continue_button: "Continue to record the entry of the processed product",
                    file_name: "Attach a detailed packaging guide with example images."
                }
            },
            work_order_replenishment: {
                title: "Entry of Completed Work Order",
                replenishment: {
                    title: "ID of the processed product inventory receipt",
                    description: "Enter the inventory number where we will record the entry of the processed product",
                    completed_description_entry: "The entry of the processed products will be recorded through the",
                    completed_description_replenishment: "inventory receipt"
                }
            }
        }
    },

    billing: {
        page_title: "Billing",
        download_report: "Download this report here",
        downloading_report: "Downloading report...",
        statement: {
            cutoff_at: "Cutoff: {DATE}",
            select_statement: "View period",
            options: {
                LOADING: "Loading periods...",
                CURRENT: "From {FIRST_DATE} to today",
                FIRST: "First period on {LAST_DATE}",
                NORMAL: "From {FIRST_DATE} to {LAST_DATE}",
                SHORTENED: "From {MONTH} {FIRST_DAY} to {LAST_DAY}"
            },
            export_statement_summary: "Export summary",
            exporting_statement_summary: "Exporting summary",
            export_success_statement_summary: "Summary export complete",
            export_error_statement_summary: "An error occurred while exporting summary, please try again later",
            export_services_summary: "Export CSV",
            exporting_services_summary: "Exporting CSV",
            export_success_services_summary: "CSV export complete",
            export_error_services_summary: "An error occurred while exporting CSV, please try again later"
        },
        totals: {
            statement_summary: "Cutoff summary",
            total: "Total to pay",
            total_to_date: "Total amount to date",
            important: "Important: ",
            no_statement: "The final total balances will be reflected up to the cutoff date.",
            payment_limit_date: "Payment deadline",
            next_cutoff_date: "Cutoff date",
            charges_without_taxes: "Charges without VAT",
            charges: "Charges",
            total_discount: "Balance in favor",
            tax_IVA: "VAT",
            percentage_IVA: "% of total"
        },
        averages: {
            order_fulfillment: "Order fulfillment",
            dtc_orders: "Direct to Consumer order fulfillment",
            marketplace_orders: "Marketplace order fulfillment",
            shipping: "Shipping",
            simple_returns: "Returns",
            carrier_returns: "Carrier returns",
            extended_zone: "Extended zone",
            pick_and_pack: "Pick & Pack",
            package: "Packaging",
            difal: "Difal",
            shipping_insurance: "Shipping insurance",
            average_costs_per_order: "Average costs per order without VAT",
            average_costs_per_order_without_taxes: "Average costs per order",
            additional_services: "Additional Services",
            order_average_description: "The average does not include costs associated with inventory withdrawal orders, manufacturing, receipts, storage, additional charges, and balances in favor.",
            average_with_additional_services: "with additional services",
            averages_descriptions: {
                simple_returns: "Costs associated with receiving parts and reverse logistics.",
                carrier_returns: "Costs associated with receiving parts and reverse logistics.",
                pick_and_pack: "Costs associated with product picking, adding inserts, and output control.",
                package: "Costs associated with boxes, packing materials and fragile packaging."
            }
        },
        currencies:{
            MXN: "MXN",
            BRL: "BRL"
        },
        summaries_options: {
            SERVICES_SUMMARY: "Breakdown by concept",
            ORDER_DETAIL: "Orders detail",
            RETURN_DETAIL: "Returns detail",
            CLAIM_DETAIL: "Claims detail"
        },
        services_summary_table: {
            not_available: "Not available",
            no_data: "No information available",
            volumetric_weight_unit: "{VOLUMETRIC_WEIGHT} kg volumetric",
            headers: {
                TITLE: "Concept",
                UNIT: "Unit",
                UNIT_VALUE: "Unit value",
                QUANTITY: "Quantity",
                TOTAL: "Total"
            },
            quantity_notes: {
                STORAGE_BY_LOCATION: "Average usage",
                STORAGE_BY_PRODUCT: "Average usage"
            },
            tables_sections: {
                PICK_AND_PACK: "Picking",
                PICK_AND_PACK_INSERT: "Add an insert",
                PICK_AND_PACK_SERIAL_NUMBER: "Output control by serial number",
                PICK_AND_PACK_FRAGILE: "Fragile package packaging",
                PICK_AND_PACK_PACKAGE: {
                    parcel_bag: "Packaging - Parcel bag",
                    jiffy_envelope: "Packaging - Jiffy envelope",
                    cubbo_box: "Packaging - Cubbo box",
                    customer_box: "Packaging - Customer box"
                },
                PACKING_MATERIAL: "Packing Materials",
                REPLENISHMENT: "Reception of pieces",
                REPLENISHMENT_LOTS: "Reception of pieces with lot control",
                RETURN_ORDER: "Returned pieces reception",
                RETURN_ORDER_LOTS: "Returned pieces reception with lot control",
                RETURN_SHIPPING_LABEL: "Return label",
                SHIPPING_LABEL_PICKUP: "Order charge",
                SHIPPING_LABEL: {
                    SP_CAP: "SP Capital",
                    SP_INT: "SP Interior",
                    RJ_CAP: "RJ Capital",
                    RJ_INT: "RJ Interior",
                    ES_CAP: "ES Capital",
                    ES_INT: "ES Interior",
                    MG_CAP: "MG Capital",
                    MG_INT: "MG Interior",
                    BA_CAP: "BA Capital",
                    BA_INT: "BA Interior",
                    SE_CAP: "SE Capital",
                    SE_INT: "SE Interior",
                    PE_CAP: "PE Capital",
                    PE_INT: "PE Interior",
                    AL_CAP: "AL Capital",
                    AL_INT: "AL Interior",
                    PB_CAP: "PB Capital",
                    PB_INT: "PB Interior",
                    RN_CAP: "RN Capital",
                    RN_INT: "RN Interior",
                    CE_CAP: "CE Capital",
                    CE_INT: "CE Interior",
                    PI_CAP: "PI Capital",
                    PI_INT: "PI Interior",
                    MA_CAP: "MA Capital",
                    MA_INT: "MA Interior",
                    PA_CAP: "PA Capital",
                    PA_INT: "PA Interior",
                    AP_CAP: "AP Capital",
                    AP_INT: "AP Interior",
                    AM_CAP: "AM Capital",
                    AM_INT: "AM Interior",
                    RR_CAP: "RR Capital",
                    RR_INT: "RR Interior",
                    AM_INT_2: "AM Interior 2",
                    AC_CAP: "AC Capital",
                    AC_INT: "AC Interior",
                    DF_CAP: "DF Capital",
                    DF_INT: "DF Interior",
                    GO_CAP: "GO Capital",
                    DF_INT_2: "DF Interior 2",
                    GO_CAP_2: "GO Capital 2",
                    GO_INT: "GO Interior",
                    RO_CAP: "RO Capital",
                    RO_INT: "RO Interior",
                    TO_CAP: "TO Capital",
                    TO_INT: "TO Interior",
                    MT_CAP: "MT Capital",
                    MT_INT: "MT Interior",
                    RO_INT_2: "RO Interior 2",
                    RO_INT_3: "RO Interior 3",
                    MS_CAP: "MS Capital",
                    MS_INT: "MS Interior",
                    PR_CAP: "PR Capital",
                    PR_INT: "PR Interior",
                    SC_CAP: "SC Capital",
                    SC_INT: "SC Interior",
                    RS_CAP: "RS Capital",
                    RS_INT: "RS Interior",
                    SUPER_SAVER: "SUPER SAVER",
                    LOCAL: "LOCAL",
                    PREMIUM: "PREMIUM",
                    ECONOMY: "ECONOMY"
                },
                SHIPPING_LABEL_EXTENDED_ZONE: {
                    SUPER_SAVER: "Extended SUPER SAVER zone",
                    LOCAL: "Extended LOCAL zone",
                    PREMIUM: "Extended PREMIUM zone",
                    ECONOMY: "Extended ECONOMY zone"
                },
                STORAGE_BY_LOCATION: "Storage",
                STORAGE_BY_PRODUCT: "Storage",
                INTERNATIONAL_SHIPPING_LABEL: {
                    NORTH_AMERICA: "North America",
                    LATIN_AMERICA: "Latin America",
                    CARIBBEAN: "Caribbean",
                    EUROPE: "Europe",
                    ASIA: "Asia",
                    AFRICA: "Africa"
                },
                CLAIM: "Disputes",
                UNIDENTIFIED_RETURN: "Unidentified returns",
                SHIPMENTS_RETURN_BY_CARRIER: "Label return by carrier",
                SHIPPING_INSURANCE: "of the order value"
            },
            tables_titles: {
                PICK_AND_PACK: "Pick and pack",
                PICK_AND_PACK_PACKAGE: "Packaging",
                REPLENISHMENT: "Reception",
                RETURN: "Returns",
                SHIPPING_LABEL: "Label generation",
                STORAGE_BY_LOCATION: "Storage",
                STORAGE_BY_PRODUCT: "Storage",
                INTERNATIONAL_SHIPPING_LABEL: "International label generation",
                OTHERS: "Others",
                CREDIT: "Credit balance"
            },
            no_packages: "No packages",
            units: {
                STORAGE_BY_LOCATION: {
                    PER_UNIT: "Charge for occupied location"
                },
                STORAGE_BY_PRODUCT: {
                    PER_UNIT: "Charge for stored item"
                },
                SHIPPING_LABEL_PICKUP: {
                    TOTAL: "Charge per order"
                },
                SHIPPING_LABEL: {
                    TOTAL: "Charge per label",
                    PER_UNIT_EXCESS: "Charge per label"
                },
                SHIPPING_LABEL_EXTENDED_ZONE: {
                    TOTAL: "Charge per label",
                },
                INTERNATIONAL_SHIPPING_LABEL: "Charge for international shipping",
                RETURN_ORDER: {
                    PER_UNIT: "Charge per returned piece",
                    TOTAL: "Charge per return",
                    PER_UNIT_EXCESS: "Charge per returned piece",
                    TOTAL_EXCESS: "Charge per return"
                },
                RETURN_ORDER_LOTS: {
                    PER_UNIT: "Charge per returned piece",
                    TOTAL: "Charge per return",
                    PER_UNIT_EXCESS: "Charge per returned piece",
                    TOTAL_EXCESS: "Charge per return"
                },
                RETURN_SHIPPING_LABEL: {
                    TOTAL: "Charge per label",
                    PER_UNIT_EXCESS: "Charge per label"
                },
                PICK_AND_PACK: {
                    PER_UNIT: "Charge per piece",
                    TOTAL: "Charge per order",
                    PER_UNIT_EXCESS: "Charge per piece",
                    TOTAL_EXCESS: "Charge per order"
                },
                PICK_AND_PACK_INSERT: {
                    PER_UNIT: "Charge per piece",
                    TOTAL: "Charge per order",
                    PER_UNIT_EXCESS: "Charge per piece",
                    TOTAL_EXCESS: "Charge per order"
                },
                PICK_AND_PACK_SERIAL_NUMBER: {
                    PER_UNIT: "Charge per piece",
                    TOTAL: "Charge per order",
                    PER_UNIT_EXCESS: "Charge per piece",
                    TOTAL_EXCESS: "Charge per order"
                },
                PICK_AND_PACK_FRAGILE: {
                    TOTAL: "Charge per package"
                },
                PICK_AND_PACK_PACKAGE: {
                    TOTAL: "Charge per package",
                    TOTAL_EXCESS: "",
                },
                REPLENISHMENT: {
                    PER_UNIT: "Charge per received piece",
                    TOTAL: "Charge per receipt",
                    PER_UNIT_EXCESS: "Charge per received piece",
                    TOTAL_EXCESS: "Charge per receipt",
                },
                REPLENISHMENT_LOTS: {
                    PER_UNIT: "Charge per received piece",
                    TOTAL: "Charge per receipt",
                    PER_UNIT_EXCESS: "Charge per received piece",
                    TOTAL_EXCESS: "Charge per receipt",
                },
                CLAIM: {
                    PER_UNIT: "Per dispute"
                },
                CUSTOM: {
                    TOTAL_DISCOUNT: "Unique discount",
                    TOTAL_CHARGE: "Unique charge"
                },
                REFUND: "Unique refund",
                WORK_ORDER: {
                    PER_UNIT: "People per day"
                },
                UNIDENTIFIED_RETURN: {
                    TOTAL: "Charge per order",
                },
            },
            usage_ranges_details: {
                general: {
                    PIECES: {
                        singular_from: "more than {FROM} piece",
                        plural_from: "more than {FROM} pieces",
                        singular_from_to: "from {FROM} to {TO} piece",
                        plural_from_to: "from {FROM} to {TO} pieces"
                    },
                    ORDERS_PIECES: {
                        singular_from: "orders with more than {FROM} piece",
                        plural_from: "orders with more than {FROM} pieces",
                        singular_from_to: "orders with {FROM} to {TO} piece",
                        plural_from_to: "orders with {FROM} to {TO} pieces"
                    },
                    WEIGHT: {
                        singular_from: "more than {FROM} kg",
                        plural_from: "more than {FROM} kg",
                        singular_from_to: "from {FROM} kg to {TO} kg",
                        plural_from_to: "from {FROM} kg to {TO} kg"
                    },
                    ORDERS_INSERTS: {
                        singular_from: "orders with more than {FROM} insert",
                        plural_from: "orders with more than {FROM} inserts",
                        singular_from_to: "orders with {FROM} to {TO} insert",
                        plural_from_to: "orders with {FROM} to {TO} inserts",
                    }
                },
                PICK_AND_PACK_FRAGILE: "Fragile packaging supplies",
                SHIPPING_LABEL_PICKUP: "Pick up at warehouse / External label",
                SHIPPING_LABEL_EXTENDED_ZONE: {
                    SUPER_SAVER: "Extended SUPER SAVER zone",
                    LOCAL: "Extended LOCAL zone",
                    PREMIUM: "Extended PREMIUM zone",
                    ECONOMY: "Extended ECONOMY zone"
                },
                STORAGE_BY_LOCATION: {
                    LOST: "Lost",
                    PALLET: "Pallet",
                    RECEPTION: "Reception",
                    PRESALE: "Presale",
                    RELOCATION: "Relocation",
                    OFF_PREMISES: "Off Premises",
                    PICKING_MINIBIN: "Minibin",
                    PICKING_STANDARD: "Regular",
                    DAMAGED: "Damaged"
                },
                STORAGE_BY_PRODUCT: {
                    LOST: "Lost",
                    PALLET: "Pallet",
                    RECEPTION: "Reception",
                    PRESALE: "Presale",
                    RELOCATION: "Relocation",
                    OFF_PREMISES: "Off Premises",
                    PICKING_MINIBIN: "Minibin",
                    PICKING_STANDARD: "Regular",
                    DAMAGED: "Damaged"
                },
                INTERNATIONAL_SHIPPING_LABEL: {
                    NORTH_AMERICA: "North America",
                    LATIN_AMERICA: "Latin America",
                    CARIBBEAN: "Caribbean",
                    EUROPE: "Europe",
                    ASIA: "Asia",
                    AFRICA: "Africa"
                },
                CLAIM: "Disputes",
                WORK_ORDER: "Work orders",
                UNIDENTIFIED_RETURN: "Unidentified returns",
            }
        },
        orders_detail: {
            order_number: "Order Number | Sales Channel",
            order_destination: "Destination",
            shipping_method: "Shipping Method | Carrier",
            pick_and_pack: "Pick and Pack | Concepts",
            shipping: "Shipping | Concepts",
            total: "Total",
            no_data: "No orders to display",
            footer: "orders",
            packages: "packages",
            package: "package",
            cubbo: "Cubbo",
            box: "box",
            boxes: "boxes",
            shipping_coverage_categories: {
                SP_CAP: "SP Capital",
                SP_INT: "SP Interior",
                RJ_CAP: "RJ Capital",
                RJ_INT: "RJ Interior",
                ES_CAP: "ES Capital",
                ES_INT: "ES Interior",
                MG_CAP: "MG Capital",
                MG_INT: "MG Interior",
                BA_CAP: "BA Capital",
                BA_INT: "BA Interior",
                SE_CAP: "SE Capital",
                SE_INT: "SE Interior",
                PE_CAP: "PE Capital",
                PE_INT: "PE Interior",
                AL_CAP: "AL Capital",
                AL_INT: "AL Interior",
                PB_CAP: "PB Capital",
                PB_INT: "PB Interior",
                RN_CAP: "RN Capital",
                RN_INT: "RN Interior",
                CE_CAP: "CE Capital",
                CE_INT: "CE Interior",
                PI_CAP: "PI Capital",
                PI_INT: "PI Interior",
                MA_CAP: "MA Capital",
                MA_INT: "MA Interior",
                PA_CAP: "PA Capital",
                PA_INT: "PA Interior",
                AP_CAP: "AP Capital",
                AP_INT: "AP Interior",
                AM_CAP: "AM Capital",
                AM_INT: "AM Interior",
                RR_CAP: "RR Capital",
                RR_INT: "RR Interior",
                AM_INT_2: "AM Interior 2",
                AC_CAP: "AC Capital",
                AC_INT: "AC Interior",
                DF_CAP: "DF Capital",
                DF_INT: "DF Interior",
                GO_CAP: "GO Capital",
                DF_INT_2: "DF Interior 2",
                GO_CAP_2: "GO Capital 2",
                GO_INT: "GO Interior",
                RO_CAP: "RO Capital",
                RO_INT: "RO Interior",
                TO_CAP: "TO Capital",
                TO_INT: "TO Interior",
                MT_CAP: "MT Capital",
                MT_INT: "MT Interior",
                RO_INT_2: "RO Interior 2",
                RO_INT_3: "RO Interior 3",
                MS_CAP: "MS Capital",
                MS_INT: "MS Interior",
                PR_CAP: "PR Capital",
                PR_INT: "PR Interior",
                SC_CAP: "SC Capital",
                SC_INT: "SC Interior",
                RS_CAP: "RS Capital",
                RS_INT: "RS Interior",
                ECONOMY: "Economy",
                LOCAL: "Local",
                SUPER_SAVER: "Super Saver",
                PREMIUM: "Premium",
                NONE_APPLIES: "None Applies"
            },
            order_destination_categories: {
                national: "National",
                international: "International",
                extended_zone: "In Extended Zone"
            },
            picking_and_packing: {
                products_quantity: "picked pieces",
                product_quantity: "picked piece",
                inserts_quantity: "added inserts",
                insert_quantity: "added insert",
                output_control_products_quantity: "products with output control",
                output_control_product_quantity: "product with output control",
                fragiles_quantity: "fragile",
                fragile_quantity: "fragile"
            },
            filters: {
                order_number: "Order number"
            }
        }
    }    
}