export const es = {
    login: {
        title: "Ingresa a tu Cuenta",
        detail: "Por favor ingresa los datos a continuación",
        email: "Email",
        password: "Contraseña",
        required_field:"Este campo es requerido",
        recover_pwd: "Recuperar Contraseña",
        error_message: "usuario/contraseña inválidos",
        button: "Ingresa a tu cuenta",
        get_in: "Ingresando...",
        create_account: "Crear una cuenta"
    },

    forgotPassword: {
        recover_password: "Recupera tu Contraseña",
        enter_your_email: "Por favor ingresa tu email y te mandaremos un email para que recuperes tu contraseña",
        required_field: "Este campo es requerido",
        back_to_login: "Regresar a Login",
        entering: "Ingresando...",
        error: "No ha podido ser!",
        check_your_email:"Checa tu email!",
        password_recovery: "Recupera tu contraseña",

    },

    signup: {
        title: "Crea una cuenta en Cubbo",
        subtitle: "Por favor ingresa los datos a continuación",
        country: "País",
        brand_name: "El nombre de tu marca",
        user_name: "Tu nombre",
        user_email: "Email",
        user_pwd: "Contraseña",
        confirm_pwd: "Repite la Contraseña",
        required_field:"Este campo es requerido",
        invalid_address: "Dirección inválida",
        pwd_characters: "La contraseña debe tener 8 carácteres",
        pwd_no_coincide: "Las contraseñas no coinciden",
        pass_strength: "La contraseña debe cumplir al menos 3 de los siguientes: una mayúscula, una minúscula, un número y/o un caracter especial (!@#$%^&*)",
        back_to_login: "Regresar a Login",
        button: "Crea tu cuenta",
        creating_account: "Creando cuenta..."
    },

    topMenu: {
        stores: "Tiendas",
        home: "Inicio",
        orders: "Pedidos",
        returns: "Retornos",
        inventory: "Inventario",
        work_orders: "Maquilas",
        replenishments: "Recibo de Inventario",
        integrations: "Integraciones",
        invoices: 'Facturas',
        billing: 'Facturación',
        settings: "Configuración",
        help: "Soporte",
        recover_pwd: "Recuperar contraseña",
        logout: "Cerrar sesión",
        spanish: "Español",
        portuguese: "Portugues",
        english: "Inglés",
    },

    stores: {
        store: "Tienda",
        stores: "Tiendas",
        products: "Productos",
        orders: "Pedidos",
        account: "Cuenta",
        created: "Creada",
        favorite: "PREFERIDA",
        go_store: "Ir a tienda",
        status: "Estatus",
        enable_store: "Habilitar tienda",
        disable_store: "Inhabilitar tienda",
        details: "Ver detalle",
        select_as_favorite: "Hacer tienda preferida",
        default_store: "Sí",
        save: "Guardado",
        save_as_favorite: "Se ha guardado tu preferencia de tienda predeterminada.",
        nothing_to_show: "No hay productos que mostrar.",
        store_detail: "Detalle Tienda"
    },

    store_detail_container: {
        name: "Nombre",
        desc: "Descripción",
        created: "Creada",
        go_to_store: "Ir a la tienda..."

    },
    formatted_relative_time: {
        today: "Hoy",
        yesterday: "Ayer",
        tomorrow: "Mañana",
        date: "{{day}} {{month}}",
        from_now: "Hace {{days}} días",
    },
    search_input: {
        search: "Buscar"
    },

    home: {
        order_stats:{
            orders: "Pedidos",
            inventory: "Inventario",
            returns: "Retornos",
            replenishments: "Recibos de inventario",

        },
        title: "¡Bienvenid@ a Cubbo, {{store_name}}!",
        delayed_orders: "Demorados",
        pending_orders: "Pendientes de enviar",
        missing_info: "Falta Información",
        returns_to_check: "Decisión pendiente",
        returning_orders: "Retornando a Cubbo",
        expected_replenishments: "Esperados",
        expected_replenishments_info: "Recibos sin agendar y agendados que llegarán pronto a Cubbo",
        processing_replenishments: "Procesandose",
        orders_without_stock: "Sin Stock",
        products_without_stock: "Productos Sin Stock",
        damaged_stock: "Piezas dañadas",
        expired_stock: "Piezas expiradas",
        reserved_by_returns_stock: "Reservado por retornos",
        packed_or_on_route: "Pedidos en ruta o empacados",
        shipped_this_week: "Pedidos enviados esta semana",
        pending_receipts: "Recibos de Inventario Pendientes",
        table_id: "ID",
        table_status: "Estado",
        estimated_date: "Fecha Esperada de Llegada",
        delivery_date: "Fecha de Llegada",
        percentage: "% COMPLETADO",
        connect_store: "Conecta tu tienda",
        inventory: "Da de Alta tu Inventario",
        ship_pref: "Configura tus preferencias de envío",
        replenish: "Envía inventario a Cubbo",
        start_to_config: "Empieza a configurar tu cuenta.",
        product_register: "Da de alta todos tus productos en Cubbo.",
        import_from_shopify: "Importar desde Shopify",
        import_from_shop: "Traemos automáticamente todos tus productos desde tu tienda Shopify.",
        import_from_excel: "Importar desde Excel",
        upload: "Sube un fichero csv, xls o xlsx con tus productos",
        manual_creation: "Crear Productos Manualmente",
        create_one_by_one: "Crea tus productos uno por uno.",
        connect_store_to_cubbo: "Conecta tu tienda a Cubbo",
        assign_location: "La tienda está conectada, ahora debes conectar una localización a Cubbo",
        assign_location_link: "-> ... -> Asignar Localización",
        connect_everything: "Conecta tu Shopify, WooCommerce, VTEX o si lo prefieres puedes integrarte por API.",
        connect_my_store: "Conectar mi Tienda",
        recommended_same_day: "Recomendado - Same Day",
        sameday_ship: "Esta configuración, asignará siempre el metodo de envío más rápido y económico posible.",
        packs: "Treggo, 99minutos y ServiEntrega",
        custom_packs: "Custom",
        custom_config: "Elige esta configuración para configurar a tu manera cada uno de tus metodos de envío.",
        economy: "Economy",
        economy_config: "Esta configuración, asignará siempre el metodo de envío más económico posible.",
        economy_packs: "Treggo, 99minutos y Estafeta",
        premium: "Premium",
        premium_config: "Esta configuración, asignará siempre el metodo de envío más económico posible en ciudades, y DHL en nacional.",
        premium_packs: "Treggo, 99minutos y DHL",
        required_store_message: "Es requerido tener una tienda conectada para poder configurar metodos de envío automáticos.",
        first_replenishment: "Crea tu primer Recibo de Inventario",
        replenishment_details: "Indicanos que día vamos a recibir tu inventario y sus detalles.",
        create_reple: "Crear Recibo",
        required_inventory_message: "*Para crear un recibo de inventario, se requiere dar de alta tu inventario primero.",
        min_stock: "Productos con poco stock",
        Go_to_Integration: "Ir a la Integración",
        no_pending_receipts: "No hay recibos de inventario pendientes.",
        coming_soon: "Próximamente.",
        Operating_Metrics: "Métricas para ti",
        configuring: "Configurando...",
        select: "Seleccionar",
        metrics_inactive_description: "Sabemos lo importante que son los datos para tomar decisiones en tu negocio. Por ello, cuando enviemos 2000 pedidos mensuales por ti, podrás acceder de manera gratuita a la sección de métricas.",
        metrics_inactive_action: "Aún no envío 2000 pedidos mensuales, pero me gustaría obtener este beneficio"
    },

    order_stats: {
        title: "Resumen de tu tienda",
        subtitle: "Datos en tiempo real de la actividad en tu tienda.",
    },

    metrics: {
        title: "Métricas",
        subtitle: "Selecciona un periodo de tiempo para ver información de los pedidos que fueron empacados durante ese tiempo",
        historics_title: "Históricos con base en el rango de fechas seleccionado",

        coming_soon: "Próximamente prodrás visualizar tus métricas aquí. ¡Espéralo pronto!",

        generic: {
            orders: "Pedidos",
            orders_with_delivery_attempt: "pedidos con intento de entrega",
            items: "Piezas",
            item: "Pieza",
            shipments: "Envíos",
            shipped: "enviados",
            synced: "sincronizados",
            delivered: "entregados",
            durations: {
                seconds: 'segundos',
                minute: 'minuto',
                minutes: 'minutos',
                hour: 'hora',
                hours: 'horas',
                day: 'día',
                days: 'días',
                month: 'mes',
                months: 'meses',
            },
            on_average: "en promedio",
            no_data: "Sin datos",
            of: "de",
        },

        packed_on_time: {
            title: "Pedidos empacados a tiempo",
            subtitle: "Pedidos que cumplieron el tiempo promesa de Cubbo",
        },
        picking_accuracy: {
            title: "Pedidos sin errores de picking",
            subtitle: "Clientes que recibieron justo lo que ordenaron",
        },
        replenishments_on_time: {
            title: "Recibos ingresados a tiempo",
            subtitle: "Recibos de inventario que completamos en menos de 24 horas",
        },

        orders_by_sales_channel_total: {
            title: "Canales de venta",
            main_column_label: "Integración",
            secondary_column_label: "Pedidos",
            grouped_rows_label: "Otras integraciones",
        },
        top_national_destinations: {
            title: "Destinos nacionales con más envíos",
            main_column_label: "Estado",
            secondary_column_label: "Envíos",
            grouped_rows_label: "Otros estados",
            INTERNATIONAL: "Internacional",
            view_others_label: "Ver otros estados",
            go_back_label: "Regresar",
        },
        shipping_methods_total: {
            title: "Paqueterías usadas para tus envíos",
            main_column_label: "Paquetería",
            secondary_column_label: "Envíos",
            grouped_rows_label: "Otras paqueterías",
        },
        stock_per_order_total: {
            title: "Piezas vendidas por pedido",
            main_column_label: "Piezas",
            secondary_column_label: "Pedidos",
            grouped_rows_label: "{{items}} piezas o más",
            item_quantity: "{{items}} piezas",
            item_quantity_single: "1 pieza",
        },

        orders_packed_on_time_historic: {
            title: "Pedidos empacados a tiempo",
            subtitle: "Pedidos que cumplieron el tiempo promesa de Cubbo",
            late: "Tarde",
            on_time: "A tiempo",
        },
        shipping_methods_historic: {
            title: "Paqueterías usadas para tus envíos",
            subtitle: "Volúmen de envíos por paquetería",
        },
        orders_sales_channel_historic: {
            title: "Pedidos por canal de venta",
            subtitle: "Canales de venta que originaron tus pedidos",
            created_at_cubbo: "Creados en Cubbo",
        },
        stock_per_order_historic: {
            title: "Promedio de piezas vendidas por pedido",
            subtitle: "Promedio de productos que tus clientes compran por pedido",
            legend: "Piezas por pedido",
        },
        total_orders_historic: {
            title: "Pedidos enviados",
            highlighted: "{{total}} pedidos",
            highlighted_description: "en total",
            legend: "Pedidos enviados",
        },
        total_shipped_stock_historic: {
            title: "Total de piezas vendidas",
            subtitle: "Cantidad de piezas vendidas por Cubbo",
            legend: "Piezas vendidas",
        },
        click_to_door: {
            title: "Click to door",
            subtitle: "Tiempo desde la compra hasta la entrega",
            average: {
                text: "{{average}} horas",
                description: "en promedio",
            },
            by_days: {
                title: "Tiempo de entrega",
                less_than_1_day: "< 24 horas",
                day: " {{day}} día",
                days: " {{days}} días",
                main_column_label: "Tiempo de entrega",
                secondary_column_label: "Envíos",
                grouped_rows_label: "6+ días",
            },
            historic: {
                title: "Tiempo de entrega",
                day: "día",
                days: "días"
            }
        },
        click_to_packed: {
            title: "Pending to packed",
            subtitle: "Tiempo desde que el pedido se registró con estado pendiente hasta que estuvo empacado",
            information_circle_text: "Un pedido se registra en estado pendiente cuando no falta información y tiene stock disponible para prepararse",
            highlighted: "{{average}} horas",
            highlighted_description: "en promedio",
        },
        collected_to_door: {
            title: "Collection to door",
            subtitle: "Tiempo desde que la paquetería lo recolectó hasta que fue entregado",
            highlighted: "{{average}} horas",
            highlighted_description: "en promedio",
            on_time: "A tiempo",
            late: "Tarde",
            sla_met_total_title: "Pedidos entregados a tiempo",
            sla_first_attempt_met_total_title: "Pedidos con primer intento de entrega a tiempo",
            select: {
                all_carriers: "Todas las paqueterías",
                all_states: "Todos los destinos",
                selected_carriers: "Paqueterías seleccionadas:",
                selected_states: "Estados seleccionados:"
            },
        },
        sales_channel: {
            title: "Canales de venta",
            subtitle: "Por dónde te compran más tus clientes",
        },
        stock_sold: {
            title: "Piezas vendidas",
            subtitle: "Total enviado por Cubbo",
            total_stock_sold: {
                text: "{{total}} piezas",
                description: "en total",
            },
            stock_per_order_average: {
                text: "{{average}} piezas",
                description: "por pedido en promedio",
            },
        },
        replenishments: {
            title: "Recibos de inventario",
            subtitle: "Total de piezas ingresadas a Cubbo",
            total_received: {
                text: "{{total}} piezas",
                description: "recibidas",
            }
        },
        orders_delivered_on_time_by_states: {
            title: "Pedidos entregados a tiempo por estados",
        },
        orders_delivered_on_time_by_carrier: {
            title: "Pedidos entregados a tiempo por paquetería",
        },
        stuck_orders: {
            title: "Pedidos con bloqueos",
            subtitle: "Cuánto tiempo pasaron los pedidos con bloqueo desde la compra hasta que el pedido pasó a estado pendiente",
            average_subtext: "Considerando sólo los pedidos con bloqueo",
            statuses: {
                backorder: "Sin stock",
                error: "Sin información",
                unpaid: "Sin pago",
                hold: "Pausado",
            },
            historic: {
                title: "Pedidos enviados",
                error_title: "Tiempo con bloqueo por falta de información",
                backorder_title: "Tiempo con bloqueo por falta de stock",
                unpaid_title: "Tiempo con bloqueo por falta de pago",
                hold_title: "Tiempo con bloqueo por pausa",
                categories: {
                    error: "Pedidos con bloqueo por falta de información",
                    backorder: "Pedidos con bloqueo por falta de stock",
                    unpaid: "Pedidos con bloqueo por falta de pago",
                    hold: "Pedidos con bloqueo por pausa",
                    valid: "Pedidos fulfilleados sin bloqueo"
                }
            },
            by_reason: {
                title: "Motivos del bloqueo",
                main_column_label: "Motivo",
                secondary_column_label: "Pedidos",
            },
        }
    },

    date_picker: {
        select_placeholder: 'Seleccionar',
        relative_ranges: {
            last_7_days: 'Últimos 7 días',
            last_30_days: 'Últimos 30 días',
            last_month: 'Último mes',
            last_year: 'Último año',
        },
    },
    
    paginator: {
        page: "Página",
        of: "de",
        total_elements: "elementos totales",
        previous: "Anterior",
        next: "Siguiente",
        per_page: " por página",
    },

    orders: {
        tracking_link_without_label: "Rastrear pedido",
        title: "Detalles de pedido",
        show_shipping_incidents: {
            from_date: ". El ",
            evidence_type: {
                product_images:"Fotos a detalle del incidente",
                label_images:"Fotos de etiqueta de envío",
                package_images:"Fotos del paquete"
            },
            created_by: {
                RECIPIENT: "Creado por tu cliente ",
                CUBBO: "Creado por Cubbo ",
            },
            info_types:{
                important: "Importante: "
            },
            toggle_description: {
                hide: "Ocultar ",
                show: "Ver "
            },
            description_title: {
                PENDING: {
                    DELAYED: "información adjunta",
                    MISSING_REFERENCES: "dirección compartida",
                    CANCELED: "información adjunta",
                    DAMAGED: "evidencia compartida",
                    MISSING_PRODUCT: "evidencia compartida",
                    FALSE_DELIVERY: "información adjunta",
                    CHANGE_ADDRESS: "dirección modificada",
                    RESCHEDULE: "fecha de entrega reprogramada",
                },
                IN_REVIEW: {
                    DELAYED: "información adjunta",
                    MISSING_REFERENCES: "dirección compartida",
                    CANCELED: "información adjunta",
                    DAMAGED: "evidencia compartida",
                    MISSING_PRODUCT: "evidencia compartida",
                    FALSE_DELIVERY: "información adjunta",
                    CHANGE_ADDRESS: "dirección modificada",
                    RESCHEDULE: "fecha de entrega reprogramada",
                },
                RESOLVED: {
                    DELAYED: "información adjunta",
                    MISSING_REFERENCES: "dirección compartida",
                    CANCELED: "información adjunta",
                    DAMAGED: "evidencia compartida",
                    MISSING_PRODUCT: "evidencia compartida",
                    FALSE_DELIVERY: "información adjunta",
                    CHANGE_ADDRESS: "dirección modificada",
                    RESCHEDULE: "fecha de entrega reprogramada",
                },
            },
            categories:{
                DELAYED: "Incidente: Demora de entrega",
                MISSING_REFERENCES: "Incidente: Complementar dirección",
                CANCELED: "Incidente: Cancelar entrega",
                DAMAGED: "Incidente: Producto dañado",
                MISSING_PRODUCT: "Incidente: Faltan productos",
                FALSE_DELIVERY: "Incidente: Entrega incorrecta",
                CHANGE_ADDRESS: "Incidente: Cambiar dirección",
                RESCHEDULE: "Incidente: Reprogramar fecha de entrega",
            },
            messages:{
                info:{
                    PENDING: {
                        DELAYED: "Tiempo promesa de resolución de 48-72 horas",
                        MISSING_REFERENCES: "",
                        CANCELED: "",
                        DAMAGED: "Tiempo promesa de resolución de 48-72 horas",
                        MISSING_PRODUCT: "Tiempo promesa de resolución de 48-72 horas",
                        FALSE_DELIVERY: "La paquetería iniciará una investigación sobre este incidente, la cual podría llevarse hasta 5 días",
                        CHANGE_ADDRESS: "",
                        RESCHEDULE: ""
                    },
                    IN_REVIEW: {
                        DELAYED: "Tiempo promesa de resolución de 48-72 horas",
                        MISSING_REFERENCES: "",
                        CANCELED: "",
                        DAMAGED: "Tiempo promesa de resolución de 48-72 horas",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "La paquetería iniciará una investigación sobre este incidente, la cual podría llevarse hasta 5 días",
                        CHANGE_ADDRESS: "",
                        RESCHEDULE: ""
                    },
                    RESOLVED: {
                        DELAYED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        MISSING_REFERENCES: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        CANCELED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        DAMAGED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        MISSING_PRODUCT: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        FALSE_DELIVERY: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        CHANGE_ADDRESS: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        RESCHEDULE: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                    },
                },
                tips:{
                    PENDING: {
                        DELAYED: "Para evitar una doble entrega, te sugerimos esperar la respuesta de paquetería antes de duplicar el pedido",
                        MISSING_REFERENCES: "",
                        CANCELED: "Puedes monitorear el pedido mediante el estado de envío o desde la guía de rastreo",
                        DAMAGED: "",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "Para evitar una doble entrega, te sugerimos esperar la respuesta de paquetería antes de duplicar el pedido",
                        CHANGE_ADDRESS: "",
                        RESCHEDULE: ""
                    },
                    IN_REVIEW: {
                        DELAYED: "Para evitar una doble entrega, te sugerimos esperar la respuesta de paquetería antes de duplicar el pedido",
                        MISSING_REFERENCES: "",
                        CANCELED: "Puedes monitorear el pedido mediante el estado de envío o desde la guía de rastreo",
                        DAMAGED: "",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "Para evitar una doble entrega, te sugerimos esperar la respuesta de paquetería antes de duplicar el pedido",
                        CHANGE_ADDRESS: "",
                        RESCHEDULE: ""
                    },
                    RESOLVED: {
                        DELAYED: {
                            STOLEN: "Lamentamos comentarte que estos casos no son reembolsables. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            LOST: "Te sugerimos iniciar una disputa y duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            NON_REFUNDABLE_DAMAGED: "La paquetería nos notificó que el pedido se dañó durante el envío y no será posible completar la entrega. Lamentablemente, este caso no es elegible para un reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            RETURNED: "El pedido se ha retornado a Cubbo, podrás visualizarlo desde la página de retornos cuando lo recibamos",
                            DELIVERED: "Te sugerimos confirmar con tu cliente que ya ha recibido su compra. De lo contrario, escribe a Soporte Cubbo para apoyarte",
                            REFUNDABLE: "Por favor, inicia una disputa para poder procesar el reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó"
                        },
                        MISSING_REFERENCES: {
                            STOLEN: "Lamentamos comentarte que estos casos no son reembolsables. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            LOST: "Te sugerimos iniciar una disputa y duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            NON_REFUNDABLE_DAMAGED: "La paquetería nos notificó que el pedido se dañó durante el envío y no será posible completar la entrega. Lamentablemente, este caso no es elegible para un reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            RETURNED: "El pedido se ha retornado a Cubbo, podrás visualizarlo desde la página de retornos cuando lo recibamos",
                            DELIVERED: "Te sugerimos confirmar con tu cliente que ya ha recibido su compra. De lo contrario, escribe a Soporte Cubbo para apoyarte",
                            REFUNDABLE: "Por favor, inicia una disputa para poder procesar el reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó"
                        },
                        CANCELED: {
                            STOLEN: "Lamentamos comentarte que estos casos no son reembolsables. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            LOST: "Te sugerimos iniciar una disputa y duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            NON_REFUNDABLE_DAMAGED: "La paquetería nos notificó que el pedido se dañó durante el envío y no será posible completar la entrega. Lamentablemente, este caso no es elegible para un reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            RETURNED: "El pedido se ha retornado a Cubbo, podrás visualizarlo desde la página de retornos cuando lo recibamos",
                            DELIVERED: "Te sugerimos confirmar con tu cliente que ya ha recibido su compra. De lo contrario, escribe a Soporte Cubbo para apoyarte",
                            REFUNDABLE: "Por favor, inicia una disputa para poder procesar el reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó"
                        },
                        DAMAGED: {
                            STOLEN: "Lamentamos comentarte que estos casos no son reembolsables. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            LOST: "Te sugerimos iniciar una disputa y duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            NON_REFUNDABLE_DAMAGED: "La paquetería nos notificó que el pedido se dañó durante el envío y no será posible completar la entrega. Lamentablemente, este caso no es elegible para un reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            RETURNED: "El pedido se ha retornado a Cubbo, podrás visualizarlo desde la página de retornos cuando lo recibamos",
                            DELIVERED: "Te sugerimos confirmar con tu cliente que ya ha recibido su compra. De lo contrario, escribe a Soporte Cubbo para apoyarte",
                            REFUNDABLE: "Por favor, inicia una disputa para poder procesar el reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó"
                        },
                        MISSING_PRODUCT: {
                            STOLEN: "Lamentamos comentarte que estos casos no son reembolsables. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            LOST: "Te sugerimos iniciar una disputa y duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            NON_REFUNDABLE_DAMAGED: "La paquetería nos notificó que el pedido se dañó durante el envío y no será posible completar la entrega. Lamentablemente, este caso no es elegible para un reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            RETURNED: "El pedido se ha retornado a Cubbo, podrás visualizarlo desde la página de retornos cuando lo recibamos",
                            DELIVERED: "Te sugerimos confirmar con tu cliente que ya ha recibido su compra. De lo contrario, escribe a Soporte Cubbo para apoyarte",
                            REFUNDABLE: "Por favor, inicia una disputa para poder procesar el reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó"
                        },
                        FALSE_DELIVERY: {
                            STOLEN: "Lamentamos comentarte que estos casos no son reembolsables. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            LOST: "Te sugerimos iniciar una disputa y duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            NON_REFUNDABLE_DAMAGED: "La paquetería nos notificó que el pedido se dañó durante el envío y no será posible completar la entrega. Lamentablemente, este caso no es elegible para un reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            RETURNED: "El pedido se ha retornado a Cubbo, podrás visualizarlo desde la página de retornos cuando lo recibamos",
                            DELIVERED: "Te sugerimos confirmar con tu cliente que ya ha recibido su compra. De lo contrario, escribe a Soporte Cubbo para apoyarte",
                            REFUNDABLE: "Por favor, inicia una disputa para poder procesar el reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó"
                        },
                        CHANGE_ADDRESS: {
                            STOLEN: "Lamentamos comentarte que estos casos no son reembolsables. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            LOST: "Te sugerimos iniciar una disputa y duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            NON_REFUNDABLE_DAMAGED: "La paquetería nos notificó que el pedido se dañó durante el envío y no será posible completar la entrega. Lamentablemente, este caso no es elegible para un reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            RETURNED: "El pedido se ha retornado a Cubbo, podrás visualizarlo desde la página de retornos cuando lo recibamos",
                            DELIVERED: "Te sugerimos confirmar con tu cliente que ya ha recibido su compra. De lo contrario, escribe a Soporte Cubbo para apoyarte",
                            REFUNDABLE: "Por favor, inicia una disputa para poder procesar el reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó"
                        },
                        RESCHEDULE: {
                            STOLEN: "Lamentamos comentarte que estos casos no son reembolsables. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            LOST: "Te sugerimos iniciar una disputa y duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            NON_REFUNDABLE_DAMAGED: "La paquetería nos notificó que el pedido se dañó durante el envío y no será posible completar la entrega. Lamentablemente, este caso no es elegible para un reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó",
                            RETURNED: "El pedido se ha retornado a Cubbo, podrás visualizarlo desde la página de retornos cuando lo recibamos",
                            DELIVERED: "Te sugerimos confirmar con tu cliente que ya ha recibido su compra. De lo contrario, escribe a Soporte Cubbo para apoyarte",
                            REFUNDABLE: "Por favor, inicia una disputa para poder procesar el reembolso. Te sugerimos duplicar el pedido para hacerle llegar a tu cliente lo que ordenó"
                        },
                    },
                },
                important_info:{
                    PENDING: {
                        DELAYED: "",
                        MISSING_REFERENCES: "La información se compartió con la paquetería. Sin embargo, esto no garantiza que el repartidor reciba los datos previo al intento de entrega, pues ya se encuentra en movimiento",
                        CANCELED: "Se envió una solicitud a la paquetería para cancelar la entrega del pedido. Sin embargo, esto no garantiza que el pedido no sea entregado, pues ya se encuentra en movimiento",
                        DAMAGED: "",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "",
                        CHANGE_ADDRESS: "La información se compartió con la paquetería. Sin embargo, esto no garantiza que el repartidor reciba los datos previo al intento de entrega, pues ya se encuentra en movimiento",
                        RESCHEDULE: "",
                    },
                    IN_REVIEW: {
                        DELAYED: "",
                        MISSING_REFERENCES: "La información se compartió con la paquetería. Sin embargo, esto no garantiza que el repartidor reciba los datos previo al intento de entrega, pues ya se encuentra en movimiento",
                        CANCELED: "Se envió una solicitud a la paquetería para cancelar la entrega del pedido. Sin embargo, esto no garantiza que el pedido no sea entregado, pues ya se encuentra en movimiento",
                        DAMAGED: "",
                        MISSING_PRODUCT: "",
                        FALSE_DELIVERY: "",
                        CHANGE_ADDRESS: "La información se compartió con la paquetería. Sin embargo, esto no garantiza que el repartidor reciba los datos previo al intento de entrega, pues ya se encuentra en movimiento",
                        RESCHEDULE: "",
                    },
                    RESOLVED: {
                        DELAYED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        MISSING_REFERENCES: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        CANCELED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        DAMAGED: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        MISSING_PRODUCT: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        FALSE_DELIVERY: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        CHANGE_ADDRESS: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                        RESCHEDULE: {
                            STOLEN: "",
                            LOST: "",
                            NON_REFUNDABLE_DAMAGED: "",
                            RETURNED: "",
                            DELIVERED: "",
                            REFUNDABLE: ""
                        },
                    },
                },
            },
            resolutions_complements: {
                LOST: {
                    DELAYED: ", pedido extraviado",
                    MISSING_REFERENCES: ", pedido extraviado",
                    CANCELED: ", pedido extraviado",
                    DAMAGED: ", pedido extraviado",
                    MISSING_PRODUCT: ", pedido extraviado",
                    FALSE_DELIVERY: ", pedido extraviado",
                    CHANGE_ADDRESS: ", pedido extraviado",
                    RESCHEDULE: ", pedido extraviado",
                },
                STOLEN: {
                    DELAYED: ", pedido robado",
                    MISSING_REFERENCES: ", pedido robado",
                    CANCELED: ", pedido robado",
                    DAMAGED: ", pedido robado",
                    MISSING_PRODUCT: ", pedido robado",
                    FALSE_DELIVERY: ", pedido robado",
                    CHANGE_ADDRESS: ", pedido robado",
                    RESCHEDULE: ", pedido robado",
                },
                NON_REFUNDABLE_DAMAGED: {
                    DELAYED: ", pedido dañado, no reembolsable",
                    MISSING_REFERENCES: ", pedido dañado, no reembolsable",
                    CANCELED: ", pedido dañado, no reembolsable",
                    DAMAGED: ", pedido dañado, no reembolsable",
                    MISSING_PRODUCT: ", pedido dañado, no reembolsable",
                    FALSE_DELIVERY: ", pedido dañado, no reembolsable",
                    CHANGE_ADDRESS: ", pedido dañado, no reembolsable",
                    RESCHEDULE: ", pedido dañado, no reembolsable",
                },
                RETURNED: {
                    DELAYED: ", retornado a Cubbo",
                    MISSING_REFERENCES: ", retornado a Cubbo",
                    CANCELED: ", retornado a Cubbo",
                    DAMAGED: ", retornado a Cubbo",
                    MISSING_PRODUCT: ", retornado a Cubbo",
                    FALSE_DELIVERY: ", retornado a Cubbo",
                    CHANGE_ADDRESS: ", retornado a Cubbo",
                    RESCHEDULE: ", retornado a Cubbo",
                },
                DELIVERED: {
                    DELAYED: ", pedido entregado",
                    MISSING_REFERENCES: ", pedido entregado",
                    CANCELED:", pedido entregado",
                    DAMAGED: ", pedido entregado",
                    MISSING_PRODUCT: ", pedido entregado",
                    FALSE_DELIVERY: ", pedido entregado",
                    CHANGE_ADDRESS: ", pedido entregado",
                    RESCHEDULE: ", pedido entregado",
                },
                REFUNDABLE: {
                    DELAYED: ", se reembolsará",
                    MISSING_REFERENCES: ", se reembolsará",
                    CANCELED: ", se reembolsará",
                    DAMAGED: ", se reembolsará",
                    MISSING_PRODUCT: ", se reembolsará",
                    FALSE_DELIVERY: ", se reembolsará",
                    CHANGE_ADDRESS: ", se reembolsará",
                    RESCHEDULE: ", se reembolsará",
                },
            },
            status: {
                PENDING: "Pendiente",
                IN_REVIEW: "En revisión",
                RESOLVED: "Resuelto"
            },
        },

        orderErrors: {
            // general
            ORDER_NUMBER_NOT_UNIQUE: "El número de pedido {order_number} ya existe para otro pedido",
            MISSING_SHIPPING_FIELD: "Hay datos faltantes en la dirección del cliente",
            MISSING_TAX_FIELD: "Hay información faltante en los datos de facturación",
            // order_lines
            UNIDENTIFIED_PRODUCT: "El producto con SKU '{sku}' de este pedido, no existe en Cubbo",
            UNIDENTIFIED_PRODUCT_SPECIFIC: "El producto con SKU '{sku}' no existe en Cubbo",
            DUPLICATE_STORE_SKU: "Hay más de un producto en Cubbo con el mismo SKU '{sku}'",
            DUPLICATE_STORE_SKU_SPECIFIC: "Hay más de un producto con el mismo SKU '{sku}' en cubbo",
            MISSING_PRODUCT_SKU: "La integración envió productos sin SKU",
            MISSING_PRODUCT_SKU_SPECIFIC: "La integración ha mandado este producto sin SKU",
            // shipping info
            UNRECOGNIZED_COUNTRY: "No se ha podido identificar el país del destino",
            INVALID_ZIPCODE: "El código postal del destino es inválido",
            UNRECOGNIZED_CITY_OR_STATE: "No se ha podido identificar la ciudad o estado del destino",
            // integration shipping method
            NO_MATCHING_INTEGRATION_SHIPPING_METHOD: "El método de envío de {integration_type} nombrado '{integration_shipping_method_name}' no se ha encontrado en Cubbo",
            NO_MATCHING_INTEGRATION_SHIPPING_METHOD_NO_NAME: "{integration_type} no ha proporcionado el método de envío que debe usarse para este pedido",
            // shipping groups
            NO_SHIPPING_GROUP_ASSIGNED: "El método de envío de la integración no tiene asignada una configuración de envío",
            // shipping method
            MISSING_SHIPPING_METHOD: "No se ha podido asignar una paquetería al pedido debido a información faltante",
            INTERNATIONAL_SHIPPING_METHOD_EXPECTED: "El pedido tiene un destino internacional, pero la paquetería seleccionada es nacional",
            COD_SHIPPING_METHOD_EXPECTED: "El pedido es pago contra entrega pero la paquetería seleccionada no soporta dicho servicio",
            MISSING_EXTERNAL_LABEL: "El pedido requiere guía externa pero ninguna ha sido proporcionada",
            SHIPPING_METHOD_HAS_NO_COVERAGE: "La paquetería no tiene cobertura para el destino",
            UNKNOWN_ORDER_WEIGHT: "No se ha podido determinar el peso total del pedido",
            SHIPPING_COST_UNAVAILABLE: "No se han encontrado precios de envío para este destino",
            BLACKLISTED_ZIP_CODE: "El código postal del destino no cuenta temporalmente con cobertura por ninguna paquetería",
            MISSING_COD_VALUE: "El pedido es pago contra entrega pero el valor de cobro no ha sido proporcionado",
            // taxing info
            BILLING_TAX_ID_WRONG_LENGTH: "El número de identificación fiscal proporcionado es incorrecto",
            INVALID_PRODUCT_INVOICING_PRICE: "El producto con SKU '{sku}' tiene un precio inválido",
            INVALID_PRODUCT_INVOICING_PRICE_SPECIFIC: "Este producto tiene un precio inválido",
            INVALID_PRODUCT_NCM: "El NCM del producto con SKU '{sku}' es inválido",
            INVALID_PRODUCT_NCM_SPECIFIC: "Este producto no tiene configuración fiscal",
            TOTAL_ORDER_VALUE_MISMATCH: "Hay diferencias en el precio total del pedido, {channel_integration_type} reportó {expected_total_price} y Cubbo calculó {calculated_total_price}, para facturar se requiere confirmación de precios",
            MELI_ORDER_NOT_READY_TO_SHIP: "Mercadolibre aún no ha generado la etiqueta de envío. En cuanto sea generada actualizaremos este pedido.",
            INVALID_TOTAL_ORDER_VALUE: "El valor total del pedido no es válido",
            NO_INVOICEABLE_PRODUCTS: "Ningún producto de tu pedido es facturable",

            // DIFAL
            MISSING_DIFAL_PAYMENT_RECEIPT: "El pedido requiere comprobante de pago de la DIFAL"
        },

        orderErrorsSolutions: {
            // general
            ORDER_NUMBER_NOT_UNIQUE: "Elige otro número para identificar a este pedido",
            MISSING_SHIPPING_FIELD: "Edita el pedido e ingresa los datos de envío faltantes para poder procesar el pedido",
            MISSING_TAX_FIELD: "Edita el pedido e ingresa los datos de facturación faltantes para poder procesar el pedido",
            // order_lines
            UNIDENTIFIED_PRODUCT: "Crea el producto en Cubbo o edita el pedido para eliminarlo de la orden y poder procesar el pedido",
            UNIDENTIFIED_PRODUCT_SPECIFIC: "",
            DUPLICATE_STORE_SKU: "Contacta a soporte para identificar el origen del error y poder solucionarlo",
            DUPLICATE_STORE_SKU_SPECIFIC: "",
            MISSING_PRODUCT_SKU: "Es posible que haya algo que modificar en tu integración para corregir este error. Contacta a soporte en caso de requerir apoyo adicional",
            MISSING_PRODUCT_SKU_SPECIFIC: "Edita el pedido y modifica el país de la dirección del cliente para poder identificar el destino correcto",
            // shipping info
            UNRECOGNIZED_COUNTRY: "Edita el pedido y modifica el país de la dirección del cliente para poder identificar el destino correcto",
            INVALID_ZIPCODE: "Edita el pedido y modifica el CP de la dirección del cliente para poder identificar el destino correcto",
            UNRECOGNIZED_CITY_OR_STATE: "Edita el pedido y modifica la ciudad y/o estado de la dirección del cliente para poder identificar el destino correcto",
            // integration shipping method
            NO_MATCHING_INTEGRATION_SHIPPING_METHOD: "Ve a la configuración de la integración y sincroniza los métodos de envío para identificar el que fue asignado a este pedido y poder asignar una paquetería", // duda
            NO_MATCHING_INTEGRATION_SHIPPING_METHOD_NO_NAME: "Edita el pedido y asigna un método de envío",
            // shipping groups
            NO_SHIPPING_GROUP_ASSIGNED: "Elige la configuración de envío del método de envío asignado a este pedido", // duda
            // shipping method
            MISSING_SHIPPING_METHOD: "Edita el pedido y asigna una paquetería para enviar este pedido", // duda
            INTERNATIONAL_SHIPPING_METHOD_EXPECTED: "Edita el pedido y modifica la paquetería asignada a este pedido", // duda
            COD_SHIPPING_METHOD_EXPECTED: "Edita el pedido y selecciona otra paquetería para poder procesar el pedido",
            MISSING_EXTERNAL_LABEL: "Edita el pedido y añade los documentos de envío necesarios",
            SHIPPING_METHOD_HAS_NO_COVERAGE: "Edita el pedido y selecciona otra paquetería para enviar este pedido",
            UNKNOWN_ORDER_WEIGHT: "Edita el producto con SKU {sku} y añade el peso correspondiente del producto", // duda
            SHIPPING_COST_UNAVAILABLE: "Corrobora que el peso de los productos del pedido sea correcto, de ser así, contacta a soporte para apoyarte a solucionar el error", // duda
            BLACKLISTED_ZIP_CODE: "Contacta al equipo de soporte para obtener mas información",
            MISSING_COD_VALUE: "Edita el pedido e ingresa el monto a cobrar de este pedido", // duda
            // taxing info
            BILLING_TAX_ID_WRONG_LENGTH: "Edita el pedido y modifica el número de identificación fiscal para poder procesar el pedido",
            INVALID_PRODUCT_INVOICING_PRICE: "Edita el pedido y asigna un precio por item a este producto",
            INVALID_PRODUCT_INVOICING_PRICE_SPECIFIC: "",
            INVALID_PRODUCT_NCM: "Da click en el nombre del producto para editarlo y modifica la configuración del NCM asignado",
            INVALID_PRODUCT_NCM_SPECIFIC: "",
            // TOTAL_ORDER_VALUE_MISMATCH: "Edita el pedido para ajustar los precios según el monto a facturar deseado. Si deseas facturar a pesar de la diferencia de precios, entra a editar el pedido y guárdalo sin modificar los precios para confirmar la facturación con el monto calculado por Cubbo.",
            TOTAL_ORDER_VALUE_MISMATCH: "Edita el pedido para ajustar los precios según el monto a facturar deseado.",
            MELI_ORDER_NOT_READY_TO_SHIP: "No es necesario que realices alguna acción adicional pues la actualización será automática",
            INVALID_TOTAL_ORDER_VALUE: "El valor del pedido debe ser mayor a 0, o estar completamente descontado",
            NO_INVOICEABLE_PRODUCTS: "Al menos un producto del pedido debe ser facturable",

            // DIFAL
            MISSING_DIFAL_PAYMENT_RECEIPT: "Esta orden se liberará automáticamente. Si después de 15 minutos la orden aún no está pendiente, consulta el saldo en el Banco Útil.",
        },

        addContainer: {
            no_address: "Dirección no suministrada",
            same_address: "La misma dirección del envío",
            existing_order: "El número de orden ya existe, intenta con otro",
            shipping_not_available: "El método de envío que seleccionaste no esta disponible para tu codigo postal: ",
            unknown_error: "Error desconocido",
            shipping_method: "Debes seleccionar un método de envío",
            external: "Externo",
            shipping_market: "Mercado Envíos",
            edit_order: "Editar Orden",
            create_order: "Crear Orden",
            keep_order: "¿Deseas guardar la orden?",
            create_orders: "¿Deseas crear la orden?",
            shipping_address: "Editar dirección de envío",
            edit_invoicing: "Editar dirección de facturación",
            details: "Detalles",
            method_of_shipment: "Metodo de envío",
            shipping_market:"Mercado Envíos",
            order: "No de orden",
            remove_products: "Productos a retirar",
            tooltip_order_number: "Este sufijo no es editable y se añadirá a el número de orden para identificarlo como retiro de inventario",
            order_number_placeholder: "Escribe un no° identificador de la orden",
            customer_address: "Dirección del cliente",
            edit: "Editar",
            billing_address: "Dirección del facturación",
            products: "Productos",
            only_numbers: "Cantidad tiene que ser siempre un número.",
            no_whitespace: "No debe contener espacios.",
            imports_products: "Importa productos a esta orden",
            imported: "Importados!",
            import: "Importar Excel",
            import_orders: "Varios pedidos desde un excel",
            quantity: "Cantidad",
            add_product: "Añadir producto",
            is_being: "Estamos",
            processing: "procesando",
            cannot_be_modified: "la orden, los productos ya no podrán ser modificados.",
            attachments: "Archivos Adjuntos",
            attach_document: "El método de envío seleccionado requiere que se adjunten documentos de envío.",
            shipping_documents: "Documentos de envío",
            Label: "Etiqueta",
            document: "Documento",
            cancel: "Cancelar",
            save: "Guardar",
            change_donation: "¿Este pedido es una donación?",
            donation_description: "Este pedido será definido como donación",
            prices: "Precios",
            total_per_item: "Total por items: ",
            shipping_price: "Precio de envio: ",
            discount_price: "Descuento: ",
            total_price: "Total del pedido: ",
            cod: "¿Este pedido es pago contra entrega?",
            paqs_cod: "Marca si la paquetería deberá cobrar al entregar este pedido",
            total_price_cod: "Precio total de la compra",
            coin: "Moneda",
            product_select: "Selecciona uno o más productos para tu pedido:",
            product_select_remove_stock: "Selecciona los productos que deseas retirar del almacen:",
            limit_products: "Hemos limitado la cantidad de productos a un máximo de 1000 unidades por orden.",
            mandatory_field: "Conoce el precio total de tus productos y añade el precio de envio:",
            stock_status_to_remove: "Estado del producto a retirar:",
            available_stock: "En buen estado",
            damaged_stock: "Dañado",
            expired_stock: "Expirado",
            add_all_available_products: "Añadir todos los productos",
            add_all_expired_products: "Añadir todos los productos expirados",
            add_all_damaged_products: "Añadir todos los productos dañados",
            all_available_added: "Se han añadido todos los productos disponibles",
            no_damaged_products_to_add: "No hay productos dañados",
            no_expired_products_to_add: "No hay productos expirados",
            all_damaged_added: "Se han añadido todos los productos dañados disponibles",
            all_expired_added: "Se han añadido todos los productos expirados disponibles",
            rem_same_condition_products: "Un pedido de retiro solo puede contener productos del mismo estado",
            import_not_available: "La importación no está disponible para este tipo de pedido",
            add_all_not_available: "Añadir todos no está disponible para este tipo de pedido",
        },
        fileFields: {
            order_number: "Número de Orden",
            sku:"SKU",
            quantity: "Cantidad",

            unit_price: "Valor Unitário",
            shipping_price: "Valor de Frete",
            discount_per_item: "Descuento por producto",
            total_price: "Valor Total en MXP",
            cpf_cnpj: "CPF/CNPJ",
            state_estadual: "Inscrição Estadual",

            name: "Nombre del Cliente",
            last_name: "Apellido",

            shipping_email: "Email",
            shipping_phone: "Teléfono",
            shipping_address1: "Dirección",
            shipping_address2:"Complemento",
            shipping_number:"Número",
            shipping_neighborhood:"Colonia",
            shipping_city:"Ciudad",
            shipping_state:"Estado",
            shipping_zip_code:"Código Postal",
            shipping_country:"País",
            shipping_configuration : "Configuración de Envio",
            is_cod: "¿Es pago contra entrega?",
            coin: "Moneda",

            billing_tax_regime:"Pessoa Física ou Jurídica",
            order_type:"Venda ou Doação",
        },
        addresView: {
            name: "Nombre",
            surname: "Apellidos",
            phone: "Telefono",
            address: "Dirección",
            billing_tax_id: "Identificación fiscal",
            billing_company: "Compañía",
            billing_tax_regime: "Régimen fiscal",
            billing_state_registration: "Número de registro estatal",
            apartment: "Apartamento, local, colonia, etc. (opcional)",
            billing_number: "Número",
            billing_neighborhood: "Barrio",
            country: "Pais",
            city: "Ciudad",
            state: "Estado / Provincia",
            postal_code: "Codigo Postal",
            save: "Guardar",
            cancel:"Cancelar",
            required_field: "Este campo es requerido.",
            postal_code_req_br: "Campo requerido, mínimo 8 caracteres, sin guiones (-)",
            postal_code_req_br_wrong: "Máximo 8 caracteres",
            postal_code_req_co: "Este campo es requerido, mínimo 6 caracteres.",
            postal_code_req_co_wrong: "Máximo 8 caracteres",
            postal_code_req_mx: "Este campo es requerido, se necesitan 5 caracteres.",
            postal_code_req_mx_wrong: "Máximo 5 caracteres"
        },

        duplicate: {
            no_address: "Dirección no suministrada",
            same_address: "La misma dirección del envío",
            existing_order: "El número de orden ya existe, intenta con otro",
            cannot_be_duplicated: "Esta orden no se puede duplicar ya que los productos no se encuentran en Cubbo",
            shipping_not_available: "El método de envío SAMEDAY no esta disponible para tu codigo postal",
            unknown_error: "Error desconocido",
            shipping_method: "Debes seleccionar un método de envío",
            create_duplicate: "Crear Duplicado",
            sure_to_duplicate: "¿Deseas duplicar la orden?",
            edit_address: "Editar dirección de envío",
            edit_invoicing: "Editar dirección de facturación",
            details: "Detalles",
            method_of_shipment: "Metodo de envío",
            order: "No de orden",
            customer_address: "Dirección del cliente",
            edit: "Editar",
            billing_address: "Dirección del facturación",
            products: "Productos",
            quantity: "Cantidad",
            only_numbers: "La cantidad siempre debe ser un número.",
            imports_products: "Importa productos a esta orden",
            imported: "Importados!",
            import: "Importar",
            add_product: "Añadir producto",
            is_being: "Estamos",
            processing: "procesando",
            cannot_be_modified: "la orden, los productos ya no podrán ser modificados.",
            cancel: "Cancelar",
            save: "Guardar",
        },

        productTable: {
            name: "Nombre",
            sku: "SKU",
            quantity: "Cantidad",
            price_per_item: "Precio por item",
            discount_per_item: "Descuento por item",
            no_products: "No has agregado productos.",
            available: "Disponible",
            damaged: "Dañado",
            expired: "Expirado",
            total_qty: "Total de unidades a retirar:",
            total_value: "Valor total de retiro:",
            invalid_discount: "El descuento debe ser menor al precio"
        },

        selectProductDialog: {
            name: "Nombre",
            sku: "SKU",
            no_added_products: "No has agregado productos.",
            cancel: "Cancelar",
            no_orders_to_show: "No hay pedidos que mostrar.",
            add: "Agregar",
            products: "Productos"
        },

        claimContainer: {
            initiate_dispute: "Iniciar Disputa",
            create_disput: "Confirma que deseas crear una disputa de",
            the_order: "para la orden con número",
            confirm: "Confirmar",
            cancel: "Cancelar",
            error: "Hubo un error inesperado:",
            disputes: "Sólo puedes iniciar disputas para pedidos que han sido enviados.",
            initiate_disputes: "Sólo puedes iniciar disputas hasta 30 días después de la fecha de envío de la orden.",
            subsequent_disputes: "Podrás iniciar disputas hasta 30 días posteriores al envío de la orden.",
            order_number: "Número de orden",
            dispute: "Disputa",
            products: "Valor de productos a compensar",
            important: "IMPORTANTE: No incluir costos relacionados con el envío o manejo de la orden en este campo. Este campo debe usarse exclusivamente para introducir el costo de los productos perdidos o guías internacionales pagadas por el cliente para devolver el producto. Si la disputa es aceptada, el costo de envío y manejo relacionado a la orden será rembolsado automáticamente, al igual que cualquier costo adicional referente a la generación de guías para devolución o envío de productos faltantes. Cualquier cantidad introducida que no sea justificada puede resultar en el rechazo de la disputa, aunque esta sea legítima.",
            compensation_testing: "Pruebas de Compensación",
            cancel: "Cancelar",
            save: "Guardar",
            duplicate_and_resend: "Duplicar y Reenviar el pedido antes que se resuelva la disputa.",
        },
        remove_stock:{
            create:{
                title: "¡Orden de retiro de inventario generada!",
                subtitle: "Puedes ubicarlo entre tus pedidos con el número:",
                when_pickup:{
                    title: "¿Cuándo puedo recogerlo?",
                    subtitle:"Al ser procesado como un pedido de retiro, el tiempo de espera dependerá del volumen de piezas a retirar.",
                    information: "Puedes consultar un aproximado:",
                    link: " aquí."
                },
                when_is_ready:{
                    title:"¿Cómo sabré cuando esté listo el pedido?",
                    subtitle: "Cuando el pedido lleve como estado “Enviado” singificará que ya puedes pasar al almacén a recogerlo." ,
                    information: "Te notificaremos por correo cuando esto suceda. "
                },
                make_appointment:{
                    title: "¿Tengo que hacer cita para recogerlo?",
                    subtitle: "No. Sólo recuerda que debes pasar a recoger el pedido en un horario de 9 am a 4 pm con tu No° de pedido y el nombre de tu tienda."
                },
                send_information:{
                    title: "¡Te enviamos esta información por correo!"
                }
            }
        },

        filters: {
            order_number: "Número de pedido",
            sku: "Que contengan el SKU",
            shipping_number: "Número de guía",
            channel_name: "Canal de venta",
            carrier_name: "Método de envío",
            status: "Estado del pedido",
            shipping_status: "Estado de envío",
            shipping_name: "Comprador o correo",
            shipping_date: "Fecha de envío",
            channel_created_at: "Fecha de creación",
            channel_name_placeholder: "Selecciona un canal de venta",
            shipping_method_placeholder: "Selecciona un método de envío",
            shipping_status_placeholder: "Selecciona un estado de envío",
            status_placeholder: "Selecciona un estado de pedido",
            is_delayed: "Demorados",
            order_click_and_collect: "Click and collect",
            shipping_incident: "Incidente con paquetería",
            shipping_incident_category_placeholder: "Cualquier categoría",
            shipping_incident_status_placeholder: "Cualquier estado",
        },

        list: {
            requiring_resolution: "Requieren resolución",
            requiring_monitoring: "Requieren monitoreo",
            eta: "Entrega estimada",
            eta_rescheduled: "Nueva entrega estimada",
            original_eta: "Entrega estimada original",
            time_zone_info: "Fecha en horario local ({{time_zone}})",
            order_number: "Nº DE PEDIDO",
            channel_name_items: "Canal de venta | Piezas",
            item: "pieza",
            items: "piezas",
            shipping_method: "Método de envío",
            status: "Estado del pedido",
            status_desc: "En manos de Cubbo",
            shipping_status: "Estado de envío",
            shipping_status_desc: "En manos de paquetería",
            full_name: "Comprador",
            no_information: "Sin estado de envío",
            no_pieces: "Sin productos a enviar",
            shipping: "MÉTODO DE ENVÍOs",
            all_stores: "Todas las tiendas",
            orders: "Pedidos",
            create_remove_stock: "Pedido para retirar inventario",
            disputes: "Disputas",
            export_button: "Exportar",
            export_invoice_button: "Descargar",
            export_title: 'Exportar pedidos',
            shipping_date: "enviado",
            show_orders: "Ver pedido",
            duplicate_order: "Duplicar pedido",
            duplicate_order_action: "Duplicar",
            edit_order_action: "Editar pedido",
            config_shipping_method: "Configurar",
            pause_order_action: "Pausar",
            cancel_order_action: "Cancelar",
            pay_order: "Marcar como pagado",
            view_return: "Ver retorno",
            edit_order: "Editar pedido",
            edit_order_remove_stock: "Editar orden para retiro de inventario",
            interrupt_order: "Interrumpir pedido",
            sure_to_interrupt_shipment: "¿Seguro que deseas interrumpir el envío de este pedido?",
            interrupt_shipment_disclaimer: "<p><strong>Importante: No se garantiza la cancelación del envío</strong>, pues el pedido ya se encuentra en el área de embarque.</p><p>Si se logra cancelar el envío, <strong>se cobrará el servicio de Pick and Pack</strong> y será reingresado como un retorno a tu inventario.</strong></p>",
            interrupt_notes: "Retorno creado por interrupción del envío del pedido #{{order_number}} (id: {{order_id}}). Interrumpido por el usuario {{user_email}}.",
            cancel_order: "Cancelar pedido",
            sure_to_cancel: "¿Estás seguro de cancelar el pedido?",
            sure_to_cancel_with_difal: "Aviso: Este pedido ya tiene el DIFAL pagado",
            pause_order: "Pausar pedido",
            sure_to_pause: "¿Estás seguro de pausar el pedido?",
            reset_order: "Reiniciar pedido",
            sure_to_reboot:" ¿Estás seguro de reiniciar el pedido?",
            sure_to_pay: "¿Estás seguro de marcar el pedido como pagado?",
            create_claim: "Iniciar Disputa",
            claim_created: '¡Disputa generada!',
            create_return: "Iniciar Devolución",
            no_orders_to_show: "No hay pedidos que mostrar.",
            footer_label: "pedidos",
            create_order: "Crear pedido",
            create_order_remove_stock: "Orden de retiro de inventario",
            create_orders: "Crear pedidos",
            shipping_date_placeholder: {
                start: "Inicio",
                end: "Fin"
            },
            since: "Desde",
            at: "El",
            few_moments: "Hace un momento",
            ago: "Hace",
            days: "días",
            day: "día",
            created_at: "Creado el",
            shipped_at: "Enviado el",
            pending_at: "Pendiente el",
            report_delay: "Reportar demora",
            external_label_desc: "Con guía externa, rastrea el pedido directo con la paquetería",
            export_dialog: {
                order_number: 'Con número de orden igual o parecido a: ',
                tracking_number: 'Con número de guía de envio igual a: ',
                channel_name: 'Que fueron creados en: ',
                status_one: 'Cuyo estado sea ',
                status_other: 'Cuyo estado sea alguno de: ',
                shipping_status_one: 'Cuyo envío esté en estado: ',
                shipping_status_other: 'Cuyo envío esté en alguno de los estados: ',
                with_shipping_incident: 'Cuyo status con paquetería sea: ',
                shipping_incident_category: 'Cuyo incidente esté en alguna de estas categorías: ',
                shipping_incident_status: 'Cuyo incidente esté en alguna de estos estados: ',
                shipping_name: 'Con destinatario igual o parecido a: ',
                shipping_date: 'Que hayan sido enviados entre el ',
                shipping_date_join: ' y el ',
                channel_created_at: 'Que hayan sido creados entre el ',
                channel_created_at_join: ' y el ',
                shipping_method: 'Con método de envío: ',
                sku: 'Que contengan el SKU: ',
                intro: 'Se exportarán pedidos que cumplan con las siguientes condiciones: ',
                confirm: 'Descargar',
                cancel: 'Cancelar',
                default_description: 'Se exportarán todos los pedidos de tu cuenta. Si deseas, usa los filtros para obtener un reporte específico.'
            },
            extended_zone: "Zona extendida",
            extended_zone_tooltip: "Pedidos de zona extendida suelen tener un mayor tiempo de entrega y tienen cargos extras"
        },

        action_bar: {
            select_action: "Selecciona una acción",
            orders_selected: "Pedidos seleccionados",
            orders: "Pedidos",
            deselect_all_orders: "Limpiar selección",
            actions: {
                CANCEL_ORDERS: "Cancelar pedidos",
                HOLD_ORDERS: "Pausar pedidos",
                UNHOLD_ORDERS: "Reiniciar pedidos",
                MARK_AS_PAYED: "Marcar como pagado"
            },
            notification: {
                canceling_orders: "Cancelando pedidos",
                orders_canceled: "Se han logrado cancelar <strong>{{canceled}} de los {{total}} pedidos seleccionados"
            },
            errors: {
                codes: {
                    ORDER_CANCELED: "Ya estan cancelados",
                    ORDER_SHIPPED: "No es posible cancelarlos",
                    ORDER_ON_HOLD: "Ya estan en pausa",
                    ORDER_CANT_BE_HOLD: "No es posible pausarlos",
                    ORDER_CANT_BE_UNHOLD: "No es posible reiniciarlos",
                    ORDER_CANT_MARK_PAID: "No es posible marcarlos como pagado",
                    valid: {
                        CANCEL_ORDERS: "Se cancelarán",
                        HOLD_ORDERS: "Se pausarán",
                        UNHOLD_ORDERS: "Se reiniciarán",
                        MARK_AS_PAYED: "Se marcarán como pagados"
                    }
                },
                error_modal: {
                    title: "Revisar pedidos",
                    confirmations: {
                        CANCEL_ORDERS: '¿Estás seguro de que deseas cancelar los {{order_count}} pedidos seleccionados?',
                        HOLD_ORDERS: '¿Estás seguro de que deseas pausar los {{order_count}} pedidos seleccionados?',
                        UNHOLD_ORDERS: '¿Estás seguro de que deseas reiniciar los {{order_count}} pedidos seleccionados?',
                        MARK_AS_PAYED: '¿Estás seguro de que deseas marcar como pagado los {{order_count}} pedidos seleccionados?',
                        no_valid_elements: 'No hay pedidos válidos para esta acción',
                        omit_errors_and_submit: "Confirmar {{action}}",
                        warning_cancel_with_difal: 'Aviso: Pedidos {{orders}} ya tienen el DIFAL pagado'
                    },
                    messages: {
                        action_not_possible: 'Para {quantity_errors} de {quantity} pedidos seleccionados, no será posible realizar esta acción',
                        omit_errors_and_submit_question: '¿Quieres omitir estos pedidos y aceptar el resto de la selección?',
                        order_numbers_with_errors: 'Número de los pedidos con errores',
                        no_valid_elements_tooltip: 'No es posible realizar esta acción para ninguno de los pedidos seleccionados',
                    },
                    action_type: {
                        CANCEL_ORDERS: "cancelación",
                        HOLD_ORDERS: "pausa",
                        UNHOLD_ORDERS: "reinicio",
                        MARK_AS_PAYED: "pago"
                    },
                    collapse: {
                        show: "Ver número de pedidos",
                        hide: "Ocultar",
                        description: "El estado del pedido no es compatible con esta acción"
                    }
                }
            },
        },

        shipping_incident : {
            status : {
                PENDING: "Pendiente",
                IN_REVIEW: "En revisión",
                RESOLVED: "Resuelto",
            },
            category : {
                DELAYED: "Demora de entrega",
                MISSING_REFERENCES: "Complemento de dirección",
                CANCELED: 'Cancelar entrega',
                DAMAGED: 'Pedido dañado',
                MISSING_PRODUCT: 'Faltan productos',
                FALSE_DELIVERY: 'Entrega incorrecta',
                CHANGE_ADDRESS: "Dirección modificada",
                RESCHEDULE: "Entrega reprogramada"
            },
            resolution: {
                STOLEN: 'Pedido robado',
                LOST: 'Pedido extraviado',
                RETURNED: 'Retornando al almacén',
                DELIVERED: 'Pedido entregado',
                NON_REFUNDABLE_DAMAGED: 'Pedido dañado no reembolsable',
                REFUNDABLE: 'El pedido se reembolsará'
            },
            reported_to_carrier: 'Reportado a la paquetería',
            with_shipping_incident: 'Con incidente'
        },

        lineItem: {
            dropshipping: "Este producto no es enviado por Cubbo",
            fragile_product: "producto es frágil",
            create_product: "Crear Producto",
            presale: "Preventa",
            no_stock: "Sin stock",
            not_enough_stock:"Stock incompleto",
            damaged: "Dañado",
            item_generated: "Esta partida ha sido generada por el sistema, si no hay producto en existencia el pedido se mandará sin esta partida.",
            generating: "Generando",
            generated: "Generado por regla de negocio",
            quantity: "Cantidad ",
            generated_info: "Este producto se generó por sistema, si no hay un producto en existencia el pedido se enviará sin este",
        },

        pricesContainer: {
            price_concept: "Concepto",
            price_amount: "Monto por cobrar",
            price_amount_desc: "La paquetería debe cobrar ",
            price_amount_desc_continuation: " al entregar este pedido, pues el envío es de tipo Cash on Delivery.",
            total_items_price: "Precio total por items",
            discount: "Descuento",
            shipping_price: "Precio de envío",
            extra_fees_price: "Cargos adicionales",
            total_order_price: "Total de la orden",
        },

        dateTime: {
            minute: "minuto",
            minutes: "minutos",
            hour: "hora",
            hours: "horas",
            day: "día",
            days: "días",
        },

        missingDataContainer: {
            solution: "Solución: ",
            missingData: "Datos faltantes",
        },

        orderMovementContainer: {
            orderMovement: "Movimiento del pedido",
            estimatedDelivery: "Entrega estimada",
            estimatedDeliveryNotAvailable: "Sin información",
            estimatedDeliveryAccuracy: "Estimación con base en el CP",
            incidents: "Incidentes reportados a paquetería",
        },

        orderHistory: {
            title: 'Historial del pedido',
            status_title: 'Estado',
            action_by: 'Por',
            no_history: 'Sin información',
            by_integration: 'la integración',
            from_tracking: 'mediante la página de rastreo',
            timelineElements: {
                initial: {
                    title: 'Sincronizando',
                    titleAlternative: 'Sincronizado',
                },
                empty: {
                    title: 'Pedido vacío',
                    description: 'El pedido se recibió desde la integración sin productos asignadas para fulfillear',
                },
                pending: {
                    title: 'Pendiente',
                },
                reset_pending: {
                    title: 'Cuenta reactivada',
                },
                error: {
                    title: "Falta información",
                    description: "Datos faltantes",
                    codes: {
                        // general
                        ORDER_NUMBER_NOT_UNIQUE: "Número de pedido duplicado",
                        MISSING_SHIPPING_FIELD: "Datos faltantes en la dirección del cliente",
                        MISSING_TAX_FIELD: "Información faltante en los datos de facturación",

                        // order_lines
                        UNIDENTIFIED_PRODUCT: "Producto con SKU '{sku}' no existe en Cubbo",
                        DUPLICATE_STORE_SKU: "SKU duplicado: '{sku}'",
                        MISSING_PRODUCT_SKU: "Productos sin SKU enviados por la integración",

                        // shipping info
                        UNRECOGNIZED_COUNTRY: "País no identificado",
                        INVALID_ZIPCODE: "CP inválido",
                        UNRECOGNIZED_CITY_OR_STATE: "Ciudad o estado no identificado",

                        // integration shipping method
                        NO_MATCHING_INTEGRATION_SHIPPING_METHOD: "Método de envío asignado no se encontró en Cubbo",
                        NO_MATCHING_INTEGRATION_SHIPPING_METHOD_NO_NAME: "Sin asignación de método de envío",

                        // shipping groups
                        NO_SHIPPING_GROUP_ASSIGNED: "Método de envío sin configuración de envío asignada",

                        // shipping method
                        MISSING_SHIPPING_METHOD: "Sin método de envío asignado por falta de información",
                        INTERNATIONAL_SHIPPING_METHOD_EXPECTED: "Método de envío asignado sin cobertura internacional",
                        COD_SHIPPING_METHOD_EXPECTED: "Método de envío asignado no disponible para pago contra entrega",
                        MISSING_EXTERNAL_LABEL: "Falta guía externa",
                        SHIPPING_METHOD_HAS_NO_COVERAGE: "Método de envío sin cobertura",
                        UNKNOWN_ORDER_WEIGHT: "Peso del pedido no determinado",
                        SHIPPING_COST_UNAVAILABLE: "Precio de envío no disponible",
                        BLACKLISTED_ZIP_CODE: "Código postal temporalmente sin cobertura",
                        MISSING_COD_VALUE: "Valor de cobro no proporcionado",

                        // taxing info
                        BILLING_TAX_ID_WRONG_LENGTH: "Número de identificación fiscal incorrecto",
                        INVALID_PRODUCT_INVOICING_PRICE: "Precio inválido para SKU: '{sku}'",
                        INVALID_PRODUCT_NCM: "NCM inválido para SKU: '{sku}'",
                        TOTAL_ORDER_VALUE_MISMATCH: "Hay diferencias en el precio total del pedido, {channel_integration_type} reportó {expected_total_price} y Cubbo calculó {calculated_total_price}, para facturar se requiere confirmación de precios",
                        MELI_ORDER_NOT_READY_TO_SHIP: "Etiqueta de envío de Mercado Libre no generada",
                        INVALID_TOTAL_ORDER_VALUE: "El valor total del pedido no es válido. El valor del pedido debe ser mayor a 0, o estar completamente descontado",
                        NO_INVOICEABLE_PRODUCTS: "Ningún producto de tu pedido es facturable. Al menos un producto del pedido debe ser facturable",

                        // DIFAL
                        MISSING_DIFAL_PAYMENT_RECEIPT: "Falta comprobante de pago de la DIFAL",
                        WEBMANIA_ERROR: "Error al generar NF-e. Error: {error}"
                    },

                    missingInfoList: {
                        invalid_sku: 'SKU: {sku} no existe en cubbo',
                        sku: 'existen productos sin SKU',
                        phone: 'teléfono',
                        email: 'correo',
                        name: 'nombre del cliente',
                        cp: 'CP inválido',
                        state: 'Estado',
                        city: 'Ciudad',
                        address: 'Dirección',
                    }
                },
                canceled: {
                    title: 'Cancelado',
                    title_tooltip: 'Ve la automatización activa desde el panel de configuración',
                },
                backorder: {
                    title: 'Sin stock',
                    description: "SKUs con Stock Incompleto : {sku}",
                    paragraph: {
                        title: 'Para dar solución a tu cliente, podrías:',
                        hide: 'Ocultar sugerencia',
                        content:{
                            p1: {
                                title: 'Esperar y resurtir producto de pieza faltante',
                                description: 'Te sugerimos notificar a tu cliente el tiempo de espera extraordinario',
                            },
                            p2: {
                                title: 'Enviar pedido parcial',
                                description: 'Duplicando este pedido eliminando la pieza sin stock y cancelando este pedido. Adicional, deberás coordinar el reembolso parcial con tu cliente',
                            },
                            p3: {
                                title: 'Cancelar y reembolsar',
                                description: 'Si no está en tus planes resurtir la pieza faltante o enviar el pedido parcial, podrías cancelar el pedido y reembolsar la compra a tu cliente',
                            }
                        }
                    }
                },
                unpaid: {
                    title: 'Sin pagar',
                },
                paid: {
                    by_user: 'Marcado como pagado',
                },
                cancelation_window: {
                    title: 'En ventana de cancelación',
                    description: "El pedido aún se puede cancelar desde la integración. El tiempo que tus pedidos permanecerán en esta ventana, depende de tu configuración en la integración",
                },
                holded: {
                    title: 'Pausado',
                },
                unhold: {
                    title: 'Reanudado',
                },
                interrupted: {
                    title: 'Interrumpido',
                    description: "El fulfillment del pedido fue cancelado mientras estaba siendo preparado. No enviaremos este pedido, pero si se va a cobrar el cargo de pick and pack",
                },
                shipment_interrupted: {
                    title: 'Interrumpido',
                    description: "Se canceló el pedido mientras se procesaba. Si el pedido logra detenerse antes del envío, se cobrará el cargo de Pick and Pack",
                },
                entirely_dropshipping: {
                    title: 'Fulfilleado externamente',
                    description: "Cubbo no almacena el producto de este pedido, por lo que no se realizará fulfillment de este pedido",
                },
                edit: {
                    title: 'Editado',
                },
                tracking_edit: {
                    title: 'Dirección editada'
                },
                duplicate: {
                    title: 'Duplicado',
                    description: '{link}',
                },
                create: {
                    title: 'Creado',
                },
                create_from: {
                    title: 'Creado como un duplicado',
                    title_complement: 'de {link}',
                    title_complement_link: 'Ver automatización configurada'
                },
                processing: {
                    title: 'Procesandose',
                },
                shipped: {
                    title: 'Esperando recolección',
                    title_pickup: 'Enviado',
                    description: "Si después de 36 horas el pedido aún no ha sido enviado, escribe a Soporte Cubbo para apoyarte",
                },
                shipment_collected: {
                    title: 'Enviado',
                    description: 'El pedido pasa a manos de la paquetería',
                },
                shipment_external_label: {
                    title: 'Con guia externa, rastrea el pedido directo con la paquetería',
                },
                shipment_canceled: {
                    title: 'Entrega cancelada',
                    description: 'Cuando el pedido regrese a Cubbo, el estado del pedido se actualizará a "Reingresado"'
                },
                shipment_in_transit: {
                    title: 'En tránsito',
                },
                shipment_out_for_delivery: {
                    title: 'En ruta de entrega',
                    description: 'El pedido se entregará en las próximas horas'
                },
                shipment_with_incident: {
                    title: 'Incidente',
                    title_delivery_attempt: 'Intento de entrega fallido'
                },
                shipment_to_be_self_collected: {
                    title: 'Recolectar en oficina',
                    description: 'El pedido se encuentra en una oficina de la paquetería esperando ser recolectado por tu cliente. Para más información contacta al equipo de Soporte',
                },
                shipment_delivered: {
                    title: 'Entregado',
                },
                returning: {
                    title: 'Retornando a Cubbo',
                    types: {
                        SIMPLE_RETURN: "Por devolución solicitada",
                        EXCHANGE: "Por devolución solicitada",
                        IMMEDIATE_EXCHANGE: "Por devolución solicitada",
                        RETURNED_BY_CARRIER: "Por entrega no exitosa",
                        TO_BE_DETERMINED: "",
                        SHIPMENT_INTERRUPTED: "Por interrupción del envío",
                    },
                },
                returned: {
                    title: 'Recibiendo retorno',
                    types: {
                        SIMPLE_RETURN: "Por devolución solicitada",
                        EXCHANGE: "Por devolución solicitada",
                        IMMEDIATE_EXCHANGE: "Por devolución solicitada",
                        RETURNED_BY_CARRIER: "Por entrega no exitosa",
                        TO_BE_DETERMINED: "",
                    },
                },
                reentered: {
                    title: 'Retorno reingresado',
                    types: {
                        SIMPLE_RETURN: "Por devolución solicitada",
                        EXCHANGE: "Por devolución solicitada",
                        IMMEDIATE_EXCHANGE: "Por devolución solicitada",
                        RETURNED_BY_CARRIER: "Por entrega no exitosa",
                        TO_BE_DETERMINED: "",
                    },
                },
                return_arrived: {
                    title: 'Retornó a Cubbo',
                    types: {
                        SIMPLE_RETURN: "Por devolución solicitada",
                        EXCHANGE: "Por devolución solicitada",
                        IMMEDIATE_EXCHANGE: "Por devolución solicitada",
                        RETURNED_BY_CARRIER: "Por entrega no exitosa",
                        TO_BE_DETERMINED: "",
                    },
                },
                return: {
                    title: 'Recibiendo retorno',
                    by_client: 'Retornado por tu cliente',
                    by_carrier: 'Retornado por paquetería, por entrega no exitosa',
                },
                shipment_returned: {
                    title: 'Retornado a Cubbo',
                    types: {
                        SIMPLE_RETURN: "Retornado por devolución solicitada",
                        EXCHANGE: "Retornado por cambio solicitado",
                        IMMEDIATE_EXCHANGE: "Retornado por cambio inmediato solicitado",
                        RETURNED_BY_CARRIER: "Retornado por paqueteria",
                        TO_BE_DETERMINED: "",
                    },
                },
                claim: {
                    title: 'Disputa',
                    statuses: {
                        PENDING: 'Pendiente',
                        REJECTED: 'Rechazada',
                        ACCEPTED: 'Aceptada',
                    },
                    categories: {
                        UNWANTED_PRODUCTS: 'Por envío de producto sobrante',
                        MISSING_PRODUCTS: 'Por producto faltante',
                        WRONG_ORDER: 'Por envío de pedido incorrecto',
                        PACKAGE_LOST: 'Por pedido extraviado',
                        DAMAGED_PRODUCT: 'Por producto dañado',
                        DELIVERY_DELAY: 'Por demora en la entrega',
                        FALSE_DELIVERY: 'Por entrega incorrecta del pedido',
                        PACKAGE_STOLEN: 'Por paquete robado',
                        DELIVERY_OTHERS: 'Otros - Paquetería',
                    }
                }
            }
        },

        pod:{
            download: "Descargar",
            received_by: "Recibido por",
            title: "Evidencia de entrega",
            not_provided: "Esta paquetería no recolecta evidencias en la entrega",
            unavailable: "Muy pronto podrás acceder a ella desde esta sección.",
            proof: "Comprobante",
            download_success: "Se ha descargado el comprobante de entrega",
            download_error: "Ocurrió un error al descargar, por favor inténtalo de nuevo más tarde",
        },

        showContainer: {
            is_first_time_buyer: "¡Nuevo Comprador!",
            is_click_and_collect: "Recolecta en punto físico",
            click_and_collect_data: "Datos del punto de recolección",
            click_and_collect_location_name: "Nombre del establecimiento ",
            kit_title: "Productos que conforman el kit",
            file: "Archivo",
            file_name: "Nombre",
            name: "Nombre ",
            send_to: "Enviar a ",
            printing_type: "Tipo de impresión",
            actions: "Acciones",
            shipping_Market: "Mercado Envíos",
            external: "Externo",
            configure_shipping: "Configurar metodo de envío",
            edit: "Editar Pedido",
            missing_information: "Hay información faltante en tu orden, revisa los datos a continuación.",
            assign_shipment: "Asignar métodos de envío",
            order_number: "Nº pedido",
            cart_id: "Carrito de compra",
            store: "Tienda",
            state: "Estado ",
            status:  "Estado de pedido",
            date_created: "Fecha de creación",
            shipping_information: "Información de envío",
            shipping_pickup: "Información de recolección",
            customer_address: "Dirección del cliente",
            shipping_order_information: "Información de pedido",
            picking_order_information: "Datos de entrega",
            shipping_method: "Paquetería",
            type_of_shipment: "Tipo de envío",
            cash_on_delivery: "Contra Reembolso",
            shipment_status: "Estado de envío",
            date_of_creation:"Fecha de creación",
            no_information: "Sin información",
            products: "Productos",
            orderProducts: "Productos del pedido",
            product_title: "Nombre de producto",
            sku_title: "SKU",
            price_title: "Precio",
            lot_title: "Lote",
            imei_title: "Número de serie",
            labels_title: "Etiquetas",
            requested_product_number: "Piezas solicitadas",
            reserved_product_number: "Piezas reservadas para este pedido",
            available_product_number_for_sale: "Inventario disponible en venta",
            available_product_number_for_sale_tooltip: "Total de piezas aún disponibles en Cubbo para vender, habiendo descontado lo reservado para tus pedidos",
            imei: "Número de serie: ",
            lot: "Lote: ",
            expiration: "Expiración: ",
            product_dont_exist: "El producto no existe en Cubbo",
            price: "Precio: ",
            sales_channel_pieces: "Canal de venta | piezas",
            tracking_number: "Noº de guía",
            attachments: "Archivos adjuntos",
            download: "Descargar",
            sendTo: "Enviar a ",
            phone: "Teléfono ",
            email: "Email ",
            address: "Dirección ",
            number: "Número ",
            neighborhood: "Barrio ",
            city: "Ciudad ",
            zip_code: "CP ",
            cpf: "CPF ",
            billing_info: "Datos de facturación",
            tax_regime: "Regimen fiscal ",
            edit_order: "Editar pedido",
            generate_label: "Generar guía",
            tracking_number_input_label: "Código de rastreo para",
            channel: "Canal",
            remove_stock_type: "Retiro de producto en buen estado",
            remove_damaged_stock_type: "Retiro de producto dañado",
            remove_expired_stock_type: "Retiro de producto expirado",
            here: "aquí",
            previous_delivery_date: "Fecha de entrega anterior",
            new_address: "Nueva dirección de entrega",
            pick_and_ship: "PickNShip",
        },
        create_bulk: {
            view_orders: "Ver Pedidos",
            ok: "De acuerdo",
            was_a_problem: "¡Ha habido un problema!",
            zero_orders_imported: "No se han podido importar tus pedidos, contacta a soporte y háznos llegar la hoja que intentaste importar.",
            thanks_for_waiting: "Gracias por el tiempo de espera.",
            error_orders_reminder: "Recuerda que los pedidos marcados con error en el paso anterior, no se importarán.",
            orders_imported: "Se han importado {{orders_number}} nuevos pedidos a tu tienda!",
            not_all_orders_imported: "Se han logrado importar {{imported_orders}} de {{total_orders}} pedidos previstos",
            we_are_importing: "Estamos importando {{orders_number}} nuevos pedidos a tu tienda!",
            orders: "PEDIDOS",
            imported: "IMPORTADOS"
        }
    },

    return: {
        container: {
            return: "Devolución",
            awaiting_return: "Esperaremos esta devolución en nuestros almacenes.",
            change: "Cambio",
            replacement_products: "Los productos de reemplazo disponibles serán apartados. En cuanto recibamos la devolución, se generará el pedido correspondiente.",
            immediate_change: "Cambio Inmediato",
            immediate_change_help: "Generaremos un pedido inmediatamente con los productos de reemplazo.",
            yes: "si",
            no: "no",
            not_readable: "Error: La orden se encuentra en un estado no elegible para retorno.",
            order_number: "Número de orden",
            store: "Tienda",
            return_type: "Tipo de Devolución",
            return_guide: "¿Generar guía de retorno?",
            generate_guide: "Se generará una guía de retorno usando",
            return_instructions: "Se proveerán las instrucciones para que el cliente pueda mandar la devolución por su cuenta.",
            returnees: "Retornados",
            add_another_product: "Agregar otro producto de la tienda",
            products: "Producto",
            quantity: "Cantidad",
            damaged_parts: "(Piezas Dañadas)",
            add_part: "Añadir pieza dañada",
            remove_product: "Quitar producto",
            replacement: "Reemplazo",
            cancel: "Cancelar",
            save: "Guardar",
            confirm: "Confirmar",
            total_return: "Confirma que serán devueltos un total de",
            product: "producto",
            confirm_return: "Confirmar Devolución",
            notes: "Notas",
        },
        productsDialog: {
            stock_release: "Confirmar Liberación de stock",
            release_the_products: "Estas apunto de liberar los productos que se habían reservado para cumplir la orden que se creará el recibir el retorno ",
            confirmation: "¿Deseas confirmar?",
            loading: "Cargando",
            confirm: "Confirmar",
            cancel: "Cancelar",
            close: "Cerrar",
        },

        returnedProduct: {
            name: "Nombre",
            no_sku: "Sin SKU",
            no_upc: "Sin UPC",
            store_inventory: "Inventario de la tienda",
            products: "Productos",
            no_products_to_show: "No hay productos que mostrar",
            accept: "Aceptar",
            cancel: "Cancelar",
        }

    },
    
    claims: {
        list: {
            value: "VALOR SOLICITADO",
            accepted_value: "VALOR ACEPTADO",
            created_at: "Fecha de Creación",
            resolved_at: "Fecha de Resolución",
            see_detail: "Ver detalle",
            see_dispute: "Ver Disputa" ,
            show_orders: "Ver orden",
            pending: "Pendiente",
            rejected: "Rechazada",
            Disputes:"Disputas",
            all_stores: "Todas las tiendas",
        },

        detail: {
            status: "Estatus",
            resolution: "Resolución",
            accepted_values: "Valor Aceptado",
            orders_number: "Número de orden",
            value_of_products: "Valor total solicitado",
            dispute: "Disputa",
            Compensation_Testing: "Pruebas de Compensación",
        },
        errors: {
            related_unresolved_existing_claim: "Esta disputa ya ha sido creada y se encuentra pendiente de resolución"
        },
        create: {
            order_number: 'Sobre el pedido con Número de Orden',
            order_status: 'Estatus',
            shipping_method: 'Paquetería con la que se envió el pedido',
            tracking_number: 'No° de Guía',
            close_button: 'Cerrar',
            save_button: 'Guardar',
            files_field_placeholder: 'Presiona aquí, o arrastra tus archivos PDF.',
            generic:{
                form_title: 'Para poder analizar este caso, necesitamos que nos apoyes con algunos datos...',
                form_subtitle: 'Recuerda que la resolución de esta disputa depende de la veracidad de esta evidencia.',
                invoice_file: 'Adjunta la factura en PDF con el IVA desglosado de los productos del pedido de los cuales se requiere un reembolso para poder solicitárselo a la paquetería.',
            },
            problems_list: {
                edit_selection: 'Editar selección',
                title: '¿Cuál es el problema?',
                delivery_delay: 'El pedido ha sido recolectado y no presenta movimiento',
                false_delivery: 'El estatus del pedido indica que fue entregado, pero mi cliente no lo recibió',
                package_stolen: 'El estatus del pedido indica que fue robado.',
                package_lost: 'El estatus del pedido indica que fue extraviado.',
                damaged_product: 'Mi cliente recibió productos dañados o en mal estado.',
                incorrect_content: 'Lo que recibió mi cliente, no coincide con lo que ordenó',
                none_of_the_above: 'Ninguno de los anteriores'
            },
            currency_input: {
                title: 'Indica el valor del producto a reembolsar',
                help: 'IMPORTANTE: Escribe ÚNICAMENTE el costo de los productos a reembolsar o guías internacionales pagadas por el cliente para devolver el producto. Cualquier otra cantidad introducida que no sea justificada puede resultar en el rechazo de la disputa, aunque ésta sea legítima.'
            },
            currency_preloaded: {
                title: 'Precio de venta de los productos, obtenido desde la integración',
                subtitle: 'Se realizará una investigación sobre el estado del pedido y se determinará cuales de los siguientes conceptos son elegibles para reembolsar:',
                processing_cost: "Costo de procesamiento del pedido",
                shipping_labels_cost: "Costo de guía de envío",
                cost_return_shipping: "Costo de guía de retorno",
                products_price: "Precio de venta de los productos",
                concept: "Concepto",
                amount: "Monto",
                without_iva: "IMPORTANTE: Monto sin IVA"
            },
            notes_input: {
                title: 'Notas',
                help: 'Confirma la dirección correcta de entrega de tu pedido, referencias adicionales del lugar de entrega y un teléfono celular de quien recibe.'
            },
            delivery_delay: {
                review_window: {
                    title: '¿En cuánto tiempo darán solución a esta disputa?',
                    subtitle: 'Levantaremos el reporte con la paquetería y esperaremos máximo 72 horas su respuesta para dar solución a esta disputa. Te notificaremos por correo cuál es la resolución final.'
                },
                refund_details: {
                    title: '¿Cuánto me reembolsarán?',
                    subtitle: 'Si el pedido se categoriza como extraviado, se reembolsará:',
                    refund_elements: [
                        'Costo operativo de Cubbo',
                        'Costo de guía del pedido',
                        'Valor indicado del producto'
                    ]
                },
                advice: {
                    title: '¿Qué hacer mientras se resuelve la disputa?',
                    subtitle: 'Te recomendamos notificar a tu cliente que el pedido esta siendo investigado.',
                    text: 'Duplica el pedido sólamente si después de 72 horas de haber guardado la disputa aún no se tiene una resolución, de lo contrario podría ocasionarse una doble entrega.'
                }
            },
            false_delivery: {
                review_window: {
                    title: '¿En cuánto tiempo darán solución a esta disputa?',
                    subtitle: 'Levantaremos el reporte con la paquetería y esperaremos máximo 72 horas su respuesta para dar solución a esta disputa. Te notificaremos por correo cuál es la resolución final.'
                },
                refund_details: {
                    title: '¿Cuánto me reembolsarán?',
                    subtitle: 'Si el pedido se categoriza como extraviado, se reembolsará:',
                    refund_elements: [
                        'Costo operativo de Cubbo',
                        'Costo de guía del pedido',
                        'Valor indicado del producto'
                    ]
                },
                advice: {
                    title: '¿Qué hacer mientras se resuelve la disputa?',
                    subtitle: 'Te recomendamos notificar a tu cliente que el pedido esta siendo investigado.',
                    text: 'Duplica el pedido sólamente si después de 72 horas de haber guardado la disputa aún no se tiene una resolución, de lo contrario podría ocasionarse una doble entrega.'
                }
            },
            package_stolen: {
                sorry_text: {
                    title: 'Lamentamos el inconveniente...',
                    help: 'Lamentablemente, por políticas de la paquetería, tenemos estipulado por contrato que estos casos no son reembolsables. Ni el valor del producto, ni el costo operativo del pedido.'
                },
                advice: {
                    title: 'Para brindar una mejor experiencia a tu cliente, te sugerimos...',
                    help: 'Duplicar el pedido para que reciba los productos que ordenó.'
                }
            },
            package_lost: {
                review_window: {
                    title: '¿En cuánto tiempo darán solución a esta disputa?',
                    subtitle: 'Levantaremos el reporte con la paquetería y esperaremos máximo 72 horas su respuesta para dar solución a esta disputa. Te notificaremos por correo cuál es la resolución final.'
                },
                refund_details: {
                    title: '¿Cuánto me reembolsarán?',
                    subtitle: 'Si la disputa es aceptada, se reembolsará:',
                    refund_elements: [
                        'Costo operativo de Cubbo',
                        'Costo de guía del pedido',
                        'Valor indicado del producto'
                    ]
                },
                advice: {
                    title: '¿Qué hacer mientras se resuelve la disputa?',
                    subtitle: 'Te recomendamos duplicar el pedido cuanto antes para brindar una mejor experiencia a tu cliente.',
                }
            },
            form_title: 'Para poder acelerar el proceso, necesitamos que nos apoyes con algunos datos...',
            form_title_preloaded: 'Monto a reembolsar',
            duplicate_order_button: 'Duplicar pedido',
            new_order_button: 'Crear pedido',
            damaged_product: {
                form_title: 'Para poder analizar este caso, necesitamos que nos apoyes con algunos datos...',
                form_subtitle: 'Recuerda que la resolución de esta disputa depende de la veracidad de esta evidencia.',
                damaged_images: 'Adjunta una fotografía de cada uno de los productos que se recibieron en mal estado',
                label_image: 'Adjunta una fotografía clara de la guía del pedido',
                package_images: 'Adjunta una fotografía de cada una de las 6 caras del paquete',
                review_window: {
                    title: '¿En cuánto tiempo darán solución a esta disputa?',
                    subtitle: 'El equipo de Cubbo levantará el reporte con la paquetería para solicitar el reembolso de los productos afectados. Te notificaremos por correo cuál es la resolución final.'
                },
                refund_details: {
                    title: '¿Cuánto me reembolsarán?',
                    subtitle: 'Si la disputa es aceptada, se reembolsará:',
                    refund_elements: [
                        'Costo operativo de Cubbo',
                        'Valor indicado del producto',
                        'Costo de guía para enviar productos faltantes'
                    ]
                },
                advice: {
                    title: '¿Qué hacer mientras se resuelve la disputa?',
                    subtitle: 'Te recomendamos crear un nuevo pedido con los productos recibidos en mal estado para hacerle llegar los reemplazos a tu cliente. Si duplicas el pedido, recuerda eliminar los productos que no son necesarios.',
                }
            },
            incorrect_content: {
                problems_list: {
                    title: 'Cuéntanos más...',
                    missing_products: 'Faltaron productos en el pedido',
                    unwanted_products: 'Se recibieron productos no ordenados',
                    wrong_order: 'Mi cliente recibió un pedido completo que no era el suyo'
                },
                missing_products: {
                    problems_list: {
                        title: 'El producto faltante era...',
                        paid_product: '...algo que mi cliente compró.',
                        promotional_product: '...algo que se debió enviar como un producto promocional.'
                    },
                    paid_product: {
                        form_title: 'Para poder analizar este caso, necesitamos que nos apoyes con algunos datos...',
                        form_subtitle: 'Recuerda que la resolución de esta disputa depende de la veracidad de esta evidencia.',
                        label_image: 'Adjunta una fotografía clara de la guía del pedido',
                        package_images: 'Adjunta una fotografía de cada una de las 6 caras del paquete',
                        review_window: {
                            title: '¿En cuánto tiempo darán solución a esta disputa?',
                            subtitle: 'El equipo de Cubbo corroborará si la incidencia sucedió en nuestro proceso de fulfillment o bien, en traslado con paquetería. Te notificaremos por correo sobre la resolución final.'
                        },
                        refund_details: {
                            title: '¿Cuánto me reembolsarán?',
                            subtitle: 'Si la disputa es aceptada, se reembolsará:',
                            refund_elements: [
                                'Costo operativo de Cubbo',
                                'Costo de guía para enviar productos faltantes'
                            ]
                        },
                        advice: {
                            title: '¿Qué hacer mientras se resuelve la disputa?',
                            subtitle: 'Te recomendamos crear cuanto antes un nuevo pedido con los productos faltantes para hacerselos llegar a tu cliente. Si duplicas el pedido, recuerda eliminar los productos que no son necesarios.',
                        }
                    },
                    promotional_product: {
                        sorry_text: {
                            title: 'Lamentamos el inconveniente...',
                            help: 'Este tipo de casos no podemos reembolsarlos, sim embargo, te pedimos levantar un ticket con este caso para poder identificar el motivo de esta falta. Es posible que haya algo que modificar en tus reglas de negocio o bien, ya no tengamos más piezas de este injerto.'
                        },
                    }
                },
                unwanted_products: {
                    form_title: 'Para poder analizar este caso, necesitamos que nos apoyes con algunos datos...',
                    form_subtitle: 'Recuerda que la resolución de esta disputa depende de la veracidad de esta evidencia.',
                    unwanted_products_images: 'Adjunta una fotografía de cada uno de los productos que se recibieron y no se ordenaron.',
                    label_image: 'Adjunta una fotografía clara de la guía del pedido',
                    package_images: 'Adjunta una fotografía de cada una de las 6 caras del paquete',
                    review_window: {
                        title: '¿En cuánto tiempo darán solución a esta disputa?',
                        subtitle: 'El equipo de Cubbo corroborará si la incidencia sucedió en nuestro proceso de fulfillment o bien, en traslado con paquetería. Te notificaremos por correo sobre la resolución final.'
                    },
                    refund_details: {
                        title: '¿Cuánto me reembolsarán?',
                        subtitle: 'Si la disputa es aceptada, se reembolsará:',
                        refund_elements: [
                            'Costo operativo de Cubbo',
                            'Costo de guía para enviar productos faltantes',
                            'Costo de la guía de retorno del producto incorrecto'
                        ]
                    },
                    advice: {
                        title: '¿Qué hacer mientras se resuelve la disputa?',
                        subtitle: 'Te recomendamos crear un nuevo pedido con el producto que no se envió en el pedido original. Además de iniciar el retorno del producto que se envió y no se ordenó.',
                    }
                },
                wrong_order: {
                    form_title: 'Para poder analizar este caso, necesitamos que nos apoyes con algunos datos...',
                    form_subtitle: 'Recuerda que la resolución de esta disputa depende de la veracidad de esta evidencia.',
                    unwanted_products_images: 'Adjunta una fotografía de cada uno de los productos que se recibieron y no se ordenaron.',
                    label_image: 'Adjunta una fotografía clara de la guía del pedido',
                    package_images: 'Adjunta una fotografía de cada una de las 6 caras del paquete',
                    official_id_images: 'Adjunta una fotografía de la identificación oficial de tu cliente',
                    review_window: {
                        title: '¿En cuánto tiempo darán solución a esta disputa?',
                        subtitle: 'El equipo de Cubbo corroborará si la incidencia sucedió en nuestro proceso de fulfillment o bien, en traslado con paquetería. Te notificaremos por correo sobre la resolución final.'
                    },
                    refund_details: {
                        title: '¿Cuánto me reembolsarán?',
                        subtitle: 'Si la disputa es aceptada, se reembolsará:',
                        refund_elements: [
                            'Costo operativo de Cubbo',
                            'Costo de guía para enviar productos faltantes',
                            'Costo de la guía de retorno del producto incorrecto'
                        ]
                    },
                    advice: {
                        title: '¿Qué hacer mientras se resuelve la disputa?',
                        subtitle: 'Te recomendamos duplicar el pedido cuanto antes para brindar una mejor experiencia a tu cliente. ',
                    }
                }

            },
            none_of_the_above: {
                sorry_text: {
                    title: 'Lamentamos el inconveniente...',
                    help: 'Ayúdanos levantando un ticket para que alguien de nuestro equipo de Customer Success atienda este caso personalmente y podamos apoyarte.'
                },
                add_ticket_button: 'Levantar ticket'
            },
            product_selector_table: {
                products_prices: "Costo de productos",
                all_products: "Todos los productos",
                some_products: "Algunos productos",
                products_to_reimburse: "Productos a reembolsar:",
                amount_to_reimburse: "Monto de productos a reembolsar: ",
                table: {
                    filter_placeholder: "Buscar por nombre o SKU",
                    headers: {
                        name: "Nombre",
                        sku: "SKU",
                        price: "Precio",
                        sent: "Enviados",
                        reimbursed: "Reembolsados"
                    }
                }
            }
        }
    },

    integrations_list: {
        active: {
            configuring: "Configurando",
            configure: "Configurar",
            active: "Activa",
            state: "estado",
            error: "Error - credenciales o permisos incorrectos",
            deleted: "Desactivada",
            shipping_methods: "Métodos de envio",
            assign_a_location:"Asignar localización",
            finance: "Finanzas",
            authorize_app_shopee_products: "Autorizar App para sincronizar productos de Shopee",
            authorize_app_shopee_finance: "Autorizar App para sincronizar finanzas de Shopee",
            import_vtex_products: "Importar Productos",
            products_sync: "Sincronizar Inventario",
            updated_shipping_methods: "Métodos de envío actualizados",
            updated_inventory: "Inventario Actualizado",
            all_stores: "Todas las tiendas",
            assign_location: "Asignar localización",
            no_integration_to_show: "No hay integraciones que mostrar.",
            Sync_shipping_methods: "Sincronizar métodos de envío",
            integration: "Integraciones",
            create_integration: "Crear Integración",
            pause_sync: "Pausar Sincronización Automática",
            activate_sync: "Activar Sincronización Automática",
            location: "Localización",
            sync_activated: "Sincronización Automática Activada",
            sync_paused: "Sincronización Automática Pausada",
            token: "Credenciales",
            generate_new_token: "Generar nuevas credenciales"
        },
    
        empty_list: {
            first_integration: "Aún no has creado tu primera integración",
            select_preferred_integration: "Selecciona tu canal de ventas preferido para conectarlo con Cubbo"
        },

        shipping: {
            Integration_Delivery_Method: "Método de Envío de la Integración",
            Type_shipping_method: "Tipo de método de envío",
            Add_Priority_Shipping_Methods: "Agregar prioridad de Metódos de Envío",
            shipping_method_cubbo: "Método de Envío Cubbo",
            type: "Tipo",
            option: "Opcion",
            all_stores: "Todas las tiendas",
            Save_Configuration: "Guardar Configuración",
            Add_Shipping_Method: "Agregar Método de Envío",
            methods_of_shipment: "Métodos de Envío",
            add_new_method: "Agrega un nuevo Método de Envío",
            no_shipping_methods: "No existen métodos de envío en esta integración.",
            Sync_shipping_methods: "Sincroniza tus métodos de envío en la lista de integraciones",
            add_option: "Agregar Opción",
            select_type: "Selecciona tipo",
            add_priority: "Agregar prioridad:",
            national: "Nacional",
            international: "Internacional",
            cash_on_delivery: "Contra Reembolso",
            priority_to_shipping_method:" Asigne prioridades para método de envío",
            integration_shipping_method_prefix: "Método de Envío de",
            shipping_group: "Configuración de Envío",
            integration_shipping_types: {
                national: {
                    label: 'Nacional',
                    tip: "Envío con alcance nacional"
                },
                international: {
                    label: 'Internacional',
                    tip: "Envío con alcance internacional"
                },
                cash_on_delivery: {
                    label: 'Pago Contra Entrega',
                    tip: "Envío nacional pagado durante la entrega"
                },
            },
            success_sync_integration_shipping_methods: "Los métodos de envío de tu integración han sido sincronizados con Cubbo.",
            error_sync_integration_shipping_methods: "Ocurrió un error al intentar sincronizar los métodos de envío de tu integración con Cubbo.",
            assign_shipping_group: "Asignar Configuración de Envío",
            unassign_shipping_group: "Quitar Configuración de Envío",
            column_help: {
                integration_shipping_method: "Este es el nombre de tu método de envío en tu integración",
                shipping_type: "Define la manera en la cual se manejará este método de envío",
                shipping_group: "Elige los transportes que podrán ser usados al enviar pedidos con este método de envío"
            },
            success_update_shipping_type: "El método de envío ha sido actualizado.",
            error_update_shipping_type: "Ocurrió un error al intentar actualizar tu método de envío.",
            missing_shipping_group_tip: "Este método de envío no tiene configuración, los pedidos no serán enviados y se encontrarán en estado 'Falta Información'",
            return_to_integrations: "Regresar",
            empty_shipping_methods: "No hay métodos de envío",
            empty_shipping_methods_create: "crea un método de envío para tu api",
            empty_shipping_methods_sync: "sincroniza los métodos de envío de tu integración",
        },

        shopify: {
            add_Barcode_in_Shopify: "Falta añadir el Código de Barras en Shopify",
            SKU_missing_in_Shopify: "Falta añadir el SKU en Shopify",
            missing: "Faltan ",
            import: "Importar",
            products: "productos",
            barcodes_in_shopify: " códigos de barras en Shopify",
            imported: "Importado",
            no_matter: "Sin Importar",
            barcode: "Código de barras",
            Updated_Inventory: "Inventario Actualizado",
            Error_importing: "Error importando",
            Import_Products: "Importar Productos",
            notice: "Aviso",
            yes: "Si",
            SKU_or_UPC_only: "Para evitar problemas operativos, Cubbo no puede importar productos que NO tengan SKU y código UPC/EAN configurado en Shopify.",
            add_SKU_or_UPC: "Puedes agregar SKU o UPC haciendo click en este link",
            import_excel_via_email: "o si lo prefieres, puedes importar los productos en excel, mandándonos un email a integraciones@cubbo.com",
        },

        vtex: {
            image: "Image",
            name: "Nombre",
            imported: "Importado",
            no_matter: "Sin importar",
            name: "Nombre",
            status: "estado",
            Updated_Inventory: "Inventario Actualizado",
            Error_importing: "Error importando",
            import_of_products: "Importación de productos en proceso, puede tardar varios segundos en terminar.",
            error: "Error importando",
            import: "Importar",
            products: "productos",
            yes: "Si",
            import_all_products: "Importar Todos los Productos",
            import_missing_products:"Importar todos los productos faltantes",
            import_shortage_products: "Importar Productos Faltantes",
            import_all: "Importar Todos",
            import_Producti: "Importar Productos",
            import_selection: "Importar Selección",
            no_import: "Sin Importar",
            preparing_import: "Preparando importación...",
            notice: "Aviso",
            SKU_or_UPC_only: "Para evitar problemas operativos, Cubbo no puede importar productos que NO tengan SKU y código UPC/EAN configurado en VTEX. Por restricciones de la integración de VTEX, no podemos mostrarte en esta tabla si tienes los códigos configurados o no. Por favor, asegurate que los tengas.",
            import_excel_via_email: "o si lo prefieres, puedes importar los productos en excel, mandándonos un email a integraciones@cubbo.com",
            nothing_to_show: "No hay productos que mostrar.",
        }
    },
    
    invoicing_list: {
        key: "Llave de Acceso",
        operation: "Operación",
        adjustment: "Ajuste",
        print: "Imprimir",
        no_files: "Sin archivo adjunto",
        invoices: "Facturas",
        invoices_message: "Facturas generadas para el recibo de inventario",
        operation_nature: "Naturaleza de la operación",
        not_invoices: "No hay Facturas por mistrar"

     },
    shipping_groups: {
        priorities: {
            CHEAPEST: "Se elegirá el mejor precio",
            QUALITY: "Se elegirá la entrega más rápida al menor precio",
        },
        fallback: "Respaldo",
        fallback_tip: "En caso de no poder obtener precios o no tener cobertura para los anteriores métodos de envío, se usará siempre éste como respaldo.",
        without_fallback: "Sin Respaldo",
        without_fallback_warning: "Sin Respaldo: pedidos sin cobertura no serán enviados y estarán en estado 'Falta Información'",
        incompatible_shipping_type: "Algunos de los métodos de envío de tu configuración serán omitidos al elegir el envío para tus pedidos ya que no son compatibles con el tipo de método de envío de tu integración.",
        all_incompatible_shipping_methods: "Ninguno de los métodos en envío de tu configuración es compatible con el tipo de envío de la integración, es recomendable elegir otra configuración.",
        allows_extended_zones: {
            yes: "Permitir zonas extendidas",
            no: "No permitir zonas extendidas",
            tip: "Entregas en zonas remotas pueden incurrir en un cargo adicional. Esta opción indica si se debe utilizar dicha cobertura para tus pedidos, el costo adicional será considerado al seleccionar el mejor transportista para tus pedidos."
        },
        allows_pobox_delivery: {
            yes: "Permitir entrega en puntos de recolección",
            no: "No permitir entrega en puntos de recolección",
            tip: "Algunas entregas en zonas remotas no pueden entregarse en la dirección exacta y deben recogerse en una ubicación cercana. Esta opción indica si se debe utilizar dicha cobertura para tus pedidos."
        }
    },

    shipping_labels: {
        shipping_labels: "Guías de Envío",
        generated_labels: "Guías Generadas",
        label_types: {
            Shipping: "Guía de envío",
            Return: "Guía de retorno"
        }
    },

    cnabs: {
        status: {
            WITHOUT_PAYMENT_RETURN: "Esperando arquivo retorno",
            WITH_PAYMENT_RETURN: "Pagado"
        },
        footer: "CNABs",
        showContainer: {
            title: "Ver CNAB",
            cnab_information: "Información del CNAB",
            reference_id: 'ID',
            total_amount: "Valor",
            num_of_items: "Pedidos incluidos",
            created_at: "Fecha de creación",
            payment_bill: "Arquivos",
            document_type: "Tipo",
            replenishment_type: "Arquivo remessa",
            return_type: "Arquivo retorno",
            payment_receipt: "Comprobante de pago",
            payment_receipt_description: "Adjunta aquí el arquivo retorno que el banco te proporcionó"
        }
    },

    invoices: {
        invoices: "Facturas",
        footer_invoice_name: "facturas",
        generated_invoices: "Facturas Generadas",
        list: {
            from_orders: {
                tab_name: 'De pedidos',
                table_title: 'Facturas de pedidos',
                columns: {
                    order_number: 'Nº de orden',
                    billing_name: 'Cliente',
                    billing_email: 'Email',
                }
            },
            from_replenishments: {
                tab_name: 'De recibos de inventario',
                table_title: 'Facturas de recibo de inventario',
                columns: {
                    replenishment_id: 'ID de recibo'
                }
            },
            from_returns: {
                tab_name: 'De retornos',
                table_title: 'Facturas de retornos',
                columns: {
                    order_number: 'Nº de orden',
                    billing_name: 'Cliente',
                    billing_email: 'Email',
                    return_id: 'ID de retorno',
                }
            },
            from_cnabs: {
                tab_name: 'CNABs',
                table_title: 'Facturas de DIFALs',
                columns: {
                    reference_id: 'ID',
                    reference_filename: "Nombre",
                    num_of_items: "Nº de pedidos",
                    total_amount: "Valor"
                }
            },
            created_at: 'Fecha de emsión',
            creation_date_placeholder: {
                start: 'Inicio',
                end: 'Fin'
            },
            type: 'Tipo de operación',
            attachments: 'Ficheros',
            noData: 'No hay facturas que mostrar',
            serial_number: 'Nº NF',
            key: 'Llave de acceso',
            status: 'Estado NF',
            filter_descriptor: {
                order_number: {
                    description: 'Con número de orden'
                },
                returned_order_number: {
                    description: 'Con número de orden'
                },
                operation_type: {
                    description: 'De tipo'
                },
                created_at: {
                    descriptionValue: '{{from}} - {{to}}',
                    description: 'Con fecha de emisión'
                },
                all_from_orders: {
                    description: 'Facturas de',
                    descriptionValue: 'Todos los NFe de órdenes'
                },
                all_from_replenishments: {
                    description: 'Facturas de',
                    descriptionValue: 'Todos los NFe de recibos'
                },
                all_warning: 'Recuerda que puedes utilizar los filtros de la tabla para descargar NFe específicos.',
                key: {
                    description: 'Con llave'
                },
                id: {
                    description: 'Con id de recibo'
                },
                purposes: {
                    description: 'Con propósito de la factura'
                },
                status: {
                    description: 'Con status'
                },
                series_and_serial_number: {
                    description: 'Con Nº de NF'
                },
            }
        },
        process_types: {
            sell: 'Venta',
            donation: 'Donación',
            symbolic_return: 'Retorno simbólico',
            remove_stock: 'Remoción de inventario',
            inventory_replenishment: 'Factura para Depósito Temporário',
            replenishment_adjustment_addition: 'Factura para Depósito Temporario - Ajuste',
            replenishment_adjustment_removal: 'Retorno de Depósito Temporário'
        },
        operation_types: {
            entry: 'Entrada',
            egress: 'Salida'
        },
        status_types: {
            approved: 'Activa',
            cancelled: 'Cancelada'
        },
        filters: {
            order_number: 'Número de órden',
            status: 'Estado de la factura',
            key: 'Llave de acceso',
            operation_type: 'Tipo de operación',
            purpose: 'Tipo de operación',
            created_at: 'Fecha de emisión',
            serial_number: 'Nº NF',
        }
    },

    shipping_methods: {
        delivery_type: 'Entrega',
        shipping_type: 'Tipo',
        services: 'Servicios',
        carrier: 'Transportista',
        delivery_types: {
            SameDay: 'Mismo Día',
            NextDay: 'Día Siguiente',
            Ground: 'Terrestre',
            Pickup: 'Recolección',
            International: 'Internacional',
        },
        shipping_types: {
            national: 'Nacional',
            international: 'Internacional',
            pickup: 'Recolección',
        },
        incompatible_shipping_type: "Este método de envío no es compatible con el envío de tu integración. Será omitido al realizar la selección."
    },

    files: {
        open: "Abrir",
        print: "Imprimir"
    },

    async_task_notification: {
        working: 'Preparando descarga...',
        done: 'Archivo generado',
        download: 'Descargar'
    },

    export_overview: {
        intro: 'Estas descargando',
        export_descriptions: {
            INVOICES: {
                SALE_INVOICE: {
                    title: 'NFs - Venta de producto'
                },
                DONATION_INVOICE: {
                    title: 'NFs - Donación de producto'
                },
                SYMBOLIC_RETURN_INVOICE: {
                    title: 'NFs - Retorno Simbólico de Depósito temporal'
                },
                REMOVE_STOCK_INVOICE: {
                    title: 'NFs - Retiro de Inventario'
                },
                REPLENISHMENT_INVOICE: {
                    title: 'NFs - Depósito temporal'
                },
                REPLENISHMENT_ADJUSTMENT_ADDITION: {
                    title: 'NFs - Depósito temporal - Ajuste',
                    subtitle: 'Cuando recibimos un artículo extra en el recibo de inventario.'
                },
                REPLENISHMENT_ADJUSTMENT_INVOICE: {
                    title: 'NFs - Retorno de depósito temporal',
                    subtitle: 'Cuando falta un artículo en el recibo de inventario.'
                },
                RETURN_INVOICE: {
                    title: 'NFs - Retorno de productos'
                }
            }
        },
        format: 'Formato',
        type: 'Tipo',
        confirm_button: 'Confirmar',
        cancel_button: 'Cancelar'
    },

    integrations: {
        select_view: {
            Select_store_location: "Seleciona la locación por default de tu tienda para Cubbo.",
            No_locations_toassign: "No existen locaciones que asignar para esta integración",
            select: "Seleccionar",
            cancel: "Cancelar",
            save: "Guardar",
            only_read_location_orders: "Deseas que Cubbo solamente trabaje ordenes que tengan esta localización asignada?"
        },

        shipping_view: {
            Integration: "Integración",
            No_shipping_methods: "No existen métodos de envío en esta integración.",
            Sync_shipping_methods: "(Sincroniza tus métodos de envío en la lista de integraciones)",
            international: "Internacional:",
            Assign_by_priority: "Asignar por prioridad:",
            Shipping_method: "Método de envío",
            priorities_shipment_method: "Asigne prioridades para este método de envío",
            option: "Opcion",
            add_option: "Agregar Opción",
            cancel: "Cancelar",
            save: "Guardar",
            loading: "Cargando",
        },

        shopify_public: {
            install_shopify: "Instalar Shopify",
            install_Cubbo_in_Shopify: "Ve a la app store de Shopify e instala Cubbo desde allí",
            Existing_sales_channel: "Canal de ventas ya existe",
            In_construction: "En construcción: Esperando a tener link de la app store de Shopify",
        },

        show_available: {
            Integrations: "Integraciones",
            import_ecommerce: "Todo lo que necesitas para integrar tu e-commerce",
            select_channel: "Selecciona el canal de ventas a integrar",
        }
    },

    integration: {
        meli: {
            existent_chanel: "Ya existe una integración para esta tienda.",
            Error_creating_integration: "Error al crear la integración.",
            integrate_meli: "Integrar Mercado Libre",
            authorize_cubbo: "Te redirigiremos a Mercado Libre para que autorices la Cubbo App.",
            go_to_meli: "Ir a Mercado Libre"
        },
        shopify: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Configure_Shopify: "Configurar Shopify",
            Create_private_app_Shopify: "Crea una private app en Shopify e inserta aquí las credenciales.",
            integrate_shopify: "Integrar Shopify",
            company_website: "tudominio.shopify.com",
        },

        ventiapp: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu tienda Ventiapp",
            enter_credentials: "Ingresa aquí tus credenciales de la plataforma de VentiApp",
            integrate_ventiapp: "Integrar Ventiapp",
        },
        
        vtex: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu tienda Vtex",
            account_management: "En tu tienda VTEX, visita Gestión de la cuenta > Cuenta > Generar access key y secret",
            store_name: "Nombre de tu tienda (App Name)",
            integrate_vtex: "Integrar Vtex",
            company_website: "ej: juguetesmx",
            is_seller: "Tu cuenta VTEX és de tipo Seller?"
        },
        amazon: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu tienda Amazon",
            store_name: "Nombre de tu tienda Amazon",
            integrate_amazon: "Integrar Amazon",
            refresh_token: "Token"
        },

        liverpool: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu cuenta Liverpool",
            account_management: "Puedes encontrar instrucciones sobre esta integración en este",
            link: "enlace",
            integrate: "Integrar Liverpool"
        },
        coppel: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu cuenta Coppel",
            account_management: "Puedes encontrar instrucciones sobre esta integración en este",
            link: "enlace",
            integrate: "Integrar Coppel"
        },
        
        woocommerce: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu tienda Woocommerce",
            integrate_woocommerce: "Integrar Woocommerce",
            add_woocommerce: "Integrar Woocommerce con API keys",
            your_brand: "tumarca.com",
        },

        shein: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu tienda Shein",
            integrate_shein: "Integrar Shein"
        },

        f1commerce: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu tienda F1commerce",
            integrate_f1commerce: "Integrar F1commerce",
            your_brand: "tumarca.com",
            codigo_chave: "Clave de servicio"
        },

        t1paginas: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu tienda T1Páginas",
            integrate_t1paginas: "Integrar T1Páginas",
            your_brand: "tumarca.com",
            codigo_chave: "Clave de acceso"
        },

        tiendanube: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu Tienda Nube",
            integrate: "Integrar Tienda Nube",
            your_brand: "tumarca.com",
        },

        bling: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu Bling",
            enter_credentials: "Ingresa aquí tus credenciales de la plataforma de Bling",
            integrate: "Integrar Bling",
            store_name: "Nombre de tu tienda",
            codigo_chave: "Clave API"
        },

        walmart: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu tienda Walmart",
            store_name: "Nombre de tu tienda",
            company_website: "ej: juguetesmx",
            integrate_walmart: "Integrar Walmart",
        },

        traycorp: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            account_management: "En tu tienda Tray Corp, visita Integrações > Tokens > Adicionar Token",
            Integrate_your_store: "Integra tu tienda Tray Corp",
            integrate_traycorp: "Integrar Tray Corp",
        },
        vnda: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu tienda Vnda.",
            integrate_vnda: "Integrar Vnda.",
            company_website: "domain.vnda.com.br",
        },
        stripe: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            account_management: "En tu tienda stripe, visita Desenvolvedores > Chaves de API > Senha secreta",
            Integrate_your_store: "Integra tu tienda Stripe.",
            integrate_stripe: "Integrar Stripe."
        },
        tray: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            account_management: "En tu tienda Tray, visita Aplicativos > Producre Cubbo > Acessar",
            Integrate_your_store: "Integra tu tienda Tray.",
            integrate_tray: "Integrar Tray.",
            company_website: "domain.com.br/web_api",
        },
        shopee: {
            Existing_sales_channel: "Canal de ventas ya existe",
            Error_creating_integration: "Error al crear integración. Comprueba que los datos sean correctos.",
            Integrate_your_store: "Integra tu tienda Shopee",
            integrate_shopee: "Integrar Shopee"
        },
    },

    addProduct: {
        optionRow: {
            option: "Opcion",
            size: "Ej Talla",
            required_field: "Este campo es requerido",
            delete: "Eliminar",
            press_enter: "Escribe y presiona enter",
        },

        container: {
            barCode: "Código de barras",
            required_field: "Este campo es requerido",
            required_field_sku: "Este campo es requerido, da click en Enter al terminar",
            type_sth: "Escribe y presiona enter",
            variants: "Variantes",
            without_variants: "Un injerto promocional no puede tener variantes.",
            multiple_options: "Este Producto tiene múltiples opciones, como diferentes tamaños o colores.",
            operational_errors: "Para evitar errores operativos, no es posible editar un kit, si deseas hacer alguna modificación te sugerimos crear un nuevo kit.",
            Dropshipping: "¿Este producto es administrado por un tercero? (Dropshipping)",
            packageless_title: "¿Este producto ya está pre-empacado?",
            packageless_description: "Activa esta característica si el producto no requiere ser empacado para enviar. La guía se pegará en el exterior del producto.",
            important: "Importante",
            has_imei_title: "¿Este producto requiere control de salida?",
            has_imei_description: "Activa esta característica si antes de enviar el producto, se requiere escanear el número de serie.",
            has_imei_warning: "El producto debe estar etiquetado con el número de serie para escanearlo durante el empacado. De lo contrario, no se podrá enviar.",
            unmanaged: "Marca si tu producto no será gestionado por Cubbo.",
            fragile_product: "¿Este producto es frágil?",
            special_care: "Marca si tu producto requiere cuidados especiales",
            promotional_product: "¿Este producto es un injerto promocional?",
            no_barcode_scanning: "Marca si tu producto es un injerto promocional que no puede ser escaneado mediante lector de código de barras.",
            drag_image: "Presiona aquí o arrastra una imagen.",
            required_image: "Si tu producto no es escaneable, la imagen del producto es requerida.",
            product_image: "Imagen del Producto",
            Save_product: "Guardar producto",
            add_product: "Desas añadir el producto",
            name: "Nombre",
            price: "Precio del producto",
            weight: "Peso (KG)",
            billing_name: "Descripción de producto internacional",
            Description: "Descripción",
            Add_attribute: "Agregar atributo",
            Add_variant: "Agregar variante",
            products: "Productos",
            Add_new_product: "Agregar producto",
            Cancel: "Cancelar",
            Save: "Guardar",
            ncm_code: "Código NCM",
            no_supported: "No soportamos este producto.",
            total: "Total:",
            product_selected: "Selecciona dos ó más productos para tu kit:",
            product_has_expiration: "Requiere control de lotes",
            product_expiration: "Marca si tu producto requiere manejo por lotes de expiración",
            freshness: "Días de frescura antes de expiración",
            buffer_stock: "Colchón",
            buffer_stock_description: "Reserva unidades disponibles de stock para reducir la probabilidad de tener sobreventas",
            add_ncm: "Añadir NCM a tienda",
            no_exist_ncm: "No tienes registrado este NCM en tu tienda aún. Asegúrate que los números sean correctos y configuralo para activarlo.",
            no_change_product: "No es posible convertir un artículo de Stock Fiscal en un artículo promocional."

        },

        kitsTable: {
            quantity: "Cantidad",
            sku: "SKU",
            price_per_item: "Precio por item",
            no_products_added: "No has agregado productos.",
            name: "Nombre",
        },

        readTable: {
            Loading_products: "Cargando productos",
        },

        variantRow: {
            variant_name: "Nombre de la variante",
            example: "ej (M-Rojo)",
            required_field: "Este campo es requerido",
            delete: "Eliminar",
            barCode: "Codigo Barras",
            type_sth: "Type something and press enter..."
        }
    },

    inventory: {
        listProducts: {
            image: "Imágen",
            name: "Nombre",
            stock: "En almacén",
            available: "Disponible en venta",
            unavailable: "No disponible",
            by_returns: "Por retornos",
            by_buffer: "Por colchón",
            buffer_tip: {
                text: "Este colchón sirve para evitar sobre venta, las piezas están en el almacén pero no se listan como disponibles.",
                buffer_tip_link: 'Ver más información.',
                buffer_tip_url: 'https://cubbohelp.zendesk.com/hc/es/articles/10621327059597-Inventario-Colch%C3%B3n',
            },
            presale: "Preventa",
            orders: "Reservado por pedidos",
            damaged: "Dañado",
            holded: "Pausado",
            expired: "Expirado",
            status: "Estado",
            active: "Activo",
            for_selling: "Para Venta",
            shipped: "Enviado",
            fragile: "Frágil",
            dropshipping: "Dropshipping",
            packageless: "Pre-empacado",
            has_imei: "Control de salida",
            kit: "Kit",
            promoInsert: "Injerto",
            edit_product: "Editar producto",
            inventory: "Inventario",
            all_stores: "Todas las tiendas",
            name: "Nombre",
            description: "Descripción",
            name_invoices: "Nombre en facturas",
            import_products: "Importa productos a esta tienda",
            import: "Importar Excel",
            download_inventory: "Descargar Inventario",
            download_previous_inventory: "Historial de inventario",
            download_inventory_success: "Exportación de CSV completa",
            download_inventory_error: "Ocurrió un error, intenta más tarde",
            download_inventory_select_date: "Selecciona el día del cual deseas consultar el inventario",
            download_inventory_cancel_button: "Cancelar",
            download_inventory_download_button: "Descargar",
            no_previous_inventory: "Aún no existe historial de inventario, intentalo mañana",
            select_inventory_date: "Selecciona la fecha que deseas consultar",
            create_product: "Crear Producto",
            create_products: "Crear Productos",
            create_kit: "Crear Kit",
            no_products_to_show: "No hay productos que mostrar.",
            see_kit: "Ver Kit",
            imported: "Importados!",
            confirm: "Confirmar",
            delete_product: "Borrar producto",
            sure_to_delete: "Estás seguro de que deseas borrar ",
            product_is_part_of_kit: "El producto forma parte de un kit",
            product_has_stock: "El producto aún tiene stock",
            product_used_in_current_orders: "Algún pedido con este producto aún se está procesando. Intenta más tarde.",
            products: "Productos",
            inWarehouse: "en almacén",
            inPresale: "en preventa",
            inReturns: "en retornos",
            holdedLot: "Lote pausado",
            expiredLot: "Lote expirado",
            byHoldedLot: "Por lote pausado",
            byExpiredLot: "Por lote expirado",
            updated_price: "Fueron eliminadas las comas",
            duplicated_bar_code: "Este {{codigo}} corresponde al UPC de un producto ya creado en tu tienda",
            duplicated_sku: 'Este {{codigo}} corresponde al SKU de un producto ya creado en tu tienda',
            price_error: 'Sólo números, máximo 2 posiciones decimales.',
            weight_error: 'Sólo números, máximo 10 posiciones decimales.'
        },
        create_bulk: {
            view_inventory: "Ver Inventario",
            ok: "De acuerdo",
            was_a_problem: "¡Ha habido un problema!",
            zero_imported: "No se han podido importar tus products, contacta a soporte y háznos llegar la hoja que intentaste importar.",
            thanks_for_waiting: "Gracias por el tiempo de espera.",
            error_reminder: "Recuerda que los productos marcados con error en el paso anterior, no se importarán.",
            products_imported: "Se han importado {{products_count}} nuevos productos a tu tienda!",
            not_all_imported: "Se han logrado importar {{products_count}} de {{total}} productos previstos",
            we_are_importing: "Estamos importando {{total_products}} nuevos productos a tu tienda!",
            products: "PRODUCTOS",
            imported: "IMPORTADOS"
        }
    },

    onboardings: {
        select_store: "Selecciona la tienda en la que quieres realizar la instalación:",
        cancel_installation: "Cancelar Instalación"

    },

    inputPlaceholder: {
        pdfFiles: "Presiona aquí o arrastra tus archivos PDF.",
        addImage: "Presiona aquí o arrastra imágenes.",
        select: "Seleccionar",
        required_field: "Este campo es requerido"
    },

    currencyInput: {
        max: "Máximo",
        dec: "Decimales",
        integer_digits: "dígitos enteros.",
        minimum_value: "Valor mínimo",
        maximum_value: "Valor máximo"
    },

    confirmDialog: {
        loading: "Cargando"
    },

    fileInputField: {
        not_pdf: "El archivo no es PDF",
        max_file: "El archivo es muy grande. Máximo",
        mb: "MB",
        min_file: "El archivo es muy pequeño. Mínimo ",
        max_images: "Sólo puedes agregar hasta",
        max_images_text: "imágenes"
    },

    imageInputField: {
        not_an_image: "El archivo no es una imagen. PNG/JPG/JPEG",
        max_image: "La imagen es muy grande. Máximo",
        mb: "MB.",
        min_image: "La imagen es muy pequeña. Mínimo",
        max_images: "Sólo puedes agregar hasta",
        max_images_text: "imágenes"
    },

    replenishments: {
        addContainer: {
            yes: "Si",
            no: "No",
            product_not_found: "Producto no encontrado en replenishment productd",
            cancel_receipts: "Tu recibo de inventario se encuentra en preventa, no es posible editarlo, si necesitas hacer modificaciones, te sugerimos cancelar este recibo, y crear uno nuevo.",
            closing: "CERRANDO",
            closing2: "CERRANDO 2",
            receipt: "Guardar recibo de inventario",
            keep_receipt: "Deseas guardar el recibo de inventario",
            activate_presale: "¿Deseas activar la preventa de este inventario?",
            products: "Productos",
            quantity: "Cantidad",
            only_number: "Cantidad tiene que ser siempre un número.",
            imports_products: "Importa productos y cantidades a este recibo",
            imported: "Importados!",
            import: "Importar Excel",
            add_product: "Añadir producto",
            arrival_date: "Fecha de llegada",
            declared_value: "Valor total declarado",
            cancel: "Cancelar",
            save: "Guardar",
            schedule_an_appointment: "Agendar Cita",
            can_not_edit_big_replenishments: "No permitimos editar recibos de más de 1000 skus, recomendamos cancelar el recibo y volverlo a cargar de nuevo.",
            view_replenishment_products: "Ver productos",
            x_number_of_products_imported: "Se importaron {{quantity}} productos.",
            skus_doesnt_exist: "Esta SKU no existe en tu inventario."
        },

        canlendlyView: {
            do_not_schedule: "No Agendar",
            close: "Cerrar",
        },

        orderTable: {
            name: "Nombres",
            sku: "SKU",
            quantity: "Cantidad",
            no_added_products: "No has agregado productos.",
        },

         container: {
            store: "TIENDA",
            products: "Nº PRODUCTOS",
            notes: "NOTAS",
            requested: "SOLICITADO",
            appointment: "CITA",
            status: "ESTATUS",
            completed_percentage: "% completado",
            go_to_appointment: "Ir a cita",
            schedule: "Agendar",
            print_invoices: "Imprimir Facturas",
            edit: "Editar",
            cancel: "Cancelar",
            reschedule: "Reagendar",
            view_products: "Ver detalle de productos",
            cancel_receipt: "Cancelar recibo",
            edit_receipt: "Editar recibo",
            view_invoices: "Ver facturas",
            cancel_inventory_receipt: "¿Deseas cancelar este recibo de inventario?",
            close: "Cerrar",
            cancel_receipt_dialog: "Cancelar",
            inventory_receipt: "Recibo de Inventario",
            all_stores: "Todas las tiendas",
            create_inventory_receipt: "Crear recibo de inventario",
            export_button: "Exportar",
            no_receipts_to_show: "No hay recibos de inventario que mostrar.",
            edit_inventory_receipt: "Editar Recibo de Inventario",
            completed_inventory_receipt: "Recibo de Inventario Completado",
            see_replenishment: "Ver Recibo",
            schedule_an_appointment: "Agendar cita",
            replenishments_page: "Recibos",
            schedule_message: "Agenda tu cita para que podamos recibir tu inventario",
            we_received: "Recibimos ",
            received_bulks_SLA: " bultos cumpliendo con la cita de recibo. ",
            received_bulks_not_attended: " bultos incumpliendo con la cita de recibo. ",
            received_SLA: "Comenzarán a procesarse en máximo 24 horas",
            received_not_attended: "Es posible que comience el procesamiento después de 24 horas",
            processing_message: "Estamos contando y almacenando tu inventario.",
            late_processing: "Demorado por incumplimiento de cita",
            on_date: "el",
            expected: "piezas esperadas",
            received: "piezas contadas",
            stored: "piezas almacenadas",
            counting: "¡Seguimos contando!",
            finished_count: "¡Conteo finalizado!",
            storing: "¡Aún no finalizamos!",
            finished_storing: "¡Almacenamiento finalizado!",
            not_ready_to_sell: "No se mostrarán como disponibles hasta ser almacenadas",
            ready_to_sell: "¡Ya disponibles para venta!",
            processing_pending: "Pendiente de procesar",
            damaged: "dañadas",
            completed_both: "Etiquetaste y segregaste tus productos correctamente. ¡Sigue así!",
            completed_labeled: "Etiquetaste tus productos correctamente, aunque estaban segregados incorrectamente.",
            completed_segregated: "Segregaste tus productos correctamente, aunque estaban etiquetados incorrectamente.",
            completed_both_wrong: "Etiquetaste y segregaste incorrectamente tus productos.",
            check: "Revisa",
            here: "aquí",
            how_to_send: "cómo debes enviarnos tus productos.",
            no_replenishments_to_show: "No hay recibos que mostrar"
         },

        export_dialog: {
            title: 'Exportar recibos de inventario',
            intro: 'Se exportarán los recibos de inventario que cumplan con las siguientes condiciones: ',
            default_description: 'Se exportarán todos los recibos de inventario de tu cuenta. Si deseas, usa los filtros para obtener un reporte específico.',
            replenishment_id: 'Con número de recibo igual a: ',
            sku: 'Con recibos que contengan el SKU: ',
            status: 'Cuyo estado sea: ',
            completed_at: 'Que hayan sido completados entre el ',
            completed_at_join: ' y el ',
            type: 'Cuyo tipo sea: ',
            confirm: 'Descargar',
            cancel: 'Cancelar',
        },

        viewReplenishment: {
             name: "Nombre",
             expected_quantity: "Esperadas",
             quantity_processed: "Procesadas",
             quantity_given: "Dañadas",
             loading_summary: "Cargando resumen...",
             detail_parts_received: "Este es el detalle de piezas recibidas:",

        },

        viewReplenishmentInfo: {
            name: "Nombre",
            sku: "SKU",
            expected_quantity: "Esperadas",
            received_quantity: "Recibidas",
            stored_quantity: "Almacenadas",
            damaged_quantity: "Dañadas",
            loading_summary: "Cargando resumen...",
            replenishment_detail: "Este es el resumen de tu recibo de inventario:",
        },

        header: {
            id_type_notes: 'ID | Tipo | Notas',
            status: 'Estado',
            pieces_summary: "Resumen de piezas",
            replenishment_summary: "Resumen de recibo",
            filter: "Filtrar",
            reset_filters: "Limpiar filtros"
        },

        search: {
            id: "ID",
            sku: "Que contengan el SKU",
            type: "Preventa/Regular",
            status: "Estado",
            completed_at: "Fecha de finalización",
            from_to: "de-hasta"
        },

        types: {
            all: "Preventa/Regular",
            presale: "Preventa",
            regular: "Regular",
        },

        footer_navigation : {
            replenishments: "recibos"
        }

    },

    lots: {
        view_lots: "Ver Lotes",
        view_lots_detailed: "Estos són los lotes de este producto",
        lot_support_actions: "Si quieres pausar un lote, cancelar un lote, o bien conseguir una lista de todos los compradores de un lote, porfavor, crea un ticket a soporte, utilizando el widget de la parte inferior derecha.",
        no_lots: "Este producto no tiene configurado el control de lotes. Si quieres conocer más sobre esta funcionalidad, contacta al equipo de soporte."
    },

    returns: {
        container: {
            order_number: "Nº DE ORDEN RETORNADA",
            new_order: "Nº DE NUEVA ORDEN",
            type: "Tipo",
            status: "Estado",
            reserved_parts: "Piezas reservadas",
            creation_date: "Fecha de Creación",
            return_details: "Ver detalles del retorno",
            review_return: "Revisar devolución",
            see_returned_order: "Ver orden retornada",
            see_order_created: "Ver orden creada",
            not_applicable: "No aplica",
            no_reserved_parts: "Sin piezas reservadas",
            returns: "Retornos",
            no_returns: "No hay retornos que mostrar.",
            see_order: "Ver Orden",
            return_detail: "Detalle Del Retorno",
            all_stores: "Todas las tiendas",
        },

        listItem: {
            damaged_parts: "(Piezas Dañadas)",
            add_damaged_part: "Añadir pieza dañada",
            remove_product: "Quitar producto",
        },

        newOrder: {
            not_applicable: "No Aplica",
            awaiting_order: "Esperando Pedido",
            forwarding_cancelled: "Reenvío Cancelado",
            decision_pending: "Decisión Pendiente",
            awaiting_review: "Esperando Revisión",
            waiting_reception: "Esperando Recepción",
            arrived_at_warehouse: "Llegó al Almacén",
        },

        table: {
        name: "Nombre",
        quantity: "Cantidad",
        no_products_added: "No has agregado productos.",
        },

        types:{
            unknown: "Desconocido",
            return: "Devolución",
            change: "Cambio",
            immediate_change: "Cambio Inmediato",
            parcel: "Paquetería",
            awaiting_review: "Esperando Revisión",
            unidentified: "No identitificado"
        },

        externalContainer: {
            unchecked_return: "Este retorno no puede ser revisado.",
            order_number: "Número de orden",
            store: "Tienda",
            notes: "Notas",
            type_of_return:"Tipo de Devolución",
            return_guide:"¿Generar guía de retorno?",
            original_content:"Contenido original",
            products_to_return:"Productos a retornar",
            add_product: "Agregar otro producto de la tienda",
            product: "Producto",
            quantity: "Cantidad",
            send_replacement: "Productos a enviar como reemplazo",
            reject: "Rechazar",
            accept: "Aceptar",
            cancel: "Cancelar", 
            confirm: "Confirmar",
            refuse_return: "¿Deseas rechazar esta devolución?",
            return: "Rechazar Devolución",
            total_returned: "Confirma que serán devueltos un total de",
            product: "producto(s).",
            accept_return: "Aceptar Devolución",
        },

        returnContainer: {
            returned_order: "Número de orden retornada",
            store: "Tienda",
            type: "Tipo",
            state: "Estado",
            return_date: "Fecha de retorno",
            notes: "Notas",
            return_of_order: "Orden creada por retorno",
            order_returned: "Es importante confirmar qué desea hacer con el pedido retornado, estamos apartando los productos necesarios para reenviar la orden.",
            cancel_forwarding: "Cancelar Reenvío",
            resend_order: "Reenviar Orden Retornada",
            returned_products: "Productos retornados ",
            order_not_returned: "La orden no ha regresado.",
            reserved_parts: "Piezas reservadas",
            release_reserved_parts: "Liberar piezas reservadas",
            no_parts_reserved: "No hay piezas reservadas",
            parts_at_order: "Piezas asignadas a nueva orden",
            directory: "Tu cliente recibirá un email con la guía de ",
            print: "Imprimir RMA",
            return_guide: "Imprimir Guía de Retorno",
            forwarding_cancellation: "Confirmar Cancelación de Reenvío",
            reserved_inventory: "El inventario reservado para el reenvío de esta orden será liberado.",
            confirm: "Confirmar",
            cancel: "Cancelar",
            confirm_order_forwarding: "Confirmar Reenvío de Orden",
            duplicate_returned_order: "La orden retornada será duplicada. Reemplazaremos cualquier producto faltante o dañado con inventario en existencia.",
            unidentified: "No identificado",
            return_label: "Guía de retorno",
            no_return_label: "Sin guía retorno",
            arrived_at_warehouse_description: "El paquete llegó, pero está a la espera de ser recibido y procesado por un operador"
        },

    },

    statuses: {
        order_statuses: {
            unknown: "Sin status",
            initial: "Sincronizando",
            empty: "Pedido vacío",
            pending: "Pendiente",
            processing: "Procesando",
            shipped: "Enviado",
            returning: "Retornando a Cubbo",
            return_arrived: "Retornó a Cubbo",
            returned: "Recibiendo retorno",
            canceled: "Cancelado",
            error: "Falta Información",
            entirely_dropshipping: "Fulfilleado Externamente",
            backorder: "Sin Stock",
            unpaid: "Sin pagar",
            cancelation_window: "En Ventana de Cancelación",
            hold: "En pausa",
            interrupted: "Interrumpido",
            waiting_pick_up: "Esperando recolección",
            awaiting_pick_up: "Pendiente de recolectar",
            picked_up: "Recolectado",
            in_warehouse: "Recibido en almacén",
            delayed: "Demorado",
            reentered: "Retorno reingresado",
        },

        replenishment_statuses: {
            unknown: "Sin status",
            select: "Selecciona un estado",
            without_scheduling: "Creado sin cita",
            generating_invoices: "Generando factura",
            requested: "Agendado",
            pending: "En progreso",
            processing: "Procesando",
            in_putaway: "Procesando",
            received: "Recibido en almacén",
            completed: "Completado",
            completed_with_differences: "Completado con diferencias",
            in_transit: "En tránsito",
            canceled: "Cancelado"
        },

        claims_statuses: {
            unknown: "Sin status",
            pending: "Pendiente",
            rejected: "Rechazada",
            accepted: "Aceptada"
        },

        returns_statuses: {
            unknow: "Sin status",
            requested: "Esperando retorno",
            pending: "Decisión Pendiente",
            cancelled: "Reenvío Cancelado",
            ordered: "Reenvío ordenado",
            received: "Recibiendo retorno",
            reentered: "Retorno reingresado",
            arrived_at_warehouse: "Llegó al almacén",
        },

        tracking_statuses: {
            unknown: "Sin status",
            in_transit: "En tránsito",
            shipment_delivered: "Entregado",
            shipment_with_incident: 'Incidente',
            shipment_canceled: 'Entrega Cancelada',
            shipment_to_be_self_collected: 'Recolectar en oficina',
            shipment_out_for_delivery: 'En ruta de entrega',
            delayed: 'Demorado'
        },

        call_to_action: {
            shipment_to_be_self_collected: 'Instrucciones',
            error: 'Ver datos faltantes',
            backorder: 'Ver las acciones posibles'
        },

        tooltip: {
            cancelation_window: 'El comprador puede cancelar el pedido desde VTEX, cuando termine la ventana de cancelación configurada en tu VTEX, prepararemos el pedido.',
            interrupted: 'El fulfillment del pedido fue cancelado mientras estaba siendo preparado. No enviaremos este pedido, pero si se va a cobrar el cargo de pick and pack.',
            entirely_dropshipping: 'Cubbo no almacena NINGUNO de los productos de este pedido',
            empty: 'El pedido no tiene piezas asignadas para fulfillear',
            no_stock: 'No se han asignado suficientes piezas para poder enviar el total solicitado de este producto'
        },

        tracking_descriptions: {
            second_try: 'Segundo intento',
            third_try: 'Tercer intento',
            client_requested: 'Solicitud del comprador',
            missing_references: 'Faltan referencias',
            not_at_home: 'El comprador estaba ausente',
            refused_payment: 'El comprador se rehusó a pagar',
            refused_package: 'El comprador rechazó el paquete',
            cubbo_requested: 'Solicitud de Cubbo',
            carrier_requested: 'Por paquetería',
            stolen: 'Robo',
            lost: 'Pérdida',
            damaged: 'Dañado',
            returned_by_carrier: 'Por entrega no exitosa',
            returned_by_client: 'Por devolución solicitada',
            in_return: "En retorno por paquetería",
        }
    },
    
    settings: {
        packing_options: {
            title: "Preferencias de empaque",
            description: "Configura el empaque que deseas habilitar para tu tienda",
            container: {
                title: "Preferencias de empaque",
                description: "Selecciona el empaque que deseas habilitar para enviar los pedidos de tu tienda",
                save_options: "Guardar cambios",
                save_processing: "Procesando",
                save_success_title: "Se actualizaron tus preferencias de empaque.",
                save_error_title: "Ocurrió un error al actualizar tus preferencias de empaque.",
                save_error_description: "Intenta más tarde, si el problema persiste contacta al equipo de soporte.",
                not_available_option: "Próximamente",
                non_fragile_packing: {
                    title: "Configuración general",
                    title_description: "Para cualquier pedido que no aplique la configuración específica, se empacará siguiendo esta configuración",
                    description: "Se empacará con la opción más económica según el volumen del pedido y disponibilidad del material",
                    options: {
                        parcel_bag: {
                            title: "Bolsa de paqueteria",
                            description: "Para productos pequeños sin riesgo a daño por impacto",
                        },
                        jiffy_envelope: {
                            title: "Sobre Jiffy",
                            description: "Para productos muy pequeños con protección moderada",
                        },
                        cubbo_box: {
                            title: "Caja Cubbo",
                            description: "Para productos pequeños y medianos",
                            disabled_hint: "No es posible deshabilitar esta opción ya que, es el empaque de respaldo por defecto",
                        },
                        multi_package_with_cubbo_box: {
                            title: "Multipaquete con Caja Cubbo",
                            description: "Para cuando los productos del pedido no caben en un sólo empaque",
                        }
                    }
                },
                fragile_packing: {
                    title: "Configuración específica",
                    title_complement: "Priorizado sobre configuración general",
                    title_description: "Sólo si el pedido cumple con las condiciones descritas, se empacará siguiendo ésta configuración",
                    description: "Cuando el pedido incluye {BOLD_START}uno o más productos frágiles{BOLD_END}, se empacará con la mejor opción de la siguiente selección",
                    sub_description: 'Cuando el pedido incluye uno o más productos configurados como {BOLD_START}frágiles{BOLD_END}.',
                    options: {
                        jiffy_envelope: {
                            title: "Sobre Jiffy",
                            description: "Con etiqueta frágil pegada en el exterior",
                        },
                        cubbo_box: {
                            title: "Caja Cubbo",
                            description: "Con etiqueta frágil pegada en el exterior y material de protección en el interior",
                            disabled_hint: "No es posible deshabilitar esta opción ya que, es el empaque de respaldo por defecto",
                        }
                    }
                },
                fragile_packing_materials: {
                    titles: {
                        for_fragile: "Se añade {BOLD_START}material de protección{BOLD_END} cuando el paquete incluye uno o más productos configurados como {BOLD_START}frágiles{BOLD_END}.",
                        for_not_fragile: "Se empacará el pedido utilizando el material de protección seleccionado."
                    },
                    for_fragile: {
                        BUBBLE_WRAP: {
                            title: "Plástico burbuja",
                            description: "Protección moderada"
                        },
                        KRAFT_PAPER: {
                            title: "Papel Kraft",
                            description: "Mas económico. Respetuoso con el medio ambiente"
                        },
                        CORN_EXTRUDATE: {
                            title: "Extruido de maíz",
                            description: "Alta protección. Respetuoso con el medio ambiente. Aplican cargos adicionales"
                        }
                    },
                    for_not_fragile: {
                        BUBBLE_WRAP: {
                            title: "Plástico burbuja",
                            description: "Protección moderada"
                        },
                        KRAFT_PAPER: {
                            title: "Papel Kraft",
                            description: "Más economico. Respetuoso con el medio ambiente"
                        },
                        CORN_EXTRUDATE: {
                            title: "Extruido de maíz",
                            description: "Alta protección. Respetuoso con el medio ambiente. Aplican cargos adicionales"
                        },
                        UNSELECTED: {
                            title: "Sin material de protección",
                            description: "Sin costo"
                        }
                    }
                },
                virtual: {
                    packageless: {
                        sub_description: 'Cuando todo el pedido es sólo un producto configurado como {BOLD_START}pre-empacado{BOLD_END}',
                        sub_description_hint: 'Para configurar un producto como pre-empacado, edita el producto y activa el botón con esta característica.',
                        options: {
                            packageless: {
                                title: "Enviar sin empaque",
                                description: "Se pegará la guía en el exterior del producto",
                                disabled_hint: "No es posible deshabilitar esta opción. Sólo aplicará para los productos configurados como pre-empacados",
                            }
                        }
                    }
                }
            }
        },

        automation_settings: {
            title: "Automatizaciones",
            sub_title: "Configura acciones automáticas para situaciones cotidianas"
        },

        automation_container: {
            unpaid_order_cancellation_panel: {
                title: "Cancelar pedidos sin pago",
                description: "Define el tiempo máximo que una orden puede estar en espera de pago.",
                payment_window_units: {
                    days_one: 'Día',
                    days_other: 'Días',
                    months_one: 'Mes',
                    months_other: 'Meses'
                },
                help_text: '{{payment_window}} después de que el pedido fue creado, lo cancelaremos si no ha sido pagado.',
                shopify_help_text: 'Los pedidos de Shopify también serán cancelados en el sitio.'
            },
            carrier_returns_cancellation_panel: {
                immediately: 'Inmediatamente',
                title: 'Cancelar retornos de paquetería',
                awaiting_review: 'Decisión pendiente',
                description_left: 'Cantidad de días que un retorno de paquetería permanecerá en ',
                description_right: ' antes de que sea cancelado automáticamente.',
                place_holder: 'Días de espera',
                days_one: 'Día',
                days_other: 'Días',
                help_text: '{{review_window}} después de que el retorno haya regresado a Cubbo, lo cancelaremos.'
            },
            enabled_result: 'activada',
            disabled_result: 'deshabilitada',
            success_message: 'Automatización {{status}}.'
        },

        store_br: {
            title_sbr: "Reglas de negocio",
            sub_title_sbr: "Crea instrucciones automatizadas para tus pedidos con condiciones específicas"
        },

        brand_identity: {
            title_sbr: "Identidad de marca",
            sub_title_sbr: "Configura recursos básicos de tu identidad de marca",
        },
        brand_identity_container: {
            logo_title:"Logotipo",
            logo_description: "Adjunta una imagen PNG o JPG",
            logo_uploaded: "Ligotipo cargado",
            name_title:"Nombre de la marca",
            name_description: "Escribe la manera en la que quieras que tu tienda sea llamada.",
            contact_title:"Correo electrónico de soporte",
            contact_description: "Escribe el correo en donde tus clientes podrán escribirte para dar seguimiento a casos específicos como pedidos robados, extraviados o dañados.",
            logo_preview: "Previsualización de uso",
            preview_tracking: "Página de rastreo",
            preview_email: "Correos de rastreo",
            logo_recomendation:"Máximo 5MB. Dimensiones recomendadas: 600 x 400 pixeles como mínimo. Formato recomendado: Fondo tranparente."

        },

        automatic_reports: {
            title: "Reportes automáticos",
            sub_title: "Activa el envío automático de cierta información de tu tienda",
            not_defined: "No definido",
            and: "y",
            more: "más",
            category: "Categoría",
            no_reports_to_show: "no hay reportes para mostrar",
            export_report: "Exportar reporte",
            error: "Ocurrió un error, intente mas tarde",
            error_insert_email_recipients: "Agrega al menos 1 destinatario",
            report_table_columns: {
                name: "Nombre",
                status: "Estado",
                category: "Categoría",
                frequency: "Frecuencia",
                recipients: "Destinatarios"
            },
            filters: {
                name: "Nombre",
                category: "Categoría",
                recipients: "Destinatarios"
            },
            report_names: {
                inventory_detail: "Inventario",
                lots_detail: "Lotes",
                inventory_kardex: "Historial de inventario",
                shipped_products: "Productos enviados",
                shipped_orders_detail: "Pedidos enviados",
                backorder_products: "Productos pendientes",
                returned_products: "Productos devueltos",
                claims_detail: "Disputas",
                replenishments_detail: "Recibos",
                return_invoices_detail: "Facturas de retorno",
                order_invoices_detail: "Facturas de pedidos",
                replenishment_invoices_detail: "Facturas de recibos",
                billing_statement_email_recipients: "Estado de cuenta"
            },
            report_categories: {
                INVENTORY: "Inventario",
                SALES: "Ventas",
                ORDERS: "Pedidos",
                RETURNS: "Retornos",
                CLAIMS: "Disputas",
                REPLENISHMENTS: "Recibos",
                INVOICES: "Facturas",
                BILLING: "Facturación"
            },
            report_frequency: {
                DAILY: "Diario",
                WEEKLY: "Semanal",
                MONTHLY: "Mensual",
                FORTNIGHTLY: "Quincenal",
                QUATERLY: "Trimestral",
                report_frequency_descriptions: {
                    DAILY: "al final del día",
                    WEEKLY: "domingo al final del día",
                    MONTHLY: "el último día del mes al final del día",
                    FORTNIGHTLY: "el 15 y el último día del mes",
                    QUATERLY: "el último día del mes",
                },
                STATEMENT: "Cada corte"
            },
            report_details: {
                about_the_report_title: "Sobre el reporte",
                delivery_status: "Envío del reporte",
                true: "Activo",
                false: "Inactivo",
                frequency_title: "Frecuencia de envío",
                frequency_placeholder: "Elige la frequencia de envío de este reporte",
                recipients_title: "Destinatarios",
                recipients_placeholder: "Escribe el correo de las personas que deseas que reciban este reporte",
                report_descriptions: {
                    inventory_detail: "Este reporte te ayudará a llevar un registro de tu inventario, identificando las piezas dañadas, expiradas, disponibles y no disponibles para venta. Encuentra también este reporte en el panel de inventarios al exportar la información.",
                    lots_detail: "Este informe le ayudará a realizar un seguimiento de su inventario de lotes, identificando piezas expiradas y disponibles",
                    inventory_kardex: "Este reporte te será útil para monitorear los movimientos de entrada o salida de tu inventario.",
                    shipped_products: "En este reporte tendrás un concentrado de todos los productos contenido en los pedidos enviados. Esto, te permitirá llevar un monitoreo de tu inventario y con esto hacer cálculos de resurtido, análisis de ventas, entre otras opciones.",
                    shipped_orders_detail: "A través de este reporte podrás medir distintas variables de tus envíos como por ejemplo, qué incidente es el más común en tus envíos y con qué paquetería se presenta, cantidad de pedidos entregados con demora, intentos de entrega que tuvo un pedido retornado, entre otras. Esta información también podrías descargarla desde el panel de ordenes, filtrando por estado de de pedido “Enviadas” y por fecha de envío.",
                    backorder_products: "Este reporte te será útil para accionar sobre los pedidos que se encuentran detenidos por falta de stock. Podrías hacer planeaciones de resurtido con base en las piezas faltantes o bien, generar estrategias de compensación para tus clientes por el tiempo de espera extraordinario.",
                    returned_products: "Revisa el estado en el que se recibieron las piezas de tus retornos. Este reporte es especialmente útil para pedidos con ",
                    claims_detail: "Este reporte te permitirá cuantificar el monto total a reembolsar de las disputas aceptadas del mes e identificar con facilidad el motivo por el cual el resto de las disputas fueron rechazadas.",
                    replenishments_detail: "Revisa cuántas piezas recibimos del total de piezas esperadas y cuántas de las piezas recibidas, fueron recibidas como dañadas. También encontrarás los recibos creados por Cubbo para reingresar al inventario retornos con guía externa o no identificada.",
                    return_invoices_detail: "Recibe en automático todas las facturas de retornos, emitidas del periodo seleccionado como frecuencia de envío. Este mismo reporte lo podrás encontrar en el panel de facturas.",
                    order_invoices_detail: "Recibe en automático todas las facturas de tus pedidos emitidas del periodo seleccionado como frecuencia de envío. Este mismo reporte lo podrás encontrar en el panel de facturas.",
                    replenishment_invoices_detail: "Recibe en automático todas las facturas de recibos de inventario, emitidas del periodo seleccionado como frecuencia de envío. Este mismo reporte lo podrás encontrar en el panel de facturas.",
                    billing_statement_email_recipients: "Este reporte se envía en cada corte, contiene adjunto un desglose detallado del estado de cuenta del periodo."
                },
                report_buttons: {
                    cancel: "Cancelar",
                    save: "Guardar",
                    show_details: "Ver detalles",
                    deactivate: "Deshabilitar reporte",
                    activate: "Habilitar reporte",
                    pause_report: "Pausar envío",
                    restart_report: "Reanudar envío",
                    download_report: "Descargar muestra"
                },
                report_notifications: {
                    status_done: "Estado del envío del reporte guardado",
                    done: "Configuración guardada",
                    error: "Error al guardar la configuracion de reportes"
                }
            }
        },

        alerts: {
            title_sbr: "Alertas",
            sub_title_sbr: "Configura a quién debemos enviar las notificaciones sobre tu tienda",
            sub_title: "Se enviará una notificación por correo a la lista de suscriptores de cada categoría cuando el evento descrito suceda.",
        },

        alerts_container: {
            categories:{
                product_expirations_email_notification:{
                    title: "Control de lotes",
                    label: "Se notificará cuando un lote haya expirado"
                },
                replenishments_email_notification:{
                    title: "Recibo de inventario",
                    label: "Se enviará confirmación cuando se agende un nuevo recibo de inventario y cuando éste se complete"
                },
                shipping_incident_created_by_cubbo_email_notification:{
                    title: "Incidentes",
                    label: "Se notificará cuando se genere uno o varios incidentes para alguno de tus pedidos"
                },
                returns_email_notification:{
                    title: "Retornos",
                    label: "Se notificará cuando se inicie la devolución de un pedido y cuando éste se reciba en Cubbo"
                },
                claims_email_notification:{
                    title: "Disputas",
                    label: "Se notificará sobre la resolución de las disputas generadas"
                },
                maquila:{
                    title: "Maquila",
                    label: "Se notificará sobre el estado de la maquila solicitada (Pendiente En proceso, Completada)"
                }
            },
            place_holder: "Escribe el correo de las personas que deseas que reciban esta notificación",
            error_message_email: "El email agregado no es válido",
            done: "Configuración guardada",
            error: "Error al guardar la configuracion de alertas"
        },

        business_rule_creator: {
            button: "Añadir regla",
            path: "Añadir regla",
            title: "Nueva regla de negocio",
            title_preview: "Estás a punto de crear esta regla de negocio",
            cancel_creation: {
                button: "Descartar",
                dialog_title: "Se perderá la información",
                dialog_description: "¿Estas seguro de que deseas salir sin guardar?",
                dialog_confirm_button: "Si, deseo salir",
                dialog_cancel_button: "No, regresar a editar",
                on_cancel: "Creación de regla de negocio cancelada"
            },
            create_rule: {
                preview_rule_button: "Previsualizar regla",
                save_rule_button: "Crear regla",
                edit_rule_button: "Regresar a editar",
                dialog_title: "Crear regla de negocio",
                dialog_description: "¿Estas seguro de que deseas crear la regla de negocio?",
                dialog_confirm_button: "Si, deseo crearla",
                dialog_cancel_button: "No, regresar a editar",
                on_error: "Ocurrió un error, intenta mas tarde",
                on_success: "Se creó la regla de negocio"
            },
            confirm_rule: {
                success: "Se creó la regla de negocio",
                error: "Ocurrió un error al crear la regla de negocio, intente mas tarde"
            },
            add_condition_button: "Añadir otra condición",
            condition_types: {
                AND: {
                    title: "Adicional a la anterior",
                    description: "El pedido deberá cumplir con todas las condiciones que se definan, para aplicar la instrucción",
                    display_name: "y..."
                },
                OR: {
                    title: "Opcional a la anterior",
                    description: "Si el pedido cumple alguna de las condiciones opcionales, se aplicará la instrucción",
                    display_name: "o..."
                }
            },
            general_business_rule_settings: {
                title: "Datos generales",
                description: "Define el nombre y el periodo de vigencia de esta regla",
                alias_field: "Alias de la regla",
                alias_placeholder: "Escribe un alias de esta regla para identificarla fácilmente",
                validity: "Vigencia",
                validity_time_zone: "La zona horaria corresponde al almacén",
                validity_start_field: "Se activará desde",
                validity_start_placeholder: "Hoy, al guardar",
                validity_end_field: "Hasta",
                without_validity_end: "Sin expiración",
                confirm_button_missing_all_required: "Define un alias y la vigencia para continuar",
                confirm_button_missing_only_alias: "Define un alias para continuar",
                confirm_button_missing_only_validity: "Define la vigencia para continuar",
                confirm_button: "Continuar para definir una acción",
                cancel_edit_button: "Cancelar",
                confirm_edit_button: "Guardar cambios",
                remaining_alias_character_counter: {
                    no_remaining: "Se alcanzó el límite de caracteres disponibles",
                    one_remaining: "Queda 1 caracter disponible",
                    multiple_remaining: "Quedan {QUANTITY} caracteres disponibles"
                },
                completed_step: {
                    from_today_with_no_limit: "Se activará desde hoy al guardar, por tiempo indefinido",
                    from_today_with_limit: "Se activará desde hoy al guardar, hasta el {END_DATE}",
                    from_some_day_with_no_limit: "Se activará desde el {START_DATE}, por tiempo indefinido",
                    from_some_day_with_limit: "Se activará desde el {START_DATE}, hasta el {END_DATE}"
                },
                validity_error_message: {
                    missing_validity_end: "Define la fecha de vigencia",
                    invalid_validity: "La fecha de activación debe ser menor o igual a la fecha de vigencia"
                }
            },
            business_rule_action_settings: {
                title: "¿Qué deseas que suceda con el pedido?",
                description: "En la próxima sección, definirás cuándo se deberá aplicar esta acción",
                change_option: "Cambiar",
                action_options_title: "Selecciona una acción",
                product_insert_options_title: "Si el pedido ya contiene algunas de las piezas de las que desean añadir ¿Qué deberíamos hacer?",
                cancel_edit_button: "Cancelar",
                confirm_edit_button: "Guardar cambios",
                confirm_edit_dialog:{
                    title: "Guardar cambios",
                    description: "Se guardarán los cambios realizados y se perderán los cambios de los pasos subsecuentes",
                    cancel_button: "Cancelar",
                    confirm_button: "Guardar cambios"
                },
                action_options: {
                    ADD_SHIPPING_PACKAGE_OPTION: {
                        title: "Utilizar ciertos injertos para empacar el pedido",
                        description: "Por ejemplo, cajas, papel china, stickers, folletos, etc"
                    },
                    ADD_PRODUCT_OPTION: {
                        title: "Añadir dentro del pedido, productos o kits de regalo",
                        description: "Por ejemplo, regalo promocional 2x1, regalo en compra mínima, etc"
                    },
                    ADD_INSERT_OPTION: {
                        title: "Añadir dentro del pedido, injertos o productos promocionales",
                        description: "Por ejemplo, regalo promocional 2x1, regalo en compra mínima, etc"
                    },
                    PAUSE_ORDER_OPTION: {
                        title: "Pausar el pedido"
                    },
                    CANCEL_ORDER_OPTION: {
                        title: "Cancelar el pedido"
                    }
                },
                confirmation_button_unselected_action: "Selecciona una acción para continuar",
                confirmation_button: "Continúa para definir una condición",
                product_selector: {
                    ADD_SHIPPING_PACKAGE_OPTION: {
                        title: "¿Qué injertos deseas que se utilicen para empacar?",
                        add_product_button: "Seleccionar injerto",
                        add_another_product_button: "Añadir otro injerto",
                        confirmation_button_missing_product: "Selecciona un artículo para continuar",
                        confirmation_button: "Continúa para definir cuándo se deberá empacar con estos injertos"
                    },
                    ADD_PRODUCT_OPTION: {
                        title: "¿Qué productos deseas que se añadan al pedido?",
                        description: "Selecciona un producto, kit o producto no escaneable (injerto)",
                        add_product_button: "Añadir producto",
                        add_another_product_button: "Añadir otro producto"
                    },
                    ADD_INSERT_OPTION: {
                        title: "¿Qué productos deseas que se añadan al pedido?",
                        description: "Selecciona un producto no escaneable (injerto)",
                        add_product_button: "Añadir injerto",
                        add_another_product_button: "Añadir otro injerto"
                    }
                },
                product_insert_options: {
                    ENSURE_PRODUCT: {
                        title: "Añadir sólo las piezas faltantes, para enviar la cantidad deseada",
                        description: "Ej: Se desean añadir 2 manzanas y el pedido ya trae 2 manzanas, no se añadirán más manzanas. Si el pedido trae 1 manzana, se añadirá 1 manzana."
                    },
                    INCLUDE_PRODUCT: {
                        title: "Sin importar que el pedido ya las contenga, añadir la cantidad deseada",
                        description: "Ej: Se desean añadir 2 manzanas y el pedido ya trae 3 manzanas, se añadirán aún así, 2 manzanas."
                    }
                },
                completed_step: {
                    PAUSE_ORDER_OPTION: "Pausar el pedido",
                    CANCEL_ORDER_OPTION: "Cancelar el pedido",
                    ADD_SHIPPING_PACKAGE_OPTION: {
                        description_start: "Para empacar, utilizar ",
                        product: "{QUANTITY} de {SKU}",
                        last_product_join: " y "
                    },
                    ADD_PRODUCT_OPTION: {
                        description_start: "Añadir al pedido, ",
                        insert_options: {
                            ENSURE_PRODUCT: "sólo si el pedido aún no lo contiene ",
                            INCLUDE_PRODUCT: "aunque el pedido ya lo contenga "
                        },
                        product: "{QUANTITY} de {SKU}",
                        last_product_join: " y ",
                        description_end: " como regalo dentro del pedido"
                    },
                    ADD_INSERT_OPTION: {
                        description_start: "Añadir al pedido, ",
                        insert_options: {
                            ENSURE_PRODUCT: "sólo si el pedido aún no lo contiene ",
                            INCLUDE_PRODUCT: "aunque el pedido ya lo contenga "
                        },
                        product: "{QUANTITY} de {SKU}",
                        last_product_join: " y ",
                        description_end: " como regalo dentro del pedido"
                    }
                }
            },
            business_rule_statement_condition: {
                title: "Define la condición",
                title_template: "Estás creando esta condición:",
                save_button: "Guardar condición",
                save_button_update: "Actualizar condición",
                cancel_button: "Descartar cambios",
                unsaved_changes: "Datos sin guardar",
                completed_condition: {
                    HAS_FRAGILE_ITEMS: "Cuando el pedido incluya productos fragiles",
                    CHANNEL_TYPE_NAME: "Cuando sea un pedido de {{channel_name}}",
                    IS_FIRST_TIME_BUYER: "Cuando sea un pedido de nuevo comprador",
                    IS_POTENTIAL_FRAUD: "Cuando el pedido sea detectado como fraude por Shopify",
                    VALIDATE_PRODUCT: {
                        validationOption:{
                            INCLUDE_PRODUCT: "Cuando el pedido incluya ",
                            NOT_INCLUDE_PRODUCT: "Cuando el pedido no incluya "
                        },
                        quantityOption: {
                            MINIMUM: "por lo menos {MIN_QUANTITY}",
                            MAXIMUM: "hasta {MAX_QUANTITY}",
                            MINIMUM_AND_MAXIMUM: "de {MIN_QUANTITY} a {MAX_QUANTITY}",
                            EXACTLY: "exactamente {EXACT_QUANTITY}"
                        },
                        exclusive: " y ningún producto más",
                        any_sku: " de cualquier producto de tu tienda",
                        specified_sku: " de alguno de estos productos...",
                        all_orders: "En todos los pedidos"
                    },
                    FOR_EVERY_PRODUCT: {
                        every_quantity: "Por cada vez que el pedido incluya {QUANTITY}",
                        with_max_quantity: " (hasta {MAX_QUANTITY})",
                        any_sku: " de cualquier producto de tu tienda",
                        specified_sku: " de alguno de estos productos..."
                    }
                }
            },
            business_rule_additional_statement_condition: {
                title: "Selecciona una condición",
                options_placeholder: "Cuando el pedido..."
            },
            business_rule_statements: {
                custom: {
                    validations: {
                        INCLUDE_PRODUCT: "Incluya",
                        NOT_INCLUDE_PRODUCT: "No incluya"
                    },
                    quantities: {
                        MINIMUM: "Por lo menos",
                        MAXIMUM: "Máximo",
                        MINIMUM_AND_MAXIMUM: "De",
                        EXACTLY: "Exactamente"
                    },
                    title_validations: "Cuando el pedido...",
                    title_business_rules_per_integration: "Cuando el pedido sea de:",
                    title_quantities: "Esta cantidad...",
                    title_sku: "De alguno de estos productos...",
                    button_add_product: "Añadir producto",
                    from_quantity_to_quantity: "a",
                    any_product: "Cualquier producto",
                    any_product_placeholder: "Cualquier producto de tu tienda",
                    not_any_product_placeholder: "Selecciona o pega el SKU de algún producto",
                    exclusive: "... siempre y cuando, el pedido no contenga ningún otro producto adicional a los enlistados",
                    single_sku_error: "Hay {QUANTITY} SKU inválido. Corrígelo o eliminalo antes de continuar",
                    multiple_sku_error: "Hay {QUANTITY} SKUs inválidos. Corrígelos o eliminalos antes de continuar",
                    error_messages: {
                        unselected_validation_option: "Selecciona una opción",
                        unselected_quantity_option: "Selecciona una opción",
                        missing_quantity: "Ingresa una cantidad",
                        invalid_range_quantity: "La cantidad minima debe ser menor a la cantidad máxima",
                        missing_products: "Pega o añade SKU's de por lo menos un producto"
                    }
                },
                for_every_product: {
                    title_for_every_product: "Por cada...",
                    title_from_sku: "de alguno de estos productos...",
                    title_complement_from_sku: "que el pedido incluya.",
                    title_max_quantity: "Máximo de piezas a añadir por producto en el pedido",
                    option_max_quantity: "Sin límite",
                    button_add_product: "Añadir producto",
                    any_product: "Cualquier producto",
                    any_product_placeholder: "Cualquier producto de tu tienda",
                    not_any_product_placeholder: "Selecciona o pega el SKU de algún producto",
                    single_sku_error: "Hay {QUANTITY} SKU inválido. Corrígelo o eliminalo antes de continuar",
                    multiple_sku_error: "Hay {QUANTITY} SKUs inválidos. Corrígelos o eliminalos antes de continuar",
                    error_messages: {
                        missing_for_every_quantity: "Ingresa una cantidad",
                        missing_products: "Pega o añade SKU's de por lo menos un producto",
                        invalid_maximum_quantity: "Ingresa una cantidad o especifica si no deseas asignar un límite de piezas"
                    }
                }
            },
            business_rule_statement_settings: {
                title: "¿Cuándo deberíamos aplicar la acción previamente definida?",
                description: "Define qué condiciones debe cumplir el pedido para que se aplique la acción",
                display_less_options: "Ver menos casos",
                display_more_options: "Ver más casos",
                statement_options: {
                    ALL_ORDERS: {
                        title: "En {BOLD_START}todos los pedidos{BOLD_END}"
                    },
                    CHANNEL_TYPE_NAME: {
                        title: "Cuando sea un pedido de {HIGHLIGHTED_START}cierto canal de venta{HIGHLIGHTED_END}"
                    },
                    IS_FIRST_TIME_BUYER: {
                        title: "Cuando sea un pedido de {BOLD_START}nuevo comprador{BOLD_END}"
                    },
                    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY: {
                        title: "Cuando el pedido incluya {BOLD_START}por lo menos{BOLD_END} {HIGHLIGHTED_START}cierta cantidad{HIGHLIGHTED_END} de {HIGHLIGHTED_START}ciertos productos{HIGHLIGHTED_END}"
                    },
                    SPECIFIED_PRODUCT_WITH_MIN_AND_MAX_QUANTITY: {
                        title: "Cuando el pedido incluya {BOLD_START}de{BOLD_END} {HIGHLIGHTED_START}cierta cantidad{HIGHLIGHTED_END} {BOLD_START}a{BOLD_END} {HIGHLIGHTED_START}cierta cantidad{HIGHLIGHTED_END} de {HIGHLIGHTED_START}ciertos productos{HIGHLIGHTED_END}"
                    },
                    FOR_EVERY_PRODUCT: {
                        title: "{BOLD_START}Por cada{BOLD_END} {HIGHLIGHTED_START}cierta cantidad{HIGHLIGHTED_END} de {HIGHLIGHTED_START}ciertos productos{HIGHLIGHTED_END} que el pedido incluya"
                    },
                    FOR_EVERY_PRODUCT_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: {
                        title: "{BOLD_START}Por cada{BOLD_END} {HIGHLIGHTED_START}cierta cantidad{HIGHLIGHTED_END} de {HIGHLIGHTED_START}ciertos productos{HIGHLIGHTED_END} que el pedido incluya {BOLD_START}, siempre y cuando no incluya{BOLD_END} {HIGHLIGHTED_START}ciertos productos{HIGHLIGHTED_END}"
                    },
                    NOT_INCLUDING_SPECIFIED_PRODUCT: {
                        title: "Cuando el pedido incluya {BOLD_START}cualquier producto que no sea{BOLD_END} {HIGHLIGHTED_START}ciertos productos{HIGHLIGHTED_END}"
                    },
                    SPECIFIED_PRODUCT_WITH_MIN_QUANTITY_AND_NOT_INCLUDING_SPECIFIED_PRODUCT: {
                        title: "Cuando el pedido incluya {HIGHLIGHTED_START}cierta cantidad{HIGHLIGHTED_END} de {HIGHLIGHTED_START}ciertos productos{HIGHLIGHTED_END} {BOLD_START}pero no incluya{BOLD_END} {HIGHLIGHTED_START}ciertos productos{HIGHLIGHTED_END}"
                    },
                    SPECIFIED_PRODUCT_EXACT_QUANTITY_AND_EXCLUSIVE: {
                        title: "Cuando el pedido incluya {BOLD_START}la cantidad exacta de{BOLD_END} {HIGHLIGHTED_START}cierta cantidad{HIGHLIGHTED_END} de {HIGHLIGHTED_START}ciertos productos{HIGHLIGHTED_END} {BOLD_START}y ningún otro producto más{BOLD_END}"
                    },
                    SPECIFIED_PRODUCT_EXACT_QUANTITY: {
                        title: "Cuando el pedido incluya {BOLD_START}la cantidad exacta de{BOLD_END} {HIGHLIGHTED_START}cierta cantidad{HIGHLIGHTED_END} de {HIGHLIGHTED_START}ciertos productos{HIGHLIGHTED_END}; puede incluir algún otro producto"
                    },
                    CUSTOM_STATEMENT: {
                        title: "{BOLD_START}Crear condición personalizada{BOLD_END}"
                    },
                    FRAUD_ORDER_DETECTED: {
                        title: "Cuando el pedido sea {BOLD_START}detectado como fraude{BOLD_END} por Shopify"
                    },
                    HAS_FRAGILE_ITEMS: {
                        title: "Cuando el pedido incluya {BOLD_START}productos frágiles{BOLD_END}"
                    }
                }
            },
            products_table: {
                product_image_header: "Imagen",
                product_name_header: "Nombre",
                product_sku_header: "SKU",
                product_quantity_header: "Cantidad deseada"
            }
        },

        store_business_container: {
            market_insert: "Añadir injerto",
            hold_order: "Pausar pedido",
            cancel_order: "Cancelar perdido",
            include_order: "Añadir estos productos, aunque el pedido ya los contenga...",
            ensure_order: "Añadir estos productos, sólo si el pedido aún no los contiene...",
            package_order: "Utilizar estos injertos al empacar...",
            info_tooltip: "Agrega instrucciones personalizadas para tus pedidos, también conocidas como Reglas de Negocio.",
            rule_type: "Añadir injerto",
            no_rules: "Aún no tienes reglas registradas",
            defined_rule: "Regla Predeterminada",
            order_has: "Cuando el pedido...",
            no_registers: "Sin registros",
            hold_orders: "Pausar pedido",
            add: "Añade",
            instruction: "Instrucción",
            quantity: "Cantidad",
            of: "de",
            some_of_this: "de alguno de estos productos",
            not_some_of_this: "producto(s) que no sea alguno de estos",
            skus: "SKUs",
            title: "Regalo de verano",
            active: "Activa",
            inactive: "Inactiva",
            inactive: "Inactiva",
            and: "y...",
            or: "o...",
            all_skus: "Cualquier SKU de la tienda.",
            include_at_least:"por lo menos ",
            not_include_at_least:"por lo menos ",
            include_exactly: "exactamente ",
            not_include_exactly: "exactamente ",
            include_range: "de ",
            not_include_range: "de ",
            include_up_to: "hasta ",
            not_include_up_to: "hasta ",
            channel_name: "Sea de {{channel_name}}",
            is_potencial_fraud: "Sea detectado como fraude por Shopify",
            is_first_time_buyer: "Sea de un nuevo comprador",
            has_fragile_items: "Contiene productos frágiles",
            except: "Siempre y cuando no sean ",
            to: "a",
            has_not: "No tenga ",
            of_the_next: "de alguno de los siguientes productos:",
            exactly: "exactamente ",
            at_least: "por lo menos ",
            of_range: "de ",
            up_to: "hasta ",
            deleted_products: 'Productos eliminados',
            includes: "Incluya",
            does_not_include: "No incluya",
            confirm_dialog: {
                title_enable: "Activar regla de negocio",
                title_disable: "Desactivar regla de negocio",
                description: "Aplicará en pedidos que se sincronicen después de confirmar",
                confirm: "Confirmar",
                cancel: "Cancelar",
                error : {
                    RULE_ENABLED_TRUE: 'Esta regla ya ha sido activada',
                    RULE_ENABLED_FALSE: 'Esta regla ya ha sido desactivada',
                    undefined: "Ocurrió un error al intentar confirmar esta operación"
                }
            },
            time_validity: {
                no_start_and_no_end_date: "sin fecha de inicio, sin expiración",
                no_start_date: "sin fecha de inicio",
                no_end_date: "sin fecha de expiración",
                from_date: "desde",
                to_date: "hasta",
                is_expired: "Vigencia expirada",
                is_not_expired: "Periodo de vigencia",
                last_update: "Última actualización",
            },
            products_to_add_table_columns: {
                desired_product: "Producto deseado",
                quantity_to_add: "Cantidad a añadir",
                max_pieces_to_add: "Máximo de piezas a añadir"
            },
            many_pieces_to_add: "piezas",
            single_piece_to_add: "pieza",
            sku: "SKU",
            kit: "KIT",
            without_max_limit: "Sin límite",
            exclusive: "y ninguna más",
            for_every_product: "Por cada vez que el pedido...",
            as_long_as_the_order: "Siempre y cuando el pedido...",
            note_max_times_rule_applied: "NOTA: El máximo de veces que esta regla aplicará en el mismo pedido, depende del máximo de piezas a añadir.",
            no_name_business_rule: "Regla sin nombre creada el",
            products_quantity: "Cantidad",
            max_products_quantity: "Máximo de piezas",
            rule_without_name: "Regla sin nombre creada el",
            filters: {
                active: "Activa",
                inactive: "Inactiva",
                add_product: "Añadir producto",
                cancel_order: "Cancelar pedido",
                use_package: "Utilizar empaque",
                hold_order: "Pausar pedido",
                category: "Categoría",
                add_this_sku: "SKU en acción",
                that_contains_this_sku: "SKU en condición",
                alias: "Alias",
                estado: "Estado",
                expired: "Expiradas"
            }
        },

        store_settings: {
            config_title: "Configuración",
            user_config: "Configuración de usuarios",
            description: "Aqui puedes ver la información de los usuarios asociados a tu tienda",
        },

        tracking_emails: {
            config_title: "Notificaciones de rastreo",
            config_subtitle: "Define sobre qué eventos deseas enviar correo a tus clientes",
            title: "Notificaciones de rastreo",
            subtitle: "Se enviará un correo notificando al comprador sobre el estado de envío del pedido, siempre y cuando la notificación esté activa en este panel.",
            save: "Guardar cambios",
            saving: "Guardando cambios",
            cancel: "Cancelar",
            tracking_type: "Correos regulares",
            shipping_incident_type: "Correos ocasionales",
            reported_shipping_incident_to_carrier_type: "Sobre incidentes reportados a la paquetería",
            reported_to_carrier_and_resolved_by_carrier: "Incidente reportado a la paquetería",
            reported_to_carrier_and_resolved_by_carrier_description: "Se enviará un correo cuando se crea y se resuelve un incidente",
            click_and_collect_type: "Click n Collect",
            in_transit_to_pickup_point: "En tránsito al punto de recolección",
            at_pickup_point: "En punto de recolección",
            packed: "Empacado, esperando recolección",
            packed_description: "La integración también envía una notificación a tu cliente sobre este evento",
            in_transit: "En tránsito",
            out_for_delivery: "En reparto",
            delivered: "Entregado",
            returned: "Retornado",
            failed_attempt: "Intento de entrega no exitoso",
            to_be_self_collected: "Recolectar en oficina",
            cancelled: "Entrega cancelada",
            stolen: "Pedido robado",
            lost: "Pedido extraviado",
            send: "Enviar",
            send_test: "Enviar prueba",
            test_sent: "Se ha enviado un correo de prueba a tu correo",
            test_error: "No se ha podido enviar el correo de prueba",
            invalid_email: " no es un correo válido",
            update_success: "Se han actualizado las notificaciones",
            update_error: "No se han podido actualizar las notificaciones",
        },

        user_settings: {
            success_message: "Usuario registrado exitosamente",
            error_message: "El registro del usuario es inválido",
            title: "Usuarios",
            subtitle: "Invitar miembros a tu tienda",
            required_field: "Este campo es requerido",
            invalid_email: "Dirección inválida",
            processing: "Procesando tu solicitud...",
            invite_user: "Invitar usuario",
            error: "Error",
            done: "¡Listo!",
            actual_users: "Usuarios actuales:",
            invite_user_placeholder: "Escribe un correo",
            disable_user_errors: {
                UNAUTHORIZED: "No estás autorizado a realizar esta acción"
            },
            invite_user_errors: {
                INVALID_EMAIL: "Este correo electrónico es inválido",
                EMAIL_EXISTS: "Este correo electrónico ya ha sido registrado en Cubbo",
                DEFAULT: "Ocurrió un error inesperado. Póngase en contacto con soporte técnico"
            },
            list: {
                name: "Nombre",
                email: "Correo",
                disable_user_action: "Deshabilitar usuario",
                no_users_to_show: "No hay usuarios que mostrar",
                users: "usuarios"
            },
            disable_user_dialog: {
                title: "Deshabilitar usuario",
                description: "¿Estás seguro de deshabilitar el usuario {{user}}?",
            }
        },

        tax_settings: {
            tax_config: "Facturación",
            description: "Configura la información necesaria para emitir facturas de tu tienda"
        },

        tax_fields: {
            success_message: "Tu informacion fue actualizada correctamente",
            error_message: "La información es inválida",
            processing: "Procesando",
            tax_id: "ID fiscal", 
            tax_regime: "Régimen fiscal ",
            tax_company_name: "Nombre fiscal de la compañía",
            tax_company_name_short: "Compañía ",
            tax_address: "Dirección fiscal",
            tax_address_number: "Número de la dirección fiscal",
            tax_address_neighborhood: "Vecindario de la dirección fiscal",
            tax_city: "Ciudad fiscal",
            state: "Estado fiscal",
            tax_zipcode: "Código Postal fiscal",
            tax_state_registration: "Código de registro estatal ",
            tax_state_registration_short: "Número de registro estatal ",
            tax_state_phone: "Teléfono fiscal",
            send_form: "Guardar cambios",
            tax_regime_physical: "Físico",
            tax_regime_legal: "Legal"
        },

        billing_config:{
            filscal_data: "Datos Fiscales",
            filscal_data_description: "Edita la información fiscal de tu tienda.",
            ncms_settings: "Configuración NCMs",
            ncms_settings_description: "Añade a tu tienda los NCMs que correspondan a tus productos y personaliza sus impuestos.",
            operation_nature: "Personaliza la naturaleza de operación",
            operation_nature_description: "Edita la naturaleza de operación de cada tipo de factura.",
            observation_invoice: "Agrega observaciones a facturas",
            observation_invoice_description: "Añade observaciones a tus facturas según el tipo de facturas que sea.",
            optional: "(Opcional)"
        },

        types_invoices:{
            symbolic_return: "Retorno simbólico de producto",
            remove_stock: "Remover stock",
            sale: "Venta de productos",
            donation: "Donación de productos",
            return_donation: "Retorno de donaciones",
            return: "Retorno de productos",
            replenishment: "Recibo de inventario",
            replenishment_adjustment: "Ajuste de recibo de inventario"

        },

        customize_bills:{
            orders_invocices_title:"Posibles facturas emitidas durante un pedido",
            return_invocices_title:"Facturas emitidas durante un retorno",
            replenishment_invocices_title:"Posibles facturas emitidas durante un recibo de inventario",
            symbolic_return_description: "Regresamos simbólicamente los productos a tu tienda liberando disponibilidad para su venta.",
            sale_description: "Se vendieron productos a tu cliente.",
            donation_description: "Se donaron productos a tu cliente.",
            remove_stock_description: "Retirarás parte de tu inventario de Cubbo.",
            return_description: "Tu cliente devolverá los productos de su pedido a tu tienda.",
            replenishment_description: "Traslado de inventario de la tienda a Cubbo.",
            replenishment_adjustment_description: "Cubbo recibió menos productos de los esperados en el recibo, por lo que se emitirá una factura declarando el retorno de los productos faltantes a tu tienda.",
            invoice_table_title: "FACTURA",
            operation_nature_table_title: "NATURALEZA DE OPERACIÓN",
            reason_table_title: "MOTIVO DE EMISIÓN",
            observation_table_title: "OBSERVACIÓN",
        },
        validate_changes:{
            title: "¿Desea salir?",
            question: "Se perderán tus cambios",
            saved_data: "Datos guardados",
            unsaved_data: "Datos sin guardar",
            yes: "Si",
            no: "No",
        },

        ncm_configurations:{
            tax_rate: "Alícuota",
            tax_rate_credit: "Alícuota de crédito",
            tax_regime_store : "Régimen fiscal de tu tienda",
            more_matches: "Existen más coincidencias... ",
            taxes: "Impuestos",
            tax_situation: "Situación tributaria",
            tax_situation_other_state: "Situación tributaria para pedidos interestaduales",
            tax_situation_international: "Situación tributaria para pedidos internacionales",
            order_invoices: "Facturas de pedido",
            return_invoices: "Facturas de retorno",
            replenihsment_invoices: "Facturas de recibos",
            same_state_entity: "Misma entidad federativa",
            other_state_entity: "Distinta entidad federativa",
            international: "Internacional",
            framing_code: "Codigo encuadramiento",
            complete_data_message: "Completa todos los campos antes de guardar.",
            cancel: "Cancelar",
            edit_ncm: "Editar NCM",
            new_ncm: "Configuración de nuevo NCM",
            parameter_configuration: "Configuración de parámetros del impuesto",
            dont_apply: "No aplica",
            ncm_configurations_store: "NCMs configurados en tu tienda:",
            no_ncms_configured: "Aún no tienes NCMs configurados.",
            invoice_table_title: "FACTURA",
            add_ncm:"Agregar NCM",
            edit_tax: "Editar impuesto",
            nfs_note: "(Nota: El cfop en las facturas de retorno simbólico, no es personalizable. Te mostramos el dato como informativo.)",
            note: "(Nota: El cfop en las facturas de recibo, no es personalizable. Te mostramos el dato como informativo.)",
            origin: "Origen"
        }
    },
    filter: {
        button: "Filtrar",
        reset: "Limpiar filtros",
        select: "Selecciona un estado",
        from_to: "de-hasta",
        export: "Exportar",
        dropdownExport: {
            breakdown_by_product: "Desglose por producto",
            breakdown_by_order: "Desglose por pedido" 
        }
    },
    generic: {
        order: "Pedido",
        orders: "Pedidos",
        error: "Error",
    },
    dialog: {
        yes: "Si",
        no: "No",
        cancel: "Cancelar"
    },
    export_notification: {
        generating: "Generando reporte",
        generated: "Reporte generado",
        download: "Descargar"
    },
    success_notification: {
        check_your_email: "¡Revisa tu email!",
        instructions_with_password: "Te hemos enviado un email con instrucciones para resetear tu contraseña."
    },
    work_orders: {
        button_navigation: "Ir a sección de maquilas",
        work_orders: "Maquilas",
        button: "Crear maquila",
        path: "Crear maquila",
        title: "Estas creando una solicitud de una maquila",
        title_preview: "Estás a punto de crear esta solicitud de maquila",
        no_work_orders: "No hay maquilas que mostrar",
        all_stores: "Todas las tiendas",
        replenishment_id: "Id de recibo de inventario",
        rem_order_id: "Id de retiro de inventario",
        on_date: "el",
        header: {
            id: "Nº maquila",
            work_order_types: "Tipo de maquila",
            statuses: 'Estado',
            work_order_histories: "Resumen de maquila",
            filter: "Filtrar",
            reset_filters: "Limpiar filtros"
        },
        subheader: {
            id: "Notas",
            work_order_types: "Origen de las piezas",
            statuses: "Observaciones",
            work_order_histories: ""
        },
        footer: {
            total: "Total",
            work_orders: "maquilas"
        },
        work_order_statuses: {
            REQUESTED: {
                title: "Solicitada",
                description: "En máximo 72 horas revisaremos tu solicitud" 
            },
            IN_VALIDATION: {
                title: "En validación",
                description: "Nos aseguraremos de contar con todos los recursos necesarios para maquilar" 
            },
            SCHEDULED: {
                title: "Próxima a iniciar",
                description1: "Tu maquila fue aprobada y se encuentra en cola para ser procesada.",
                description2: "Tendrá un costo estimado de",
                description3: "El costo se definirá al finalizar la maquila"
            },
            IN_PROGRESS: {
                title: "En progreso",
                description1: "Hay",
                description2: "personas asignadas a tu maquila, verás las piezas maquiladas ingresar a tu inventario mediante el recibo"
            },
            COMPLETED: {
                title: "Completada",
                description1: "Se maquilaron el total de piezas requeridas",
                description2: "el costo de esta maquila será de",
                description3: "Se ingresarán las piezas en el recibo de inventario"
            },
            REJECTED: {
                title: "Rechazado"
            },
            CANCELED: {
                title:"Cancelada"
            }
        },
        filters: {
            reset_filters: "Limpiar filtros",
            search: {
                id: "Nº maquila",
                work_order_types: "Tipo de maquila",
                statuses: "Estado"
            },
            id: "Nº maquila",
            statuses: "Selecciona un estado",
            work_order_types: "Selecciona un tipo de maquila"
        },
        cancel_creation: {
            button: "Descartar",
            dialog_title: "Se perderá la información",
            dialog_description: "¿Estas seguro de que deseas salir sin guardar?",
            dialog_confirm_button: "Si, deseo salir",
            dialog_cancel_button: "No, regresar a editar",
            on_cancel: "Creación de maquila cancelada"
        },
        create_work_order: {
            preview_work_order_button: "Previsualizar solicitud",
            save_work_order_button: "Crear maquila",
            edit_work_order_button: "Regresar a editar",
            dialog_title: "Crear maquila",
            dialog_description: "¿Estás seguro de que deseas crear la maquila?",
            dialog_confirm_button: "Sí, deseo crearla",
            dialog_cancel_button: "No, regresar a editar",
            on_error: "Ocurrió un error, intenta mas tarde",
            on_success: "Se creó la maquila"
        },
        confirm_work_order: {
            success: "Se creó la maquila",
            error: "Ocurrió un error al crear la maquila, intente más tarde"
        },
        work_order_detail: {
            title: "Ver solicitud de maquila",
            notes: "Notas",
            order_details: {
                title: "Detalles de solicitud",
                work_order_id: "Nº de maquila",
                solicitant: "Solicitante",
                work_order_status: "Estado de maquila"
            },
            product_origin: {
                title: "Origen del material requerido",
                rem_order_id: "Retiro de inventario",
                no_rem_order: "Esta maquila no tiene pedido de retiro de inventario"
            },
            work_order_info: {
                title: "Solicitud de maquila",
                work_order_type: "Tipo de proceso",
                notes: "Notas",
                instruction: "Instrucción",
                activity: "Actividad"
            },
            replenishment: {
                title: "Ingreso de producto maquilado",
                replenishment_id: "Recibo de invetario de producto",
                replenishment_note: "Desde este recibo de inventario podrás ver el progreso del ingreso a tu inventario del producto maquilado"
            }
        },
        creation_sections: {
            work_order_type: {
                title: "Tipo de maquila",
                select_work_order: {
                    title: "Selecciona la maquila que requieres",
                    description: "Considera que todo el material requerido tendrá que ser proporcionado al equipo de operaciones para su procesamiento",
                    options: {
                        LABELING_PLASTISEAL: {
                            title: "De etiquetado o plastifechado",
                            subtitle: "Reetiquetar y/o sellar una bolsa con calor"
                        },
                        PRODUCT_VALIDATION: {
                            title: "De validación de producto",
                            subtitle: "Contar productos o checar estado de los mismos"
                        },
                        KIT_ASSEMBLY_SHRINKWRAP: {
                            title: "De armado de kits y/o aplicación de termoencogido",
                            subtitle: "Empacar más de un productos en la misma unidad y/o se aplica una película plástica"
                        },
                        PACKAGING: {
                            title: "De empaquetado",
                            subtitle: "Colocar un producto dentro de su envase"
                        },
                        PRODUCT_SEGREGATION: {
                            title: "De segregación de producto",
                            subtitle: "Separar más de un SKU para su almacenamiento"
                        },
                    }
                }
            },
            required_material: {
                title: "Material requerido",
                product_origin: {
                    title: "Origen de los productos e injertos requeridos",
                    description: "Cómo va a enviarnos el material necesario para completar la maquila",
                    options: {
                        ALL_INVENTORY_AT_WAREHOUSE: {
                            title: "Todo el material se encuentra en mi inventario almacenado en Cubbo disponible para vender",
                            completed_description: "El inventario se encuentra almacenado en Cubbo y se retirará mediante el"
                        },
                        MATERIAL_IN_TRANSIT_TO_WAREHOUSE: {
                            title: "El material va en camino al almacén",
                            completed_description: "El material va en camino al almacén"
                        },
                        PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE: {
                            title: "Parte del material se encuentra almacenado en Cubbo y otra parte va en camino al almacén",
                            completed_description: "Parte del material se encuentra almacenado en Cubbo y se retirará mediante el"
                        }
                    }
                },
                removal_order: {
                    title: "Id del pedido de retiro de inventario del material requerido",
                    description: "Escribe el número del pedido en donde nos indicas que productos retirar de tu inventario para maquilarlos",
                    create_rem_order: "Aún no creo el pedido de retiro de inventario",
                    removal_order: "retiro de inventario",
                    continue_button: "Continuar para definir instrucciones de maquila"
                }
            },
            work_order_instructions: {
                title: "Instrucciones para maquilar",
                download_file: {
                    title: "Descarga este archivo y rellénalo con los detalles de tu solicitud",
                    subtitle: "Considera que todo el material requerido tendrá que ser proporcionado al equipo de operaciones para su procesamiento",
                    files_placeholder: "Presiona aquí o arrastra tus archivos PDF o Excel o XML",
                    attach_document: "Adjunta el archivo de instrucciones",
                    notes: "Notas sobre tu maquila",
                    completed_description_notes: "Notas",
                    continue_button: "Continuar para registrar el ingreso del producto maquilado",
                    file_name: "Adjunta un instructivo detallado de tu empaque con imágenes de ejemplo",
                    instructions_name: "Manual de solicitud de maquilas "
                }
            },
            work_order_replenishment: {
                title: "Ingreso de maquila completada",
                replenishment: {
                    title: "Id del recibo de inventario del producto maquilado",
                    description: "Escribe el número de inventario en donde registraremos el ingreso del producto maquilado",
                    completed_description_entry: "El ingreso de los productos maquilados se registrará mediante el",
                    completed_description_replenishment: "recibo de inventario",
                    create_replenishment: "Aún no creo el recibo de inventario"
                }
            }
        }
    },

    billing: {
        page_title: "Facturación",
        download_report: "Descarga este reporte aquí",
        downloading_report: "Descargando reporte...",
        statement: {
            cutoff_at: "Corte: {DATE}",
            select_statement: "Ver periodo",
            options: {
                LOADING: "Cargando periodos...",
                CURRENT: "Del {FIRST_DATE} al día de hoy",
                FIRST: "Primer corte al {LAST_DATE}",
                NORMAL: "Del {FIRST_DATE} al {LAST_DATE}",
                SHORTENED: "Del {FIRST_DAY} al {LAST_DAY} de {MONTH}"
            },
            export_statement_summary: "Exportar resumen",
            exporting_statement_summary: "Exportando resumen",
            export_success_statement_summary: "Exportación de resumen completa",
            export_error_statement_summary: "Ocurrió un error al exportar resumen, intentalo más tarde",
            export_services_summary: "Exportar CSV",
            exporting_services_summary: "Exportando CSV",
            export_success_services_summary: "Exportación CSV completa",
            export_error_services_summary: "Ocurrió un error al exportar CSV, intentalo más tarde"
        },
        totals: {
            statement_summary: "Resumen del corte",
            total: "Total a pagar",
            total_to_date: "Monto total al día de hoy",
            important: "Importante: ",
            no_statement: "Los saldos totales definitivos se verán reflejados hasta la fecha de corte.",
            payment_limit_date: "Fecha límite de pago",
            next_cutoff_date: "Fecha de corte",
            charges_without_taxes: "Cargos sin IVA",
            charges: "Cargos",
            total_discount: "Saldo a favor",
            tax_IVA: "IVA",
            percentage_IVA: "% del total"
        },
        averages: {
            order_fulfillment: "Fulfillment de pedido",
            dtc_orders: "Fulfillment de pedido<br/><strong>Direct to Consumer</strong>",
            marketplace_orders: "Fulfillment de pedido<br/> de <strong>Marketplace</strong>",
            shipping: "Envíos",
            simple_returns: "Retornos por devolución",
            carrier_returns: "Retornos por paquetería",
            extended_zone: "Zona extendida",
            pick_and_pack: "Pick & Pack",
            package: "Empaque",
            difal: "Difal",
            shipping_insurance: "Seguro de envío",
            average_costs_per_order: "Costos promedio por pedido antes de impuestos",
            average_costs_per_order_without_taxes: "Costos promedio por pedido",
            additional_services: "Servicios adicionales",
            order_average_description: "El promedio no incluye costos asociados a pedidos de retiros de inventario, maquilas, recibos, almacenamiento, cargos adicionales y saldos a favor.",
            average_with_additional_services: "con servicios adicionales",
            averages_descriptions: {
                simple_returns: "Costos asociados a recibo de piezas y logística inversa.",
                carrier_returns: "Costos asociados a recibo de piezas y logística inversa.",
                pick_and_pack: "Costos asociados a picking de productos, agregar injertos y control de salida.",
                package: "Costos asociados a cajas, materiales de empaque y embalaje frágil."
            }
        },
        currencies:{
            MXN: "MXN",
            BRL: "BRL"
        },
        summaries_options: {
            SERVICES_SUMMARY: "Desglose por concepto",
            ORDER_DETAIL: "Detalle de pedidos",
            RETURN_DETAIL: "Detalle de retornos",
            CLAIM_DETAIL: "Detalle de disputas"
        },
        services_summary_table: {
            not_available: "No disponible",
            no_data: "No existe información disponible",
            volumetric_weight_unit: "{VOLUMETRIC_WEIGHT} kg volumétricos",
            headers: {
                TITLE: "Concepto",
                UNIT: "Unidad",
                UNIT_VALUE: "Valor unitario",
                QUANTITY: "Cantidad",
                TOTAL: "Total"
            },
            quantity_notes: {
                STORAGE_BY_LOCATION: "Promedio de uso",
                STORAGE_BY_PRODUCT: "Promedio de uso"
            },
            tables_sections: {
                PICK_AND_PACK: "Picking",
                PICK_AND_PACK_INSERT: "Añadir un injerto",
                PICK_AND_PACK_SERIAL_NUMBER: "Control de salida por número de serie",
                PICK_AND_PACK_FRAGILE: "Embalaje de paquete frágil",
                PICK_AND_PACK_PACKAGE: {
                    parcel_bag: "Empaque - Bolsa de paquetería",
                    jiffy_envelope: "Empaque - Sobre Jiffy",
                    cubbo_box: "Empaque - Caja Cubbo",
                    customer_box: "Empaque - Caja cliente"
                },
                PACKING_MATERIAL: "Materiales de empaque",
                REPLENISHMENT: "Recibo de piezas",
                REPLENISHMENT_LOTS: "Recibo de piezas con control de lote",
                RETURN_ORDER: "Recibo de piezas retornadas",
                RETURN_ORDER_LOTS: "Recibo de piezas retornadas con control de lote",
                RETURN_SHIPPING_LABEL: "Guía de retorno",
                SHIPPING_LABEL_PICKUP: "Cargo por pedido",
                SHIPPING_LABEL: {
                    SP_CAP: "SP Capital",
                    SP_INT: "SP Interior",
                    RJ_CAP: "RJ Capital",
                    RJ_INT: "RJ Interior",
                    ES_CAP: "ES Capital",
                    ES_INT: "ES Interior",
                    MG_CAP: "MG Capital",
                    MG_INT: "MG Interior",
                    BA_CAP: "BA Capital",
                    BA_INT: "BA Interior",
                    SE_CAP: "SE Capital",
                    SE_INT: "SE Interior",
                    PE_CAP: "PE Capital",
                    PE_INT: "PE Interior",
                    AL_CAP: "AL Capital",
                    AL_INT: "AL Interior",
                    PB_CAP: "PB Capital",
                    PB_INT: "PB Interior",
                    RN_CAP: "RN Capital",
                    RN_INT: "RN Interior",
                    CE_CAP: "CE Capital",
                    CE_INT: "CE Interior",
                    PI_CAP: "PI Capital",
                    PI_INT: "PI Interior",
                    MA_CAP: "MA Capital",
                    MA_INT: "MA Interior",
                    PA_CAP: "PA Capital",
                    PA_INT: "PA Interior",
                    AP_CAP: "AP Capital",
                    AP_INT: "AP Interior",
                    AM_CAP: "AM Capital",
                    AM_INT: "AM Interior",
                    RR_CAP: "RR Capital",
                    RR_INT: "RR Interior",
                    AM_INT_2: "AM Interior 2",
                    AC_CAP: "AC Capital",
                    AC_INT: "AC Interior",
                    DF_CAP: "DF Capital",
                    DF_INT: "DF Interior",
                    GO_CAP: "GO Capital",
                    DF_INT_2: "DF Interior 2",
                    GO_CAP_2: "GO Capital 2",
                    GO_INT: "GO Interior",
                    RO_CAP: "RO Capital",
                    RO_INT: "RO Interior",
                    TO_CAP: "TO Capital",
                    TO_INT: "TO Interior",
                    MT_CAP: "MT Capital",
                    MT_INT: "MT Interior",
                    RO_INT_2: "RO Interior 2",
                    RO_INT_3: "RO Interior 3",
                    MS_CAP: "MS Capital",
                    MS_INT: "MS Interior",
                    PR_CAP: "PR Capital",
                    PR_INT: "PR Interior",
                    SC_CAP: "SC Capital",
                    SC_INT: "SC Interior",
                    RS_CAP: "RS Capital",
                    RS_INT: "RS Interior",
                    SUPER_SAVER: "SUPER SAVER",
                    LOCAL: "LOCAL",
                    PREMIUM: "PREMIUM",
                    ECONOMY: "ECONOMY"
                },
                SHIPPING_LABEL_EXTENDED_ZONE: {
                    SUPER_SAVER: "Zona extendida SUPER SAVER",
                    LOCAL: "Zona extendida LOCAL",
                    PREMIUM: "Zona extendida PREMIUM",
                    ECONOMY: "Zona extendida ECONOMY"
                },
                STORAGE_BY_LOCATION: "Almacenamiento",
                STORAGE_BY_PRODUCT: "Almacenamiento",
                INTERNATIONAL_SHIPPING_LABEL: {
                    NORTH_AMERICA: "Norte América",
                    LATIN_AMERICA: "América Latina",
                    CARIBBEAN: "Caribe",
                    EUROPE: "Europa",
                    ASIA: "Asia",
                    AFRICA: "África"
                },
                CLAIM: "Disputas",
                UNIDENTIFIED_RETURN: "Retornos no identificados",
                SHIPMENTS_RETURN_BY_CARRIER: "Guía de retorno por paquetería",
                SHIPPING_INSURANCE: "del valor del pedido"
            },
            tables_titles: {
                PICK_AND_PACK: "Pick and pack",
                PICK_AND_PACK_PACKAGE: "Empaque",
                REPLENISHMENT: "Recibo",
                RETURN: "Retornos",
                SHIPPING_LABEL: "Generación de guías",
                STORAGE_BY_LOCATION: "Almacenamiento",
                STORAGE_BY_PRODUCT: "Almacenamiento",
                INTERNATIONAL_SHIPPING_LABEL: "Generación de guías internacionales",
                DIFAL: "Difal",
                SHIPPING_INSURANCE: "Seguro de envío",
                OTHERS: "Otros",
                CREDIT: "Saldo a favor"
            },
            no_packages: "Sin paquetes",
            units: {
                STORAGE_BY_LOCATION: {
                    PER_UNIT: "Cargo por ubicación ocupada"
                },
                STORAGE_BY_PRODUCT: {
                    PER_UNIT: "Cargo por pieza almacenada"
                },
                SHIPPING_LABEL_PICKUP: {
                    TOTAL: "Cargo por pedido"
                },
                SHIPPING_LABEL: {
                    TOTAL: "Cargo por guía",
                    PER_UNIT_EXCESS: "Cargo por guía"
                },
                SHIPPING_LABEL_EXTENDED_ZONE: {
                    TOTAL: "Cargo por guía",
                },
                INTERNATIONAL_SHIPPING_LABEL: "Cargo por envío internacional",
                RETURN_ORDER: {
                    PER_UNIT: "Cargo por pieza retornada",
                    TOTAL: "Cargo por retorno",
                    PER_UNIT_EXCESS: "Cargo por pieza retornada",
                    TOTAL_EXCESS: "Cargo por retorno"
                },
                RETURN_ORDER_LOTS: {
                    PER_UNIT: "Cargo por pieza retornada",
                    TOTAL: "Cargo por retorno",
                    PER_UNIT_EXCESS: "Cargo por pieza retornada",
                    TOTAL_EXCESS: "Cargo por retorno"
                },
                RETURN_SHIPPING_LABEL: {
                    TOTAL: "Cargo por guía",
                    PER_UNIT_EXCESS: "Cargo por guía"
                },
                PICK_AND_PACK: {
                    PER_UNIT: "Cargo por pieza",
                    TOTAL: "Cargo por pedido",
                    PER_UNIT_EXCESS: "Cargo por pieza",
                    TOTAL_EXCESS: "Cargo por pedido"
                },
                PICK_AND_PACK_INSERT: {
                    PER_UNIT: "Cargo por pieza",
                    TOTAL: "Cargo por pedido",
                    PER_UNIT_EXCESS: "Cargo por pieza",
                    TOTAL_EXCESS: "Cargo por pedido"
                },
                PICK_AND_PACK_SERIAL_NUMBER: {
                    PER_UNIT: "Cargo por pieza",
                    TOTAL: "Cargo por pedido",
                    PER_UNIT_EXCESS: "Cargo por pieza",
                    TOTAL_EXCESS: "Cargo por pedido"
                },
                PICK_AND_PACK_FRAGILE: {
                    TOTAL: "Cargo por paquete"
                },
                PICK_AND_PACK_PACKAGE: {
                    TOTAL: "Cargo por paquete",
                    TOTAL_EXCESS: "",
                },
                REPLENISHMENT: {
                    PER_UNIT: "Cargo por pieza recibida",
                    TOTAL: "Cargo por recibo",
                    PER_UNIT_EXCESS: "Cargo por pieza recibida",
                    TOTAL_EXCESS: "Cargo por recibo",
                },
                REPLENISHMENT_LOTS: {
                    PER_UNIT: "Cargo por pieza recibida",
                    TOTAL: "Cargo por recibo",
                    PER_UNIT_EXCESS: "Cargo por pieza recibida",
                    TOTAL_EXCESS: "Cargo por recibo",
                },
                CLAIM: {
                    PER_UNIT: "Por disputa"
                },
                CUSTOM: {
                    TOTAL_DISCOUNT: "Descuento único",
                    TOTAL_CHARGE: "Cargo único"
                },
                REFUND: "Reembolso único",
                WORK_ORDER: {
                    PER_UNIT: "Personas por día"
                },
                UNIDENTIFIED_RETURN: {
                    TOTAL: "Cargo por pedido",
                },
            },
            usage_ranges_details: {
                general: {
                    PIECES: {
                        singular_from: "más de {FROM} pieza",
                        plural_from: "más de {FROM} piezas",
                        singular_from_to: "de {FROM} a {TO} pieza",
                        plural_from_to: "de {FROM} a {TO} piezas"
                    },
                    ORDERS_PIECES: {
                        singular_from: "pedidos con más de {FROM} pieza",
                        plural_from: "pedidos con más de {FROM} piezas",
                        singular_from_to: "pedidos con {FROM} a {TO} pieza",
                        plural_from_to: "pedidos con {FROM} a {TO} piezas"
                    },
                    WEIGHT: {
                        singular_from: "más de {FROM} kg",
                        plural_from: "más de {FROM} kg",
                        singular_from_to: "de {FROM} kg a {TO} kg",
                        plural_from_to: "de {FROM} kg a {TO} kg"
                    },
                    ORDERS_INSERTS: {
                        singular_from: "pedidos con más de {FROM} injerto",
                        plural_from: "pedidos con más de {FROM} injertos",
                        singular_from_to: "pedidos con {FROM} a {TO} injerto",
                        plural_from_to: "pedidos con {FROM} a {TO} injertos",
                    }
                },
                PICK_AND_PACK_FRAGILE: "Insumos de empaque frágil",
                SHIPPING_LABEL_PICKUP: "Recoger en almacén / Guía externa",
                SHIPPING_LABEL_EXTENDED_ZONE: {
                    SUPER_SAVER: "SUPER SAVER Zona extendida",
                    LOCAL: "LOCAL Zona extendida",
                    PREMIUM: "PREMIUM Zona extendida",
                    ECONOMY: "ECONOMY Zona extendida"
                },
                STORAGE_BY_LOCATION: {
                    LOST: "Lost",
                    PALLET: "Pallet",
                    RECEPTION: "Recepción",
                    PRESALE: "Preventa",
                    RELOCATION: "Reubicación",
                    OFF_PREMISES: "Off Premises",
                    PICKING_MINIBIN: "Minibin",
                    PICKING_STANDARD: "Regular",
                    DAMAGED: "Dañados"
                },
                STORAGE_BY_PRODUCT: {
                    LOST: "Lost",
                    PALLET: "Pallet",
                    RECEPTION: "Recepción",
                    PRESALE: "Preventa",
                    RELOCATION: "Reubicación",
                    OFF_PREMISES: "Off Premises",
                    PICKING_MINIBIN: "Minibin",
                    PICKING_STANDARD: "Regular",
                    DAMAGED: "Dañados"
                },
                INTERNATIONAL_SHIPPING_LABEL: {
                    NORTH_AMERICA: "Norte América",
                    LATIN_AMERICA: "América Latina",
                    CARIBBEAN: "Caribe",
                    EUROPE: "Europa",
                    ASIA: "Asia",
                    AFRICA: "África"
                },
                CLAIM: "Disputas",
                WORK_ORDER: "Maquilas",
                UNIDENTIFIED_RETURN: "Retornos no identificados",
                DIFAL: "Difal",
                SHIPPING_INSURANCE: "Seguro de envío",
                PACKING_MATERIAL: {
                    BUBBLE_WRAP: "Plástico burbuja",
                    KRAFT_PAPER: "Papel Kraft",
                    CORN_EXTRUDATE: "Extruido de maíz",
                }
            }
        },
        orders_detail: {
            order_number: "Nº de pedido | Canal de venta",
            order_destination: "Destino",
            shipping_method: "Tipo de envío | Paquetería",
            pick_and_pack: "Pick and pack | Conceptos",
            shipping: "Envío | Conceptos",
            total: "Total",
            no_data: "No hay pedidos que mostrar",
            footer: "pedidos",
            packages: "paquetes",
            package: "paquete",
            cubbo: "Cubbo",
            box: "caja",
            boxes: "cajas",
            shipping_coverage_categories: {
                SP_CAP: "SP Capital",
                SP_INT: "SP Interior",
                RJ_CAP: "RJ Capital",
                RJ_INT: "RJ Interior",
                ES_CAP: "ES Capital",
                ES_INT: "ES Interior",
                MG_CAP: "MG Capital",
                MG_INT: "MG Interior",
                BA_CAP: "BA Capital",
                BA_INT: "BA Interior",
                SE_CAP: "SE Capital",
                SE_INT: "SE Interior",
                PE_CAP: "PE Capital",
                PE_INT: "PE Interior",
                AL_CAP: "AL Capital",
                AL_INT: "AL Interior",
                PB_CAP: "PB Capital",
                PB_INT: "PB Interior",
                RN_CAP: "RN Capital",
                RN_INT: "RN Interior",
                CE_CAP: "CE Capital",
                CE_INT: "CE Interior",
                PI_CAP: "PI Capital",
                PI_INT: "PI Interior",
                MA_CAP: "MA Capital",
                MA_INT: "MA Interior",
                PA_CAP: "PA Capital",
                PA_INT: "PA Interior",
                AP_CAP: "AP Capital",
                AP_INT: "AP Interior",
                AM_CAP: "AM Capital",
                AM_INT: "AM Interior",
                RR_CAP: "RR Capital",
                RR_INT: "RR Interior",
                AM_INT_2: "AM Interior 2",
                AC_CAP: "AC Capital",
                AC_INT: "AC Interior",
                DF_CAP: "DF Capital",
                DF_INT: "DF Interior",
                GO_CAP: "GO Capital",
                DF_INT_2: "DF Interior 2",
                GO_CAP_2: "GO Capital 2",
                GO_INT: "GO Interior",
                RO_CAP: "RO Capital",
                RO_INT: "RO Interior",
                TO_CAP: "TO Capital",
                TO_INT: "TO Interior",
                MT_CAP: "MT Capital",
                MT_INT: "MT Interior",
                RO_INT_2: "RO Interior 2",
                RO_INT_3: "RO Interior 3",
                MS_CAP: "MS Capital",
                MS_INT: "MS Interior",
                PR_CAP: "PR Capital",
                PR_INT: "PR Interior",
                SC_CAP: "SC Capital",
                SC_INT: "SC Interior",
                RS_CAP: "RS Capital",
                RS_INT: "RS Interior",
                ECONOMY: "Economy",
                LOCAL: "Local",
                SUPER_SAVER: "Super saver",
                PREMIUM: "Premium",
                NONE_APPLIES: "No aplica"
            },
            order_destination_categories: {
                national: "Nacional",
                international: "Internacional",
                extended_zone: "En zona extendida"
            },
            picking_and_packing:{
                products_quantity: "piezas pickeadas",
                product_quantity: "pieza pickeada",
                inserts_quantity: "injertos añadidos",
                insert_quantity: "injerto añadido",
                output_control_products_quantity: "productos con control de salida",
                output_control_product_quantity: "producto con control de salida",
                fragiles_quantity: "frágiles",
                fragile_quantity: "frágil",
            },
            filters: {
                order_number: "Número de pedido"
            }
        }
    }
}